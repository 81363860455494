import { t } from '@nodal/i18n';
import { forwardRef } from 'react';

import { ContractForm } from 'components/ContractForm';

import type { PaymentContractProps } from 'components/ContractForm';

export const PaymentContract = forwardRef<HTMLDivElement, PaymentContractProps>(
  ({ signed }, ref) => (
    <div className="space-y-4 w-full h-full">
      <div className="py-8 px-6 space-y-6 bg-white rounded-lg border border-grey-forest-200">
        <div className="flex flex-col gap-4">
          <h3 className="text-2xl font-semibold leading-8 text-grey-forest-900">
            {t('Nodal Contract')}
          </h3>
          <p className="text-base font-normal leading-6 text-sand-900">
            {t('Please sign and submit the following forms')}
          </p>
        </div>
        <ContractForm signed={signed} ref={ref} />
      </div>
    </div>
  ),
);

PaymentContract.displayName = 'PaymentContract';
