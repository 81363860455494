import { useCandidate } from 'components/CandidateDetails';

import { usePermissions } from '@b2b/app/PermissionsProvider';

import { MedicalReview } from './MedicalReview';
import { getMedicalReviewStagesBasedOn } from './stages';

export const MedicalReviewConnected = () => {
  const { permissions } = usePermissions();
  const { candidate } = useCandidate();

  if (!candidate?.role || !permissions) {
    return null;
  }

  const stages = getMedicalReviewStagesBasedOn({
    role: candidate.role,
    canInitiateMedicalRequest: permissions.canInitiateMedicalRequest,
    canEditMedicalFiles: permissions.canEditMedicalFiles,
  });

  return (
    <MedicalReview
      stages={stages}
      supervisionAccess={!!permissions?.canInitiateMedicalRequest}
    />
  );
};
