import { apiEnums } from '@nodal/api';
import { useUploadMedicalDocuments } from '@nodal/core/hooks/useUploadMedicalDocuments';
import { useConfirmationDialog } from '@nodal/uikit/components/ConfirmationDialog';

import { useCandidate } from '@b2b/components/CandidateDetails';

import { ClinicRecords } from './ClinicRecords';

export const ClinicRecordsConnected = () => {
  const { candidate } = useCandidate();
  const confirm = useConfirmationDialog();

  const medicalReviewId = candidate?.medical_review?.id;

  const clinicalRecords = candidate?.medical_review?.documents?.filter(
    ({ document_type }) =>
      document_type ===
        apiEnums.MedicalDocumentDocumentTypeEnum.ClinicalRecords ||
      document_type === apiEnums.MedicalDocumentDocumentTypeEnum.SurroDoc,
  );

  const { onDelete, onUpload, uploadedFiles, disabled } =
    useUploadMedicalDocuments({
      documents: clinicalRecords,
      confirm,
      documentType: apiEnums.MedicalDocumentDocumentTypeEnum.ClinicalRecords,
      medicalReviewId,
    });

  return (
    <ClinicRecords
      uploaded={uploadedFiles}
      onUpload={onUpload}
      onDelete={onDelete}
      disabled={disabled}
    />
  );
};
