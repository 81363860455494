import { useCandidate } from '@b2b/components/CandidateDetails';

import { roleToMedicalReviewsUids } from '../stages';

import { MfmClearance } from './MfmClearance';

export const MfmClearanceConnected = () => {
  const { candidate, onUpdateMedicalReview } = useCandidate();

  const mfmClearanceUid = roleToMedicalReviewsUids.get(
    candidate?.role,
  )?.mfmClearance;

  const { status, content_object, completion_date } =
    candidate?.medical_review?.steps?.find(
      ({ uid }) => uid === mfmClearanceUid,
    ) || {};

  if (!mfmClearanceUid || !candidate) {
    return null;
  }

  return (
    <MfmClearance
      status={status}
      details={content_object}
      onChange={(data) =>
        onUpdateMedicalReview(candidate.id, data, mfmClearanceUid)
      }
      completionDate={completion_date ? new Date(completion_date) : undefined}
    />
  );
};
