import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { buildDisplaySteps } from '@nodal/core/flows/Screening/utils';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { Steps } from '@nodal/uikit/components/Steps';
import classNames from 'classnames';
import { useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import { screeningPaths } from '@b2b/consts/paths';

import { BackgroundCheck } from './BackgroundCheck';
import { FormQuestions } from './FormQuestions';
import { HipaaConsentForms } from './HipaaConsentForms';
import { IdentityVerification } from './IdentityVerification';
import { MedicalRecordReview } from './MedicalRecordReview';
import { PersonalInformation } from './PersonalInformation';
import { ScreeningComplete } from './ScreeningComplete';

import type { ScreeningProps } from './Screening.interface';
import type { StepProps as Step } from '@nodal/uikit/components/Step';

export const Screening: FC<ScreeningProps> = ({
  steps: screeningSteps,
  activeStepIndex,
  role,
}) => {
  const params = useParams();
  const path = params['*'];

  const steps: Step[] = useMemo(
    () => buildDisplaySteps(screeningSteps, activeStepIndex),
    [screeningSteps, activeStepIndex],
  );

  const shouldStepsBeRendered =
    path !== '' &&
    path !== screeningPaths.complete &&
    activeStepIndex !== undefined;

  const screeningContainerClassName = classNames('w-full', {
    'flex flex-col flex-1 justify-center items-center': !shouldStepsBeRendered,
  });

  return (
    <div className={screeningContainerClassName}>
      <div className="flex flex-col gap-4 py-10 mx-4 max-w-full bg-transparent sm:flex sm:flex-row sm:gap-8 sm:mx-6 lg:mx-8 2xl:mx-auto 2xl:max-w-7xl">
        {shouldStepsBeRendered && (
          <div className="w-full h-full sm:w-72">
            <Steps steps={steps} activeIndex={activeStepIndex} />
          </div>
        )}
        <div className="flex-1 w-full">
          <Routes>
            <Route
              path={screeningPaths.profile}
              element={<PersonalInformation />}
            />
            {(role === apiEnums.UserRoleEnum.Oed ||
              role === apiEnums.UserRoleEnum.Osd ||
              role === apiEnums.UserRoleEnum.Odo) && (
              <Route
                path={screeningPaths.questions}
                element={<FormQuestions role={role} />}
              />
            )}
            <Route
              path={screeningPaths.medical}
              element={<MedicalRecordReview />}
            />
            <Route
              path={screeningPaths.complete}
              element={<ScreeningComplete />}
            />
            <Route
              path={screeningPaths.identity}
              element={<IdentityVerification />}
            />
            <Route
              path={screeningPaths.signing}
              element={<HipaaConsentForms />}
            />
            <Route
              path={screeningPaths.background}
              element={<BackgroundCheck />}
            />
            <Route path="*" element={<LoadingScreen />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};
