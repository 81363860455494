import type { FC } from 'react';

import { ChevronLeftIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';

import type { BackButtonProps } from './BackButton.interface';

export const BackButton: FC<BackButtonProps> = ({ title }) => {
  const navigate = useNavigate();
  const handleGoBack = () => navigate(-1);

  return (
    <button onClick={handleGoBack}>
      <div className="flex gap-2 items-center font-avenir-next text-sm font-medium leading-5 text-grey-forest-600">
        <ChevronLeftIcon className="aspect-square w-6 fill-current" />
        {title}
      </div>
    </button>
  );
};
