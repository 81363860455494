import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { Checkbox } from '@nodal/uikit/components/Checkbox/Checkbox';
import { Link } from 'react-router-dom';

import type { MedicalFormsProps } from './MedicalForms.interface';

export const MedicalForms: FC<MedicalFormsProps> = ({
  forms,
  hipaaConsentFormMeta: {
    manualScreenApproval: { onManualApprove, manualApprovalEnabled },
    manuallyChecked,
    status,
  },
}) => {
  return (
    <>
      <div className="w-full divide-y divide-grey-forest-100">
        {forms.map(({ title, label, url, disabled, path, hidden }) => (
          <div
            className="grid grid-cols-2 gap-4 justify-between py-2 whitespace-pre-wrap sm:grid-cols-3 sm:items-center"
            key={title}
          >
            <p className="col-start-1 text-sm text-grey-forest-700">{title}</p>
            <p className="col-start-2 ml-auto text-sm font-medium text-forest-500 sm:ml-0">
              {label}
            </p>
            {!hidden && (
              <div className="flex col-span-2 col-start-1 justify-center m-1 sm:col-span-1 sm:col-start-3 sm:justify-end">
                {path ? (
                  <Link to={path}>
                    <Button
                      variant="primary"
                      disabled={disabled}
                      className="sm:text-sm"
                    >
                      {t('View Record Form')}
                    </Button>
                  </Link>
                ) : (
                  <a href={url} rel="noreferrer" target="_blank" download>
                    <Button
                      variant="primary"
                      disabled={disabled}
                      className="sm:text-sm"
                    >
                      {t('Download {title}', title)}
                    </Button>
                  </a>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      {manualApprovalEnabled && (
        <div className="flex gap-6 mt-4 w-full sm:justify-end">
          <Checkbox
            label={t('HIPAA and Consent form were manually collected')}
            onChange={(e) =>
              onManualApprove(
                e.target.checked ? e.target.checked.toString() : undefined,
              )
            }
            id="hipaa_consent_forms_manual_approve"
            value={manuallyChecked ? manuallyChecked?.toString() : undefined}
            disabled={
              status === apiEnums.ScreenStatusEnum.App && !manuallyChecked
            }
          />
        </div>
      )}
    </>
  );
};
