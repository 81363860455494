import { apiEnums } from '@nodal/api';
import { placeSchema } from '@nodal/core/consts/placeSchema';
import { t } from '@nodal/i18n';
import * as Yup from 'yup';

import type { ApiModel } from '@nodal/api';

export const validationSchema = Yup.object({
  third_party_provider: Yup.string()
    .nullable()
    .required(t('This field is required')),
  first_name: Yup.string().nullable().required(t('This field is required')),
  last_name: Yup.string().nullable().required(t('This field is required')),
  date_of_birth: Yup.string().nullable().required(t('This field is required')),
  address: placeSchema,
  phone_number: Yup.number().nullable().required(t('This field is required')),
  delivery: Yup.string().nullable().required(t('This field is required')),
  data_provider_type: Yup.string()
    .nullable()
    .required(t('This field is required')),
  doctor_first_name: Yup.string()
    .nullable()
    .required(t('This field is required')),
  doctor_last_name: Yup.string()
    .nullable()
    .required(t('This field is required')),
  doctor_address: placeSchema,
  doctor_phone_number: Yup.number()
    .nullable()
    .required(t('This field is required')),
  fax_details: Yup.object().shape({
    prenatal_records_selected: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    delivery_records_selected: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    disease_records_selected: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    other_records_selected: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    other_records_details: Yup.string()
      .nullable()
      .when('has_other_records', {
        is: true,
        then: Yup.string().nullable().required(t('This field is required')),
      }),
    ob_clearance_letter_required: Yup.boolean()
      .nullable()
      .required(t('This field is required')),
    to_fax_number: Yup.number()
      .nullable()
      .when('fax_adapter_type', {
        is: apiEnums.FaxAdapterTypeEnum.Srfax,
        then: Yup.number().nullable().required(t('This field is required')),
      }),
    cp_from_name: Yup.string().nullable(),
    cp_to_name: Yup.string().nullable(),
    cp_organization: Yup.string().nullable(),
    cp_subject: Yup.string().nullable(),
    cp_comment: Yup.string().nullable(),
    to_email: Yup.string()
      .nullable()
      .when('fax_adapter_type', {
        is: apiEnums.FaxAdapterTypeEnum.Email,
        then: Yup.string()
          .nullable()
          .email(t('Enter a valid email address'))
          .required(t('This field is required')),
      }),
    fax_adapter_type: Yup.mixed<ApiModel.FaxAdapterTypeEnum>().required(
      t('This field is required'),
    ),
  }),
});
