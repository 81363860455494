import type { FC } from 'react';

import { MailIcon } from '@heroicons/react/outline';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { Link } from 'react-router-dom';

import { appPaths, organizationPaths as paths } from '@b2b/consts/paths';

import type { CandidateRequestSubmittedProps } from './CandidateRequestSubmitted.interface';

export const CandidateRequestSubmitted: FC<CandidateRequestSubmittedProps> = ({
  email,
}) => {
  return (
    <div className="flex gap-4 justify-center py-12 px-4 m-auto w-full max-w-5xl">
      <div className="flex flex-col gap-6 justify-center items-center">
        <MailIcon className="w-16 text-grey-forest-400 stroke-1" />
        <div className="text-center">
          <h2 className="mb-2 text-2xl font-semibold leading-8 text-grey-forest-900">
            {t('Candidate request submitted')}
          </h2>
          <p className="text-base font-normal leading-6 text-grey-forest-600">
            {t(
              'The new request has been submitted and the candidate will receive an email link to begin screening. Candidate status updates can be found in your dashboard and candidate detail view.',
            )}
          </p>
          <br />
          <p className="text-base font-normal leading-6 text-grey-forest-600">
            {t(
              'To finalize the request, the contract and payment link have been sent to',
            )}
            &nbsp;
            <span className="font-medium">{email}</span>.&nbsp;
          </p>
        </div>
        <Link to={`${paths.app}/${appPaths.candidates}`}>
          <Button variant="primary" className="justify-center">
            {t('Go to Dashboard')}
          </Button>
        </Link>
      </div>
    </div>
  );
};
