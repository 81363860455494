import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { appPaths, organizationPaths as paths } from 'consts/paths';

import { CandidateRequestSubmitted } from './CandidateRequestSubmitted';

import type { CandidateRequestSubmittedProps } from './CandidateRequestSubmitted.interface';

const useCandidateRequestSubmitted = () => {
  const navigate = useNavigate();
  const { state } = useLocation() as { state: CandidateRequestSubmittedProps };

  useEffect(() => {
    // NOTE: if the email is missing in the state, redirect it to the dashboard
    if (!state?.email) {
      navigate(`${paths.app}/${appPaths.candidates}`);
    }
  }, [state, navigate]);

  return { email: state?.email };
};

export const CandidateRequestSubmittedConnected = () => {
  const { email } = useCandidateRequestSubmitted();

  if (!email) return <LoadingScreen />;

  return <CandidateRequestSubmitted email={email} />;
};
