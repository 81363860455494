import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { pathsFrom, signupPaths } from '@b2b/consts/paths';

import { SignUpScreen } from './SignUpScreen';

import type { InvitationSignUpFormValues } from './SignUpScreen.interface';
import type { ApiModel, GlobalError } from '@nodal/api';
import type { FormikConfig, FormikHelpers } from 'formik';

const errorCodeToErrorMessage = new Map([
  ['invalid_invitation_id_enc', t('Invalid registration link')],
  ['invalid_invitation_email', t('Invalid registration email')],
  ['expired_invitation_id_enc', t('The registration link has expired')],
]);

const initialValues: InvitationSignUpFormValues = {
  email: '',
  password1: '',
  password2: '',
  agree_terms: false,
  non_field_errors: '',
  invitation_id_enc: '',
};

const validationSchema: Yup.SchemaOf<InvitationSignUpFormValues> =
  Yup.object().shape({
    email: Yup.string().required(t('Required field')),
    password1: Yup.string().required(t('Required field')),
    password2: Yup.string()
      .oneOf([Yup.ref('password1'), null], t('Passwords do not match'))
      .required(t('Required field')),
    agree_terms: Yup.boolean()
      .required()
      .oneOf([true], t('The Nodal Terms must be accepted')),
    non_field_errors: Yup.string().optional(),
    invitation_id_enc: Yup.string().optional(),
  });

const useInvitationSignUp = ({
  redirectPaths,
  invitationId,
  email,
}: {
  redirectPaths: { success: string };
  invitationId: string;
  email: string;
}): FormikConfig<InvitationSignUpFormValues> => {
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const register = useMutation(
    (payload: ApiModel.B2bApiB2bInvitationsFinishCreateRequest) =>
      apiClient.api.B2bApi.b2bInvitationsFinishCreate(payload),
  );

  const handleSubmit = async (
    data: InvitationSignUpFormValues,
    { setErrors }: FormikHelpers<InvitationSignUpFormValues>,
  ) => {
    data.invitation_id_enc
      ? await register.mutateAsync(
          {
            invitationFinish: {
              password: data.password1,
              email: data.email,
              invitation_id_enc: data.invitation_id_enc,
            },
          },
          {
            onSuccess: () => {
              navigate(redirectPaths.success, {
                state: { email: data.email },
              });
            },

            onError: (e: GlobalError) => {
              if (e?.response?.data) {
                const { code, password } = e.response.data || {};

                const nonFieldErrorMessage = errorCodeToErrorMessage.get(code);

                if (nonFieldErrorMessage) {
                  setErrors({
                    non_field_errors: nonFieldErrorMessage,
                  });
                } else if (password) {
                  setErrors({ password1: e.response.data.password });
                } else {
                  setErrors(e.response.data);
                }
              }
            },
          },
        )
      : null;
  };

  return {
    onSubmit: handleSubmit,
    initialValues: { ...initialValues, invitation_id_enc: invitationId, email },
    validationSchema,
  };
};

export const CandidateSignUpScreenConnected: FC = () => {
  const paths = pathsFrom['candidate'];

  const { search } = useLocation() as {
    search: string;
  };

  const urlSearchParams = new URLSearchParams(search);
  const invitationId = urlSearchParams.get('invitation_id_enc') || '';
  const email = urlSearchParams.get('email') || '';

  const { initialValues, onSubmit, validationSchema } = useInvitationSignUp({
    redirectPaths: { success: signupPaths.creationSuccess },
    invitationId,
    email,
  });

  return (
    <SignUpScreen
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      title={t('Start Your Medical Review')}
      redirectPaths={{ signin: paths.signin }}
      disabledEmail
      roleSelectionVisible={false}
    />
  );
};
