import type { FC } from 'react';

import { PlusIcon } from '@heroicons/react/solid';
import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { TextareaAsync } from '@nodal/uikit/components/Textarea';
import { format } from 'date-fns';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { candidateDetailsPaths, medicalRequestPaths } from '@b2b/consts/paths';

import type { RecordsPullProps } from './RecordsPull.interface';
import type { ApiModel } from '@nodal/api';

const labelFromFaxStatus = new Map([
  [apiEnums.SentStatusEnum.InProgress, t('In Progress')],
  [apiEnums.SentStatusEnum.Failed, t('Failed')],
  [apiEnums.SentStatusEnum.Sent, t('Complete')],
  [apiEnums.SentStatusEnum.SendingEmail, t('Sending Email')],
]);

const getLabelFrom = (
  thirdPartyProvider: ApiModel.ThirdPartyProviderEnum,
  type?: ApiModel.FaxAdapterTypeEnum,
) => {
  switch (thirdPartyProvider) {
    case apiEnums.ThirdPartyProviderEnum.Datavant:
      return t('Datavant');
    case apiEnums.ThirdPartyProviderEnum.Fax:
      if (type === apiEnums.FaxAdapterTypeEnum.Srfax) {
        return t('SRFax');
      }

      if (type === apiEnums.FaxAdapterTypeEnum.Email) {
        return t('Fax Email');
      }

      if (type === apiEnums.FaxAdapterTypeEnum.Pdf) {
        return t('Fax PDF');
      }
    default:
      return undefined;
  }
};

export const RecordsPull: FC<RecordsPullProps> = ({
  manualPulls,
  supervisionAccess,
  disabled,
  details,
  onChange,
  plutoRecord,
}) => {
  return supervisionAccess ? (
    <>
      <p className="row-start-1 text-sm text-grey-forest-700">{t('Pluto')}</p>
      <div className="grid grid-cols-2 col-start-3 row-start-1 gap-2 justify-between items-center pb-4 border-b border-grey-forest-100">
        <div className="col-span-2 mx-auto sm:row-start-1 sm:ml-0">
          {!plutoRecord.created && !plutoRecord.syncCompleted && (
            <ButtonLoading
              disabled={disabled}
              variant="primary"
              onClick={plutoRecord.onCreate}
              className="sm:text-xs"
            >
              {t('Submit request')}
            </ButtonLoading>
          )}
          {plutoRecord.created && !plutoRecord.syncCompleted && (
            <div className="flex flex-col gap-2 items-center sm:flex-row sm:gap-6">
              <p className="text-sm text-grey-forest-500">{t('Processing')}</p>
              <ButtonLoading
                disabled={disabled}
                variant="primary"
                onClick={plutoRecord.onDataSync}
                className="sm:text-xs"
              >
                {t('Data Sync')}
              </ButtonLoading>
            </div>
          )}
          {plutoRecord.syncCompleted && (
            <p className="text-sm font-medium text-forest-500">
              {t('Complete')}
            </p>
          )}
        </div>
        <p className="col-span-2 row-start-2 text-center text-grey-forest-400 sm:row-start-1 sm:ml-auto">
          {plutoRecord.created
            ? format(new Date(plutoRecord.created), 'MM/dd/yyyy')
            : null}
        </p>
      </div>
      {manualPulls?.map(
        ({
          id,
          third_party_provider,
          doctor_health_system,
          created,
          fax_pdf_documents,
          fax_status,
          fax_details,
        }) => (
          <Fragment key={id}>
            <p className="col-start-2 text-sm text-grey-forest-700">
              {getLabelFrom(
                third_party_provider,
                fax_details?.fax_adapter_type,
              )}
            </p>
            <div className="grid grid-cols-2 col-start-3 gap-2 items-center pb-4 border-b border-grey-forest-100 sm:items-start lg:grid-cols-[1fr_2fr_1fr]">
              <div className="col-start-1 lg:col-start-1 lg:row-start-1">
                <Link
                  to={`${candidateDetailsPaths.medicalRequest}/${medicalRequestPaths.preview}/${id}`}
                >
                  <Button variant="primary" className="sm:text-xs">
                    {t('View request')}
                  </Button>
                </Link>
              </div>
              {/* NOTE: Currently there will be only one fax pdf document in the array */}
              {third_party_provider === apiEnums.ThirdPartyProviderEnum.Fax &&
              fax_pdf_documents[0]?.content_url ? (
                <div className="lg:col-start-1 lg:row-start-2 lg:ml-0">
                  <a
                    href={fax_pdf_documents[0].content_url}
                    rel="noreferrer"
                    target="_blank"
                    download
                  >
                    <Button variant="primary" className="sm:text-xs">
                      {t('Download PDF')}
                    </Button>
                  </a>
                </div>
              ) : (
                <p className="row-span-2 row-start-1 text-forest-400 break-words lg:col-start-2">
                  {doctor_health_system}
                </p>
              )}
              {fax_status && (
                <p className="row-span-2 row-start-1 text-forest-400 lg:col-start-2">
                  {labelFromFaxStatus.get(fax_status)}
                </p>
              )}
              {fax_details?.cp_organization && (
                <p className="row-span-2 row-start-3 text-forest-400 break-words lg:col-start-2 lg:row-start-2">
                  {fax_details?.cp_organization}
                </p>
              )}
              {fax_details?.to_email && (
                <p className="row-span-2 row-start-1 w-full text-forest-400 break-words lg:col-start-2">
                  {fax_details?.to_email}
                </p>
              )}
              <p className="col-start-2 row-span-2 row-start-1 ml-auto text-grey-forest-400 lg:col-start-3">
                {format(new Date(created), 'MM/dd/yyyy')}
              </p>
            </div>
          </Fragment>
        ),
      )}
      <div className="flex col-start-3 gap-4 justify-center pb-4 w-full  sm:justify-start">
        <Link
          to={`${candidateDetailsPaths.medicalRequest}/${medicalRequestPaths.new}`}
        >
          <Button
            variant="primary"
            className="flex gap-2 justify-between sm:text-xs"
            disabled={disabled}
          >
            <PlusIcon className="w-5 h-5" />
            {t('Add new request')}
          </Button>
        </Link>
      </div>
      <p className="col-start-2 text-sm text-grey-forest-700">
        {t('Findings')}
      </p>

      <div className="col-start-3">
        <TextareaAsync
          placeholder={t('Notes')}
          value={details?.notes || undefined}
          onBlur={(value) =>
            details
              ? onChange({ content_object: { ...details, notes: value } })
              : undefined
          }
          disabled={!details}
        />
      </div>
    </>
  ) : (
    // NOTE: Currently the medical record pull step can be performed externally
    // and we don't have a way to track its status,
    // so for now we don't want to show the content with status for user without supervisionAccess
    <p className="col-start-2 ml-auto text-sm font-medium text-forest-500 sm:ml-0"></p>
  );
};
