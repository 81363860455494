import { apiEnums } from '@nodal/api';
import { getFormattedAddress } from '@nodal/core/utils';
import { t } from '@nodal/i18n';
import { format } from 'date-fns';

import type { OrganizationProfileDetail } from './OrganizationProfile.interface';
import type { ApiModel } from '@nodal/api';

const organizationTypeToLabel = new Map<string, string>([
  [apiEnums.OrganizationTypeEnum.Ip, t('Intended Parents')],
  ['agency', t('Agency')],
  ['clinic', t('Clinic')],
  ['other', t('Other')],
]);

export const getContactDetails = (
  organization: ApiModel.Organization,
  email?: string,
): OrganizationProfileDetail[] => {
  const baseProfileDetails = [
    {
      label: t('Phone Number'),
      value: organization?.phone_number || undefined,
    },
    {
      label: t('Home Address'),
      value: organization?.address
        ? getFormattedAddress(organization.address, [
            'line1',
            'line2',
            'city',
            'state',
            'zipcode',
          ])
        : undefined,
    },
  ];

  return email
    ? [
        {
          label: t('Email'),
          value: email,
        },
        ...baseProfileDetails,
      ]
    : baseProfileDetails;
};

export const getOrganizationDetails = (
  organization: ApiModel.Organization,
): OrganizationProfileDetail[] => [
  {
    label: t('Name'),
    value: organization?.name || undefined,
  },
  {
    label: t('Type'),
    value: organizationTypeToLabel.get(
      organization.business_type || organization.organization_type,
    ),
  },
  {
    label: t('Date Joined'),
    value: organization?.created
      ? format(new Date(organization.created), 'MM/dd/yyyy')
      : undefined,
  },
];
