import type { ChangeEvent, FC } from 'react';

import debounce from 'lodash/debounce';
import { useMemo, useEffect, useCallback, useState } from 'react';

import { Textarea } from './Textarea';

import type { TextareaAsyncProps } from './TextareaAsync.interface';

export const TextareaAsync: FC<TextareaAsyncProps> = ({ onBlur, ...props }) => {
  const [disabled, setDisabled] = useState(false);
  const [value, setValue] = useState<string | undefined>(
    props.value ? props.value.toString() : undefined,
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value),
    [],
  );

  const debouncedOnBlur = useMemo(
    () =>
      debounce(
        async () => {
          try {
            setDisabled(true);

            await onBlur(value);
          } finally {
            setDisabled(false);
          }
        },
        500,
        { leading: true, trailing: false },
      ),
    [onBlur, value],
  );

  useEffect(
    () => () => {
      debouncedOnBlur.cancel();
    },
    [debouncedOnBlur],
  );

  return (
    <Textarea
      {...props}
      onBlur={debouncedOnBlur}
      disabled={disabled}
      onChange={handleChange}
      value={value}
    />
  );
};
