import type { FC } from 'react';

import { apiEnums } from '@nodal/api';

import { useOrganizationDetails } from '../OrganizationDetails.connect';

import { OrganizationProfile } from './OrganizationProfile';
import { getOrganizationDetails, getContactDetails } from './utils';

export const OrganizationProfileConnected: FC = () => {
  const { organization, users } = useOrganizationDetails();

  const organizationDetails = getOrganizationDetails(organization);

  // NOTE: the ip org type should only have one user, so we display the user's email address
  const email =
    organization.organization_type === apiEnums.OrganizationTypeEnum.Ip
      ? users[0].email
      : undefined;

  const contactDetails = getContactDetails(organization, email);

  return (
    <OrganizationProfile
      id={organization.id}
      contactDetails={contactDetails}
      organizationDetails={organizationDetails}
    />
  );
};
