import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useModal } from '@nodal/uikit/components/Modal';
import React, { useEffect, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { appPaths } from 'consts/paths';
import { getProfileDisplayName } from 'utils/candidate';

import { ParentsProfileViews } from './ParentsProfileViews';

import type { ParentsProfileViewsConnectedProps } from './ParentsProfileViews.interface';

export const ParentsProfileViewsConnected: FC<
  ParentsProfileViewsConnectedProps
> = ({ userId }) => {
  const donorCurrentViewRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { close } = useModal();
  const apiClient = useApiClient();

  const { data: marketplaceUsersList } = useQuery(
    queryKeys.usersMarketplaceUsersList,
    () =>
      userId
        ? apiClient.api.UsersApi.usersMarketplaceUsersList({
            userId,
          })
        : null,
  );

  const { data: favoritedList } = useQuery(
    queryKeys.usersFavoritedList,
    () =>
      userId
        ? apiClient.api.UsersApi.usersFavoritedList({
            userId,
          })
        : null,
    {
      refetchOnMount: false,
    },
  );

  const { data: viewedList } = useQuery(queryKeys.usersViewedList, () =>
    userId
      ? apiClient.api.UsersApi.usersViewedList({
          userId,
          ordering: '-created',
        })
      : null,
  );

  const [lastViewedProfile] = viewedList?.data || [];

  const profileViews = marketplaceUsersList?.data?.map(
    ({ id, profile, profile_photos, introduction }) => {
      return {
        id,
        name: getProfileDisplayName(profile),
        photoUrl: profile_photos[0]?.image_url || undefined,
        favorite: !!favoritedList?.data?.find(
          (favorite) => favorite.parent_profile.id === id,
        ),
        viewed: !!viewedList?.data?.find(({ viewee }) => viewee?.id === id),
        invited: !!introduction,
        focused: lastViewedProfile?.viewee?.id === id,
      };
    },
  );

  const onShowProfile = (candidateId: number) => {
    queryClient.removeQueries([queryKeys.candidateRetrieve, candidateId]);
    queryClient.removeQueries(queryKeys.candidateProfileRetrieve);

    navigate(`${appPaths.candidate}/${candidateId}`);
    close();
  };

  useEffect(() => {
    if (donorCurrentViewRef.current) {
      donorCurrentViewRef.current.scrollIntoView();
    }
  }, []);

  if (!profileViews) {
    return <LoadingScreen />;
  }

  return (
    <ParentsProfileViews
      profileViews={profileViews}
      onShowProfile={onShowProfile}
      ref={donorCurrentViewRef}
    />
  );
};
