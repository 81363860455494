import type { FC } from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import classNames from 'classnames';

import type { RequestPaymentProps } from './RequestPayment.interface';

export const RequestPayment: FC<RequestPaymentProps> = ({
  payer,
  payerLabel,
  onResendPaymentLink,
  paymentStatus,
  packageName,
  errorMessage,
  onNavigateRequest,
}) => {
  return (
    <div className="flex flex-col gap-4 w-full sm:grid sm:grid-cols-3 sm:items-center">
      <p className="col-start-1 text-sm  text-grey-forest-700">
        {t('Payment Details')}
      </p>
      <div className="col-start-2 text-sm font-medium text-forest-500">
        {payerLabel ? t('Payment by: {payer}', payerLabel) : null}
        {errorMessage && (
          <div className="flex gap-1 items-center mt-1">
            <ExclamationCircleIcon className="w-4 h-4 fill-red-500" />
            <p className="text-xs text-red-600">{errorMessage}</p>
          </div>
        )}
      </div>

      <div className="col-start-3 row-start-1 sm:ml-auto">
        {payer === apiEnums.PayingEntityEnum.ExtParents &&
          paymentStatus !== apiEnums.PaymentStatusEnum.Completed && (
            <ButtonLoading
              variant="primary"
              className="justify-center"
              onClick={onResendPaymentLink}
            >
              {t('Resend Payment Link')}
            </ButtonLoading>
          )}
        {payer === apiEnums.PayingEntityEnum.Org &&
          paymentStatus !== apiEnums.PaymentStatusEnum.Completed && (
            <Button
              variant="primary"
              className="justify-center"
              onClick={onNavigateRequest}
            >
              {t('Continue')}
            </Button>
          )}
      </div>

      <p
        className={classNames(
          'col-span-2 col-start-2 text-sm font-medium text-forest-500',
          payerLabel || errorMessage ? 'row-start-2' : 'row-start-1',
        )}
      >
        {packageName ? t('Package selected: {packageName}', packageName) : null}
      </p>
    </div>
  );
};
