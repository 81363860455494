import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { PaymentComplete } from '@nodal/uikit/components/Payment';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { usePaymentComplete } from '@b2b/components/PaymentFlow';
import {
  appPaths,
  organizationPaths as paths,
  requestPaths,
} from '@b2b/consts/paths';
import { getCurrentScreeningPayment } from '@b2b/utils/candidateRequest';

import type { PaymentStatus } from '@nodal/uikit/components/Payment/PaymentStatusCard';

const getButtonMeta = ({
  status,
  requestId,
}: {
  status: PaymentStatus;
  requestId: string;
}) => {
  switch (status) {
    case 'active':
      return {
        path: `${paths.app}/${appPaths.candidates}`,
        title: t('Go to Dashboard'),
      };
    case 'failed':
      return {
        path: `${paths.app}/${appPaths.request}/${requestPaths.update}/${requestId}`,
        title: t('Resubmit Payment'),
      };
    default:
      return undefined;
  }
};

export const OrganizationPaymentCompleteConnected: FC = () => {
  const { id } = useParams() as { id: string };
  const apiClient = useApiClient();

  const { data: requestRetrieve } = useQuery(
    queryKeys.requestRetrieve,
    () =>
      apiClient.api.B2bApi.b2bInvitationsRetrieve({
        id: Number.parseInt(id),
      }),
    // Payments are async, check customer payment status every 3 seconds
    { refetchInterval: 3000 },
  );

  const request = requestRetrieve?.data;
  const paymentStatus = request?.screening_payments
    ? getCurrentScreeningPayment(request.screening_payments)?.payment_status
    : undefined;

  const paymentComplete = usePaymentComplete({
    redirectPath: `${paths.app}/${appPaths.candidates}`,
    paymentStatus,
  });

  if (!paymentComplete || !request) return <LoadingScreen />;

  const buttonMeta = getButtonMeta({
    status: paymentComplete.status,
    requestId: id,
  });

  return <PaymentComplete {...paymentComplete} buttonMeta={buttonMeta} />;
};
