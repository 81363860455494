import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { useMutation, useQueryClient } from 'react-query';

import { usePermissions } from 'app/PermissionsProvider';

import { useCandidate } from '@b2b/components/CandidateDetails';

import { MatchingPayment } from './MatchingPayment';

import type { ApiModel } from '@nodal/api';

export const MatchingPaymentConnected: FC = () => {
  const { candidate, ipTasks } = useCandidate();
  const { permissions } = usePermissions();
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const paymentTask = ipTasks?.find((task) => task.type === 'ip-final-payment');

  const paymentReceived =
    paymentTask?.content_object &&
    'final_payment_submitted' in paymentTask?.content_object
      ? paymentTask?.content_object.final_payment_submitted
      : undefined;

  const tasksStaffSubmitCreateMutation = useMutation(
    (request: ApiModel.TasksApiTasksStaffSubmitCreateRequest) =>
      apiClient.api.TasksApi.tasksStaffSubmitCreate(request),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          queryKeys.b2bUserTasksList,
          // component where mutation is called wouldn't be rendered if there is no candidate
          candidate!.id,
        ]),
    },
  );

  if (!candidate) {
    return null;
  }

  const { subscription } = candidate;
  const planName = subscription ? subscription?.plan?.name : undefined;
  const paymentTypeLabel = subscription
    ? t('Subscription')
    : t('One-time payment');

  const onChangePayment = async (paymentSubmitted: boolean) => {
    if (candidate?.id && paymentTask) {
      await tasksStaffSubmitCreateMutation.mutateAsync({
        id: paymentTask.id,
        taskContentObject: {
          id: candidate.id,
          // TODO change to enum after schema adjustments https://linear.app/nodal-health/issue/NOD-897/be-adjust-schema-for-ip-dashboard
          task_type: 'ip-final-payment',
          final_payment_submitted: paymentSubmitted,
        },
      });
    }
  };

  const showPaymentReceivedCheckbox =
    !!permissions?.canViewAndEditManualChecks && paymentReceived !== undefined;

  return (
    <MatchingPayment
      planName={planName}
      paymentTypeLabel={paymentTypeLabel}
      onChangePaymentReceived={onChangePayment}
      showPaymentReceivedCheckbox={showPaymentReceivedCheckbox}
      paymentReceived={!!paymentReceived}
    />
  );
};
