import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { AppShell } from '@nodal/uikit/components/AppShell';
import { ConfirmationDialogProvider } from '@nodal/uikit/components/ConfirmationDialog';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { posthog } from 'posthog-js';
import { useEffect, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { AccountDeactivated } from 'components/AccountDeactivated';

import { Screening } from '@b2b/components/Screening';
import { TopNav } from '@b2b/components/TopNav';
import { candidateMenuItems } from '@b2b/components/TopNav/topNavItems';
import { appPaths } from '@b2b/consts/paths';
import { settings } from '@b2b/settings';

import { useCandidateForcedRedirectPath } from './useCandidateForcedRedirectPath';

export const CandidateAppProtected = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { pathname } = useLocation();
  const scrollToTopRef = useRef<HTMLDivElement | null>(null);

  const userProfile = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  const { data: userData } = userProfile || {};
  const { role, email, profile } = userData?.data ?? {};
  const { first_name, last_name } = profile || {};

  useEffect(() => {
    if (!email || !settings.getPostHogEnabled()) return;

    posthog.identify(email, {
      name: `${first_name} ${last_name}`,
      role,
    });
  }, [role, email, first_name, last_name]);

  useEffect(() => {
    return () => queryClient.removeQueries();
  }, [queryClient]);

  useEffect(() => {
    scrollToTopRef?.current?.scrollIntoView();
  }, [pathname]);

  const forcedRedirectPath = useCandidateForcedRedirectPath({
    role: userData?.data?.role,
    status: userData?.data?.status,
  });

  useEffect(() => {
    if (forcedRedirectPath) {
      navigate(forcedRedirectPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcedRedirectPath]);

  return (
    <AppShell
      ref={scrollToTopRef}
      top={<TopNav user={userData?.data} menuItems={candidateMenuItems} />}
      center={
        role ? (
          <ConfirmationDialogProvider>
            <Routes>
              <Route path={`${appPaths.screening}/*`} element={<Screening />} />
              <Route
                path={appPaths.deactivated}
                element={<AccountDeactivated />}
              />
            </Routes>
          </ConfirmationDialogProvider>
        ) : (
          <LoadingScreen />
        )
      }
    />
  );
};
