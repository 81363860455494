import { apiEnums } from '@nodal/api';
import { screenTypes } from '@nodal/core/consts/screenTypes';
import { t } from '@nodal/i18n';

import { usePermissions } from '@b2b/app/PermissionsProvider';
import { useCandidate } from '@b2b/components/CandidateDetails';
import { useManualScreenApproval } from '@b2b/components/CandidateDetails/ManualScreenApproval/useManualScreenApproval';
import { candidateDetailsPaths } from '@b2b/consts/paths';
import { screenStatusToLabel } from '@b2b/utils/candidate';

import { MedicalForms } from './MedicalForms';

import type { Form } from './MedicalForms.interface';
import type { ApiModel } from '@nodal/api';

export const MedicalFormsConnected = () => {
  const { candidate, signedDocuments } = useCandidate();
  const { permissions } = usePermissions();

  const hipaaConsentScreen = candidate?.screens.find(
    ({ type }) => type === screenTypes.hipaaConsentForm,
  );

  const manualScreenApproval = useManualScreenApproval({
    status: hipaaConsentScreen?.status,
    id: hipaaConsentScreen?.id,
    type: hipaaConsentScreen?.type,
  });

  const {
    content_object: hipaaConsentFormContent,
    status: hipaaConsentStatus,
    manual_check: hipaaConsentManuallyChecked,
  } = hipaaConsentScreen || {};

  const { status: medicalRecordStatus } =
    candidate?.screens.find(
      ({ type }) => type === screenTypes.medicalRecordReview,
    ) || {};

  const consentForm = signedDocuments?.find(
    ({ document_type }) =>
      document_type === apiEnums.DropboxDocumentTypeEnum.ConsentFormB2b ||
      document_type === apiEnums.DropboxDocumentTypeEnum.ConsentForm,
  );

  const hipaaForm = signedDocuments?.find(
    ({ document_type }) =>
      document_type === apiEnums.DropboxDocumentTypeEnum.Hipaa,
  );

  const { signed_file_url: consentSignedFileUrl, is_signed: consentSigned } =
    consentForm || {};
  const { signed_file_url: hipaaSignedFileUrl, is_signed: hipaaSigned } =
    hipaaForm || {};

  const { hipaa_signed, consent_form_signed } =
    (hipaaConsentFormContent as ApiModel.HipaaConsentFormScreen) || {};

  const forms: Form[] = [
    {
      title: t('HIPAA Form'),
      label:
        hipaa_signed || hipaaConsentStatus === apiEnums.ScreenStatusEnum.App
          ? t('Complete')
          : t('Not Started'),
      disabled: !hipaaSigned || !hipaaSignedFileUrl,
      url: hipaaSignedFileUrl || undefined,
      hidden: !permissions?.canViewMedicalDocuments,
    },
    {
      title: t('Consent Form'),
      label:
        consent_form_signed ||
        hipaaConsentStatus === apiEnums.ScreenStatusEnum.App
          ? t('Complete')
          : t('Not Started'),
      disabled: !consentSigned || !consentSignedFileUrl,
      url: consentSignedFileUrl || undefined,
      hidden: !permissions?.canViewMedicalDocuments,
    },
    {
      title: t('Medical Record Form'),
      label: medicalRecordStatus
        ? screenStatusToLabel.get(medicalRecordStatus)
        : t('Not Started'),
      disabled:
        !medicalRecordStatus ||
        medicalRecordStatus === apiEnums.ScreenStatusEnum.Pend,
      path: candidateDetailsPaths.medical,
      hidden: !permissions?.canViewMedicalDocuments,
    },
  ];

  return (
    <MedicalForms
      forms={forms}
      hipaaConsentFormMeta={{
        manualScreenApproval,
        manuallyChecked: !!hipaaConsentManuallyChecked,
        status: hipaaConsentStatus,
      }}
    />
  );
};
