import type { FC } from 'react';

import { useSignUp } from '@nodal/core/flows/hooks/useSignUp';
import { t } from '@nodal/i18n';

import { pathsFrom, signupPaths } from '@b2b/consts/paths';

import { SignUpScreen } from './SignUpScreen';

export const OrganizationSignUpScreenConnected: FC = () => {
  const paths = pathsFrom['organization'];

  const { initialValues, onSubmit, validationSchema } = useSignUp({
    redirectPaths: { checkEmail: signupPaths.checkEmail },
  });

  return (
    <SignUpScreen
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      title={t('Join Nodal Screen')}
      redirectPaths={{ signin: paths.signin }}
      roleSelectionVisible
    />
  );
};
