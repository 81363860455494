import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { MatchProfileSkeleton } from '@nodal/core/flows/MatchProfile';
import { convertToFeetAndInches } from '@nodal/core/utils';

import { useCandidate } from '../CandidateDetails.connect';

import { MatchProfileConnected as MatchProfile } from './MatchProfile.connect';

import type { ApiModel } from '@nodal/api';
import type { DonorMatchProfileUser } from '@nodal/core/flows/MatchProfile';
import type { MatchProfileData } from '@nodal/core/flows/MatchProfile/MatchProfile.interface';

const transformUserData = (user: DonorMatchProfileUser) => {
  const {
    profile: { height, weight },
  } = user;

  const heightValue = height || null;

  const { feet, inches } = convertToFeetAndInches(
    typeof heightValue === 'number' ? heightValue : null,
  );

  const donorProfileData: DonorMatchProfileUser = user && {
    ...user,
    profile: {
      ...user?.profile,
      body_measurements: {
        height_in: inches,
        height_ft: feet,
        weight,
      },
    },
  };

  return donorProfileData;
};

export const SurrogateMatchProfileConnected: FC = () => {
  const { candidate, profile } = useCandidate();

  // NOTE: Type casting because we define the type in more detail than we get from the API,
  // the view will be only for donor profile
  const donorProfile = profile as ApiModel.DonorProfile;

  if (!candidate || !profile) {
    return <MatchProfileSkeleton inMarketplace={false} />;
  }

  const user: MatchProfileData = {
    ...candidate,
    role: apiEnums.UserRoleEnum.Dnr,
    profile: donorProfile,
  };

  return <MatchProfile user={transformUserData(user)} />;
};
