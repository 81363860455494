import { t } from '@nodal/i18n';

export const getCurrentOrganization = <T extends object>(
  organizations?: T[],
  canViewAllOrganization = false,
): T | undefined =>
  // NOTE: Currently we support only one organization per user
  // so there will always be one element in the array
  // unless the user has access to all organizations, then return undefined
  organizations && !canViewAllOrganization ? organizations[0] : undefined;

export const organizationTypeToLabel = new Map<string, string>([
  ['ip', t('Intended Parents')],
  ['agency', t('Agency')],
  ['clinic', t('Clinic')],
  ['other', t('Other')],
]);
