import type { FC } from 'react';

import { states } from '@nodal/core/consts/states';
import { t } from '@nodal/i18n';
import { AddressInput } from '@nodal/uikit/components/AddressInput';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { PhoneNumberInput } from '@nodal/uikit/components/PhoneNumberInput';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Form, Formik } from 'formik';
import 'cropperjs/dist/cropper.css';

import type { NonBusinessContactDetailsProps } from './NonBusinessContactDetails.interface';
import type { Address } from '@nodal/uikit/models';

export const NonBusinessContactDetails: FC<NonBusinessContactDetailsProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
}) => {
  return (
    <div className="flex gap-4 justify-center py-6 px-4 mx-auto w-full max-w-5xl sm:py-8">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validationOnChange={false}
        enableReinitialize
      >
        {({ isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form className="space-y-4 w-full lg:w-step-view">
            <div className="p-8 bg-white rounded-lg">
              <h3 className="text-xl font-semibold leading-7 text-gray-900">
                {t('Personal Information')}
              </h3>
              <div className="grid grid-cols-1 gap-x-4 gap-y-10 mt-6">
                <TextInput
                  label={t('First Name')}
                  name="first_name"
                  type="text"
                />
                <TextInput
                  label={t('Last Name')}
                  name="last_name"
                  type="text"
                />
              </div>
            </div>
            <div className="p-8 bg-white rounded-lg">
              <div>
                <h3 className="text-xl font-semibold leading-7 text-gray-900">
                  {t('Contact Details')}
                </h3>
              </div>
              <div className="grid grid-cols-1 gap-x-4 gap-y-10 mt-6">
                <PhoneNumberInput
                  label={t('Phone Number')}
                  name="phone_number"
                  placeholder="(555) 987-6543"
                  type="text"
                  className="lg:w-1/2"
                />
                <AddressInput
                  name="address"
                  onChange={(address: Address) =>
                    Object.entries(address).forEach(([key, value]) => {
                      setFieldValue(`address.${key}`, value);
                      setFieldTouched(`address.${key}`, false);
                    })
                  }
                  states={states}
                />
              </div>
            </div>
            <div className="flex justify-end">
              <ButtonLoading
                type="submit"
                variant="primary"
                loading={isSubmitting}
                className="justify-center w-full sm:w-max"
              >
                {t('Submit')}
              </ButtonLoading>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
