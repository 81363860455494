import { addressSchema } from '@nodal/core/consts/addressSchema';
import { t } from '@nodal/i18n';
import * as Yup from 'yup';

import type { UserProfilePhoto } from '@nodal/api';

export const getValidationSchema = (canEditOrganization: boolean) => {
  const profileSchema = {
    first_name: Yup.string().nullable().required(t('This field is required')),
    last_name: Yup.string().nullable().required(t('This field is required')),
    title: Yup.string().nullable().required(t('This field is required')),
    image: Yup.mixed<UserProfilePhoto>().optional(),
  };

  const organizationSchema = {
    phone_number: Yup.number().nullable().required(t('This field is required')),
    address: addressSchema.required(),
    name: Yup.string().nullable().required(t('This field is required')),
    business_type: Yup.string()
      .nullable()
      .required(t('This field is required')),
    other_business_type: Yup.string()
      .nullable()
      .when('business_type', {
        is: 'other',
        then: Yup.string().nullable().required(t('This field is required')),
      }),
  };

  return canEditOrganization
    ? Yup.object({
        ...profileSchema,
        ...organizationSchema,
      })
    : Yup.object({
        ...profileSchema,
      });
};
