import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { Checkbox } from '@nodal/uikit/components/Checkbox/Checkbox';
import classNames from 'classnames';

import type { BackgroundCheckProps } from './BackgroundCheck.interface';

export const BackgroundCheck: FC<BackgroundCheckProps> = ({
  stages,
  onGenerateNewLink,
  status,
  manuallyChecked,
  manualScreenApproval: { onManualApprove, manualApprovalEnabled },
  onAddNewCheckr,
}) => {
  return (
    <>
      <div className="relative w-full divide-y divide-grey-forest-100">
        {stages.map((stage, stageIdx) => {
          return (
            <div
              key={stageIdx}
              className="flex flex-col gap-4 py-3 whitespace-pre-wrap sm:grid sm:auto-cols-fr"
            >
              <p className="col-start-1 text-sm text-grey-forest-700">
                {stage.title}
              </p>

              {stage.results.map(({ label, value, id }, idx) => (
                <div
                  className={`flex flex-wrap gap-x-6 gap-y-2 justify-between items-start break-all sm:flex-col sm:justify-start md:flex-row md:items-center col-start-${
                    idx + 2
                  }`}
                  key={idx}
                >
                  <p
                    className={classNames(
                      'text-sm font-medium text-forest-500',
                      {
                        'text-red-500': value === 'expired',
                        underline: stageIdx === 0,
                      },
                    )}
                    title={label}
                  >
                    {label}
                  </p>
                  {value === 'expired' && id && (
                    <ButtonLoading
                      variant="primary"
                      onClick={() => onGenerateNewLink(id)}
                      className="justify-center w-fit sm:text-xs"
                    >
                      {t('Generate new link')}
                    </ButtonLoading>
                  )}
                </div>
              ))}
              {onAddNewCheckr && (
                <div className={`col-start-${stage.results.length + 2}`}>
                  {stageIdx === 0 && (
                    <button
                      onClick={onAddNewCheckr}
                      className="text-sm font-medium text-forest-500"
                    >
                      {t('+ a new background check')}
                    </button>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {manualApprovalEnabled && (
        <div className="flex gap-6 mt-4 w-full sm:justify-end">
          <Checkbox
            label={t('Background check is manually approved')}
            onChange={(e) =>
              onManualApprove(
                e.target.checked ? e.target.checked.toString() : undefined,
              )
            }
            id="checkr_manual_approve"
            value={manuallyChecked ? manuallyChecked?.toString() : undefined}
            disabled={
              status === apiEnums.ScreenStatusEnum.App && !manuallyChecked
            }
          />
        </div>
      )}
    </>
  );
};
