import type { FC } from 'react';

import { queryKeys } from '@nodal/core/consts/query';
import { findIndex } from 'lodash';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useCandidate } from 'components/CandidateDetails';
import {
  appPaths,
  organizationPaths as paths,
  requestPaths,
} from 'consts/paths';

import { useCandidateRequest } from './hooks/useCandidateRequest';
import { requestStepsMeta } from './requestSteps';

export const CandidateRequestRedirect: FC = () => {
  const params = useParams();
  const queryClient = useQueryClient();
  const { candidate } = useCandidate();

  const path = params['*'] || '';

  const { started, completed, steps, currentStepIndex, refresh, request } =
    useCandidateRequest();

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      if (candidate?.id) {
        queryClient.removeQueries([queryKeys.candidateRetrieve, candidate.id]);
      }
    };
  }, [candidate?.id, queryClient]);

  useEffect(() => {
    const activeIndex = findIndex(
      steps?.length ? steps : requestStepsMeta,
      (step) => step.path === path,
    );

    refresh({ activeIndex: activeIndex >= 0 ? activeIndex : undefined });
    // NOTE: Only when path has changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    if (completed) {
      navigate(`${paths.app}/${appPaths.candidates}`);
      return;
    }

    // NOTE: We cannot determine yet where we are.
    if (currentStepIndex === undefined || !steps.length) {
      return;
    }

    if (!started) {
      navigate(
        `${paths.app}/${appPaths.request}/${requestPaths.new}/${requestPaths.profile}`,
      );
      return;
    }

    // NOTE: navigate when no path is indicated.
    // We allow users to return to previous step
    if (started && request && !path) {
      const requestPath =
        steps.find((step) => step.index === currentStepIndex)?.path ||
        steps[0].path;

      navigate(
        `${paths.app}/${appPaths.request}/${requestPaths.update}/${request.id}/${requestPath}`,
      );
      return;
    }
  }, [currentStepIndex, navigate, completed, started, request, path, steps]);

  return <></>;
};
