import { apiEnums } from '@nodal/api';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { appPaths, candidatePaths } from 'consts/paths';

import type { ApiModel } from '@nodal/api';

export const useCandidateForcedRedirectPath = ({
  role,
  status,
}: {
  role?: ApiModel.UserRoleEnum;
  status?: ApiModel.UserStatusEnum;
}) => {
  const [forcedRedirectPath, setForcedRedirectPath] = useState<string>();
  const params = useParams();
  const path = params['*'] || '';

  useEffect(() => {
    if (role && status) {
      if (
        role !== apiEnums.UserRoleEnum.Odo &&
        role !== apiEnums.UserRoleEnum.Oed &&
        role !== apiEnums.UserRoleEnum.Osd
      ) {
        setForcedRedirectPath(candidatePaths.logout);
        return;
      }

      if (status === apiEnums.UserStatusEnum.Dea) {
        setForcedRedirectPath(appPaths.deactivated);
        return;
      }

      // NOTE: Autoredirect, usually after sign-in.
      if (!path) {
        setForcedRedirectPath(appPaths.screening);
        return;
      }
    }
  }, [role, path, status]);

  return forcedRedirectPath;
};
