import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { useScreening } from '@nodal/core/flows/Screening';
import {
  FormQuestions,
  getDonorQuestions,
  eggDonorQuestions,
  spermDonorQuestions,
  transformDonorSubmitValues,
  transformEggDonorSubmitValues,
  transformSpermDonorSubmitValues,
  eggDonorSchema,
  spermDonorSchema,
  getDonorSchema,
} from '@nodal/core/flows/Screening/FormQuestions';
import { useAlertFormQuestions } from '@nodal/core/flows/Screening/FormQuestions/useAlertFormQuestions';
import { t } from '@nodal/i18n';
import fromPairs from 'lodash/fromPairs';
import { useMemo } from 'react';

import type {
  FormQuestionsConnectedProps,
  FormQuestionsMeta,
} from './FormQuestions.interface';
import type { ApiModel } from '@nodal/api';
import type {
  FormValues,
  DonorQuestionnaireScreen,
} from '@nodal/core/flows/Screening/FormQuestions';

const userRoleToFormQuestionsMeta = new Map<
  ApiModel.UserRoleEnum,
  FormQuestionsMeta
>([
  [
    apiEnums.UserRoleEnum.Odo,
    {
      title: t('Surrogate Application'),
      description: t(
        'Please complete the following questions of the Nodal Surrogate Application. They must be completed in one session and will take around 5mins to complete.',
      ),
      questions: getDonorQuestions(false),
      validationSchema: getDonorSchema(false),
      submitFormatter: transformDonorSubmitValues,
    },
  ],
  [
    apiEnums.UserRoleEnum.Oed,
    {
      title: t('Egg Donor Application'),
      description: t(
        'Please complete the following questions of the Nodal Egg Donor Application. They must be completed in one session and will take around 5mins to complete.',
      ),
      questions: eggDonorQuestions,
      validationSchema: eggDonorSchema,
      submitFormatter: transformEggDonorSubmitValues,
    },
  ],
  [
    apiEnums.UserRoleEnum.Osd,
    {
      title: t('Sperm Donor Application'),
      description: t(
        'Please complete the following questions of the Nodal Sperm Donor Application. They must be completed in one session and will take around 5mins to complete.',
      ),
      questions: spermDonorQuestions,
      validationSchema: spermDonorSchema,
      submitFormatter: transformSpermDonorSubmitValues,
    },
  ],
]);

export const FormQuestionsConnected: FC<FormQuestionsConnectedProps> = ({
  role,
}) => {
  const { currentStep, submit } = useScreening();

  const { questions, validationSchema, title, description, submitFormatter } =
    userRoleToFormQuestionsMeta.get(role) || {};

  const initialValues = useMemo<FormValues<DonorQuestionnaireScreen>>(
    // TODO:
    // Temp. ignore this problem
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => fromPairs(questions.map((q) => [q.value, q.default])),
    [questions],
  );

  const { alert } = useAlertFormQuestions(currentStep);

  if (!currentStep || !questions || !submitFormatter) {
    return null;
  }

  const onSubmit = (data: DonorQuestionnaireScreen) => {
    const submitData = submitFormatter(data);
    submit(submitData);
  };

  return (
    <FormQuestions
      questions={questions}
      initialValues={{ ...initialValues, id: currentStep.id }}
      validationSchema={validationSchema}
      alert={alert}
      onSubmit={onSubmit}
      title={title}
      description={description}
    />
  );
};
