import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { Select } from '@nodal/uikit/components/Select';
import { Textarea } from '@nodal/uikit/components/Textarea';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import type { DisqualificationFormProps } from './DisqualificationForm.interface';

export const disqualificationReasonsOptions = [
  {
    label: t('Medical Concerns'),
    value: apiEnums.DisqualificationReasonEnum.MedicalConcerns,
  },
  {
    label: t('Application Flags'),
    value: apiEnums.DisqualificationReasonEnum.ApplicationFlags,
  },
  {
    label: t('Background Check Flag'),
    value: apiEnums.DisqualificationReasonEnum.BackgroundCheckFlags,
  },
  { label: t('Other'), value: apiEnums.DisqualificationReasonEnum.Other },
];

const initialValues = {
  disqualification_reason: disqualificationReasonsOptions[0].value,
  disqualification_reason_optional_details: undefined,
};

const validationSchema = Yup.object().shape({
  disqualification_reason: Yup.string(),
  disqualification_reason_optional_details: Yup.string().optional(),
});

export const DisqualificationForm: FC<DisqualificationFormProps> = ({
  message,
  onSubmit,
  onClose,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
    validateOnBlur={false}
    validationOnChange={false}
  >
    <Form className="relative px-4 text-left bg-white">
      <div>
        <div className="mt-2">
          <p className="text-sm text-grey-forest-500">{message}</p>
        </div>
        <div className="flex flex-col gap-4 pt-4">
          <Select
            label={t('Reason for disqualification')}
            name="disqualification_reason"
            options={disqualificationReasonsOptions}
          />
          <Textarea
            label={t('Additional details (optional)')}
            name="disqualification_reason_optional_details"
          />
        </div>
      </div>
      <div className="gap-2 mt-5 sm:flex sm:flex-row-reverse sm:mt-4">
        <ButtonLoading variant="primary" type="submit">
          {t('Yes')}
        </ButtonLoading>
        <Button type="button" variant="tertiary" onClick={onClose}>
          {t('Cancel')}
        </Button>
      </div>
    </Form>
  </Formik>
);
