import type { ApiModel } from '@nodal/api';

export const getBusinessTypeInitialValues = (
  businessType: ApiModel.Organization['business_type'],
) => {
  return businessType && businessType !== 'agency' && businessType !== 'clinic'
    ? {
        business_type: 'other',
        other_business_type: businessType || undefined,
      }
    : {
        business_type: businessType,
        other_business_type: undefined,
      };
};
