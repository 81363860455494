import { ChevronRightIcon, EyeIcon, HeartIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { Avatar } from '@nodal/uikit/components/Avatar';
import classNames from 'classnames';
import { forwardRef } from 'react';

import { Envelope } from '@b2b/assets';

import type { ParentsProfileViewsProps } from './ParentsProfileViews.interface';

export const ParentsProfileViews = forwardRef<
  HTMLDivElement,
  ParentsProfileViewsProps
>(({ profileViews, onShowProfile }, donorCurrentViewRef) => {
  return (
    <div className="overflow-y-auto max-h-full divide-y divide-grey-forest-200 sm:max-h-100-modal">
      {profileViews.map(
        ({ name, photoUrl, viewed, favorite, invited, focused, id }) => (
          <div
            className={classNames(
              'px-8 cursor-pointer hover:bg-grey-forest-100',
              {
                'border-l-4 !border-l-forest-400': focused,
              },
            )}
            onClick={() => onShowProfile(id)}
            key={id}
            ref={focused ? donorCurrentViewRef : null}
          >
            <div className="flex justify-between items-center py-4 w-full">
              <div className="flex gap-3 items-center w-full">
                <Avatar avatarUrl={photoUrl} className="w-10 h-10" />
                <p
                  className={classNames(
                    'text-grey-forest-900 flex flex-col text-sm',
                    {
                      'text-forest-500 font-medium': focused,
                    },
                  )}
                >
                  <span>{name}</span>
                  {focused && (
                    <span className="text-xs text-forest-400">
                      {t('Surrogates current view')}
                    </span>
                  )}
                </p>
              </div>
              <div className="flex gap-4 justify-end items-center w-full">
                {viewed && (
                  <div title={t('Viewed')}>
                    <EyeIcon className="w-6 h-6 fill-forest-500" />
                  </div>
                )}
                {favorite && (
                  <div title={t('Favorite')}>
                    <HeartIcon className="w-6 h-6 fill-forest-500" />
                  </div>
                )}
                {invited && (
                  <div title={t('Invited')}>
                    <Envelope className="w-6 h-5 fill-forest-500" />
                  </div>
                )}
              </div>
              <ChevronRightIcon className="flex-none ml-4 w-6 h-6 text-grey-forest-500" />
            </div>
          </div>
        ),
      )}
    </div>
  );
});

ParentsProfileViews.displayName = 'ParentsProfileViews';
