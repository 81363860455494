import { placeSchema } from '@nodal/core/consts/placeSchema';
import { t } from '@nodal/i18n';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  third_party_provider: Yup.string()
    .nullable()
    .required(t('This field is required')),
  first_name: Yup.string().nullable().required(t('This field is required')),
  middle_name: Yup.string().nullable().optional(),
  last_name: Yup.string().nullable().required(t('This field is required')),
  date_of_birth: Yup.string().nullable().required(t('This field is required')),
  gender: Yup.string().nullable().required(t('This field is required')),
  address: placeSchema,
  phone_number: Yup.number().nullable().required(t('This field is required')),
  delivery: Yup.string().nullable().required(t('This field is required')),
  doctor_first_name: Yup.string()
    .nullable()
    .required(t('This field is required')),
  doctor_middle_name: Yup.string().nullable().optional(),
  doctor_last_name: Yup.string()
    .nullable()
    .required(t('This field is required')),
  doctor_health_system: Yup.string()
    .nullable()
    .required(t('This field is required')),
  doctor_address: placeSchema,
  doctor_phone_number: Yup.number()
    .nullable()
    .required(t('This field is required')),
  consent_file_path: Yup.string()
    .nullable()
    .required(t('This field is required')),
  doctor_date_of_service_start: Yup.string()
    .nullable()
    .required(t('This field is required')),
  doctor_date_of_service_end: Yup.string().nullable().optional(),
  doctor_instructions: Yup.string().nullable().optional(),
});
