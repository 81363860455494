import { t } from '@nodal/i18n';

export const parentsStaffReviews = [
  {
    name: 'all_stages_complete',
    label: t('Are all other documents/steps complete?'),
    value: false,
  },
  {
    name: 'send_to_matching',
    label: t('Send to matching?'),
    value: false,
  },
];

export const donorStaffReviews = [
  {
    name: 'cleared_for_medical_review',
    label: t('Has medical review been cleared?'),
    value: false,
  },
  {
    name: 'all_stages_complete',
    label: t('Are all other documents/steps complete?'),
    value: false,
  },
  {
    name: 'send_to_matching',
    label: t('Send to matching?'),
    value: false,
  },
];
