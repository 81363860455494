import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';

import type { BackgroundCheckStage } from './BackgroundCheck.interface';

const invitationStatusOptions = [
  {
    label: t('Active'),
    value: apiEnums.CheckrScreenCandidateInvitationStatusEnum.Active,
  },
  {
    label: t('Not Created'),
    value: apiEnums.CheckrScreenCandidateInvitationStatusEnum.NotCreated,
  },
  {
    label: t('Expired'),
    value: apiEnums.CheckrScreenCandidateInvitationStatusEnum.Expired,
  },
];

export const stages: BackgroundCheckStage[] = [
  {
    title: t('Email/s'),
    name: 'email',
  },
  {
    title: t('Invitation status'),
    name: 'invitation_status',
    options: invitationStatusOptions,
  },
  {
    title: t('Sex offender search'),
    name: 'sex_offender_search',
  },
  {
    title: t('Global watchlist search'),
    name: 'global_watchlist_search',
  },
  {
    title: t('National criminal search'),
    name: 'national_criminal_search',
  },
  {
    title: t('Ssn trace'),
    name: 'ssn_trace',
  },
  {
    title: t('County criminal search'),
    name: 'county_criminal_searches',
  },
];
