import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { screenTypes } from '@nodal/core/consts/screenTypes';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useCallback } from 'react';

import { useCandidate } from '@b2b/components/CandidateDetails';

import { useMedicalRecordFormValues } from '../useMedicalRecordFormValues';
import { getDeliveryProviderDetails } from '../utils';

import { DatavantForm } from './DatavantForm';
import { initialValues } from './initialValues';
import { validationSchema } from './validation';

import type { RecordPullProviderDetails } from '../MedicalRequestFormsWrapper.interface';
import type { DatavantFormConnectedProps } from './DatavantForm.interface';
import type { ApiModel } from '@nodal/api';

export const DatavantFormConnected: FC<DatavantFormConnectedProps> = ({
  editable,
  values,
}) => {
  const { candidate } = useCandidate();
  const formValues = useMedicalRecordFormValues({
    defaultValues: initialValues,
  });

  const medicalScreen = candidate?.screens.find(
    ({ type }) => type === screenTypes.medicalRecordReview,
  )?.content_object as ApiModel.MedicalRecordReviewScreen;

  const onChangeDeliveryCount = useCallback(
    (
      updateDeliveryValues: (values: RecordPullProviderDetails) => void,
      value: number,
    ) => {
      const pregnancyDetail = getDeliveryProviderDetails(
        medicalScreen,
        apiEnums.DataProviderTypeEnum.Pregnancy,
        value,
      );

      updateDeliveryValues({
        ...pregnancyDetail,
        delivery: value,
      });
    },
    [medicalScreen],
  );

  if (!formValues) {
    return <LoadingScreen />;
  }

  return (
    <DatavantForm
      initialValues={values || formValues.initialValues}
      onSubmit={formValues.onSubmit}
      editable={editable}
      validationSchema={validationSchema}
      onChangeDeliveryCount={onChangeDeliveryCount}
    />
  );
};
