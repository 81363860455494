import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { getPaymentInfo, transformPaymentStatus } from '../utils';

import type { PaymentDetails } from '../PaymentFlow.interface';
import type { ApiModel } from '@nodal/api';

export const usePaymentComplete = ({
  redirectPath,
  paymentStatus,
}: {
  redirectPath?: string;
  paymentStatus?: ApiModel.PaymentStatusEnum;
}) => {
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const { state } = useLocation() as { state: PaymentDetails };

  const { data: userData } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  useEffect(() => {
    if (!state && redirectPath) {
      // NOTE: If there is no state passed to the component, redirect to the proper path
      navigate(redirectPath);
    }
  }, [state, navigate, redirectPath]);

  if (!state || !paymentStatus || !userData?.data) return;

  const { created, amount } = state || {};

  // NOTE: As long as the backend does not update the customer, the status will be inactive, in this case show processing status
  const status: ApiModel.PaymentStatusEnum =
    paymentStatus === apiEnums.PaymentStatusEnum.NotInitiated || !paymentStatus
      ? apiEnums.PaymentStatusEnum.Processing
      : paymentStatus;

  const { messages, title } =
    getPaymentInfo(status, userData?.data?.email) || {};

  return {
    title,
    messages,
    amount: amount,
    date: created ? new Date(created) : undefined,
    status: transformPaymentStatus(status),
    email: userData.data.email,
  };
};
