import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { get } from 'lodash';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { useCandidate } from 'components/CandidateDetails';

import { FinalReview } from './FinalReview';
import { donorStaffReviews, parentsStaffReviews } from './staffReviews';

import type { StaffReview } from './FinalReview.interface';
import type { ApiModel } from '@nodal/api';

const roleToStaffReview = new Map<
  ApiModel.UserRoleEnum | undefined,
  StaffReview[]
>([
  [apiEnums.UserRoleEnum.Par, parentsStaffReviews],
  [apiEnums.UserRoleEnum.Nap, parentsStaffReviews],
  [apiEnums.UserRoleEnum.Dnr, donorStaffReviews],
]);

const useMatchProfileReview = ({
  profile,
}: {
  profile?: ApiModel.ProfileObject;
}) => {
  const [shouldShowDecisionButtons, setShowDecisionButtons] = useState(false);

  if (
    (profile && !('match_profile_review_status' in profile)) ||
    !profile?.match_profile_review_status
  ) {
    return null;
  }

  const { match_profile_review_status } = profile;

  const shouldShowReviewButton =
    !shouldShowDecisionButtons &&
    (match_profile_review_status ===
      apiEnums.MatchProfileReviewStatusEnum.ToReview ||
      match_profile_review_status ===
        apiEnums.MatchProfileReviewStatusEnum.Pending);

  return {
    shouldShowReviewButton,
    shouldShowDecisionButtons,
    matchProfileReviewStatus: match_profile_review_status,
    onMatchProfileReviewChange: () => setShowDecisionButtons(false),
    onClickReview: () => setShowDecisionButtons(true),
  };
};

const useStaffReview = ({
  role,
  profile,
}: {
  role?: ApiModel.UserRoleEnum;
  profile?: ApiModel.ProfileObject;
}) => {
  const staffReviewsMeta = roleToStaffReview.get(role);

  const staffReviews = staffReviewsMeta?.map((review) => ({
    ...review,
    value: get(profile, review.name),
  }));

  return {
    staffReviews,
  };
};

export const FinalReviewConnected = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const { candidate, profile } = useCandidate();
  const matchProfileReviewData = useMatchProfileReview({ profile });
  const { staffReviews } = useStaffReview({ role: candidate?.role, profile });

  const finalReviewUpdate = useMutation(
    (request: ApiModel.B2bApiB2bUserProfileFinalReviewPartialUpdateRequest) =>
      apiClient.api.B2bApi.b2bUserProfileFinalReviewPartialUpdate(request),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeys.candidateProfileRetrieve),
    },
  );

  if (!matchProfileReviewData || !staffReviews || !candidate) {
    return null;
  }

  const { onMatchProfileReviewChange, ...matchProfileReview } =
    matchProfileReviewData;

  const onReviewChange = async (
    data: ApiModel.PatchedDonorProfileFinalReview,
  ) => {
    await finalReviewUpdate.mutateAsync({
      id: candidate.id,
      patchedDonorProfileFinalReview: data,
    });

    if (data.match_profile_review_status) {
      onMatchProfileReviewChange();
    }
  };

  return (
    <FinalReview
      onReviewChange={onReviewChange}
      staffReviews={staffReviews}
      {...matchProfileReview}
    />
  );
};
