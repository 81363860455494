import type { FC } from 'react';

import { Pagination } from './Pagination';
import { usePaginationRange } from './usePaginationRange';

import type { PaginationConnectedProps } from './Pagination.interface';

export const PaginationConnected: FC<PaginationConnectedProps> = ({
  pageCount,
  onChange,
  page,
}) => {
  const range =
    usePaginationRange({
      page,
      pageCount,
    }) || [];

  // NOTE: Do not render pagination if there is only one page
  if (page === 0 || range.length < 2) {
    return null;
  }

  return <Pagination range={range} page={page} onChange={onChange} />;
};
