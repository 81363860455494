import type { FC } from 'react';

import classNames from 'classnames';

import type { TabsProps } from './Tabs.interface';

export const Tabs: FC<TabsProps> = ({ tabs, activeTabIndex, onChange }) => {
  return (
    <nav
      className={classNames(
        'flex divide-x divide-gray-200 rounded-lg border border-grey-forest-200 w-max my-4',
        tabs.length < 2 ? 'hidden' : 'block',
      )}
      aria-label="Tabs"
    >
      {tabs.map((tab, tabIdx) => (
        <button
          key={tab.id}
          className={classNames(
            activeTabIndex === tabIdx
              ? 'text-grey-forest-900'
              : 'text-grey-forest-500 hover:text-grey-forest-700',
            tabIdx === 0 ? 'rounded-l-lg' : '',
            tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
            'group relative overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-grey-forest-50 focus:z-10 min-w-[200px] mb-px',
          )}
          onClick={() => onChange(tabIdx)}
        >
          <span>{tab.label}</span>
          <span
            className={classNames(
              activeTabIndex === tabIdx ? 'bg-forest-500' : 'bg-transparent',
              'absolute inset-x-0 bottom-0 h-0.5',
            )}
          />
        </button>
      ))}
    </nav>
  );
};
