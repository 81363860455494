import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { SelectAsync } from '@nodal/uikit/components/Select';

import type { ContractProps } from './Contract.interface';
import type { ApiModel } from '@nodal/api';

export const Contract: FC<ContractProps> = ({
  onSelectContractType,
  contractType,
  contractOptions,
  contractLink,
}) => (
  <div className="w-full">
    <div className="flex gap-4 items-center py-3 whitespace-pre-wrap sm:grid sm:grid-cols-4">
      <p className="col-span-2 text-sm text-grey-forest-700">{t('Contract')}</p>
      <div className="flex col-span-2 gap-4 items-center">
        <p className="text-sm font-medium text-forest-500">
          {t('Select contract type:')}
        </p>

        <div className="flex gap-4 items-center">
          <div className="min-w-[250px]">
            <SelectAsync
              onChange={(
                contractType: ApiModel.IpContractDocumentTypeDocumentTypeEnum,
              ) => onSelectContractType(contractType)}
              options={contractOptions}
              name="contract_type"
              value={contractType}
              disabled={!!contractType}
            />
          </div>
        </div>

        {contractLink && (
          <a href={contractLink} target="_blank" rel="noreferrer">
            <Button
              variant="primary"
              className="justify-center w-fit sm:text-xs"
            >
              {t('View contract')}
            </Button>
          </a>
        )}
      </div>
    </div>
  </div>
);
