import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { Checkbox } from '@nodal/uikit/components/Checkbox';
import { PastDateInput } from '@nodal/uikit/components/DateInput';
import { HiddenField } from '@nodal/uikit/components/HiddenField';
import { PhoneNumberInput } from '@nodal/uikit/components/PhoneNumberInput';
import { PlaceInput } from '@nodal/uikit/components/PlaceInput';
import { RadioGroup } from '@nodal/uikit/components/RadioGroup';
import { Select } from '@nodal/uikit/components/Select';
import { Textarea } from '@nodal/uikit/components/Textarea';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Form, Formik } from 'formik';

import type { FaxFormProps } from './FaxForm.interface';
import type { ApiModel } from '@nodal/api';

const faxTypeOptions = [
  {
    value: apiEnums.FaxAdapterTypeEnum.Srfax,
    label: t('SRFax'),
  },
  {
    value: apiEnums.FaxAdapterTypeEnum.Email,
    label: t('Email'),
  },
  {
    value: apiEnums.FaxAdapterTypeEnum.Pdf,
    label: t('Pdf only'),
  },
];

export const FaxForm: FC<FaxFormProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  editable,
  onChangeProvider,
  providerOptions,
  onChangeFaxType,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validationOnChange={false}
    >
      {({ isSubmitting, setValues, values }) => (
        <Form className="space-y-4">
          <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
            <h3 className="text-xl font-semibold leading-7 text-gray-900">
              {t('Fax Type')}
            </h3>
            <div className="flex flex-col gap-x-4 gap-y-10 mt-6">
              <RadioGroup
                name="fax_details.fax_adapter_type"
                disabled={!editable}
                options={faxTypeOptions}
                onChange={(e) =>
                  onChangeFaxType
                    ? onChangeFaxType(
                        (updatedValues) =>
                          setValues({
                            ...values,
                            fax_details: {
                              ...values.fax_details,
                              ...updatedValues,
                            },
                          }),
                        e.target.value as ApiModel.FaxAdapterTypeEnum,
                      )
                    : null
                }
              />
            </div>
          </div>
          {values.fax_details?.fax_adapter_type ===
            apiEnums.FaxAdapterTypeEnum.Srfax && (
            <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
              <h3 className="text-xl font-semibold leading-7 text-gray-900">
                {t('Cover Sheet')}
              </h3>
              <div className="flex flex-col gap-x-4 gap-y-10 mt-6">
                <PhoneNumberInput
                  name="fax_details.to_fax_number"
                  className="lg:w-1/2"
                  disabled={!editable}
                  placeholder="(555) 987-6543"
                  label={t('Fax Number')}
                />
                <TextInput
                  label={t('Organization')}
                  name="fax_details.cp_organization"
                  type="text"
                  disabled={!editable}
                />
                <TextInput
                  label={t('To Name')}
                  name="fax_details.cp_to_name"
                  type="text"
                  disabled={!editable}
                />
                <TextInput
                  label={t('From Name')}
                  name="fax_details.cp_from_name"
                  type="text"
                  disabled={!editable}
                />
                <TextInput
                  label={t('Subject')}
                  name="fax_details.cp_subject"
                  type="text"
                  disabled={!editable}
                />
                <Textarea
                  label={t('Comments')}
                  name="fax_details.cp_comment"
                  disabled={!editable}
                />
                <HiddenField name="fax_details.sender_email" />
                <HiddenField name="fax_details.from_header" />
              </div>
            </div>
          )}

          {values.fax_details?.fax_adapter_type ===
            apiEnums.FaxAdapterTypeEnum.Email && (
            <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
              <h3 className="text-xl font-semibold leading-7 text-gray-900">
                {t('Email')}
              </h3>
              <div className="flex flex-col gap-x-4 gap-y-10 mt-6">
                <TextInput
                  label={t('To')}
                  name="fax_details.to_email"
                  type="text"
                  disabled={!editable}
                  placeholder={t('Enter a valid email address')}
                />
                <TextInput
                  label={t('Subject')}
                  name="fax_details.cp_subject"
                  type="text"
                  disabled={!editable}
                />
                <Textarea
                  label={t('Body')}
                  name="fax_details.cp_comment"
                  disabled={!editable}
                />
                <HiddenField name="fax_details.sender_email" />
                <HiddenField name="fax_details.from_header" />
              </div>
            </div>
          )}

          <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
            <h3 className="text-xl font-semibold leading-7 text-gray-900">
              {t('Provider Details')}
            </h3>
            <div className="flex flex-col gap-x-4 gap-y-10 mt-6">
              <Select
                label={t('Select Provider')}
                name="delivery_provider"
                options={providerOptions}
                onChange={(value) =>
                  onChangeProvider
                    ? onChangeProvider(
                        (updatedValues, organization) =>
                          setValues({
                            ...values,
                            ...updatedValues,
                            fax_details: values.fax_details
                              ? {
                                  ...values.fax_details,
                                  cp_organization: organization,
                                }
                              : undefined,
                          }),
                        value,
                      )
                    : null
                }
                disabled={!editable}
              />
              <TextInput
                label={t('Provider First Name')}
                name="doctor_first_name"
                type="text"
                disabled={!editable}
              />
              <TextInput
                label={t('Provider Last Name')}
                name="doctor_last_name"
                type="text"
                disabled={!editable}
              />
              <PhoneNumberInput
                label={t("Provider's Phone Number")}
                name="doctor_phone_number"
                className="lg:w-1/2"
                disabled={!editable}
              />
              <PlaceInput
                label={t("Provider's Address")}
                name="doctor_address"
                type="address"
                disabled={!editable}
              />
            </div>
          </div>
          <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
            <h3 className="text-xl font-semibold leading-7 text-gray-900">
              {t('Candidate Details')}
            </h3>
            <div className="flex flex-col gap-x-4 gap-y-10 mt-6">
              <TextInput
                label={t('First Name')}
                name="first_name"
                type="text"
                disabled={!editable}
              />
              <TextInput
                label={t('Last Name')}
                name="last_name"
                type="text"
                disabled={!editable}
              />
              <PastDateInput
                label={t('Date of Birth')}
                name="date_of_birth"
                placeholder="YYYY-DD-MM"
                className="lg:w-1/2"
                disabled={!editable}
              />
              <PhoneNumberInput
                label={t('Phone Number')}
                name="phone_number"
                className="lg:w-1/2"
                disabled={!editable}
              />
            </div>
          </div>
          <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
            <h3 className="text-2xl font-semibold text-gray-900">
              {t('Records Requested')}
            </h3>
            <div className="flex flex-col gap-x-4 gap-y-6 mt-6">
              <Checkbox
                label={t('ALL Obstetrical/Prenatal/CNM Records')}
                name="fax_details.prenatal_records_selected"
                disabled={!editable}
              />
              <Checkbox
                label={t(
                  'ALL Delivery Records (Labor & Delivery Nursing Records/Infant Delivery Summary)',
                )}
                name="fax_details.delivery_records_selected"
                disabled={!editable}
              />
              <Checkbox
                label={t(
                  'ALL Records *Include HIV/AIDS & Sexual Transmitted Disease Info*',
                )}
                name="fax_details.disease_records_selected"
                disabled={!editable}
              />
            </div>

            <h4 className="mt-6 text-xl font-semibold leading-7 text-gray-900">
              {t('Other')}
            </h4>
            <div className="flex flex-col gap-x-4 gap-y-6 mt-6">
              <div className="flex items-center">
                <Checkbox
                  label=""
                  name="fax_details.other_records_selected"
                  disabled={!editable}
                />
                <TextInput
                  name="fax_details.other_records_details"
                  type="text"
                  disabled={!editable}
                  className="w-full"
                  placeholder={
                    values?.fax_details?.other_records_selected
                      ? t('Please enter no more than 80 characters')
                      : undefined
                  }
                  maxLength={80}
                />
              </div>
              <Checkbox
                label={t('Include OB Clearance Letter')}
                name="fax_details.ob_clearance_letter_required"
                disabled={!editable}
              />
            </div>
          </div>
          {editable && (
            <div className="flex justify-end">
              <ButtonLoading
                type="submit"
                variant="primary"
                loading={isSubmitting}
                className="justify-center px-6 w-full sm:w-max"
              >
                {t('Submit')}
              </ButtonLoading>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};
