import type { FC } from 'react';

import { useGooglePlacesApi } from '@nodal/core/hooks/useGooglePlacesApi';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';

import { useContactDetailsForm } from '../useContactDetailsForm';
import { useProfileDetails } from '../useProfileDetails';
import { useProfilePhoto } from '../useProfilePhoto';

import { SuperadminContactDetails } from './SuperadminContactDetails';
import { schema as validationSchema } from './validation';

import type { ContactDetailsValues } from './SuperadminContactDetails.interface';

export const SuperadminContactDetailsConnected: FC = () => {
  const placesApiReady = useGooglePlacesApi();
  const { profile, updateProfile } = useProfileDetails();

  const { profilePhoto, save: saveProfilePhoto } = useProfilePhoto();

  const submit = async (submitData: ContactDetailsValues) => {
    const { image, ...profileData } = submitData;

    await Promise.all([updateProfile(profileData), saveProfilePhoto(image)]);
  };

  const { onSubmit } = useContactDetailsForm({ submit });

  if (!profile || !placesApiReady) {
    return <LoadingScreen />;
  }

  return (
    <SuperadminContactDetails
      onSubmit={onSubmit}
      initialValues={{ ...profile, image: profilePhoto }}
      validationSchema={validationSchema}
    />
  );
};
