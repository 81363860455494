import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useQuery } from 'react-query';

export const useClientSecret = (requestId?: number) => {
  const apiClient = useApiClient();

  const { data } = useQuery(
    queryKeys.billingStripeInvitationPaymentRetrieve,
    () =>
      apiClient.api.BillingApi.billingStripeInvitationPaymentRetrieve({
        id: requestId!,
      }),
    { enabled: !!requestId },
  );

  // NOTE: Using cast and ts-ignore due to broken API types,
  // expected type is BillingPaymentIntent.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const paymentIntent = data?.data as BillingPaymentIntent;

  // TODO: At the moment we only support card payment,
  // in the future, extend this to include client secrets for other payment methods
  return { clientSecret: paymentIntent?.card.client_secret };
};
