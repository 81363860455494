import every from 'lodash/every';
import isNull from 'lodash/isNull';
import omit from 'lodash/omit';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { appPaths, organizationPaths } from 'consts/paths';
import { canCreateCandidateRequest } from 'utils/candidate';

import type { FeatureFlags } from './FeatureFlagsProvider.interface';
import type { PermissionsScope } from './PermissionsProvider.interface';
import type { ApiModel } from '@nodal/api';

export const useOrganizationForcedRedirectPath = ({
  profile,
  candidateRequests,
  permissions,
  features,
}: {
  profile?: ApiModel.User['profile'];
  candidateRequests?: ApiModel.Invitation[];
  permissions?: PermissionsScope;
  features?: FeatureFlags;
}) => {
  const [forcedRedirectPath, setForcedRedirectPath] = useState<string>();
  const params = useParams();
  const path = params['*'] || '';

  useEffect(() => {
    if (permissions && features) {
      const { canManageInvitations, canViewAllOrganizations } = permissions;

      if (!permissions.hasAccessToApp) {
        setForcedRedirectPath(organizationPaths.logout);
        return;
      }

      const userProfileNotComplete = every(
        Object.values(omit(profile, 'id')),
        isNull,
      );

      // NOTE: Autoredirect, usually after sign-in.
      if (!path && candidateRequests) {
        if (userProfileNotComplete) {
          setForcedRedirectPath(appPaths.profile);
          return;
        }

        if (
          !candidateRequests.length &&
          canCreateCandidateRequest({
            isInvitationFeatureEnabled: features.invitations,
            canManageInvitations,
            canViewAllOrganizations,
          })
        ) {
          setForcedRedirectPath(appPaths.welcome);
          return;
        }

        setForcedRedirectPath(appPaths.candidates);
        return;
      }
    }
  }, [candidateRequests, path, profile, permissions, features]);

  return forcedRedirectPath;
};
