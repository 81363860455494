import { Popover } from '@headlessui/react';
import { createInitials, sortPhotosByOrder } from '@nodal/core/utils';
import { Avatar } from '@nodal/uikit/components/Avatar';
import { MenuButton } from '@nodal/uikit/components/MenuButton';
import { NavigationItems } from '@nodal/uikit/components/NavigationItems';
import { useMemo } from 'react';

import { NodalLogoBeige } from '@b2b/assets';

import type { MobileNavProps } from './MobileNav.interface';

export const MobileNav: React.FC<MobileNavProps> = ({
  open,
  menuItems,
  navigationItems,
  user,
  onClose,
}) => {
  const { email } = user || {};
  const { first_name, last_name } = user?.profile || {};

  const avatarUrl = useMemo(
    () =>
      user?.profile_photos?.length
        ? sortPhotosByOrder(user.profile_photos).find((photo) => photo)?.image
        : '',
    [user?.profile_photos],
  );

  const initials = useMemo(
    () =>
      first_name && last_name ? createInitials(first_name, last_name) : '',
    [first_name, last_name],
  );

  return (
    <>
      <div className="flex relative justify-start items-center px-4 h-[var(--height-top-nav)]">
        <img
          className="block w-[80px] h-8"
          src={NodalLogoBeige}
          alt="Nodal Logo"
        />
      </div>
      <div className="absolute top-8 right-2 -translate-y-1/2">
        {menuItems?.length ? <MenuButton open={open} /> : null}
      </div>
      <div className="flex justify-end items-center w-full">
        <Popover.Panel className="left-0 w-full">
          <div className="space-y-1">
            <div className="block">
              <div
                className={
                  navigationItems?.length
                    ? 'px-2 pt-2 pb-3 border-t border-forest-400'
                    : ''
                }
              >
                <NavigationItems
                  navigationItems={navigationItems}
                  onClose={onClose}
                />
              </div>
              <div className="p-2 pb-3 border-t border-forest-400">
                <div className="group block visible shrink-0 px-3 pt-2 pb-3">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <Avatar
                        avatarUrl={avatarUrl}
                        initials={initials}
                        className="w-10 h-10"
                      />
                      <div className="ml-3">
                        <p className="text-base font-medium text-beige-500">
                          {first_name} {last_name}
                        </p>
                        <p className="text-sm font-medium text-moss-300">
                          {email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <NavigationItems
                  navigationItems={menuItems}
                  onClose={onClose}
                />
              </div>
            </div>
          </div>
        </Popover.Panel>
      </div>
    </>
  );
};
