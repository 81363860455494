import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';

import { donorRoleKeys } from 'consts/candidateRoles';

import { getMedicalReviewStatus } from '@b2b/utils/candidate';

import { candidateStepsStatus } from './InvitedCandidates.interface';

import type {
  CandidateStepStatus,
  StatusCount,
} from './InvitedCandidates.interface';
import type { ApiModel } from '@nodal/api';

export const labelByStatus: Record<CandidateStepStatus, string> = {
  processing: t('In Progress'),
  review: t('In Review'),
  complete: t('Complete'),
  deactivated: t('Deactivated'),
};

export const colorByStatus: Record<CandidateStepStatus, string> = {
  processing: 'text-yellow-700',
  review: 'text-blue-500',
  complete: 'text-forest-400',
  deactivated: 'text-grey-forest-500',
};

export const getCandidateStepStatus = ({
  status,
  medicalReview,
}: {
  status?: ApiModel.UserStatusEnum;
  medicalReview?: ApiModel.ReviewNested;
}): CandidateStepStatus => {
  const medicalReviewStatus = getMedicalReviewStatus(medicalReview);

  if (status === apiEnums.UserStatusEnum.Scr) {
    return candidateStepsStatus.processing;
  }

  if (
    status === apiEnums.UserStatusEnum['Scr+'] &&
    (medicalReviewStatus === apiEnums.ScreenStatusEnum.Pend ||
      medicalReviewStatus === apiEnums.ScreenStatusEnum.Proc)
  ) {
    return candidateStepsStatus.review;
  }

  if (
    status === apiEnums.UserStatusEnum['Scr+'] &&
    (medicalReviewStatus === apiEnums.ScreenStatusEnum.App ||
      medicalReviewStatus === apiEnums.ScreenStatusEnum.Rej)
  ) {
    return candidateStepsStatus.complete;
  }

  if (
    status === apiEnums.UserStatusEnum.Dea ||
    status === apiEnums.UserStatusEnum.Dis
  ) {
    return candidateStepsStatus.deactivated;
  }

  return candidateStepsStatus.processing;
};

export const getDonorStatusCounts = (
  users: ApiModel.B2BUserNonDetailedList[],
): StatusCount[] =>
  [
    candidateStepsStatus.processing,
    candidateStepsStatus.review,
    candidateStepsStatus.complete,
    candidateStepsStatus.deactivated,
  ].map((stepStatus) => ({
    status: stepStatus,
    count: users.filter(
      ({ status, medical_review, role }) =>
        getCandidateStepStatus({
          status,
          medicalReview: medical_review,
        }) === stepStatus && donorRoleKeys.includes(role!),
    ).length,
  }));

export const calulateTotalActive = (statusCounts: StatusCount[]) =>
  statusCounts.reduce(
    (total: number, { status, count }: StatusCount) =>
      status !== candidateStepsStatus.deactivated ? total + count : total,
    0,
  );
