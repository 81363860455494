import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import {
  initialValues,
  ivfTransferInitialValues,
  pregnancyDetailInitialValues,
} from '@nodal/core/flows/Screening/MedicalRecordReview/initialValues';
import { schema } from '@nodal/core/flows/Screening/MedicalRecordReview/validation';
import { useGooglePlacesApi } from '@nodal/core/hooks/useGooglePlacesApi';
import { t } from '@nodal/i18n';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import some from 'lodash/some';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { usePermissions } from '@b2b/app/PermissionsProvider';
import { useCandidate } from '@b2b/components/CandidateDetails';

import { MedicalRecord } from './MedicalRecord';

import type { ApiModel } from '@nodal/api';
import type { MedicalRecordReviewScreen } from '@nodal/core/flows/Screening/MedicalRecordReview';

const title = t('Medical Record Review');

const determineDoctorClinicCountry = (
  pregnancyDetail?: ApiModel.MedicalRecordReviewScreenPregnancyDetail,
) => {
  if (some(pregnancyDetail?.doctor_clinic_address, (value) => !isNil(value))) {
    return apiEnums.DoctorClinicCountryEnum.Us;
  }

  if (pregnancyDetail?.doctor_clinic_international_address) {
    return apiEnums.DoctorClinicCountryEnum.Other;
  }

  return pregnancyDetailInitialValues.doctor_clinic_country;
};

const useMedicalRecordScreen = () => {
  const { permissions } = usePermissions();
  const { candidate } = useCandidate();
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const screen = candidate?.screens.find(
    ({ type }) => type === 'medical-record-review',
  );

  const screenSubmit = useMutation(
    (request: ApiModel.ScreensApiScreensSubmitCreateRequest) =>
      apiClient.api.ScreensApi.screensSubmitCreate(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          queryKeys.candidateRetrieve,
          // component where mutation is called wouldn't be rendered if there is no candidate
          candidate!.id,
        ]);
        toast.success(t('Successfully updated'));
      },
      onError: () => {
        toast.error(t('Something went wrong...'));
      },
    },
  );

  const uploadInsurancePhotos = useMutation(
    (
      request: ApiModel.ScreensApiScreensInsuranceDocumentPartialUpdateRequest,
    ) =>
      apiClient.api.ScreensApi.screensInsuranceDocumentPartialUpdate(request, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    {
      onError: () => {
        toast.error(t('Failed to upload insurance photos'));
      },
    },
  );

  useEffect(() => {
    if (candidate && !screen) {
      // NOTE: Go back to the candidate detail overview page if this candidate
      // does not have this screen defined in the screening process
      navigate('../');
    }
  }, [candidate, screen, navigate]);

  if (!candidate || !screen) {
    return null;
  }

  const { content_object, id } = screen;

  const onSubmit = async (data: MedicalRecordReviewScreen) => {
    const {
      no_insurance,
      insurance_photo_front,
      insurance_photo_back,
      ...formValues
    } = data || {};

    const submitData = {
      ...formValues,
      pregnancy_details: data.pregnancy_details.map((detail) =>
        detail.same_doctor_details_as_first
          ? {
              ...data.pregnancy_details[0],
              delivery_date: detail.delivery_date,
            }
          : detail,
      ),
      ivf_transfers_details: data.ivf_transfers_details.map((detail) =>
        detail.same_doctor_details_as_first
          ? {
              ...data.ivf_transfers_details[0],
            }
          : detail,
      ),
      health_insurance: no_insurance ? null : data.health_insurance,
    };

    // NOTE: To avoid CORS issues, only the updated pictures should be included in the payload
    const updatedInsurancePhotos = pickBy(
      {
        insurance_photo_front,
        insurance_photo_back,
      },
      // NOTE: If the value is not a string, it means that the photo has been updated
      // because is a File object
      (value) => typeof value !== 'string',
    );

    if (!no_insurance && !isEmpty(updatedInsurancePhotos)) {
      await uploadInsurancePhotos.mutateAsync({
        id,
        patchedMedicalRecordReviewScreenDocuments: updatedInsurancePhotos,
      });
    }

    await screenSubmit.mutateAsync({
      id,
      screenContentObject: submitData,
    });
  };

  // NOTE: Too many screen types defined in API types cause TS error
  // Expected type in this screen is ApiModel.MedicalRecordReviewScreen
  const values = content_object as ApiModel.MedicalRecordReviewScreen;

  const { pregnancy_details, ivf_transfers_details } = values;

  const initialPregnancyCount =
    pregnancy_details?.length || initialValues.previous_pregnancy_count;

  const initialPregnacyDetails = pregnancy_details?.length
    ? pregnancy_details.map((detail, idx) => {
        return {
          ...detail,
          same_doctor_details_as_first: !!(
            isEqual(
              // NOTE: delivery_date is a separate field and is not autocompleted
              // when same_doctor_details_as_first is checked
              omit(detail, 'delivery_date'),
              omit(pregnancy_details[0], 'delivery_date'),
            ) && idx !== 0
          ),
          doctor_clinic_country: determineDoctorClinicCountry(detail),
        };
      })
    : Array(initialPregnancyCount).fill(pregnancyDetailInitialValues);

  const initialIvfTransferCount =
    ivf_transfers_details?.length || initialValues.previous_ivf_transfer_count;

  const initialIvfTransferDetails = ivf_transfers_details?.length
    ? ivf_transfers_details.map((detail, idx) => {
        return {
          ...detail,
          same_doctor_details_as_first: !!(
            isEqual(
              // NOTE: transfer_date is a separate field and is not autocompleted
              // when same_doctor_details_as_first is checked
              omit(detail, 'transfer_date'),
              omit(ivf_transfers_details[0], 'transfer_date'),
            ) && idx !== 0
          ),
        };
      })
    : Array(initialIvfTransferCount).fill(ivfTransferInitialValues);

  return {
    initialValues: {
      ...values,
      previous_pregnancy_count: initialPregnancyCount,
      pregnancy_details: initialPregnacyDetails,
      id,
      no_insurance: values.health_insurance ? false : true,
      previous_ivf_transfer_count: initialIvfTransferCount,
      ivf_transfers_details: initialIvfTransferDetails,
    },
    onSubmit,
    editable: !!permissions?.canEditMedicalDocuments,
    previewPhotoEnabled: true,
  };
};

export const MedicalRecordConnected = () => {
  const medicalRecord = useMedicalRecordScreen();
  const placesApiReady = useGooglePlacesApi();

  if (!medicalRecord || !placesApiReady) {
    return <LoadingScreen />;
  }

  return (
    <MedicalRecord validationSchema={schema} title={title} {...medicalRecord} />
  );
};
