import { apiEnums } from '@nodal/api';

import { settings } from 'settings';

import type { ApiModel } from '@nodal/api';

const candidateRoleSelectionVisible =
  settings.hasSpermAndEggDonorsIncludedInCandidateRoles();

export const initialCandidate: ApiModel.PatchedInvitation = {
  // NOTE: If the candidateRoleSelectionVisible is set to true then we will allow
  // selecting the role of the candidate, in other case we will assign 'odo' (as agreed)
  // Ref. https://linear.app/nodal-health/issue/NOD-351/remove-candidate-selection-from-b2b
  role: candidateRoleSelectionVisible ? null : apiEnums.UserRoleEnum.Odo,
  first_name: null,
  middle_name: null,
  last_name: null,
  email: null,
  phone_number: null,
  organization_id: null,
  partner_email: null,
};
