import type { FC } from 'react';

import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useGooglePlacesApi } from '@nodal/core/hooks/useGooglePlacesApi';
import { sortPhotosByOrder } from '@nodal/core/utils';
import { t } from '@nodal/i18n';
import { useConfirmationDialog } from '@nodal/uikit/components/ConfirmationDialog';
import { useModal } from '@nodal/uikit/components/Modal';
import groupBy from 'lodash/groupBy';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { usePermissions } from 'app/PermissionsProvider';
import { donorRoleKeys } from 'consts/candidateRoles';
import { candidateDetailsPaths } from 'consts/paths';

import {
  getCurrentInvitation,
  getProfileDisplayName,
} from '@b2b/utils/candidate';

import { useCandidate } from '../../CandidateDetails.connect';
import { DonorEditProfileForm } from '../DonorEditProfileForm';
import { ParentsEditProfileForm } from '../ParentsEditProfileForm';

import { CandidateProfile } from './CandidateProfile';
import { dnrProfileDetails, parProfileDetails } from './profileDetails';
import { StageDetails } from './StageDetails/StageDetails';

import type {
  CandidateProfileConnectedProps,
  CandidateProfileDetails,
} from './CandidateProfile.interface';
import type { ApiModel } from '@nodal/api';

const roleToProfileDetails = new Map<
  ApiModel.UserRoleEnum,
  CandidateProfileDetails[]
>([
  [apiEnums.UserRoleEnum.Par, parProfileDetails],
  [apiEnums.UserRoleEnum.Nap, parProfileDetails],
  [apiEnums.UserRoleEnum.Dnr, dnrProfileDetails],
  [apiEnums.UserRoleEnum.Odo, dnrProfileDetails],
  [apiEnums.UserRoleEnum.Osd, dnrProfileDetails],
  [apiEnums.UserRoleEnum.Oed, dnrProfileDetails],
]);

const useCandidateDropdownMenuItems = ({
  invitation,
  canEditCandidateInvitation,
  matchProfileEnabled,
}: {
  invitation?: ApiModel.InvitationNested;
  canEditCandidateInvitation: boolean;
  matchProfileEnabled: boolean;
}) => {
  const confirm = useConfirmationDialog();
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { candidate } = useCandidate();

  const resendInvitationLink = useMutation(
    (request: ApiModel.B2bApiB2bInvitationsResendCreateRequest) =>
      apiClient.api.B2bApi.b2bInvitationsResendCreate(request),
    {
      onSuccess: () => {
        toast.success(t('Invitation link has been sent'));
      },
      onError: () => {
        toast.error(t('Something went wrong'));
      },
    },
  );

  const cancelRequest = useMutation(
    (request: ApiModel.B2bApiB2bInvitationsCancelCreateRequest) =>
      apiClient.api.B2bApi.b2bInvitationsCancelCreate(request),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          queryKeys.candidateRetrieve,
          // component where mutation is called wouldn't be rendered if there is no candidate
          candidate!.id,
        ]);
        toast.success(t('Request has been canceled'));
      },
      onError: () => {
        toast.error(t('Something went wrong'));
      },
    },
  );

  const onCancelRequest = async (id: number) => {
    const confirmed = await confirm({
      title: t('Cancel Request'),
      message: t(
        "Are you sure you want to cancel the request for this candidate? This action will deactivate the candidate's invitation link and change them to inactive.",
      ),
      variant: 'alert',
    });

    if (confirmed) {
      await cancelRequest.mutateAsync({
        id,
      });
    }
  };

  const dropdownMenuItems = [
    {
      label: t('Resend candidate invite link'),
      onClick: async () =>
        invitation?.id
          ? await resendInvitationLink.mutateAsync({
              id: invitation.id,
            })
          : null,
      hidden:
        !invitation?.status ||
        invitation?.status !== apiEnums.InvitationStatusEnum.Sent ||
        !canEditCandidateInvitation,
    },
    {
      label: t('Cancel request'),
      onClick: async () =>
        invitation?.id ? await onCancelRequest(invitation.id) : null,
      hidden:
        !invitation?.status ||
        invitation?.status === apiEnums.InvitationStatusEnum.Canceled ||
        !canEditCandidateInvitation,
    },
    {
      label: t('Download match profile'),
      onClick: () => navigate(`${candidateDetailsPaths.matchProfile}#export`),
      hidden: !matchProfileEnabled,
    },
  ];

  return dropdownMenuItems.filter(({ hidden }) => !hidden);
};

export const CandidateProfileConnected: FC<CandidateProfileConnectedProps> = ({
  candidate,
  profile,
  ipTasks,
}) => {
  const placesApiReady = useGooglePlacesApi();
  const { open } = useModal();
  const { permissions } = usePermissions();

  const { role, id, profile_photos, invitations, show_matching_badge } =
    candidate;

  const tasksCount = ipTasks?.length;
  const finishedTasksCount = ipTasks?.filter(
    (ipTasks) => ipTasks.status === apiEnums.TaskStatusEnum.Complete,
  ).length;
  const finishedTasksCoefficient =
    finishedTasksCount && tasksCount
      ? parseFloat((finishedTasksCount / tasksCount).toFixed(2))
      : undefined;
  const finishedTasksPercent = finishedTasksCoefficient
    ? `${finishedTasksCoefficient * 100}%`
    : undefined;

  const stagesWithTasks = groupBy(ipTasks, (task) => task.stage);
  const stages = Object.keys(stagesWithTasks) as ApiModel.TaskStageEnum[];

  const dropdownMenuItems = useCandidateDropdownMenuItems({
    invitation: getCurrentInvitation(invitations),
    canEditCandidateInvitation: !!permissions?.canEditCandidateInvitation,
    matchProfileEnabled:
      (role === apiEnums.UserRoleEnum.Dnr ||
        role === apiEnums.UserRoleEnum.Par) &&
      !!permissions?.canDownloadMatchProfile,
  });

  if (!role || !placesApiReady || !permissions) {
    return null;
  }

  const handleViewTasksDetails = () => {
    open({
      size: 'lg',
      title: t('Candidate Tasks Completed'),
      render: () => {
        return (
          <div className="flex justify-evenly p-8">
            {stages.map((stage) => (
              <StageDetails
                key={stage}
                stage={stage}
                stagesWithTasks={stagesWithTasks}
              />
            ))}
          </div>
        );
      },
    });
  };

  const onEdit = () =>
    open({
      title: t('Edit Profile'),
      render: () => {
        if (donorRoleKeys.find((r) => r === role)) {
          return <DonorEditProfileForm id={candidate.id} profile={profile} />;
        }

        if (
          role === apiEnums.UserRoleEnum.Par ||
          role === apiEnums.UserRoleEnum.Nap
        ) {
          return <ParentsEditProfileForm id={candidate.id} profile={profile} />;
        }
      },
    });

  const profileDetailsByRole = roleToProfileDetails.get(role) || [];

  const profileDetails = profileDetailsByRole.reduce(
    (
      arr: CandidateProfileDetails[],
      details: CandidateProfileDetails,
    ): CandidateProfileDetails[] => {
      const formattedValues = details.formatter(profile);

      return formattedValues
        ? [
            ...arr,
            {
              ...details,
              value: formattedValues,
            },
          ]
        : arr;
    },
    [],
  );

  const patientReportUrl = candidate?.medical_review?.documents?.find(
    ({ document_type }) =>
      document_type === apiEnums.MedicalDocumentDocumentTypeEnum.FinalReview,
  )?.content_url;

  // NOTE: Backend doesn't return photos sorted by order, so we do it on the frontend,
  // which is important when displaying a profile photo (first in order)
  const sortedProfilePhotos = sortPhotosByOrder(profile_photos);

  return (
    <CandidateProfile
      name={getProfileDisplayName(profile)}
      id={id}
      show_matching_badge={show_matching_badge}
      role={role}
      profileDetails={profileDetails}
      photoUrl={sortedProfilePhotos?.[0]?.image_url || undefined}
      dropdownMenuItems={dropdownMenuItems}
      onEdit={permissions.canEditCandidateDetails ? onEdit : undefined}
      patientReport={
        permissions.canViewMedicalDocuments
          ? {
              url: patientReportUrl || undefined,
            }
          : undefined
      }
      finishedTasksCoefficient={finishedTasksCoefficient}
      finishedTasksPercent={finishedTasksPercent}
      onViewTasksDetails={handleViewTasksDetails}
    />
  );
};
