import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { SelectAsync } from '@nodal/uikit/components/Select';
import { TextareaAsync } from '@nodal/uikit/components/Textarea';
import { format } from 'date-fns';

import { medicalReviewStatusToLabel } from 'utils/candidate';

import type { RxDxHistoryProps } from './RxDxHistory.interface';

const statusOptions = [
  {
    label: t('Select your option'),
    value: apiEnums.ReviewStepStatusEnum.Pending,
    hidden: true,
  },
  {
    label: t('Submitted'),
    value: apiEnums.ReviewStepStatusEnum.Complete,
  },
  {
    label: t('Rejected'),
    value: apiEnums.ReviewStepStatusEnum.Rejected,
  },
];

export const RxDxHistory: FC<RxDxHistoryProps> = ({
  details,
  completionDate,
  status,
  supervisionAccess,
  onChange,
}) =>
  supervisionAccess ? (
    <>
      <p className="row-start-1 text-sm text-grey-forest-700">{t('IBU')}</p>
      <div className="grid grid-cols-2 col-start-3 row-start-1 justify-between items-center">
        <div className="-mt-1 sm:row-start-1">
          <SelectAsync
            onChange={(value) => onChange({ status: value })}
            value={status}
            name="status"
            options={statusOptions}
            disabled={!status}
          />
        </div>
        <p className="ml-auto text-grey-forest-400 sm:row-start-1">
          {completionDate ? format(completionDate, 'MM/dd/yyyy') : null}
        </p>
      </div>
      <p className="row-start-2 text-sm text-grey-forest-700">
        {t('Findings')}
      </p>
      <div className="col-start-3 row-start-2">
        <TextareaAsync
          placeholder={t('Notes')}
          value={details?.notes || undefined}
          onBlur={(value) =>
            details
              ? onChange({ content_object: { ...details, notes: value } })
              : undefined
          }
          disabled={!details}
        />
      </div>
    </>
  ) : (
    <p className="col-start-2 ml-auto text-sm font-medium text-forest-500 sm:ml-0">
      {/* NOTE: until the candidate registers, no medical reviews are created
       * In this case status is undefined and we want to show pending status. */}
      {medicalReviewStatusToLabel.get(
        status || apiEnums.ReviewStepStatusEnum.Pending,
      )}
    </p>
  );
