import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { CheckboxAsync } from '@nodal/uikit/components/Checkbox';
import { SelectAsync } from '@nodal/uikit/components/Select';
import { format } from 'date-fns';

import type { MfmClearanceProps } from './MfmClearance.interface';

const statusOptions = [
  {
    label: t('Select your option'),
    value: apiEnums.ReviewStepStatusEnum.Pending,
    hidden: true,
  },
  {
    label: t('Cleared'),
    value: apiEnums.ReviewStepStatusEnum.Complete,
  },
  {
    label: t('Disqualified'),
    value: apiEnums.ReviewStepStatusEnum.Rejected,
  },
];

export const MfmClearance: FC<MfmClearanceProps> = ({
  details,
  onChange,
  status,
  completionDate,
}) => {
  return (
    <>
      <div className="col-span-2 row-start-2 sm:col-span-1 sm:col-start-3 sm:row-start-1">
        <CheckboxAsync
          label={t('Need MFM review?')}
          checked={!!details?.mfm_review}
          onChange={(value: boolean) =>
            details
              ? onChange({ content_object: { ...details, mfm_review: value } })
              : undefined
          }
          id="mfm_review"
          disabled={!details}
        />
      </div>
      <p className="hidden col-start-2 row-start-2 my-auto text-sm text-grey-forest-700 sm:block">
        {t('Results')}
      </p>
      <div className="grid grid-cols-2 col-start-3 justify-between items-center w-full sm:row-start-2">
        <div className="-mt-1 sm:row-start-2">
          <SelectAsync
            value={status}
            onChange={(value) => onChange({ status: value })}
            options={statusOptions}
            name="status"
            disabled={!status}
          />
        </div>

        <p className="ml-auto text-grey-forest-400 sm:row-start-2">
          {completionDate ? format(completionDate, 'MM/dd/yyyy') : null}
        </p>
      </div>
    </>
  );
};
