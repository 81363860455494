import type { FC } from 'react';

import {
  EditPhotosItem,
  ImageCropperModal,
} from '@nodal/core/flows/MatchProfile';
import { FormInputError } from '@nodal/uikit/components/FormInputError';
import { useState } from 'react';

import type { ProfilePhotoEditorProps } from './ProfilePhotoEditor.interface';

export const ProfilePhotoEditor: FC<ProfilePhotoEditorProps> = ({
  value,
  onAdd,
  onEdit,
  onDelete,
  onSave,
  error,
  label,
  name,
  editedUrl,
}) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <div className="flex flex-col gap-4">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div>
        <EditPhotosItem
          onChange={(file) => {
            onAdd(file);
            setEditMode(true);
          }}
          onDelete={onDelete}
          onEdit={() => {
            onEdit();
            setEditMode(true);
          }}
          loading={false}
          item={value}
          sortable={false}
          variant="rounded"
        />
        {error ? <FormInputError error={error} id={name} /> : null}
      </div>
      <ImageCropperModal
        variant="rounded"
        onClose={() => setEditMode(false)}
        open={editMode}
        imageDataUrl={editedUrl}
        onSave={onSave}
      />
    </div>
  );
};
