import type { FC } from 'react';

import { SearchIcon } from '@heroicons/react/solid';
import { useState } from 'react';

import type { SearchProps } from './Search.interface';

export const Search: FC<SearchProps> = ({
  value: initialValue,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  return (
    <div className="flex items-center px-4 w-full bg-white rounded-lg border border-grey-forest-200 shadow-sm">
      <SearchIcon className="w-6 h-6 fill-moss-500" />
      <input
        type="text"
        value={value}
        className="block pr-3 w-full text-sm rounded-lg border-transparent focus:border-transparent focus:outline-none focus:ring-0"
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        {...props}
      />
    </div>
  );
};
