import type { FC, ReactElement } from 'react';

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { screenTypes } from '@nodal/core/consts/screenTypes';
import { t } from '@nodal/i18n';
import { StatusBadge } from '@nodal/uikit/components/StatusBadge';
import { format } from 'date-fns';

import { BackgroundCheck } from './BackgroundCheck';
import { CandidateApplication } from './CandidateApplication';
import { Contract } from './Contract';
import { FinalReview } from './FinalReview';
import { IdentityVerification } from './IdentityVerification';
import { ParentsMatching, SurrogateMatching } from './Matching';
import { MatchingPayment } from './MatchingPayment';
import { MedicalForms } from './MedicalForms';
import { MedicalReview } from './MedicalReview';
import { RequestPayment } from './RequestPayment';

import type { ScreeningDetailsProps } from './ScreeningDetails.interface';
import type { ScreenType } from '@nodal/core/consts/screenTypes';

const screenTypeToElement: {
  [key in ScreenType]?: ReactElement;
} = {
  [screenTypes.requestPayment]: <RequestPayment />,
  [screenTypes.parentsQuestionnaire]: <CandidateApplication />,
  [screenTypes.donorQuestionnaire]: <CandidateApplication />,
  [screenTypes.stripeIdentity]: <IdentityVerification />,
  [screenTypes.checkr]: <BackgroundCheck />,
  [screenTypes.medicalForms]: <MedicalForms />,
  [screenTypes.medicalReview]: <MedicalReview />,
  [screenTypes.contract]: <Contract />,
  [screenTypes.finalReview]: <FinalReview />,
  [screenTypes.parentsMatching]: <ParentsMatching />,
  [screenTypes.surrogateMatching]: <SurrogateMatching />,
  [screenTypes.matchingPayment]: <MatchingPayment />,
};

export const ScreeningDetails: FC<ScreeningDetailsProps> = ({ screens }) => {
  return (
    <div className="flex flex-col gap-3">
      {screens.map(({ title, status, completionDate, type }) => (
        <Disclosure key={title}>
          {({ open }) => (
            <>
              <div className="grid grid-cols-4 gap-4 items-center py-4 px-6 bg-grey-forest-50 rounded-lg">
                <h4 className="col-start-1 text-base font-semibold text-grey-forest-900">
                  {title}
                </h4>
                <p className="hidden col-start-2 w-full text-sm text-grey-forest-500 sm:block">
                  {completionDate
                    ? t(
                        'Completed {date}',
                        format(new Date(completionDate), 'MM/dd/yyyy'),
                      )
                    : null}
                </p>

                <div className="flex col-span-2 col-start-3 gap-1 ml-auto sm:gap-8">
                  {status && <StatusBadge status={status} />}
                  <Disclosure.Button>
                    <ChevronUpIcon
                      className={`${
                        !open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-grey-forest-500`}
                    />
                  </Disclosure.Button>
                </div>
              </div>
              {type && (
                <Disclosure.Panel>
                  <div className=" items-center py-4 px-6 bg-white">
                    {screenTypeToElement[type]}
                  </div>
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};
