import type { FC } from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';

export const AccountDeactivated: FC = () => (
  <div className="flex flex-col grid-cols-8 col-span-10 col-start-2 gap-x-8 justify-center items-center py-16 px-4 m-auto bg-transparent rounded-lg sm:grid sm:justify-center sm:px-0 lg:grid-cols-10">
    <div className="flex col-span-6 col-start-2 row-start-1 justify-center items-center w-full lg:col-start-3">
      <ExclamationCircleIcon className="aspect-square w-12 h-12 fill-red-500" />
    </div>
    <h1 className="col-span-6 col-start-2 row-start-2 pt-8 m-auto w-max text-2xl font-semibold leading-8 text-center text-grey-forest-900 sm:text-3xl sm:font-bold sm:leading-9 lg:col-start-3">
      {t('Account Deactivated')}
    </h1>
    <p className="col-span-6 col-start-2 row-start-3 pt-4 w-full text-base font-normal leading-6 text-center text-grey-forest-700 sm:text-lg lg:col-start-3">
      {t(
        'Your account is currently not active, please contact hello@nodal.com to reactivate your account',
      )}
    </p>
  </div>
);
