import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { CheckboxAsync } from '@nodal/uikit/components/Checkbox';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { candidateDetailsPaths } from 'consts/paths';

import type { FinalReviewProps } from './FinalReview.interface';
import type { ApiModel } from '@nodal/api';

const reviewStatusToLabel = new Map<
  ApiModel.DonorProfileMatchProfileReviewStatus,
  string
>([
  [apiEnums.MatchProfileReviewStatusEnum.Rejected, t('Rejected')],
  [apiEnums.MatchProfileReviewStatusEnum.Accepted, t('Approved')],
]);

const reviewStatusToColor = new Map<
  ApiModel.DonorProfileMatchProfileReviewStatus,
  string
>([
  [apiEnums.MatchProfileReviewStatusEnum.Rejected, 'text-red-500'],
  [apiEnums.MatchProfileReviewStatusEnum.Accepted, 'text-forest-300'],
]);

export const FinalReview: FC<FinalReviewProps> = ({
  shouldShowReviewButton,
  shouldShowDecisionButtons,
  matchProfileReviewStatus,
  onClickReview,
  staffReviews,
  onReviewChange,
}) => {
  return (
    <div className="w-full divide-y divide-grey-forest-100">
      <div className="flex flex-col gap-4 items-center py-3 whitespace-pre-wrap sm:grid sm:grid-cols-3">
        <p className="col-start-1 text-sm text-grey-forest-700">
          {t('Match Profile')}
        </p>

        <div className="flex flex-wrap col-span-2 col-start-2 gap-x-6 gap-y-2 justify-between items-center break-all sm:justify-start">
          {shouldShowDecisionButtons ? (
            <>
              <p className={'text-sm font-medium text-forest-500'}>
                {t('Match profile ready?')}
              </p>
              <div className="flex gap-2">
                <ButtonLoading
                  variant="primary"
                  onClick={() =>
                    onReviewChange({
                      match_profile_review_status:
                        apiEnums.MatchProfileReviewStatusEnum.Rejected,
                    })
                  }
                  className="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-red-500 hover:bg-red-400 rounded-xl border border-transparent focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-sm sm:text-xs"
                >
                  {t('No')}
                </ButtonLoading>
                <ButtonLoading
                  variant="primary"
                  onClick={() =>
                    onReviewChange({
                      match_profile_review_status:
                        apiEnums.MatchProfileReviewStatusEnum.Accepted,
                    })
                  }
                  className="justify-center w-fit sm:text-xs"
                >
                  {t('Yes')}
                </ButtonLoading>
              </div>
            </>
          ) : (
            <p className={'text-sm font-medium text-forest-500'}>
              {t('Match profile review')}
            </p>
          )}
          <Link to={candidateDetailsPaths.matchProfile}>
            <Button
              variant="primary"
              className="justify-center w-fit sm:text-xs"
            >
              {t('View Match Profile')}
            </Button>
          </Link>

          {shouldShowReviewButton ? (
            <Button
              variant={'primary'}
              onClick={onClickReview}
              className="justify-center w-fit sm:text-xs"
              disabled={
                matchProfileReviewStatus ===
                apiEnums.MatchProfileReviewStatusEnum.Pending
              }
            >
              {t('Review')}
            </Button>
          ) : (
            <p
              className={classNames(
                'text-sm font-medium',
                reviewStatusToColor.get(matchProfileReviewStatus),
              )}
            >
              {reviewStatusToLabel.get(matchProfileReviewStatus)}
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-4 py-3 whitespace-pre-wrap sm:grid sm:grid-cols-3">
        <p className="col-start-1 text-sm text-grey-forest-700">
          {t('Staff Review')}
        </p>

        <div className="flex flex-col flex-wrap col-span-2 col-start-2 gap-x-6 gap-y-4 break-all">
          {staffReviews.map(({ label, name, value }) => (
            <CheckboxAsync
              label={label}
              id={name}
              checked={!!value}
              key={name}
              onChange={(newValue) =>
                onReviewChange({
                  [name]: newValue,
                })
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};
