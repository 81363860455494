import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import {
  EditPhotos,
  useEditPhotos,
} from '@nodal/core/flows/MatchProfile/EditPhotos';
import { OverlayPhoto } from '@nodal/core/flows/MatchProfile/EditPhotos/OverlayPhoto';
import { useDragAndDrop } from '@nodal/core/hooks/useDragAndDrop';
import { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useMutation, useQueryClient } from 'react-query';

import { useCandidate } from 'components/CandidateDetails/CandidateDetails.connect';

import type {
  ApiModel,
  UserProfilePhotoFile,
  UserProfilePhoto,
} from '@nodal/api';

const usePhotoActions = ({ userId }: { userId?: number }) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const addPhoto = useMutation(
    (requestParameters: ApiModel.UsersApiUsersPhotosCreateRequest) =>
      apiClient.api.UsersApi.usersPhotosCreate(requestParameters),
    {
      onSuccess: (...[, variables]) => {
        queryClient.invalidateQueries([
          queryKeys.candidateRetrieve,
          variables.userId,
        ]);
      },
    },
  );

  const updatePhoto = useMutation(
    (requestParameters: ApiModel.UsersApiUsersPhotosPartialUpdateRequest) =>
      apiClient.api.UsersApi.usersPhotosPartialUpdate(requestParameters),
    {
      onSuccess: (...[, variables]) => {
        queryClient.invalidateQueries([
          queryKeys.candidateRetrieve,
          variables.userId,
        ]);
      },
    },
  );

  const removePhoto = useMutation(
    (requestParameters: ApiModel.UsersApiUsersPhotosDestroyRequest) =>
      apiClient.api.UsersApi.usersPhotosDestroy(requestParameters),
    {
      onSuccess: (...[, variables]) => {
        queryClient.invalidateQueries([
          queryKeys.candidateRetrieve,
          variables.userId,
        ]);
      },
    },
  );

  const onUploadPhoto = useCallback(
    async (
      data: Omit<
        ApiModel.UsersApiUsersPhotosCreateRequest,
        'image' | 'userId'
      > & {
        image: File;
      },
    ) => {
      try {
        // NOTE: Bug in API schema - image for upload photo has File type
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const submitResponse = await addPhoto.mutateAsync({ ...data, userId });

        return submitResponse?.data;
      } catch (error) {
        // TODO: Premove this if we will have error handling
        return console.error(error);
      }
    },
    [addPhoto, userId],
  );

  const onEditPhotos = useCallback(
    async (data: Array<UserProfilePhotoFile>) => {
      try {
        // NOTE: Bug in API schema - image for update photo has File type
        await Promise.all(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data.map((file) => updatePhoto.mutateAsync({ ...file, userId })),
        );

        return { success: true };
      } catch (error) {
        return { success: false, error };
      }
    },
    [updatePhoto, userId],
  );

  const onDeletePhoto = useCallback(
    async (data: UserProfilePhoto) => {
      if (!userId) return;
      try {
        const response = await removePhoto.mutateAsync({ ...data, userId });

        return response?.data;
      } catch (error) {
        // TODO: remove this if we will have error handling
        return console.error(error);
      }
    },
    [removePhoto, userId],
  );

  return {
    onUpload: onUploadPhoto,
    onUpdate: onEditPhotos,
    onRemove: onDeletePhoto,
  };
};

export const EditPhotosConnected = ({ minPhotos = 9 }) => {
  const { candidate } = useCandidate();
  const { onUpload, onUpdate, onRemove } = usePhotoActions({
    userId: candidate?.id,
  });

  const {
    photos,
    editedImageData,
    loadingPhotos,
    onReorder,
    ...photoHandlers
  } = useEditPhotos({
    minPhotos,
    onUpload,
    onUpdate,
    onRemove,
    profileData: candidate,
  });

  const { sensors, onDragHandlers, draggedId } = useDragAndDrop(onReorder);

  const draggedPhoto = photos.find((item) => item.id === draggedId);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      {...onDragHandlers}
    >
      <SortableContext items={photos} strategy={rectSortingStrategy}>
        <EditPhotos
          photos={photos}
          loadingPhotos={loadingPhotos}
          editedImageData={editedImageData}
          {...photoHandlers}
        />
      </SortableContext>
      {draggedPhoto?.image &&
        createPortal(
          <DragOverlay>
            <OverlayPhoto src={draggedPhoto.image} />
          </DragOverlay>,
          document.body,
        )}
    </DndContext>
  );
};
