import { apiEnums } from '@nodal/api';

import { settings } from 'settings';

import type { RecordPullFormValues } from '../MedicalRequestFormsWrapper.interface';

const defaultFaxFormDetails = settings.getDefaultFaxFormDetails();

export const initialValues: RecordPullFormValues = {
  third_party_provider: apiEnums.ThirdPartyProviderEnum.Fax,
  first_name: null,
  middle_name: null,
  last_name: null,
  date_of_birth: null,
  address: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    zipcode: null,
    location: null,
  },
  phone_number: null,
  delivery: 1,
  data_provider_type: apiEnums.DataProviderTypeEnum.ObGyn,
  doctor_first_name: null,
  doctor_last_name: null,
  doctor_address: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    zipcode: null,
    location: null,
  },
  doctor_phone_number: null,
  fax_details: {
    fax_adapter_type: apiEnums.FaxAdapterTypeEnum.Srfax,
    prenatal_records_selected: true,
    delivery_records_selected: true,
    disease_records_selected: true,
    other_records_selected: false,
    other_records_details: null,
    ob_clearance_letter_required: false,
    to_fax_number: null,
    cp_from_name: defaultFaxFormDetails?.cp_from_name || null,
    cp_to_name: defaultFaxFormDetails?.cp_to_name || null,
    cp_organization: null,
    cp_subject: defaultFaxFormDetails?.cp_subject || null,
    cp_comment: defaultFaxFormDetails?.cp_comment_srfax,
    sender_email: defaultFaxFormDetails?.sender_email || null,
    from_header: defaultFaxFormDetails?.from_header || null,
    to_email: null,
  },
};
