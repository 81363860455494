import { t } from '@nodal/i18n';

import { candidateRequestStepIds } from 'consts/candidateRequestStepIds';
import { requestPaths } from 'consts/paths';
import { getCurrentScreeningPayment } from 'utils/candidateRequest';

import { canProceedWithPayment } from '@b2b/utils/payment';

import type { RequestStep } from './CandidateRequest.interface';

export const requestStepsMeta: RequestStep[] = [
  {
    title: t('Candidate Information'),
    path: requestPaths.profile,
    id: candidateRequestStepIds.profile,
    isCurrentStep: (request) => !request,
    index: 0,
  },
  {
    title: t('Screening Package'),
    path: requestPaths.screening,
    id: candidateRequestStepIds.screening,
    isCurrentStep: (request) =>
      !!(
        request &&
        !getCurrentScreeningPayment(request.screening_payments)?.package
      ),
    index: 1,
  },
  {
    title: t('Nodal Contract'),
    path: requestPaths.contract,
    id: candidateRequestStepIds.contract,
    isCurrentStep: (request) =>
      !!(
        request &&
        !getCurrentScreeningPayment(request.screening_payments)?.contract
          ?.is_signed
      ),
    index: 2,
  },
  {
    title: t('Payment'),
    path: requestPaths.payment,
    id: candidateRequestStepIds.payment,
    isCurrentStep: (request) => {
      const paymentStatus = request
        ? getCurrentScreeningPayment(request?.screening_payments).payment_status
        : undefined;

      return !!(request && canProceedWithPayment(paymentStatus));
    },
    index: 3,
  },
];
