import type { FC } from 'react';

import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import {
  ScreeningContextProvider,
  useScreening,
} from '@nodal/core/flows/Screening';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { screeningPaths } from 'consts/paths';

import { Screening } from './Screening';

const stepToPath = new Map<string, string>([
  ['parents-profile', screeningPaths.profile],
  ['donor-profile', screeningPaths.profile],
  ['parents-questionnaire', screeningPaths.questions],
  ['donor-questionnaire', screeningPaths.questions],
  ['stripe-identity', screeningPaths.identity],
  ['checkr', screeningPaths.background],
  ['hipaa-consent-form', screeningPaths.signing],
  ['medical-record-review', screeningPaths.medical],
  ['organization-candidate-profile', screeningPaths.profile],
]);

const ScreeningContextConsumer: FC = () => {
  const apiClient = useApiClient();
  const { currentStep, steps, completed } = useScreening();

  const { data: userMe } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  if (!userMe?.data.role || !steps?.length || (!completed && !currentStep))
    return <LoadingScreen />;

  return (
    <Screening
      role={userMe.data.role}
      steps={steps}
      activeStepIndex={currentStep?.index}
    />
  );
};

const ScreeningInitialRedirect: FC = () => {
  const { started, completed, steps, currentStep, refresh } = useScreening();
  const navigate = useNavigate();

  useEffect(() => {
    refresh();
    // NOTE: Only on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (completed) {
      navigate(`${screeningPaths.complete}`);
      return;
    }

    // NOTE: We cannot determine yet where we are.
    if (!currentStep || !steps.length) {
      return;
    }

    const allPending = steps.every(
      (s) => s.status === apiEnums.ScreenStatusEnum.Pend,
    );

    if (allPending && !started) {
      navigate(screeningPaths.profile);
      return;
    }

    if (!allPending || started) {
      const path = stepToPath.get(currentStep.type) || screeningPaths.profile;
      navigate(path);
      return;
    }
  }, [navigate, steps, currentStep, started, completed]);

  return <></>;
};

export const ScreeningConnected: FC = () => (
  <ScreeningContextProvider>
    <Routes>
      <Route path="*" element={<ScreeningInitialRedirect />} />
    </Routes>

    <ScreeningContextConsumer />
  </ScreeningContextProvider>
);
