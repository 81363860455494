import { usePermissions } from '@b2b/app/PermissionsProvider';
import { useCandidate } from '@b2b/components/CandidateDetails';

import { roleToMedicalReviewsUids } from '../stages';

import { ReviewDecision } from './ReviewDecision';

export const ReviewDecisionConnected = () => {
  const { permissions } = usePermissions();
  const { candidate, onUpdateMedicalReview } = useCandidate();

  const reviewDecisionUid = roleToMedicalReviewsUids.get(
    candidate?.role,
  )?.reviewDecision;

  const { status, completion_date } =
    candidate?.medical_review?.steps?.find(
      ({ uid }) => uid === reviewDecisionUid,
    ) || {};

  if (!reviewDecisionUid || !candidate) {
    return null;
  }

  return (
    <ReviewDecision
      onChange={(data) =>
        onUpdateMedicalReview(candidate.id, data, reviewDecisionUid)
      }
      supervisionAccess={!!permissions?.canInitiateMedicalRequest}
      status={status}
      completionDate={completion_date ? new Date(completion_date) : undefined}
    />
  );
};
