import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { RadioGroup } from '@nodal/uikit/components/RadioGroup';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';

import { settings } from 'settings';
import { payerToLabel } from 'utils/candidate';

import { requestPaths } from '@b2b/consts/paths';

import type {
  PayerOption,
  ScreeningPackageProps,
} from './ScreeningPackage.interface';

const payerOptions: PayerOption[] = [
  {
    value: apiEnums.PayingEntityEnum.ExtParents,
    label: payerToLabel.get(apiEnums.PayingEntityEnum.ExtParents) || '',
  },
  {
    value: apiEnums.PayingEntityEnum.Org,
    label: payerToLabel.get(apiEnums.PayingEntityEnum.Org) || '',
  },
];

const payerSelectionEnabled = settings.getFeatureAdminExternalPaymentEnabled();

export const ScreeningPackage: FC<ScreeningPackageProps> = ({
  packages,
  initialValues,
  validationSchema,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form className="flex flex-col space-y-4 w-full lg:w-step-view ">
          <div className="flex flex-col p-8 bg-white rounded-lg border border-grey-forest-200">
            <div>
              <h2 className="text-2xl font-semibold leading-7 text-gray-900">
                {t('Screening Package')}
              </h2>
              <p className="pt-2 text-base font-normal leading-6 text-grey-forest-600 sm:text-sm sm:leading-5">
                {t(
                  "Select the screening package(s) you'd like the Candidate to receive.",
                )}
              </p>
            </div>
            <div className="mt-6">
              <h3 className="text-xl font-semibold leading-7 text-gray-900">
                {t('Candidate Screening')}
              </h3>
              <RadioGroup
                name="package"
                options={packages.map(({ id, label, description }) => ({
                  value: id?.toString(),
                  label,
                  description,
                }))}
              />
            </div>
          </div>
          {values.paying_entity !== apiEnums.PayingEntityEnum.Prepaid &&
            payerSelectionEnabled && (
              <div className="flex flex-col p-8 bg-white rounded-lg border border-grey-forest-200">
                <div>
                  <h2 className="text-2xl font-semibold leading-7 text-gray-900">
                    {t('Payment Details')}
                  </h2>
                  <p className="pt-2 text-base font-normal leading-6 text-grey-forest-600 sm:text-sm sm:leading-5">
                    {t(
                      'Select who is paying for the medical screening process.',
                    )}
                  </p>
                </div>
                <div className="mt-2">
                  <RadioGroup
                    name="paying_entity"
                    options={[payerOptions[0]]}
                  />
                  {values.paying_entity ===
                    apiEnums.PayingEntityEnum.ExtParents && (
                    <div className="mt-4 mb-8 space-y-8">
                      <TextInput
                        name="payer_name"
                        type="text"
                        label={t('Name')}
                      />
                      <TextInput
                        name="payer_email"
                        type="text"
                        label={t('Email')}
                      />
                    </div>
                  )}
                  <RadioGroup
                    name="paying_entity"
                    options={[payerOptions[1]]}
                  />
                </div>
              </div>
            )}

          <div className="flex gap-4 justify-between">
            <Link to={`../${requestPaths.profile}`} className="w-full sm:w-max">
              <Button
                className="justify-center px-6 w-full sm:w-max"
                variant="tertiary"
              >
                {t('Back')}
              </Button>
            </Link>
            <ButtonLoading
              type="submit"
              variant="primary"
              loading={isSubmitting}
              className="justify-center px-6 w-full sm:w-max"
              disabled={!values.package || !values.paying_entity}
            >
              {values.paying_entity === apiEnums.PayingEntityEnum.Prepaid
                ? t('Submit')
                : t('Next')}
            </ButtonLoading>
          </div>
        </Form>
      )}
    </Formik>
  );
};
