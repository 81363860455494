import { apiEnums } from '@nodal/api';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  externalPayerAppPaths as appPaths,
  externalPayerPaths,
} from '@b2b/consts/paths';

import type { ApiModel } from '@nodal/api';

export const useExternalPayerForcedRedirectPath = ({
  role,
}: {
  role?: ApiModel.UserRoleEnum;
}) => {
  const [forcedRedirectPath, setForcedRedirectPath] = useState<string>();
  const params = useParams();
  const path = params['*'] || '';

  useEffect(() => {
    if (role) {
      if (role !== apiEnums.UserRoleEnum.Oextp) {
        setForcedRedirectPath(externalPayerPaths.logout);
        return;
      }

      if (!path) {
        setForcedRedirectPath(appPaths.request);
        return;
      }
    }
  }, [role, path]);

  return forcedRedirectPath;
};
