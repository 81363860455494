import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';

export const introductionStageOptions = [
  {
    value: apiEnums.IntroductionStageEnum.Intros,
    label: t('Introductions'),
  },
  {
    value: apiEnums.IntroductionStageEnum.MatchAcceptance,
    label: t('Match Acceptance'),
  },
  {
    value: apiEnums.IntroductionStageEnum.Matched,
    label: t('Matched'),
  },
  {
    value: apiEnums.IntroductionStageEnum.Broken,
    label: t('Match Broken'),
  },
  {
    value: apiEnums.IntroductionStageEnum.Expired,
    label: t('Expired'),
  },
];
