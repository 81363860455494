import { usePermissions } from '@b2b/app/PermissionsProvider';
import { useCandidate } from '@b2b/components/CandidateDetails';

import { roleToMedicalReviewsUids } from '../stages';

import { RxDxHistory } from './RxDxHistory';

export const RxDxHistoryConnected = () => {
  const { permissions } = usePermissions();
  const { candidate, onUpdateMedicalReview } = useCandidate();

  const rxDxHistoryUid = roleToMedicalReviewsUids.get(
    candidate?.role,
  )?.rxDxHistory;

  const { status, content_object, completion_date } =
    candidate?.medical_review?.steps?.find(
      ({ uid }) => uid === rxDxHistoryUid,
    ) || {};

  if (!rxDxHistoryUid || !candidate) {
    return null;
  }

  return (
    <RxDxHistory
      status={status}
      details={content_object}
      completionDate={completion_date ? new Date(completion_date) : undefined}
      onChange={(data) =>
        onUpdateMedicalReview(candidate.id, data, rxDxHistoryUid)
      }
      supervisionAccess={!!permissions?.canInitiateMedicalRequest}
    />
  );
};
