import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import type { PdfElement } from '@nodal/core/flows/MatchProfile';

const defaultOptions = {
  scale: 2,
  scrollY: -window.scrollY,
  backgroundColor: '#F8F7F6',
  letterRendering: true,
  allowTaint: true,
  useCORS: true,
};

const defaultPadding = 15;

export const convertToPdf = async (pdfElements: PdfElement[], name: string) => {
  let pageCount = 1;
  let positionY = 0;

  const pdf = new jsPDF('p', 'px', 'a4');

  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  pdf.setFillColor(defaultOptions.backgroundColor);
  pdf.rect(0, pageHeight, pageWidth, -pageHeight, 'F');

  for (const element of pdfElements) {
    const canvas = await html2canvas(element.ref, defaultOptions);
    const imgHeight = (canvas.height * pageWidth) / canvas.width;

    if (element.page !== pageCount) {
      pdf.addPage();
      const heightLeft = imgHeight - pageHeight;

      pdf.setFillColor(defaultOptions.backgroundColor);
      pdf.rect(0, pageHeight, pageWidth, heightLeft, 'F');
      pageCount = pageCount + 1;
      positionY = 0;
    }

    // NOTE: Here, we render 'element.ref' as an image on the PDF, capturing content
    // from the DOM. The previously downloaded images (in MatchProfile.connect) are included
    // within 'element.ref,' ensuring they become part of the rendered content.
    const imgData = canvas.toDataURL('image/jpeg');

    pdf.addImage(imgData, 'JPEG', 0, positionY, pageWidth, imgHeight);

    if (element.page === pageCount) {
      positionY += imgHeight + defaultPadding;
    }
  }

  pdf.save(`${name}.pdf`);
};
