import type { FC } from 'react';

import { PencilIcon } from '@heroicons/react/solid';
import { BadgeJustJoined } from '@nodal/core/assets';
import { t } from '@nodal/i18n';
import { AvatarPlaceholderSvg } from '@nodal/uikit/assets';
import { Button } from '@nodal/uikit/components/Button';
import { DropdownMenu } from '@nodal/uikit/components/DropdownMenu';
import { ProgressBar } from '@nodal/uikit/components/ProgressBar';

import { candidateRoleToLabel } from '@b2b/utils/candidate';

import type { CandidateProfileProps } from './CandidateProfile.interface';

export const CandidateProfile: FC<CandidateProfileProps> = ({
  name,
  id,
  show_matching_badge,
  role,
  profileDetails,
  patientReport,
  photoUrl,
  onEdit,
  dropdownMenuItems,
  finishedTasksCoefficient,
  finishedTasksPercent,
  onViewTasksDetails,
}) => (
  <>
    <div className="p-8 w-full bg-white rounded-lg">
      <div className="flex flex-col grid-cols-2 gap-12 justify-between sm:grid">
        <div className="flex flex-col gap-6 w-full lg:flex-row">
          <div className="flex relative min-w-[200px] max-w-[200px] min-h-[200px] max-h-[200px]">
            <img
              src={photoUrl || AvatarPlaceholderSvg}
              className="object-cover w-full rounded-lg border border-gray-200 drop-shadow-lg"
            />
            {show_matching_badge && (
              <img
                className="absolute -top-6 -left-6 w-[72px] h-[72px]"
                src={BadgeJustJoined}
              />
            )}
          </div>
          <div className="lg:w-2/3">
            <div className="flex justify-between w-full">
              <h3 className="text-xl font-semibold leading-7 text-gray-900">
                {name}
              </h3>

              <p className="text-base font-medium text-grey-forest-500">
                ID: {id}
              </p>
            </div>
            <div className="flex justify-between mt-1">
              <p className="py-1 text-base font-medium text-grey-forest-500">
                {candidateRoleToLabel.get(role)}
              </p>
              {!patientReport && dropdownMenuItems.length > 0 && (
                <DropdownMenu menuItems={dropdownMenuItems} />
              )}
            </div>
            {finishedTasksCoefficient && (
              <>
                <div className="pt-4">
                  <ProgressBar
                    variant="lg"
                    progress={finishedTasksCoefficient}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <p className="py-1 text-xs font-medium leading-6 text-grey-forest-500">
                    <span className="pr-2 text-grey-forest-900">
                      {finishedTasksPercent}
                    </span>
                    {t('Tasks completed')}
                  </p>
                  <button
                    type="button"
                    className="text-xs font-medium leading-6 text-grey-forest-500 underline"
                    onClick={onViewTasksDetails}
                  >
                    {t('View details')}
                  </button>
                </div>
              </>
            )}

            <div className="flex gap-4 mt-6 w-full lg:mt-12">
              {patientReport && (
                <>
                  <a href={patientReport.url} rel="noreferrer" target="_blank">
                    <Button
                      variant="primary"
                      disabled={!patientReport.url}
                      className="justify-center w-full rounded-lg"
                    >
                      {patientReport.url
                        ? t('View Patient Report')
                        : t('Patient Report Pending')}
                    </Button>
                  </a>
                  {dropdownMenuItems.length > 0 && (
                    <DropdownMenu menuItems={dropdownMenuItems} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-8 w-full">
          <div>
            <div className="flex justify-between w-full">
              <h4 className="text-base font-semibold text-grey-forest-900">
                {t('Contact Information')}
              </h4>
              {onEdit && (
                <button
                  onClick={onEdit}
                  type="button"
                  className="flex gap-2 justify-between items-center px-3"
                >
                  <PencilIcon className="w-5 h-5 text-forest-400 sm:w-4 sm:h-4" />
                  <p className="hidden text-sm font-medium leading-4 text-forest-500 sm:block">
                    {t('Edit')}
                  </p>
                </button>
              )}
            </div>
            {profileDetails.map(({ label, value }) => (
              <div
                className="grid grid-cols-1/2 py-4 border-b border-grey-forest-200"
                key={label}
              >
                <p className="w-fit text-base font-medium text-grey-forest-500">
                  {label}
                </p>
                <p className="w-full text-base font-medium text-grey-forest-900 whitespace-pre-line">
                  {value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </>
);
