import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { MatchProfileSkeleton } from '@nodal/core/flows/MatchProfile';
import { hasSamePartnerAddress } from '@nodal/core/utils/profile';

import { useCandidate } from '../CandidateDetails.connect';

import { MatchProfileConnected as MatchProfile } from './MatchProfile.connect';

import type { ApiModel } from '@nodal/api';

export const ParentsMatchProfileConnected: FC = () => {
  const { candidate, profile } = useCandidate();

  // NOTE: Type casting because we define the type in more detail than we get from the API,
  // the view will be only for parents profile
  const parentsProfile = profile as ApiModel.ParentsProfile;

  if (!candidate || !profile) {
    return <MatchProfileSkeleton inMarketplace={false} />;
  }

  const user = {
    ...candidate,
    role: apiEnums.UserRoleEnum.Par,
    profile: {
      ...parentsProfile,
      same_partner_address: hasSamePartnerAddress(parentsProfile),
    },
  };

  return <MatchProfile user={user} />;
};
