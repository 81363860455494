// TODO: Consider making secrets less visible in the app
export const settings = {
  getBasePath() {
    return import.meta.env.VITE_BASE_PATH;
  },
  getCandidateSignUpBasePath() {
    return import.meta.env.VITE_CANDIDATE_SIGN_UP_BASE_PATH;
  },
  getOrganizationSignUpBasePath() {
    return import.meta.env.VITE_ORGANIZATION_SIGN_UP_BASE_PATH;
  },
  getApiUrl() {
    return import.meta.env.VITE_API_URL || '';
  },
  getPostHogEnabled() {
    return !!import.meta.env.VITE_POSTHOG_API_KEY;
  },
  getCandidateSignUpHidden() {
    return import.meta.env.VITE_CANDIDATE_SIGN_UP_HIDDEN === 'true';
  },
  getOrganizationSignUpHidden() {
    return import.meta.env.VITE_ORGANIZATION_SIGN_UP_HIDDEN === 'true';
  },
  getStripePublishableKey() {
    return import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '';
  },
  getHelloSignAppId() {
    return import.meta.env.VITE_HELLOSIGN_APP_ID || '';
  },
  // NOTE: It informs that the egg and sperm donor roles are included in the candidate roles.
  // Currently, we have these options disabled in the application,
  // but it is planned to turn them back on in the future.
  // TODO: When enabling these roles - remove this flag
  hasSpermAndEggDonorsIncludedInCandidateRoles() {
    return (
      import.meta.env.VITE_FEAT_SPERM_EGG_DONORS_INCLUDED_IN_CANDIDATE_ROLES ===
      'true'
    );
  },
  getMedicalRequestProviderEmail() {
    return import.meta.env.VITE_MEDICAL_REQUEST_PROVIDER_EMAIL || '';
  },
  getFeatureAdminExternalPaymentEnabled() {
    return import.meta.env.VITE_FEAT_ADMIN_EXTERNAL_PAYMENT_ENABLED === 'true';
  },
  getDefaultFaxFormDetails() {
    return JSON.parse(
      import.meta.env.VITE_FEAT_DEFAULT_FAX_FORM_DETAILS || '""',
    );
  },
  getIpDashboardFeatureEnabled() {
    return import.meta.env.VITE_IP_DASHBOARD_FEATURE_ENABLED === 'true';
  },
};
