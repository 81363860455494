import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { t } from '@nodal/i18n';

export const InvalidPaymentLink = () => {
  return (
    <div className="flex gap-8 justify-center items-center min-h-screen bg-beige-50">
      <div className="flex flex-col gap-4 justify-center items-center">
        <div className="flex overflow-hidden relative z-20 justify-center items-center w-12 h-12 bg-red-100 rounded-full">
          <ExclamationCircleIcon className="absolute z-10 w-20 h-20 text-red-600 bg-red-100 stroke-1" />
        </div>
        <div className="text-center">
          <h2 className="mx-auto mb-2 text-2xl font-semibold leading-8 text-grey-forest-900">
            {t('Payment link has expired or is invalid')}
          </h2>
          <p className="text-base font-normal leading-6 text-grey-forest-600">
            {t(
              'Sorry for the inconvenience, it looks like the payment link has expired or is invalid.',
            )}
          </p>
          <p className="text-base font-normal leading-6 text-grey-forest-600">
            {t(
              'Please contact your organization to receive a new payment link.',
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
