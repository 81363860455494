import type { FC } from 'react';

import { t } from '@nodal/i18n';
import classNames from 'classnames';

import type { StatusBagdeProps, Status } from './StatusBadge.interface';

const labelByStatus: Record<Status, string> = {
  unregistered: t('Not Registered'),
  pending: t('Not Started'),
  processing: t('In Progress'),
  complete: t('Complete'),
  review: t('In Review'),
  rejected: t('Disqualified'),
  deactivated: t('Deactivated'),
  failed: t('Failed'),
};

const labelColorByStatus: Record<Status, string> = {
  unregistered: 'text-yellow-600',
  pending: 'text-yellow-700',
  processing: 'text-blue-500',
  review: 'text-blue-500',
  complete: 'text-forest-400',
  rejected: 'text-red-700',
  deactivated: 'text-grey-forest-400',
  failed: 'text-red-700',
};

export const StatusBadge: FC<StatusBagdeProps> = ({
  status,
  dot = false,
  label,
}) => (
  <div className="flex gap-2 items-center w-max">
    {dot && (
      <div className="flex absolute -ml-4 w-max">
        <span className="w-2 h-2 bg-red-600 rounded-full" />
      </div>
    )}
    <span
      className={classNames(
        'text-sm font-medium w-full',
        labelColorByStatus[status],
      )}
    >
      {label || labelByStatus[status]}
    </span>
  </div>
);
