import { t } from '@nodal/i18n';

import { canViewMatchingSections } from 'utils/candidate';

import { appPaths, organizationPaths, candidatePaths } from '@b2b/consts/paths';

import type { NavigationItem } from '@nodal/uikit/components/NavigationItems';

export const getOrganizationNavigationItemsByPermissions = ({
  canViewAllOrganizations,
  canManageMatching,
  isMatchingFeatureEnabled,
}: {
  canViewAllOrganizations: boolean;
  canManageMatching: boolean;
  isMatchingFeatureEnabled: boolean;
}) => {
  let navigationItems = [
    {
      label: t('Candidates'),
      href: `${organizationPaths.app}/${appPaths.candidates}`,
    },
  ];

  if (canViewAllOrganizations) {
    navigationItems = [
      ...navigationItems,
      {
        label: t('Organizations'),
        href: `${organizationPaths.app}/${appPaths.organizations}`,
      },
    ];
  }

  if (
    canViewMatchingSections({
      canViewAllOrganizations,
      canManageMatching,
      isMatchingFeatureEnabled,
    })
  ) {
    navigationItems = [
      ...navigationItems,
      {
        label: t('Introductions'),
        href: `${organizationPaths.app}/${appPaths.introductions}`,
      },
      {
        label: t('Nodal Marketplace'),
        href: `${organizationPaths.app}/${appPaths.nodalMarketplace}`,
      },
    ];
  }

  return navigationItems;
};

export const organizationMenuItems: NavigationItem[] = [
  {
    href: `${organizationPaths.app}/${appPaths.profile}`,
    label: t('Edit Profile'),
  },
  {
    href: organizationPaths.logout,
    label: t('Sign out'),
    active: true,
  },
];

export const candidateMenuItems: NavigationItem[] = [
  {
    href: candidatePaths.logout,
    label: t('Sign out'),
    active: true,
  },
];
