import { TrashIcon } from '@heroicons/react/outline';
import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import {
  appPaths,
  organizationPaths as paths,
  requestPaths,
} from 'consts/paths';

import {
  SearchFilterTable,
  useSearchFilterTable,
} from '@b2b/components/SearchFilterTable';

import { useCancelRequest } from './useCancelRequest';

import type { ApiModel, QueryParameters } from '@nodal/api';

const columnHelper = createColumnHelper<ApiModel.Invitation>();

export const getColumns = (cancelRequest: (id: number) => Promise<void>) => [
  columnHelper.accessor('id', {
    cell: (info) => info.getValue(),
    enableSorting: false,
  }),
  columnHelper.accessor((row) => `${row.first_name} ${row.last_name}`, {
    id: 'search_name_display',
    cell: (info) => (
      <span className="text-grey-forest-700">{info.getValue()}</span>
    ),
    header: () => t('Name'),
  }),
  columnHelper.accessor((row) => format(new Date(row.created), 'P'), {
    id: 'created',
    header: () => t('Date of request'),
    cell: (info) => info.renderValue(),
  }),
  columnHelper.accessor(
    (row) => (
      <div className="flex gap-2 items-center">
        <Link
          to={`${paths.app}/${appPaths.request}/${requestPaths.update}/${row.id}`}
          className="ml-auto"
        >
          <Button variant="primary" className="w-max font-normal sm:text-xs">
            {t('Continue')}
          </Button>
        </Link>
        <Button
          variant="danger"
          onClick={async () => await cancelRequest(row.id)}
        >
          <TrashIcon className="w-4 h-4 text-white" />
        </Button>
      </div>
    ),
    {
      id: 'action',
      cell: (info) => info.renderValue(),
      header: () => '',
      enableSorting: false,
    },
  ),
];

export const InvitationsSearchFilterTableConnected = () => {
  const apiClient = useApiClient();
  const { cancelRequest } = useCancelRequest();

  const initialQueryParameters: QueryParameters<ApiModel.B2bApiB2bInvitationsListRequest> =
    {
      page: 1,
      pageSize: 10,
      ordering: ['-created'],
      search: '',
      filters: {
        status: [apiEnums.InvitationStatusEnum.NotSent],
      },
    };

  const queryCallback = (
    query: QueryParameters<ApiModel.B2bApiB2bInvitationsListRequest>,
  ) =>
    apiClient.api.B2bApi.b2bInvitationsList({
      ...query,
      ...query.filters,
      searchNameDisplay: query.search,
      // NOTE: Use casting because ordering type (string[]) in QueryParameters
      // is less specific than in the API Schema
    } as ApiModel.B2bApiB2bInvitationsListRequest);

  const table = useSearchFilterTable<
    ApiModel.Invitation,
    ApiModel.B2bApiB2bInvitationsListRequest
  >({
    initialQuery: initialQueryParameters,
    columns: getColumns(cancelRequest),
    queryKey: queryKeys.invitationsList,
    queryCallback,
    urlSearchParamsEnabled: false,
  });

  return <SearchFilterTable {...table} />;
};
