import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { useModal } from '@nodal/uikit/components/Modal';
import { useQuery } from 'react-query';

import { useCandidate } from '@b2b/components/CandidateDetails';
import { ParentsProfileViews } from '@b2b/components/CandidateDetails/CandidateOverview/ParentsProfileViews';

import { Matching } from './Matching';
import { buildIntroductions, buildMatchingDetails } from './utils';

export const SurrogateMatchingConnected = () => {
  const apiClient = useApiClient();
  const { candidate } = useCandidate();
  const { open } = useModal();

  const { data: usersIntroductionsList } = useQuery(
    queryKeys.usersIntroductionsList,
    () =>
      candidate?.id
        ? apiClient.api.UsersApi.usersIntroductionsList({
            userId: candidate.id,
          })
        : null,
  );

  const { data: favoritedList } = useQuery(queryKeys.usersFavoritedList, () =>
    candidate?.id
      ? apiClient.api.UsersApi.usersFavoritedList({ userId: candidate.id })
      : undefined,
  );

  const onViewParentsList = () => {
    open({
      title: t('IP profile views'),
      render: () => <ParentsProfileViews userId={candidate?.id} />,
    });
  };

  const matchingDetails = buildMatchingDetails({
    favoritesCount: favoritedList?.data?.length ?? 0,
    introductionsCount: usersIntroductionsList?.data?.length,
  });

  if (!matchingDetails || !usersIntroductionsList?.data) {
    return null;
  }

  const introductionsDetails = buildIntroductions(
    usersIntroductionsList.data,
    candidate?.role,
  );

  return (
    <Matching
      matchingDetails={matchingDetails}
      introductions={introductionsDetails}
      onViewParentsList={onViewParentsList}
    />
  );
};
