import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useQuery } from 'react-query';

import { useContractForm } from '@b2b/components/ContractForm/useContractForm';
import { settings } from '@b2b/settings';
import { getCurrentScreeningPayment } from '@b2b/utils/candidateRequest';

import { useCandidateRequest } from '../hooks/useCandidateRequest';

import { PaymentContract } from './PaymentContract';

const clientId = settings.getHelloSignAppId();

export const PaymentContractConnected = () => {
  const { finishStep, request } = useCandidateRequest();
  const apiClient = useApiClient();

  const { data: usersMe } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  const contractDocument = request?.screening_payments
    ? getCurrentScreeningPayment(request?.screening_payments)?.contract
    : undefined;

  const contractForm = useContractForm({
    submit: finishStep,
    userId: usersMe?.data?.id,
    clientId,
    documentId: contractDocument?.id,
  });

  if (!contractForm) {
    return <LoadingScreen />;
  }

  const { contractSignRef, signed } = contractForm;

  return <PaymentContract ref={contractSignRef} signed={signed} />;
};
