import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useScreening } from '@nodal/core/flows/Screening';
import { BackgroundCheck } from '@nodal/core/flows/Screening/BackgroundCheck';
import { useBackgroundCheck } from '@nodal/core/flows/Screening/BackgroundCheck/useBackgroundCheck';
import { t } from '@nodal/i18n';
import { useQuery } from 'react-query';

import type { ApiModel } from '@nodal/api';

const title = t('Background Check');

export const BackgroundCheckConnected: FC = () => {
  const apiClient = useApiClient();
  const { refresh, submit, currentStep } = useScreening();

  const { data: userMe } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  const { email } = userMe?.data ?? {};
  const { partner_email } = userMe?.data
    ?.profile as ApiModel.OrganizationCandidateProfile;

  const {
    alert,
    description,
    submitted,
    validationSchema,
    onSubmit,
    emailData,
  } = useBackgroundCheck({
    refresh,
    submit,
    currentStep,
    email,
    partnerEmail: partner_email || undefined,
  });

  if (!emailData) {
    return null;
  }

  return (
    <BackgroundCheck
      title={title}
      description={description}
      initialValues={{ ...emailData }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      alert={alert}
      submitted={submitted}
    />
  );
};
