import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { AppShell } from '@nodal/uikit/components/AppShell';
import { ConfirmationDialogProvider } from '@nodal/uikit/components/ConfirmationDialog';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { ModalProvider } from '@nodal/uikit/components/Modal';
import { posthog } from 'posthog-js';
import { useEffect, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { CandidateDetails } from '@b2b/components/CandidateDetails';
import { Introduction } from '@b2b/components/CandidateDetails/Introduction';
import { CandidateRequest } from '@b2b/components/CandidateRequest';
import { CandidateRequestSubmitted } from '@b2b/components/CandidateRequestSubmitted';
import { Candidates } from '@b2b/components/Candidates';
import {
  BusinessContactDetails,
  SuperadminContactDetails,
  NonBusinessContactDetails,
} from '@b2b/components/ContactDetails';
import { Introductions } from '@b2b/components/Introductions';
import { NodalMarketplaceDashboard } from '@b2b/components/NodalMarketplaceDashboard';
import { OrganizationDetails } from '@b2b/components/OrganizationDetails';
import { OrganizationPaymentComplete } from '@b2b/components/OrganizationPaymentComplete';
import { Organizations } from '@b2b/components/Organizations';
import { TopNav } from '@b2b/components/TopNav';
import {
  organizationMenuItems,
  getOrganizationNavigationItemsByPermissions,
} from '@b2b/components/TopNav/topNavItems';
import { WelcomeScreen } from '@b2b/components/WelcomeScreen';
import { appPaths } from '@b2b/consts/paths';
import { settings } from '@b2b/settings';
import {
  canCreateCandidateRequest,
  canViewMatchingSections,
} from '@b2b/utils/candidate';

import { useFeatureFlags } from './FeatureFlagsProvider';
import { usePermissions } from './PermissionsProvider';
import { useOrganizationForcedRedirectPath } from './useOrganizationForcedRedirectPath';

const renderContactDetailsElement = (
  canViewAllOrganizations: boolean,
  businessFeatureEnabled: boolean,
) => {
  if (canViewAllOrganizations) {
    return <SuperadminContactDetails />;
  } else if (!canViewAllOrganizations && businessFeatureEnabled) {
    return <BusinessContactDetails />;
  } else if (!businessFeatureEnabled) {
    return <NonBusinessContactDetails />;
  }
};

export const OrganizationAppProtected = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { permissions } = usePermissions();
  const { features } = useFeatureFlags();

  const { pathname } = useLocation();
  const scrollToTopRef = useRef<HTMLDivElement | null>(null);

  const userProfile = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  const { data: invitationsList } = useQuery(queryKeys.invitationsList, () =>
    apiClient.api.B2bApi.b2bInvitationsList(),
  );

  const { data: userData } = userProfile || {};
  const { role, email, profile } = userData?.data ?? {};
  const { first_name, last_name } = profile || {};

  useEffect(() => {
    if (!email || !settings.getPostHogEnabled()) return;

    posthog.identify(email, {
      name: `${first_name} ${last_name}`,
      role,
    });
  }, [role, email, first_name, last_name]);

  useEffect(() => {
    return () => queryClient.removeQueries();
  }, [queryClient]);

  // NOTE: Scroll to the top of the page when the pathname changes,
  // to ensure starting at the beginning of the page,
  // rather than preserving the previous cursor position.
  useEffect(() => {
    scrollToTopRef?.current?.scrollIntoView();
  }, [pathname]);

  const forceRedirectPath = useOrganizationForcedRedirectPath({
    profile: userData?.data?.profile,
    candidateRequests: invitationsList?.data?.results,
    permissions,
    features,
  });

  useEffect(() => {
    if (forceRedirectPath) {
      navigate(forceRedirectPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRedirectPath]);

  const { canManageInvitations, canViewAllOrganizations, canManageMatching } =
    permissions || {};

  return (
    <AppShell
      ref={scrollToTopRef}
      top={
        <TopNav
          user={userData?.data}
          menuItems={organizationMenuItems}
          navigationItems={getOrganizationNavigationItemsByPermissions({
            canViewAllOrganizations: !!canViewAllOrganizations,
            canManageMatching: !!canManageMatching,
            isMatchingFeatureEnabled: !!features?.matching,
          })}
        />
      }
      center={
        role && permissions && features ? (
          <ConfirmationDialogProvider>
            <ModalProvider>
              <Routes>
                {canCreateCandidateRequest({
                  isInvitationFeatureEnabled: features.invitations,
                  canManageInvitations: !!canManageInvitations,
                  canViewAllOrganizations: !!canViewAllOrganizations,
                }) && (
                  <Route path={appPaths.welcome} element={<WelcomeScreen />} />
                )}
                <Route
                  path={`${appPaths.request}/*`}
                  element={<CandidateRequest />}
                />
                <Route path={appPaths.welcome} element={<WelcomeScreen />} />
                <Route
                  path={appPaths.profile}
                  element={renderContactDetailsElement(
                    !!canViewAllOrganizations,
                    features.business,
                  )}
                />
                <Route
                  path={`${appPaths.paymentCompleteId}/*`}
                  element={<OrganizationPaymentComplete />}
                />
                <Route
                  path={`${appPaths.requestSubmittedId}/*`}
                  element={<CandidateRequestSubmitted />}
                />
                <Route
                  path={`${appPaths.candidates}/*`}
                  element={<Candidates />}
                />
                {canViewAllOrganizations && (
                  <>
                    <Route
                      path={`${appPaths.organizations}/*`}
                      element={<Organizations />}
                    />
                    <Route
                      path={`${appPaths.organizationId}/*`}
                      element={<OrganizationDetails />}
                    />
                  </>
                )}
                {canViewMatchingSections({
                  isMatchingFeatureEnabled: features.matching,
                  canManageMatching: !!canManageMatching,
                  canViewAllOrganizations: !!canViewAllOrganizations,
                }) && (
                  <>
                    <Route
                      path={`${appPaths.introductions}/*`}
                      element={<Introductions />}
                    />
                    <Route
                      path={`${appPaths.introductionId}/*`}
                      element={<Introduction />}
                    />
                    <Route
                      path={`${appPaths.nodalMarketplace}/*`}
                      element={<NodalMarketplaceDashboard />}
                    />
                  </>
                )}
                <Route
                  path={`${appPaths.candidateId}/*`}
                  element={<CandidateDetails />}
                />
              </Routes>
            </ModalProvider>
          </ConfirmationDialogProvider>
        ) : (
          <LoadingScreen />
        )
      }
    />
  );
};
