import { addressSchema } from '@nodal/core/consts/addressSchema';
import { t } from '@nodal/i18n';
import * as Yup from 'yup';

export const schema = Yup.object({
  first_name: Yup.string().nullable().required(t('This field is required')),
  middle_name: Yup.string().nullable().optional(),
  last_name: Yup.string().nullable().required(t('This field is required')),
  maiden_name: Yup.string().nullable().optional(),
  phone_number: Yup.number().nullable().required(t('This field is required')),
  date_of_birth: Yup.string().nullable().required(t('This field is required')),
  address: addressSchema.required(),
  image: Yup.mixed<File>().nullable().required(t('You must upload a photo')),
});
