import type { FC } from 'react';

import {
  DesktopComputerIcon,
  PencilAltIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { apiEnums } from '@nodal/api';
import { TextareaAsync } from '@nodal/uikit/components/Textarea';

import type { NoteItemProps } from './NoteItem.interface';
import type { ApiModel } from '@nodal/api';

const getIconBy = (type?: ApiModel.TypeEnum) => {
  switch (type) {
    case apiEnums.TypeEnum.Manual:
      return PencilAltIcon;
    case apiEnums.TypeEnum.Auto:
      return DesktopComputerIcon;
    default:
      return null;
  }
};

export const NoteItem: FC<NoteItemProps> = ({
  content,
  id,
  onUpdate,
  onRemove,
  type,
}) => {
  const Icon = getIconBy(type);

  return (
    <div className="flex gap-2 items-center" key={id}>
      {Icon && <Icon className="w-5 h-5 text-forest-500" />}
      <TextareaAsync
        onBlur={(value) => onUpdate(id, value)}
        value={content}
        className="w-full"
      />
      <button type="button" onClick={() => onRemove(id)}>
        <TrashIcon className="w-5 h-5 text-grey-forest-500" />
      </button>
    </div>
  );
};
