export const badgeStatus = {
  unregistered: 'unregistered',
  pending: 'pending',
  processing: 'processing',
  complete: 'complete',
  review: 'review',
  rejected: 'rejected',
  deactivated: 'deactivated',
  failed: 'failed',
} as const;

export type Status = typeof badgeStatus[keyof typeof badgeStatus];

export interface StatusBagdeProps {
  status: Status;
  dot?: boolean;
  label?: string;
}
