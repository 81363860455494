import { useApiClient } from '@nodal/api';
import { useScreening } from '@nodal/core/flows/Screening';
import { useGooglePlacesApi } from '@nodal/core/hooks/useGooglePlacesApi';
import { getCompressedFile } from '@nodal/core/utils';
import { t } from '@nodal/i18n';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { PersonalInformation } from './PersonalInformation';
import { schema as validationSchema } from './validation';

import type { PersonalInformationFormValues } from './PersonalInformation.interface';
import type { ApiModel } from '@nodal/api';

const initialValues = {
  first_name: null,
  middle_name: null,
  last_name: null,
  maiden_name: null,
  phone_number: null,
  date_of_birth: null,
  address: {
    line1: null,
    city: null,
    state: null,
    zipcode: null,
    location: null,
  },
  image: null,
};

export const PersonalInformationConnected = () => {
  const { submit, currentStep } = useScreening();
  const placesApiReady = useGooglePlacesApi();
  const apiClient = useApiClient();

  const addPhoto = useMutation(
    (requestParameters: ApiModel.UserPhotosApiUserPhotosCreateRequest) =>
      apiClient.api.UserPhotosApi.userPhotosCreate(requestParameters),
    {
      onError: () => {
        toast.error(t('Something went wrong...'));
      },
    },
  );

  const onSubmit = async (data: PersonalInformationFormValues) => {
    const { image, ...submitData } = data;

    // NOTE: Bug in Api Schema - image type is a string, but should be a File
    const imgFile = image as File;

    const compressedImage = await getCompressedFile({
      file: imgFile,
      fileName: imgFile.name,
    });

    // NOTE: BUG in Api - imageUrl, id is required (Api demands it)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await addPhoto.mutateAsync({ image: compressedImage, imageUrl: '', id: 0 });

    submit(submitData);
  };

  if (!placesApiReady || !currentStep) {
    return <LoadingScreen />;
  }

  return (
    <PersonalInformation
      initialValues={{
        ...initialValues,
        id: currentStep.id,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    />
  );
};
