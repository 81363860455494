import { ChevronRightIcon } from '@heroicons/react/solid';
import { useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import {
  SearchFilterTable,
  useSearchFilterTable,
} from '@b2b/components/SearchFilterTable';
import { appPaths, organizationPaths as paths } from '@b2b/consts/paths';
import { organizationTypeToLabel } from '@b2b/utils/organization';

import type { ApiModel, QueryParameters } from '@nodal/api';

const columnHelper = createColumnHelper<ApiModel.OrganizationNonDetailed>();

const columns = [
  columnHelper.accessor('id', {
    cell: (info) => info.getValue(),
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    cell: (info) => (
      <span className="text-grey-forest-700">{info.getValue()}</span>
    ),
    header: () => t('Name'),
  }),
  columnHelper.accessor((row) => organizationTypeToLabel.get(row.type), {
    id: 'type',
    header: () => t('Org Type'),
    cell: (info) => info.renderValue(),
  }),
  columnHelper.accessor(
    (row) => (row.created ? format(new Date(row.created), 'P') : null),
    {
      id: 'created',
      header: () => t('Date of join'),
      cell: (info) => info.renderValue(),
    },
  ),
  columnHelper.accessor((row) => row.total_active_sent, {
    id: 'total_active_sent',
    header: () => t('Total'),
    cell: (info) => info.renderValue(),
  }),

  columnHelper.accessor(
    (row) => (
      <div className="mx-auto w-6 h-6 fill-grey-forest-500">
        <Link to={`${paths.app}/${appPaths.organization}/${row.id}`}>
          <ChevronRightIcon className="w-full" />
        </Link>
      </div>
    ),
    {
      id: 'action',
      cell: (info) => info.renderValue(),
      header: () => '',
      enableSorting: false,
    },
  ),
];

export const OrganizationsConnected = () => {
  const apiClient = useApiClient();

  const initialQueryParameters: QueryParameters<ApiModel.B2bApiB2bOrganizationsNonDetailedListRequest> =
    {
      page: 1,
      pageSize: 10,
      ordering: ['-created'],
      search: '',
    };

  const queryCallback = (
    query: QueryParameters<ApiModel.B2bApiB2bOrganizationsNonDetailedListRequest>,
  ) =>
    apiClient.api.B2bApi.b2bOrganizationsNonDetailedList({
      ...query.filters,
      ...query,
      name: query.search,
      // NOTE: Use casting because ordering type (string[]) in QueryParameters
      // is less specific than in the API Schema
    } as ApiModel.B2bApiB2bOrganizationsNonDetailedListRequest);

  const table = useSearchFilterTable<
    ApiModel.OrganizationNonDetailed,
    ApiModel.B2bApiB2bOrganizationsNonDetailedListRequest
  >({
    initialQuery: initialQueryParameters,
    columns,
    queryKey: 'organizationsNonDetailedList',
    queryCallback,
    urlSearchParamsEnabled: false,
  });

  return (
    <div className="flex relative flex-col col-span-12 w-full h-full font-avenir-next text-sm font-normal leading-5">
      <div className="w-full bg-white">
        <div className="flex gap-6 justify-center items-center p-6 mx-auto max-w-7xl sm:justify-between md:px-6 lg:px-8">
          <h2 className="hidden text-2xl leading-7 text-gray-900 sm:block">
            {t('Organizations')}
          </h2>
        </div>
      </div>
      <div className="overflow-x-auto px-4 mx-auto w-full max-w-7xl sm:px-6 lg:px-8">
        <SearchFilterTable {...table} />
      </div>
    </div>
  );
};
