import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';

import {
  candidateStatusOptions,
  getCandidateStatusOptionsByOrganization,
  getSupportedCandidateRolesBy,
} from 'utils/candidate';

import type { Filter } from '@b2b/components/SearchFilterTable/Filters';

import type { ApiModel } from '@nodal/api';
import type { FeatureFlags } from 'app/FeatureFlagsProvider.interface';
import type { PermissionsScope } from 'app/PermissionsProvider.interface';

export const getInitialFilters = (
  permissions?: PermissionsScope,
  features?: FeatureFlags,
) => ({
  role: getSupportedCandidateRolesBy({
    canViewAllOrganizations: !!permissions?.canViewAllOrganizations,
    hasAccessToMatching: !!(
      permissions?.canManageMatching && features?.matching
    ),
    hasAccessToNavigator: !!(
      permissions?.canManageNavigator && features?.navigator
    ),
  }),
  organizationId: undefined,
  status: undefined,
  billingCompleted: undefined,
  applicationFlaggedToReview: undefined,
  matchProfileToReview: undefined,
  // NOTE: We return only active candidates, i.e. those who have created screens
  emptyScreens: false,
  candidateSteps: undefined,
});

const candidateOptions = [
  {
    label: t('Surrogate'),
    value: apiEnums.UserRoleEnum.Dnr,
  },
  {
    label: t('Intended Parents'),
    value: apiEnums.UserRoleEnum.Par,
  },
  {
    label: t('Organization Donor'),
    value: apiEnums.UserRoleEnum.Odo,
  },
  {
    label: t('Organization Egg Donor'),
    value: apiEnums.UserRoleEnum.Oed,
  },
  {
    label: t('Organization Sperm Donor'),
    value: apiEnums.UserRoleEnum.Osd,
  },
  {
    label: t('Navigator'),
    value: apiEnums.UserRoleEnum.Nap,
  },
];

export const candidateStepsOptions = [
  {
    value: apiEnums.CandidateStepsEnum.NotRegistered,
    label: t('Not Registered'),
  },
  {
    value: apiEnums.CandidateStepsEnum.PersonalInformation,
    label: t('Personal Information'),
  },
  {
    value: apiEnums.CandidateStepsEnum.Application,
    label: t('Application'),
  },
  {
    value: apiEnums.CandidateStepsEnum.IdCheck,
    label: t('Identity Check'),
  },
  {
    value: apiEnums.CandidateStepsEnum.HipaaConsentForm,
    label: t('HIPAA / Consent Forms'),
  },
  {
    value: apiEnums.CandidateStepsEnum.MedicalForm,
    label: t('Medical Record Form'),
  },
  {
    value: apiEnums.CandidateStepsEnum.Checkr,
    label: t('Background Check'),
  },
  {
    value: apiEnums.CandidateStepsEnum.FinalReview,
    label: t('Final Review'),
  },
  {
    value: apiEnums.CandidateStepsEnum.Matching,
    label: t('Matching'),
  },
  {
    value: apiEnums.CandidateStepsEnum.Complete,
    label: t('Complete'),
  },
  {
    value: apiEnums.CandidateStepsEnum.Rejected,
    label: t('Rejected'),
  },
  {
    value: apiEnums.CandidateStepsEnum.Deactivated,
    label: t('Deactivated'),
  },
];

const getCandidateTypeOptionsByPermission = ({
  canViewAllOrganizations,
  hasAccessToMatching,
  hasAccessToNavigator,
}: {
  canViewAllOrganizations: boolean;
  hasAccessToMatching: boolean;
  hasAccessToNavigator: boolean;
}) => {
  const supportedRoles = getSupportedCandidateRolesBy({
    canViewAllOrganizations,
    hasAccessToMatching,
    hasAccessToNavigator,
  });

  return candidateOptions.filter(({ value }) => supportedRoles.includes(value));
};

const getCandidateStepsOptionsByPermission = ({
  canViewAllOrganizations,
  hasAccessToMatching,
}: {
  canViewAllOrganizations: boolean;
  hasAccessToMatching: boolean;
}) => {
  if (canViewAllOrganizations || hasAccessToMatching) {
    return candidateStepsOptions;
  }

  return candidateStepsOptions.filter(
    ({ value }) => value !== apiEnums.CandidateStepsEnum.Rejected,
  );
};

export const getFiltersDetails = ({
  canViewAllOrganizations,
  hasAccessToMatching,
  hasAccessToNavigator,
}: {
  canViewAllOrganizations: boolean;
  hasAccessToMatching: boolean;
  hasAccessToNavigator: boolean;
}): Filter<ApiModel.B2bApiB2bUsersListRequest>[] => [
  {
    id: 'organizationId',
    label: t('Organization'),
    options: [],
    value: null,
    type: 'select',
  },
  {
    id: 'role',
    label: t('Candidate Type'),
    options: getCandidateTypeOptionsByPermission({
      canViewAllOrganizations,
      hasAccessToMatching,
      hasAccessToNavigator,
    }),
    value: null,
    type: 'multiselect',
  },
  {
    id: 'status',
    label: t('Status'),
    options: canViewAllOrganizations
      ? candidateStatusOptions
      : getCandidateStatusOptionsByOrganization({
          hasAccessToMatching,
          hasAccessToNavigator,
        }),
    value: null,
    type: 'select',
  },
  {
    id: 'candidateSteps',
    label: t('Candidate Steps'),
    options: getCandidateStepsOptionsByPermission({
      canViewAllOrganizations,
      hasAccessToMatching,
    }),
    value: null,
    type: 'select',
  },
  {
    id: 'billingCompleted',
    label: t('Billing'),
    options: [
      {
        label: t('Payment complete'),
        value: 'true',
      },
      {
        label: t('Payment not complete'),
        value: 'false',
      },
    ],
    value: null,
    type: 'select',
  },
  {
    id: 'medicalReviewStatus',
    label: t('Medical Summary'),
    options: [
      {
        label: t('Not Started'),
        value: apiEnums.MedicalReviewStatusEnum.NotStarted,
      },
      {
        label: t('In Progress'),
        value: apiEnums.MedicalReviewStatusEnum.InProgress,
      },
      {
        label: t('Complete'),
        value: apiEnums.MedicalReviewStatusEnum.Finished,
      },
      {
        label: t('Disqualified'),
        value: apiEnums.MedicalReviewStatusEnum.Rejected,
      },
    ],
    value: null,
    type: 'select',
  },
  {
    id: 'applicationFlaggedToReview',
    label: t('Application flagged for review'),
    value: null,
    type: 'checkbox',
  },
  {
    id: 'matchProfileToReview',
    label: t('Match Profile flagged for review'),
    value: null,
    type: 'checkbox',
  },
];
