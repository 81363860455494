import type { FC } from 'react';

import { useGooglePlacesApi } from '@nodal/core/hooks/useGooglePlacesApi';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import compact from 'lodash/compact';
import pickBy from 'lodash/pickBy';

import { usePermissions } from '@b2b/app/PermissionsProvider';

import { useContactDetailsForm } from '../useContactDetailsForm';
import { useOrganizationDetails } from '../useOrganizationDetails';
import { useProfileDetails } from '../useProfileDetails';
import { useProfilePhoto } from '../useProfilePhoto';

import { BusinessContactDetails } from './BusinessContactDetails';
import { getBusinessTypeInitialValues } from './utils';
import { getValidationSchema } from './validation';

import type { ContactDetailsValues } from './BusinessContactDetails.interface';

export const BusinessContactDetailsConnected: FC = () => {
  const placesApiReady = useGooglePlacesApi();
  const { profile, updateProfile } = useProfileDetails();
  const { updateOrganization } = useOrganizationDetails();
  const { permissions } = usePermissions();

  const { profilePhoto, save: saveProfilePhoto } = useProfilePhoto();

  const { canEditOrganization } = permissions || {};

  const submit = async (submitData: ContactDetailsValues) => {
    const { id, other_business_type, business_type, image } = submitData;

    const organizationData = {
      ...pickBy(submitData, (value, key) =>
        ['phone_number', 'address', 'name'].includes(key),
      ),
      business_type:
        business_type === 'other' ? other_business_type : business_type,
    };

    const profileData = pickBy(submitData, (value, key) =>
      ['first_name', 'last_name', 'title'].includes(key),
    );

    await Promise.all(
      compact([
        updateProfile(profileData),
        saveProfilePhoto(image),
        canEditOrganization && updateOrganization(id!, organizationData),
      ]),
    );
  };

  const { onSubmit } = useContactDetailsForm({ submit });

  const { organization } = useOrganizationDetails();

  if (!profile || !organization || !placesApiReady || !permissions) {
    return <LoadingScreen />;
  }

  return (
    <BusinessContactDetails
      onSubmit={onSubmit}
      initialValues={{
        ...profile,
        ...organization,
        ...getBusinessTypeInitialValues(organization.business_type),
        image: profilePhoto,
      }}
      validationSchema={getValidationSchema(permissions.canEditOrganization)}
      organizationEditable={permissions.canEditOrganization}
    />
  );
};
