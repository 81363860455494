import type { FC } from 'react';

import { CheckEmailScreen } from '@nodal/core/flows/SignUp/CheckEmailScreen';
import { ConfirmEmailScreen } from '@nodal/core/flows/SignUp/ConfirmEmailScreen';
import { EmailNotConfirmedScreen } from '@nodal/core/flows/SignUp/EmailNotConfirmedScreen';
import { Route, Routes } from 'react-router-dom';

import { useAppRole } from '@b2b/app/RoleRoute';
import { pathsFrom, signupPaths } from '@b2b/consts/paths';

import { AccountCreationSuccessScreen } from './AccountCreationSuccessScreen';
import {
  CandidateSignUpScreen,
  OrganizationSignUpScreen,
} from './SignUpScreen';

export const SignUp: FC = () => {
  const { role } = useAppRole();

  const rolePaths = pathsFrom[role];

  return (
    <Routes>
      {role === 'organization' && (
        <Route path="/" element={<OrganizationSignUpScreen />} />
      )}
      {role === 'candidate' && (
        <Route path="/" element={<CandidateSignUpScreen />} />
      )}
      <Route
        path={signupPaths.creationSuccess}
        element={
          <AccountCreationSuccessScreen
            redirectPaths={{
              signin: rolePaths.signin,
            }}
          />
        }
      />
      <Route path={signupPaths.checkEmail} element={<CheckEmailScreen />} />
      <Route
        path={signupPaths.confirmEmail}
        element={
          <ConfirmEmailScreen
            redirectPaths={{
              checkEmail: `../${signupPaths.checkEmail}`,
              signin: rolePaths.signin,
            }}
          />
        }
      />
      <Route
        path={signupPaths.emailNotConfirmed}
        element={
          <EmailNotConfirmedScreen
            redirectPaths={{
              checkEmail: `../${signupPaths.checkEmail}`,
            }}
          />
        }
      />
    </Routes>
  );
};
