import { addBasePath } from '@nodal/core/utils';
import { t } from '@nodal/i18n';
import { differenceInMinutes, formatISO, parseISO, subMinutes } from 'date-fns';

import { basePaths } from '@b2b/consts/paths';
import { settings } from '@b2b/settings';

// NOTE: The signup base path defined for each role in settings
// is added to the basic signup path
export const getRoleSignupPath = (role: 'candidate' | 'organization') =>
  addBasePath(
    basePaths.signup,
    role === 'candidate'
      ? settings.getCandidateSignUpBasePath()
      : settings.getOrganizationSignUpBasePath(),
  );

export const convertToIsoStringWithoutOffset = (date: string) => {
  const dateWithoutOffset = subMinutes(
    new Date(date),
    new Date(date).getTimezoneOffset(),
  );

  return formatISO(dateWithoutOffset, {
    representation: 'complete',
  });
};

export const convertTimeDifferenceToTimeLabel = (
  dateString: string,
): string => {
  const minutes = differenceInMinutes(new Date(), parseISO(dateString));

  return convertMinutesToTimeLabel(minutes);
};

export const convertMinutesToTimeLabel = (minutes: number): string => {
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);

  let label;

  if (days > 0) {
    label = days === 1 ? t('day') : t('days');
    return `${days} ${label}`;
  } else if (hours > 0) {
    label = hours === 1 ? t('hour') : t('hours');
    return `${hours} ${label}`;
  } else {
    return t(`{minutes} min`, minutes.toString());
  }
};
