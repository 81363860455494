import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { useConfirmationDialog } from '@nodal/uikit/components/ConfirmationDialog';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import type { ApiModel } from '@nodal/api';

// NOTE: this hook needs to be used with the dialog provider (ConfirmationDialogProvider)
export const useCancelRequest = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const confirm = useConfirmationDialog();

  const cancelRequestCreate = useMutation(
    (request: ApiModel.B2bApiB2bInvitationsCancelCreateRequest) =>
      apiClient.api.B2bApi.b2bInvitationsCancelCreate(request),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.invitationsList),
      onError: () => {
        toast.error(t('Something went wrong'));
      },
    },
  );

  const cancelRequest = async (id: number) => {
    const confirmed = await confirm({
      title: t('Cancel Request'),
      message: t(
        'Are you sure you want to cancel this request? This action cannot be undone and will remove it from the requests list.',
      ),
      variant: 'alert',
    });

    if (confirmed) {
      await cancelRequestCreate.mutateAsync({
        id,
      });
    }
  };

  return {
    cancelRequest,
  };
};
