import { apiEnums } from '@nodal/api';
import { medicalReviewsUids } from '@nodal/core/consts/medicalReviewsUids';
import { t } from '@nodal/i18n';

import type { MedicalStage } from './MedicalReview.interface';
import type { ApiModel } from '@nodal/api';
import type { MedicalReviewsUid } from '@nodal/core/consts/medicalReviewsUids';

type MedicalRecordReviewUids = {
  rxDxHistory: MedicalReviewsUid;
  reviewDecision: MedicalReviewsUid;
  medicalRecordPull: MedicalReviewsUid;
  mfmClearance: MedicalReviewsUid;
  clinicRecords?: MedicalReviewsUid;
};

export const roleToMedicalReviewsUids = new Map<
  ApiModel.UserRoleEnum | undefined,
  MedicalRecordReviewUids
>([
  [
    apiEnums.UserRoleEnum.Dnr,
    {
      rxDxHistory: medicalReviewsUids.donorRxDxHistory,
      reviewDecision: medicalReviewsUids.donorReviewDecision,
      medicalRecordPull: medicalReviewsUids.donorMedicalRecordPull,
      mfmClearance: medicalReviewsUids.donorMfmClearance,
      clinicRecords: medicalReviewsUids.donorClinicRecords,
    },
  ],
  [
    apiEnums.UserRoleEnum.Odo,
    {
      rxDxHistory: medicalReviewsUids.orgCandidateRxDxHistory,
      reviewDecision: medicalReviewsUids.orgCandidateReviewDecision,
      medicalRecordPull: medicalReviewsUids.orgCandidateMedicalRecordPull,
      mfmClearance: medicalReviewsUids.orgCandidateMfmClearance,
      clinicRecords: medicalReviewsUids.orgCandidateClinicRecords,
    },
  ],
  [
    apiEnums.UserRoleEnum.Osd,
    {
      rxDxHistory: medicalReviewsUids.orgCandidateRxDxHistory,
      reviewDecision: medicalReviewsUids.orgCandidateReviewDecision,
      medicalRecordPull: medicalReviewsUids.orgCandidateMedicalRecordPull,
      mfmClearance: medicalReviewsUids.orgCandidateMfmClearance,
      clinicRecords: medicalReviewsUids.orgCandidateClinicRecords,
    },
  ],
  [
    apiEnums.UserRoleEnum.Oed,
    {
      rxDxHistory: medicalReviewsUids.orgCandidateRxDxHistory,
      reviewDecision: medicalReviewsUids.orgCandidateReviewDecision,
      medicalRecordPull: medicalReviewsUids.orgCandidateMedicalRecordPull,
      mfmClearance: medicalReviewsUids.orgCandidateMfmClearance,
      clinicRecords: medicalReviewsUids.orgCandidateClinicRecords,
    },
  ],
]);

export const getMedicalReviewStagesBasedOn = ({
  role,
  canInitiateMedicalRequest,
  canEditMedicalFiles,
}: {
  role: ApiModel.UserRoleEnum;
  canInitiateMedicalRequest: boolean;
  canEditMedicalFiles: boolean;
}): MedicalStage[] => {
  let stages: MedicalStage[] = [];

  if (canInitiateMedicalRequest) {
    stages = [
      {
        uid: roleToMedicalReviewsUids.get(role)?.rxDxHistory,
        title: t('Rx/Dx History'),
      },
      {
        uid: roleToMedicalReviewsUids.get(role)?.medicalRecordPull,
        title: t('Medical Record Pull'),
      },
      {
        uid: roleToMedicalReviewsUids.get(role)?.mfmClearance,
        title: t('MFM Clearance'),
      },
      {
        uid: roleToMedicalReviewsUids.get(role)?.clinicRecords,
        title: t('Clinic Records'),
      },
      {
        uid: roleToMedicalReviewsUids.get(role)?.reviewDecision,
        title: t('Medical Review Decision'),
      },
    ];
  } else if (canEditMedicalFiles) {
    stages = [
      {
        uid: roleToMedicalReviewsUids.get(role)?.rxDxHistory,
        title: t('Rx/Dx History'),
      },
      {
        uid: roleToMedicalReviewsUids.get(role)?.clinicRecords,
        title: t('Clinic Records'),
      },
      {
        uid: roleToMedicalReviewsUids.get(role)?.reviewDecision,
        title: t('Nodal Review'),
      },
    ];
  } else {
    stages = [
      {
        uid: roleToMedicalReviewsUids.get(role)?.rxDxHistory,
        title: t('Rx/Dx History'),
      },
      {
        uid: roleToMedicalReviewsUids.get(role)?.reviewDecision,
        title: t('Nodal Review'),
      },
    ];
  }

  return stages.filter(({ uid }) => uid);
};
