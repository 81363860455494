import type { AuthTokenProvider } from '@nodal/api/auth';

// TODO: Consider moving to shared package, e.g. core?

export interface Storage<T extends string = string> {
  setItem: (key: string, value: T) => Promise<void>;
  getItem: (key: string) => Promise<T | null>;
  removeItem: (key: string) => Promise<void>;
}

export enum StorageKeys {
  accessToken = 'access_token',
  refreshToken = 'refresh_token',
  deviceSettings = 'device_settings',
}

const defaultStorage = {
  getItem: async (key: string) => window.localStorage.getItem(key),
  setItem: async (key: string, value: string) =>
    window.localStorage.setItem(key, value),
  removeItem: async (key: string) => window.localStorage.removeItem(key),
};

export const createAuthTokensProvider = (
  storage: Storage = defaultStorage,
): AuthTokenProvider => {
  const setAccessToken = async (accessToken?: string) => {
    if (accessToken) {
      await storage.setItem(StorageKeys.accessToken, accessToken);
    }
  };

  const setRefreshToken = async (refreshToken?: string) => {
    if (refreshToken) {
      await storage.setItem(StorageKeys.refreshToken, refreshToken);
    }
  };

  const setTokens = async (data?: { access: string; refresh: string }) => {
    await setAccessToken(data?.access);
    await setRefreshToken(data?.refresh);
  };

  const getAccessToken = () => storage.getItem(StorageKeys.accessToken);

  const getRefreshToken = () => storage.getItem(StorageKeys.refreshToken);

  const removeAccessToken = () => storage.removeItem(StorageKeys.accessToken);

  const removeRefreshToken = () => storage.removeItem(StorageKeys.refreshToken);

  const removeTokens = async () => {
    await removeAccessToken();
    await removeRefreshToken();
  };

  const getIsAuthenticated = async () => !!(await getAccessToken());

  return {
    setTokens,
    setAccessToken,
    setRefreshToken,
    getAccessToken,
    getRefreshToken,
    removeTokens,
    getIsAuthenticated,
  };
};
