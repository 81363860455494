import type { FC } from 'react';

import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { useModal } from '@nodal/uikit/components/Modal';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useCandidate } from '../../../../CandidateDetails.connect';

import { NewBackgroundCheckForm } from './NewBackgroundCheckForm';

import type {
  NewBackgroundCheckFormConnectedProps,
  NewBackgroundCheckValues,
} from './NewBackgroundCheckForm.interface';
import type { ApiModel } from '@nodal/api';

const initialValues: NewBackgroundCheckValues = {
  email: undefined,
};

const schema = Yup.object({
  email: Yup.string()
    .nullable()
    .email(t('Enter a valid email address'))
    .required(t('This field is required')),
});

export const NewBackgroundCheckFormConnected: FC<
  NewBackgroundCheckFormConnectedProps
> = ({ id }) => {
  const { close } = useModal();
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { candidate } = useCandidate();

  const resubmitCheckr = useMutation(
    (request: ApiModel.ScreensApiScreensResubmitCheckrCreateRequest) =>
      apiClient.api.ScreensApi.screensResubmitCheckrCreate(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          queryKeys.candidateRetrieve,
          // component where mutation is called wouldn't be rendered if there is no candidate
          candidate!.id,
        ]);
      },
      onError: () => {
        toast.error(t('Something went wrong...'));
      },
    },
  );

  const onSubmit = async (data: NewBackgroundCheckValues) => {
    await resubmitCheckr.mutateAsync({
      id,
      checkrScreen: {
        candidates: [],
        input_candidates: [
          {
            role: apiEnums.RoleFd4Enum.Secondary,
            email: data.email,
          },
        ],
      },
    });

    close();
  };

  return (
    <NewBackgroundCheckForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    />
  );
};
