import type { FC } from 'react';

import debounce from 'lodash/debounce';
import { useCallback, useMemo, useEffect } from 'react';

import { Search } from './Search';

import type { SearchProps } from './Search.interface';

export const SearchConnected: FC<SearchProps> = ({ onChange, ...props }) => {
  const debouncedSearch = useMemo(
    () => debounce((newValue: string) => onChange(newValue), 500),
    [onChange],
  );

  const handleInputChange = useCallback(
    (newValue: string) => {
      debouncedSearch(newValue);
    },
    [debouncedSearch],
  );

  useEffect(() => {
    return () => debouncedSearch.cancel();
  }, [debouncedSearch]);

  return <Search onChange={handleInputChange} {...props} />;
};
