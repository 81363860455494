import type { FC } from 'react';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  appPaths,
  organizationPaths as paths,
  requestPaths,
} from 'consts/paths';

import { useNewCandidateRequest } from './hooks/useNewCandidateRequest';

export const NewCandidateRequestRedirect: FC = () => {
  const { setInitialSteps, currentStepIndex, steps } = useNewCandidateRequest();

  const navigate = useNavigate();

  useEffect(() => {
    setInitialSteps();
    // NOTE: Only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // NOTE: We cannot determine yet where we are.
    if (currentStepIndex === undefined || !steps.length) {
      return;
    }

    navigate(
      `${paths.app}/${appPaths.request}/${requestPaths.new}/${requestPaths.profile}`,
    );
    return;
  }, [currentStepIndex, navigate, steps.length]);

  return <></>;
};
