import type { FC } from 'react';

import { states } from '@nodal/core/consts/states';
import { t } from '@nodal/i18n';
import { AddressInput } from '@nodal/uikit/components/AddressInput';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { PastDateInput } from '@nodal/uikit/components/DateInput';
import { ImageInput } from '@nodal/uikit/components/ImageInput';
import { PhoneNumberInput } from '@nodal/uikit/components/PhoneNumberInput';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Form, Formik } from 'formik';

import type { PersonalInformationProps } from './PersonalInformation.interface';
import type { Address } from '@nodal/uikit/models';

export const PersonalInformation: FC<PersonalInformationProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
    validateOnBlur={false}
    validationOnChange={false}
  >
    {({ isSubmitting, setFieldValue, setFieldTouched }) => (
      <Form className="space-y-4 w-full lg:w-step-view">
        <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
          <div className="flex flex-col gap-4 p-8 w-full text-left bg-beige-500 rounded-lg">
            <h3 className="text-2xl font-semibold text-sand-900">
              {t('Welcome to Nodal')}
            </h3>
            <p className="text-base font-normal leading-6 text-sand-900">
              {t('Please follow the prompts to submit your medical review.')}
            </p>
          </div>
          <h3 className="mt-6 text-xl font-semibold leading-7 text-gray-900">
            {t('Personal Information')}
          </h3>
          <div className="flex flex-col gap-x-4 gap-y-10 mt-6">
            <TextInput label={t('First Name')} name="first_name" type="text" />
            <TextInput
              label={t('Middle Name')}
              name="middle_name"
              type="text"
            />
            <TextInput label={t('Last Name')} name="last_name" type="text" />
            <TextInput
              label={t(
                'Maiden name or any other names used in the past (optional)',
              )}
              name="maiden_name"
              type="text"
            />
            <PastDateInput
              label={t('Date of Birth')}
              name="date_of_birth"
              placeholder="YYYY-DD-MM"
              className="lg:w-1/2"
            />
            <ImageInput
              name="image"
              label={t('Please upload a headshot')}
              className="h-80 md:w-1/2"
            />
            <div className="col-span-6">
              <div className="relative">
                <div
                  className="flex absolute inset-0 items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="flex relative justify-start">
                  <span className="pr-3 text-base font-semibold leading-7 text-gray-900 bg-white">
                    {t('Contact Details')}
                  </span>
                </div>
              </div>
            </div>
            <PhoneNumberInput
              label={t('Phone Number')}
              name="phone_number"
              placeholder="(555) 987-6543"
              type="text"
              className="lg:w-1/2"
            />
            <AddressInput
              name="address"
              onChange={(address: Address) =>
                Object.entries(address).forEach(([key, value]) => {
                  setFieldValue(`address.${key}`, value);
                  setFieldTouched(`address.${key}`, false);
                })
              }
              states={states}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <ButtonLoading
            type="submit"
            variant="primary"
            loading={isSubmitting}
            className="justify-center px-6 w-full sm:w-max"
          >
            {t('Submit')}
          </ButtonLoading>
        </div>
      </Form>
    )}
  </Formik>
);
