import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { EditForm, useEditForm } from '@nodal/core/flows/MatchProfile/EditForm';
import { useGooglePlacesApi } from '@nodal/core/hooks/useGooglePlacesApi';
import { objectToFormData } from '@nodal/core/utils';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { useCandidate } from 'components/CandidateDetails';

import type { EditFormConnectedProps } from './EditForm.interface';
import type { ApiModel, DonorUser, ParentsUser } from '@nodal/api';
import type { ProfileData } from '@nodal/core/flows/MatchProfile';
import type { AxiosProgressEvent } from 'axios';

export const useProfileUpdate = (candidateId?: number) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const [uploadProgress, setUploadProgress] = useState<number>();

  const updateProfile = useMutation(
    (request: ApiModel.B2bApiB2bUserProfilePartialUpdateRequest) => {
      const multipart = !!(
        request?.patchedProfileObject &&
        'video' in request.patchedProfileObject &&
        request.patchedProfileObject?.video
      );

      const requestParams = multipart
        ? {
            // NOTE: objectToFormData returns FormData type so
            // we need to do proper casting
            // TODO: Consider to improve this
            patchedProfileObject: objectToFormData(
              request.patchedProfileObject,
            ) as ApiModel.PatchedProfileObject,
            id: request.id,
          }
        : request;

      const options = {
        headers: {
          'Content-Type': multipart
            ? 'multipart/form-data'
            : 'application/json',
        },
        onUploadProgress: (e: AxiosProgressEvent) => {
          if (e.total) {
            setUploadProgress(e.loaded / e.total);
          }
        },
      };

      return apiClient.api.B2bApi.b2bUserProfilePartialUpdate(
        requestParams,
        options,
      );
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeys.candidateProfileRetrieve),
    },
  );

  const onUpdateProfile = async (data: ApiModel.PatchedProfileObject) => {
    if (candidateId) {
      await updateProfile.mutateAsync({
        id: candidateId,
        patchedProfileObject: data,
      });
    }
  };

  return {
    onUpdateProfile,
    uploadProgress,
  };
};

export const EditFormConnected = <T extends ProfileData>({
  profileSections,
  section,
  onCloseModal,
}: EditFormConnectedProps<T>) => {
  const placesApiReady = useGooglePlacesApi();
  const { candidate, profile } = useCandidate();
  const { onUpdateProfile, uploadProgress } = useProfileUpdate(candidate?.id);

  // NOTE: Using casting, because this view will be using only for donors and parents
  // and the candidate type is more specific (includes b2b users)
  const userProfile = profile as DonorUser['profile'] | ParentsUser['profile'];

  const { initialValues, questions, onSubmit, validationSchema } = useEditForm({
    profileSections,
    section,
    onCloseModal,
    submit: onUpdateProfile,
    userProfile,
  });

  if (!placesApiReady) {
    return null;
  }

  return (
    <EditForm
      initialValues={initialValues}
      questions={questions}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      section={section}
      role={candidate?.role}
      uploadProgress={uploadProgress}
    />
  );
};
