import type { FC } from 'react';

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AccountCreationSuccessScreen } from './AccountCreationSuccessScreen';

import type {
  AccountCreationSuccessScreenProps,
  EmailValue,
} from './AccountCreationSuccessScreen.interface';

export const AccountCreationSuccessScreenConnected: FC<
  AccountCreationSuccessScreenProps
> = ({ redirectPaths }) => {
  // NOTE: added type cast, because there is an issue with types in react-router v6
  const { state } = useLocation() as { state: EmailValue };
  const email = state?.email;

  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      // NOTE: Navigate back to the sign-up page if the email is not available in the state.
      // This page is accessed after successful account creation,
      // and the email is passed in the state during navigation.
      navigate('../');
    }
  }, [email, navigate]);

  return <AccountCreationSuccessScreen redirectPaths={redirectPaths} />;
};
