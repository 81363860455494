import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { PastDateInput } from '@nodal/uikit/components/DateInput';
import { PhoneNumberInput } from '@nodal/uikit/components/PhoneNumberInput';
import { PlaceInput } from '@nodal/uikit/components/PlaceInput';
import { Select } from '@nodal/uikit/components/Select';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Form, Formik } from 'formik';

import type { DatavantFormProps } from './DatavantForm.interface';

const deliveryOptions = [
  {
    label: t('First Delivery'),
    value: 1,
  },
  {
    label: t('Second Delivery'),
    value: 2,
  },
  {
    label: t('Third Delivery'),
    value: 3,
  },
  {
    label: t('Fourth Delivery'),
    value: 4,
  },
  {
    label: t('Fifth Delivery'),
    value: 5,
  },
];

export const DatavantForm: FC<DatavantFormProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  onChangeDeliveryCount,
  editable,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validationOnChange={false}
    >
      {({ isSubmitting, setValues, values }) => (
        <Form className="space-y-4">
          <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
            <h3 className="text-xl font-semibold leading-7 text-gray-900">
              {t('Candidate Details')}
            </h3>
            <div className="flex flex-col gap-x-4 gap-y-10 mt-6">
              <TextInput
                label={t('Consent file path')}
                name="consent_file_path"
                type="text"
                disabled={!editable}
              />
              <TextInput
                label={t('First Name')}
                name="first_name"
                type="text"
                disabled={!editable}
              />
              <TextInput
                label={t('Middle Name')}
                name="middle_name"
                type="text"
                disabled={!editable}
              />
              <TextInput
                label={t('Last Name')}
                name="last_name"
                type="text"
                disabled={!editable}
              />
              <PastDateInput
                label={t('Date of Birth')}
                name="date_of_birth"
                placeholder="YYYY-DD-MM"
                className="lg:w-1/2"
                disabled={!editable}
              />
              <TextInput
                label={t('Gender')}
                name="gender"
                type="text"
                disabled={!editable}
              />
              <PlaceInput
                name="address"
                label={t('Address')}
                type="address"
                placeholder={t('Enter the address and select location')}
                disabled={!editable}
              />
              <PhoneNumberInput
                label={t('Phone Number')}
                name="phone_number"
                className="lg:w-1/2"
                disabled={!editable}
              />
            </div>
          </div>
          <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
            <h3 className="text-xl font-semibold leading-7 text-gray-900">
              {t('Provider Details')}
            </h3>
            <div className="flex flex-col gap-x-4 gap-y-10 mt-6">
              <Select
                label={t('Delivery')}
                name="delivery"
                options={deliveryOptions}
                onChange={(value) =>
                  onChangeDeliveryCount
                    ? onChangeDeliveryCount(
                        (updatedValues) =>
                          setValues({ ...values, ...updatedValues }),
                        Number(value),
                      )
                    : null
                }
                disabled={!editable}
              />
              <TextInput
                label={t('Provider First Name')}
                name="doctor_first_name"
                type="text"
                disabled={!editable}
              />
              <TextInput
                label={t('Provider Middle Name (optional)')}
                name="doctor_middle_name"
                type="text"
                disabled={!editable}
              />
              <TextInput
                label={t('Provider Last Name')}
                name="doctor_last_name"
                type="text"
                disabled={!editable}
              />
              <TextInput
                label={t("Provider's Clinic Name")}
                name="doctor_health_system"
                type="text"
                disabled={!editable}
              />
              <PlaceInput
                label={t("Provider's Address")}
                name="doctor_address"
                type="address"
                disabled={!editable}
              />
              <PhoneNumberInput
                label={t("Provider's Phone Number")}
                name="doctor_phone_number"
                className="lg:w-1/2"
                disabled={!editable}
              />
              <PastDateInput
                label={t('Start Date of Service')}
                name="doctor_date_of_service_start"
                placeholder="YYYY-DD-MM"
                className="lg:w-1/2"
                disabled={!editable}
              />
              <PastDateInput
                label={t('End Date of Service')}
                name="doctor_date_of_service_end"
                placeholder="YYYY-DD-MM"
                className="lg:w-1/2"
                disabled={!editable}
              />
              <TextInput
                label={t('Notes to Provider')}
                name="doctor_instructions"
                type="text"
                disabled={!editable}
              />
            </div>
          </div>
          {editable && (
            <div className="flex justify-end">
              <ButtonLoading
                type="submit"
                variant="primary"
                loading={isSubmitting}
                className="justify-center px-6 w-full sm:w-max"
              >
                {t('Submit')}
              </ButtonLoading>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};
