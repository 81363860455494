import type { FC, ReactNode } from 'react';

import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { requestPaths } from 'consts/paths';

import { CandidateRequest } from './CandidateRequest';
import { CandidateRequestRedirect } from './CandidateRequestRedirect';
import { NewCandidateRequestRedirect } from './NewCandidateRequestRedirect';

import type { CandidateRequestProviderProps } from './CandidateRequest.interface';

const initialValues: CandidateRequestProviderProps = {
  started: false,
  completed: false,
  steps: [],
  currentStepIndex: undefined,
  requestId: undefined,
  updateSteps: () => {
    throw new Error('updateSteps not initialized');
  },
  updateCurrentStepIndex: () => {
    throw new Error('updateCurrentStepIndex not initialized');
  },
  updateStarted: () => {
    throw new Error('updateStarted not initialized');
  },
  updateCompleted: () => {
    throw new Error('updateCompleted not initialized');
  },
  updateRequestId: () => {
    throw new Error('updateRequestId not initialized');
  },
};

export const CandidateRequestContext = createContext(initialValues);

export const CandidateRequestContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { id } = useParams() as { id: string };

  const [currentStepIndex, setCurrentStepIndex] =
    useState<CandidateRequestProviderProps['currentStepIndex']>();

  const [steps, setSteps] = useState<CandidateRequestProviderProps['steps']>(
    [],
  );

  const [started, setStarted] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [requestId, setRequestId] = useState<number>(Number.parseInt(id));

  const updateCurrentStepIndex = useCallback(
    (step: CandidateRequestProviderProps['currentStepIndex']) => {
      setCurrentStepIndex(step);
    },
    [],
  );

  const updateSteps = useCallback(
    (steps: CandidateRequestProviderProps['steps']) => setSteps(steps),
    [],
  );

  const updateCompleted = useCallback((value: boolean) => {
    setCompleted(value);
  }, []);

  const updateStarted = useCallback((value: boolean) => {
    setStarted(value);
  }, []);

  const updateRequestId = useCallback((value: number) => {
    setRequestId(value);
  }, []);

  const value = useMemo(
    () => ({
      currentStepIndex,
      updateCurrentStepIndex,
      steps,
      updateSteps,
      started,
      updateStarted,
      completed,
      updateCompleted,
      requestId,
      updateRequestId,
    }),
    [
      currentStepIndex,
      updateCurrentStepIndex,
      steps,
      updateSteps,
      completed,
      updateCompleted,
      started,
      updateStarted,
      requestId,
      updateRequestId,
    ],
  );

  return (
    <CandidateRequestContext.Provider value={value}>
      {children}
    </CandidateRequestContext.Provider>
  );
};

const CandidateRequestContextConsumer: FC = () => {
  const { currentStepIndex, steps } = useContext(CandidateRequestContext);

  if (!steps?.length || currentStepIndex === undefined)
    return <LoadingScreen />;

  return <CandidateRequest steps={steps} activeStepIndex={currentStepIndex} />;
};

export const CandidateRequestConnected: FC = () => {
  return (
    <Routes>
      <Route
        path={`${requestPaths.updateId}/*`}
        element={
          <CandidateRequestContextProvider>
            <CandidateRequestRedirect />
            <CandidateRequestContextConsumer />
          </CandidateRequestContextProvider>
        }
      />
      <Route
        path={`${requestPaths.new}/*`}
        element={
          <CandidateRequestContextProvider>
            <NewCandidateRequestRedirect />
            <CandidateRequestContextConsumer />
          </CandidateRequestContextProvider>
        }
      />
      <Route path="*" element={<Navigate to={requestPaths.new} />} />
    </Routes>
  );
};
