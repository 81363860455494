import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { useCandidate } from 'components/CandidateDetails/CandidateDetails.connect';

import { NoteItem } from './NoteItem';

import type { NoteItemConnectedProps } from './NoteItem.interface';
import type { ApiModel } from '@nodal/api';

export const NoteItemConnected: FC<NoteItemConnectedProps> = ({
  id,
  content,
  type,
}) => {
  const { candidate } = useCandidate();

  const userId = candidate?.id;

  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const noteUpdateMutation = useMutation(
    (request: ApiModel.UsersApiUsersUserNotePartialUpdateRequest) =>
      apiClient.api.UsersApi.usersUserNotePartialUpdate(request),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.userNotesList),
    },
  );

  const noteRemoveMutation = useMutation(
    (request: ApiModel.UsersApiUsersUserNoteDestroyRequest) =>
      apiClient.api.UsersApi.usersUserNoteDestroy(request),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.userNotesList),
    },
  );

  const handleRemove = useCallback(
    async (id: number) => {
      await noteRemoveMutation.mutateAsync({
        id,
        userId: userId!,
      });
    },
    [noteRemoveMutation, userId],
  );

  const handleUpdate = useCallback(
    async (id: number, value?: string) => {
      await noteUpdateMutation.mutateAsync({
        id,
        userId: userId!,
        patchedUserNoteEditCreate: {
          content: value,
        },
      });
    },
    [noteUpdateMutation, userId],
  );

  return (
    <NoteItem
      id={id}
      content={content}
      type={type}
      onRemove={handleRemove}
      onUpdate={handleUpdate}
    />
  );
};
