import { apiEnums } from '@nodal/api';
import { medicalReviewsUids } from '@nodal/core/consts/medicalReviewsUids';
import { t } from '@nodal/i18n';
import { badgeStatus } from '@nodal/uikit/components/StatusBadge';
import omit from 'lodash/omit';
import some from 'lodash/some';

import { settings } from 'settings';

import type { ApiModel } from '@nodal/api';
import type { Status as BadgeStatus } from '@nodal/uikit/components/StatusBadge';

export const candidateRoleToLabel = new Map<ApiModel.B2BUser['role'], string>([
  [apiEnums.UserRoleEnum.Odo, t('Surrogate')],
  [apiEnums.UserRoleEnum.Oed, t('Egg Donor')],
  [apiEnums.UserRoleEnum.Osd, t('Sperm Donor')],
  [apiEnums.UserRoleEnum.Par, t('Intended Parents')],
  [apiEnums.UserRoleEnum.Dnr, t('Surrogate')],
  [apiEnums.UserRoleEnum.Nap, t('Navigator')],
]);

export const payerToLabel = new Map<
  ApiModel.B2bCandidatePaymentPayingEntity,
  string
>([
  [apiEnums.PayingEntityEnum.ExtParents, t('Intended Parent(s)')],
  [apiEnums.PayingEntityEnum.Org, t('My business')],
  [apiEnums.PayingEntityEnum.Prepaid, t('Prepaid package')],
]);

export const screenStatusToLabel = new Map<ApiModel.ScreenStatusEnum, string>([
  [apiEnums.ScreenStatusEnum.Pend, t('Not Started')],
  [apiEnums.ScreenStatusEnum.Proc, t('In Progress')],
  [apiEnums.ScreenStatusEnum.Rev, t('In Review')],
  [apiEnums.ScreenStatusEnum.App, t('Complete')],
  [apiEnums.ScreenStatusEnum.Rej, t('Disqualified')],
  [apiEnums.ScreenStatusEnum.Half, t('Half Complete')],
]);

export const userStatusToLabel: { [key in ApiModel.UserStatusEnum]: string } = {
  [apiEnums.UserStatusEnum.Dea]: t('Deactivated'),
  [apiEnums.UserStatusEnum.Dis]: t('Disqualified'),
  [apiEnums.UserStatusEnum.Scr]: t('Screening'),
  [apiEnums.UserStatusEnum['Scr+']]: t('Screening Complete'),
  [apiEnums.UserStatusEnum.Mat]: t('Matching'),
  [apiEnums.UserStatusEnum['Mat+']]: t('Matched'),
  [apiEnums.UserStatusEnum.Nac]: t('Navigator Complete'),
};

export const screenStatusToBadgeStatus = new Map<
  ApiModel.ScreenStatusEnum,
  BadgeStatus
>([
  [apiEnums.ScreenStatusEnum.Pend, badgeStatus.pending],
  [apiEnums.ScreenStatusEnum.Proc, badgeStatus.processing],
  [apiEnums.ScreenStatusEnum.Rej, badgeStatus.rejected],
  [apiEnums.ScreenStatusEnum.App, badgeStatus.complete],
  [apiEnums.ScreenStatusEnum.Half, badgeStatus.complete],
  [apiEnums.ScreenStatusEnum.Rev, badgeStatus.review],
]);

export const taskStatusToBadgeStatus = new Map<
  ApiModel.TaskStatusEnum,
  BadgeStatus
>([
  [apiEnums.TaskStatusEnum.Pending, badgeStatus.pending],
  [apiEnums.TaskStatusEnum.Processing, badgeStatus.processing],
  [apiEnums.TaskStatusEnum.Complete, badgeStatus.complete],
  [apiEnums.TaskStatusEnum.Rejected, badgeStatus.rejected],
  [apiEnums.TaskStatusEnum.Review, badgeStatus.review],
]);

export const medicalReviewStatusToBadgeStatus = new Map<
  ApiModel.MedicalReviewStatusEnum,
  BadgeStatus
>([
  [apiEnums.MedicalReviewStatusEnum.NotStarted, badgeStatus.pending],
  [apiEnums.MedicalReviewStatusEnum.InProgress, badgeStatus.processing],
  [apiEnums.MedicalReviewStatusEnum.Rejected, badgeStatus.rejected],
  [apiEnums.MedicalReviewStatusEnum.Finished, badgeStatus.complete],
]);

export const billingStatusToBagdeStatus = new Map<
  ApiModel.BillingStatusEnum,
  BadgeStatus
>([
  [apiEnums.BillingStatusEnum.Pending, badgeStatus.pending],
  [apiEnums.BillingStatusEnum.InProgress, badgeStatus.processing],
  [apiEnums.BillingStatusEnum.Failed, badgeStatus.failed],
  [apiEnums.BillingStatusEnum.Complete, badgeStatus.complete],
]);

export const medicalReviewStatusToLabel = new Map<
  ApiModel.ReviewStepStatusEnum,
  string
>([
  [apiEnums.ReviewStepStatusEnum.Pending, t('Not Started')],
  [apiEnums.ReviewStepStatusEnum.Complete, t('Complete')],
  [apiEnums.ReviewStepStatusEnum.Rejected, t('Disqualified')],
]);

export const getProfileDisplayName = (
  profile: ApiModel.ProfileObject,
): string => {
  const { first_name, last_name } = profile;
  const hasPartner =
    'partner_first_name' in profile && 'partner_last_name' in profile;

  if (hasPartner) {
    const { partner_first_name, partner_last_name } = profile;

    if (first_name && last_name && partner_first_name && partner_last_name) {
      if (last_name === partner_last_name) {
        return t(
          '{firstName} & {partnerFirstName} {lastName}',
          first_name,
          partner_first_name,
          last_name,
        );
      }

      return t(
        `{firstName} {lastName} & {partnerFirstName} {partnerLastName}`,
        first_name,
        last_name,
        partner_first_name,
        partner_last_name,
      );
    }
  }

  return `${first_name} ${last_name}`;
};

export const candidateStatusOptions = [
  {
    value: apiEnums.UserStatusEnum.Dea,
    label: userStatusToLabel[apiEnums.UserStatusEnum.Dea],
  },
  {
    value: apiEnums.UserStatusEnum.Dis,
    label: userStatusToLabel[apiEnums.UserStatusEnum.Dis],
  },
  {
    value: apiEnums.UserStatusEnum.Scr,
    label: userStatusToLabel[apiEnums.UserStatusEnum.Scr],
  },
  {
    value: apiEnums.UserStatusEnum['Scr+'],
    label: userStatusToLabel[apiEnums.UserStatusEnum['Scr+']],
  },
  {
    value: apiEnums.UserStatusEnum.Mat,
    label: userStatusToLabel[apiEnums.UserStatusEnum.Mat],
  },
  {
    value: apiEnums.UserStatusEnum['Mat+'],
    label: userStatusToLabel[apiEnums.UserStatusEnum['Mat+']],
  },
  {
    value: apiEnums.UserStatusEnum.Nac,
    label: userStatusToLabel[apiEnums.UserStatusEnum.Nac],
  },
];

export const getCandidateStatusOptionsByOrganization = ({
  hasAccessToMatching,
  hasAccessToNavigator,
}: {
  hasAccessToMatching: boolean;
  hasAccessToNavigator: boolean;
}) => {
  if (hasAccessToMatching && !hasAccessToNavigator) {
    return candidateStatusOptions.filter(
      ({ value }) => value !== apiEnums.UserStatusEnum.Nac,
    );
  } else if (hasAccessToNavigator && !hasAccessToMatching) {
    return candidateStatusOptions.filter(
      ({ value }) =>
        value !== apiEnums.UserStatusEnum['Mat'] &&
        value !== apiEnums.UserStatusEnum['Mat+'],
    );
  } else {
    return candidateStatusOptions.filter(
      ({ value }) =>
        value !== apiEnums.UserStatusEnum['Mat'] &&
        value !== apiEnums.UserStatusEnum['Mat+'] &&
        value !== apiEnums.UserStatusEnum.Nac,
    );
  }
};

export const canCreateCandidateRequest = ({
  isInvitationFeatureEnabled,
  canManageInvitations,
  canViewAllOrganizations,
}: {
  isInvitationFeatureEnabled: boolean;
  canManageInvitations: boolean;
  canViewAllOrganizations: boolean;
}) =>
  (canManageInvitations && isInvitationFeatureEnabled) ||
  canViewAllOrganizations;

export const canViewMatchingSections = ({
  isMatchingFeatureEnabled,
  canManageMatching,
  canViewAllOrganizations,
}: {
  isMatchingFeatureEnabled: boolean;
  canManageMatching: boolean;
  canViewAllOrganizations: boolean;
}) =>
  (isMatchingFeatureEnabled && canManageMatching) || canViewAllOrganizations;

export const getSupportedCandidateRolesBy = ({
  canViewAllOrganizations,
  hasAccessToMatching,
  hasAccessToNavigator,
}: {
  canViewAllOrganizations: boolean;
  hasAccessToMatching: boolean;
  hasAccessToNavigator: boolean;
}) => {
  const spermAndEggDonorsRoles =
    settings.hasSpermAndEggDonorsIncludedInCandidateRoles()
      ? [apiEnums.UserRoleEnum.Oed, apiEnums.UserRoleEnum.Osd]
      : [];

  if (canViewAllOrganizations) {
    return [
      apiEnums.UserRoleEnum.Odo,
      ...spermAndEggDonorsRoles,
      apiEnums.UserRoleEnum.Dnr,
      apiEnums.UserRoleEnum.Par,
      apiEnums.UserRoleEnum.Nap,
    ];
  } else if (hasAccessToMatching) {
    return [apiEnums.UserRoleEnum.Dnr, apiEnums.UserRoleEnum.Par];
  } else if (hasAccessToNavigator) {
    return [apiEnums.UserRoleEnum.Nap];
  } else {
    return [apiEnums.UserRoleEnum.Odo, ...spermAndEggDonorsRoles];
  }
};

export const canViewCandidateDetails = ({
  role,
  canViewAllOrganizations,
  hasAccessToMatching,
  hasAccessToNavigator,
}: {
  role: ApiModel.UserRoleEnum;
  canViewAllOrganizations: boolean;
  hasAccessToMatching: boolean;
  hasAccessToNavigator: boolean;
}) =>
  getSupportedCandidateRolesBy({
    canViewAllOrganizations,
    hasAccessToMatching,
    hasAccessToNavigator,
  }).find((supportedRole) => supportedRole === role);

export const getCurrentInvitation = (
  invitations?: ApiModel.InvitationNested[],
) =>
  invitations?.find(
    (invitation) =>
      invitation.status !== apiEnums.InvitationStatusEnum.Canceled,
  );

export const getMedicalReviewStatus = (
  medicalReview?: ApiModel.ReviewNested,
): ApiModel.ScreenStatusEnum => {
  if (!medicalReview) {
    return apiEnums.ScreenStatusEnum.Pend;
  }

  const { steps, pulls_count } = medicalReview || {};

  const reviewDecisionStep = steps.find(
    ({ uid }) =>
      uid === medicalReviewsUids.donorReviewDecision ||
      uid === medicalReviewsUids.orgCandidateReviewDecision,
  );

  const stepsContentObjectsWithoutId = steps.map(({ content_object }) => {
    // NOTE: Filter out the id that is defined at the beginning
    return omit(content_object, 'id');
  });

  // NOTE: The completion status of the medial review section is determined by the reviewDecisionStep.
  // We rely on its status if the reviewDecisionStep is completed or rejected.
  // Otherwise, we check other conditions to detect if it is in progress or pending.
  if (reviewDecisionStep?.status === apiEnums.ReviewStepStatusEnum.Complete) {
    return apiEnums.ScreenStatusEnum.App;
  }

  if (reviewDecisionStep?.status === apiEnums.ReviewStepStatusEnum.Rejected) {
    return apiEnums.ScreenStatusEnum.Rej;
  }

  const hasNonEmptyValueInContentObject = some(
    stepsContentObjectsWithoutId,
    (content) => some(content, (value) => value),
  );

  // NOTE: When any action has been taken in the medical review section, the status should change to "in progress' - it applies to pulls, steps statuses or content objects
  if (
    pulls_count ||
    some(steps, { status: apiEnums.ReviewStepStatusEnum.Rejected }) ||
    some(steps, { status: apiEnums.ReviewStepStatusEnum.Complete }) ||
    hasNonEmptyValueInContentObject
  ) {
    return apiEnums.ScreenStatusEnum.Proc;
  }

  return apiEnums.ScreenStatusEnum.Pend;
};
