import type { FC } from 'react';

import { SignInForm } from '@nodal/core/flows/SignInForm';
import { t } from '@nodal/i18n';
import { Link } from 'react-router-dom';

import type { SignInScreenProps } from './SignInScreen.interface';

export const SignInScreen: FC<SignInScreenProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  redirectPaths,
  signUpHidden,
}) => (
  <>
    <div className="flex flex-col gap-4">
      <h2 className="mt-6 text-2xl font-semibold leading-8 text-gray-900 sm:text-3xl sm:font-bold sm:leading-9">
        {t('Welcome back')}
      </h2>
      <p className="text-base font-normal leading-6 text-grey-forest-600 sm:text-lg sm:leading-7">
        {t('Simplifying the screening process')}
      </p>
    </div>
    <div className="">
      <SignInForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        redirectPaths={{ forgotPassword: redirectPaths.forgotPassword }}
      />
    </div>
    {!signUpHidden && (
      <div className="text-base font-normal leading-6 text-center text-grey-forest-600">
        {t("Don't have an account?")}&nbsp;
        <Link
          to={redirectPaths.signup}
          className="text-base font-semibold leading-6 text-forest-500"
        >
          {t('Sign up')}
        </Link>
      </div>
    )}
  </>
);
