import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { CheckboxAsync } from '@nodal/uikit/components/Checkbox';
import {
  dateFormat,
  timeFormat,
} from '@nodal/uikit/components/DateInput/dateFormat';
import { DateInput } from '@nodal/uikit/components/DateInput/DateInput';
import classNames from 'classnames';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import {
  appPaths,
  candidateDetailsPaths as paths,
  organizationPaths,
} from '@b2b/consts/paths';

import type { MatchingProps } from './Matching.interface';

const futureYears = new Date().getFullYear() + 5;
const maxDate = `${futureYears}-12-31`;
const minDate = '2018-01-01';
const minTime = '01:00 AM';
const maxTime = '12:59 AM';

export const Matching: FC<MatchingProps> = ({
  matchingDetails,
  introductions,
  onChangeRunbookCallDate,
  onChangeRunbookCallScheduled,
  runbookCallDate,
  runbookCallScheduled,
  onViewParentsList,
}) => (
  <div className="w-full divide-y divide-grey-forest-100">
    {onChangeRunbookCallScheduled && onChangeRunbookCallDate && (
      <div className="flex flex-col gap-4 py-3 whitespace-pre-wrap sm:grid sm:grid-cols-3">
        <p className="col-start-1 text-sm text-grey-forest-700">
          {t('Matching Tasks')}
        </p>
        <div className="flex flex-row gap-8 text-sm text-grey-forest-700">
          <CheckboxAsync
            label={t('Call scheduled to review runbook')}
            checked={!!runbookCallScheduled}
            onChange={onChangeRunbookCallScheduled}
          />
        </div>
        <div className="flex flex-col gap-2 text-sm text-grey-forest-700">
          {t('Date of call')}
          <DateInput
            name="runbook_review_call_date"
            max={`${maxDate}, ${maxTime}`}
            min={`${minDate}, ${minTime}`}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            value={runbookCallDate || undefined}
            onChange={(runbookCallDate) =>
              onChangeRunbookCallDate(new Date(runbookCallDate))
            }
          />
        </div>
      </div>
    )}
    <div className="flex flex-col gap-4 py-3 whitespace-pre-wrap sm:grid sm:grid-cols-3">
      <p className="col-start-1 text-sm text-grey-forest-700">
        {t('Matching Data')}
      </p>
      <div className="grid grid-cols-2 col-span-2 col-start-2 gap-x-6 gap-y-2 justify-between break-all sm:justify-start">
        {matchingDetails.map(({ label, value }) => (
          <Fragment key={label}>
            <label className="text-sm text-grey-forest-500">{label}</label>
            <p className="text-sm font-medium text-right text-forest-400 sm:text-left">
              {value}
            </p>
          </Fragment>
        ))}
      </div>
    </div>
    {onViewParentsList && (
      <div className="flex flex-col gap-4 py-3 whitespace-pre-wrap sm:grid sm:grid-cols-3">
        <p className="col-start-1 text-sm text-grey-forest-700">
          {t('IP profile views')}
        </p>
        <div className="gap-x-6 gap-y-2">
          <Button
            onClick={onViewParentsList}
            variant="primary"
            className="sm:text-sm"
          >
            {t('View IP List')}
          </Button>
        </div>
      </div>
    )}
    <div className="flex flex-col gap-4 py-3 whitespace-pre-wrap md:grid md:grid-cols-3">
      <p className="col-start-1 text-sm text-grey-forest-700">
        {t('Introductions')}
      </p>

      <div className="overflow-x-auto col-span-2 col-start-2 gap-x-6 gap-y-2 break-all">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="pr-2 pb-2 text-sm font-normal text-left text-grey-forest-500 whitespace-nowrap sm:pl-0">
                {t('Name(s)')}
              </th>
              <th className="px-2 pb-2 text-sm font-normal text-left text-grey-forest-500 whitespace-nowrap">
                {t('Stage')}
              </th>
              <th className="px-2 pb-2 text-sm font-normal text-left text-grey-forest-500 whitespace-nowrap">
                {t('Date Expires')}
              </th>
            </tr>
          </thead>
          <tbody>
            {introductions.map(
              ({ id, candidateId, name, stage, expirationDate }) => (
                <tr key={id}>
                  <td className="py-2 pr-2 text-sm whitespace-nowrap sm:pl-0">
                    <Link
                      to={`${organizationPaths.app}/${appPaths.candidate}/${candidateId}`}
                      className="font-medium text-forest-500 underline"
                    >
                      {name}
                    </Link>
                  </td>
                  <td
                    className={classNames(
                      `p-2 text-sm text-forest-500 whitespace-nowrap font-medium`,
                      {
                        'text-red-500': stage === 'Expired',
                      },
                    )}
                  >
                    {stage}
                  </td>
                  <td className="p-2 text-sm font-medium text-forest-500 whitespace-nowrap">
                    {expirationDate}
                  </td>
                  <td className="relative p-2 text-sm font-medium text-right whitespace-nowrap sm:pr-0">
                    <Link
                      to={`${paths.introduction}/${id}`}
                      className="text-forest-500 hover:text-forest-600 underline"
                    >
                      {t('View introduction')}
                    </Link>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);
