import type { FC } from 'react';

import { XIcon } from '@heroicons/react/outline';

import type { FilterChipProps } from './FilterChip.interface';

export const FilterChip: FC<FilterChipProps> = ({ label, onRemove }) => {
  return (
    <div className="flex gap-1 justify-between items-center py-1 px-2 w-max bg-white rounded-full border border-grey-forest-200">
      <p className="text-xs font-medium leading-4 text-grey-forest-700">
        {label}
      </p>
      <button className="w-4 h-4 text-grey-forest-500" onClick={onRemove}>
        <XIcon />
      </button>
    </div>
  );
};
