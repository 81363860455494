import type { FC } from 'react';

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { medicalRequestPaths } from 'consts/paths';

import { useCandidate } from '@b2b/components/CandidateDetails';

import { MedicalRequestFormsWrapper } from './MedicalRequestFormsWrapper';

export const MedicalRequestPreview: FC = () => {
  const { id } = useParams() as { id: string };
  const { candidate } = useCandidate();

  const navigate = useNavigate();

  const medicalPullReview = candidate?.medical_review?.pulls.find(
    (pull) => pull.id === Number.parseInt(id),
  );

  useEffect(() => {
    // NOTE: If the id of the request doesn't exist then redirect to the new request
    if (candidate?.medical_review && !medicalPullReview) {
      navigate(`../${medicalRequestPaths.new}`);
    }
  }, [id, navigate, medicalPullReview, candidate?.medical_review]);

  if (!medicalPullReview) return null;

  return (
    <MedicalRequestFormsWrapper values={medicalPullReview} editable={false} />
  );
};
