import { apiEnums } from '@nodal/api';
import { getFormattedAddress } from '@nodal/core/utils';
import { t } from '@nodal/i18n';
import entries from 'lodash/entries';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';

import type {
  RecordPullFormValues,
  RecordPullProviderDetails,
} from './MedicalRequestFormsWrapper.interface';
import type { ApiModel } from '@nodal/api';

export const buildPopulatedInitialValues = ({
  initialValues,
  profile,
  candidate,
  providerDetails,
  signedConsentFormUrl,
}: {
  initialValues: RecordPullFormValues;
  profile: ApiModel.DonorProfileScreen;
  candidate: ApiModel.B2BUser;
  providerDetails: RecordPullProviderDetails;
  signedConsentFormUrl?: string;
}): ApiModel.RecordPull => {
  const { address } = profile || {};

  const populatedValues = {
    ...profile,
    ...providerDetails,
    consent_file_path: signedConsentFormUrl,
    // NOTE: Assign Female for donor by default
    gender:
      candidate.role === apiEnums.UserRoleEnum.Odo ||
      candidate.role === apiEnums.UserRoleEnum.Dnr
        ? t('Female')
        : null,
    address: {
      ...address,
      line2: address
        ? getFormattedAddress(address, ['line1', 'city', 'state'])
        : null,
    },
    last_name: profile?.maiden_name
      ? `${profile.last_name} (${profile.maiden_name})`
      : profile.last_name,
    fax_details: {
      ...initialValues.fax_details,
      cp_organization: providerDetails.doctor_health_system,
    },
  };

  return fromPairs(
    entries(initialValues).map(([key, value]) => [
      key,
      get(populatedValues, key) || value,
    ]),
  ) as ApiModel.RecordPull;
};

const providerEnumToProviderKey = new Map<
  ApiModel.RecordPullDataProviderType,
  string
>([
  [apiEnums.DataProviderTypeEnum.ObGyn, 'ob_gyn_details'],
  [apiEnums.DataProviderTypeEnum.Pregnancy, 'pregnancy_details'],
  [apiEnums.DataProviderTypeEnum.IvfTransfers, 'ivf_transfers_details'],
]);

export const getDeliveryProviderDetails = (
  medicalScreenContent: ApiModel.MedicalRecordReviewScreen,
  provider?: ApiModel.RecordPullDataProviderType,
  deliveryOption?: number,
): RecordPullProviderDetails => {
  const deliveryIndex = Math.max(deliveryOption ?? 0, 1) - 1;
  const providerKey = providerEnumToProviderKey.get(provider!);

  const providerDetails = providerKey
    ? get(medicalScreenContent, providerKey)?.[deliveryIndex]
    : undefined;

  return providerDetails
    ? {
        ...providerDetails,
        doctor_address: providerDetails.doctor_clinic_address,
        doctor_health_system: providerDetails.doctor_clinic_name,
      }
    : {
        doctor_first_name: null,
        doctor_middle_name: null,
        doctor_last_name: null,
        doctor_health_system: null,
        doctor_address: {
          line1: null,
          line2: null,
          city: null,
          state: null,
          zipcode: null,
          location: null,
        },
        doctor_phone_number: null,
      };
};
