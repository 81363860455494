import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';

import { Select } from './Select';

import type { SelectValue } from './Select.interface';
import type { SelectAsyncProps } from './SelectAsync.interface';

export const SelectAsync = <T extends string | number>({
  onChange,
  ...props
}: SelectAsyncProps<T>) => {
  const [disabled, setDisabled] = useState(props.disabled);

  const debouncedOnChange = useRef(
    debounce(
      async (newValue: SelectValue<T>) => {
        try {
          setDisabled(true);
          await onChange(newValue);
        } finally {
          setDisabled(false);
        }
      },
      500,
      { leading: true, trailing: false },
    ),
  ).current;

  useEffect(
    () => () => {
      debouncedOnChange.cancel();
    },
    [debouncedOnChange],
  );

  return <Select {...props} onChange={debouncedOnChange} disabled={disabled} />;
};
