import type { FC } from 'react';

import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import classNames from 'classnames';

import type { PaginationProps } from './Pagination.interface';

export const Pagination: FC<PaginationProps> = ({ page, range, onChange }) => {
  const lastPage = range[range.length - 1];

  return (
    <nav className="flex justify-between items-center py-8 px-6 bg-white border-t border-grey-forest-200">
      <div className="flex flex-1 -mt-px w-0">
        {page !== 1 && (
          <button
            className="group inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 hover:text-forest-600"
            onClick={() => onChange(page - 1)}
          >
            <ArrowLeftIcon
              className="mr-3 w-5 h-5 text-gray-400 group-hover:text-forest-600"
              aria-hidden="true"
            />
            {t('Previous')}
          </button>
        )}
      </div>
      <div className="flex">
        {range.map((pageValue, idx) => {
          return (
            <button
              className={classNames(
                'inline-flex items-center px-4 pt-4 text-sm text-grey-forest-500 border-t-2 border-transparent',
                {
                  'cursor-default': typeof pageValue === 'string',
                  'text-forest-600 border-forest-500 font-medium':
                    page === pageValue,
                },
              )}
              onClick={
                typeof pageValue === 'number'
                  ? () => onChange(pageValue)
                  : undefined
              }
              key={idx}
            >
              {pageValue}
            </button>
          );
        })}
      </div>
      <div className="flex flex-1 justify-end -mt-px w-0">
        {page !== lastPage && (
          <button
            className="group inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 hover:text-forest-600"
            onClick={() => onChange(page + 1)}
          >
            {t('Next')}
            <ArrowRightIcon
              className="ml-3 w-5 h-5 text-gray-400 group-hover:text-forest-600"
              aria-hidden="true"
            />
          </button>
        )}
      </div>
    </nav>
  );
};
