import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { format } from 'date-fns';
import isNumber from 'lodash/isNumber';

import { getProfileDisplayName } from '@b2b/utils/candidate';
import { introductionStageOptions } from '@b2b/utils/introduction';

import type { Introduction, MatchingDetail } from './Matching.interface';
import type { ApiModel } from '@nodal/api';

export const buildIntroductions = (
  introductions: ApiModel.IntroductionFull[],
  role?: ApiModel.UserRoleEnum,
): Introduction[] =>
  introductions.map((introduction) => ({
    id: introduction.id,
    candidateId:
      role === apiEnums.UserRoleEnum.Par
        ? introduction.donor_profile.user.id
        : introduction.parent_profile.user.id,

    name: getProfileDisplayName(
      role === apiEnums.UserRoleEnum.Par
        ? introduction.donor_profile
        : introduction.parent_profile,
    ),
    stage:
      // NOTE: stage is optional in the API,
      // so that's why we have to do fallback to Intros
      introductionStageOptions.find(({ value }) => value === introduction.stage)
        ?.label || t('Introductions'),
    expirationDate:
      introduction.expiration_date &&
      introduction.stage !== apiEnums.IntroductionStageEnum.Expired
        ? format(new Date(introduction.expiration_date), 'MM/dd/yyyy')
        : undefined,
  }));

export const buildMatchingDetails = ({
  favoritesCount,
  introductionsCount,
  profileViewsCount,
  uniqueProfileViewsCount,
}: {
  favoritesCount: number;
  introductionsCount?: number;
  profileViewsCount?: number;
  uniqueProfileViewsCount?: number;
}): MatchingDetail[] => {
  const matchingDetails: MatchingDetail[] = [
    { value: favoritesCount, label: t('Number of favorites'), visible: true },
    {
      value: introductionsCount,
      label: t('Number of introductions sent'),
      visible: isNumber(introductionsCount),
    },
    {
      value: t(
        '{profileViewsCount} (total) / {uniqueProfileViewsCount} (unique)',
        profileViewsCount?.toString() || '',
        uniqueProfileViewsCount?.toString() || '',
      ),
      label: t('Profile views'),
      visible: isNumber(profileViewsCount) && isNumber(uniqueProfileViewsCount),
    },
  ]
    .filter(({ visible }) => visible)
    .map(({ value, label }) => ({
      value: value!.toString(),
      label,
    }));

  return matchingDetails;
};
