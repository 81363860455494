import type { FC } from 'react';

import { SignUpForm } from '@nodal/core/flows/SignUp/SignUpForm';
import { t } from '@nodal/i18n';
import { Link } from 'react-router-dom';

import type { SignUpScreenProps } from './SignUpScreen.interface';

export const SignUpScreen: FC<SignUpScreenProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  title,
  redirectPaths,
  disabledEmail = false,
  roleSelectionVisible,
}) => {
  return (
    <>
      <div className="flex flex-col gap-4">
        <h2 className="mt-6 text-2xl font-semibold leading-8 text-gray-900 sm:text-3xl sm:font-bold sm:leading-9">
          {title}
        </h2>
        <p className="text-base font-normal leading-6 text-grey-forest-600 sm:text-lg sm:leading-7">
          {t('Simplifying the screening process')}
        </p>
      </div>

      <div>
        <SignUpForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          disabledEmail={disabledEmail}
          roleSelectionVisible={roleSelectionVisible}
        />
        <div className="pb-4 mt-8 text-base font-normal leading-6 text-center text-grey-forest-600">
          {t('Already have an account?')}&nbsp;
          <Link
            to={redirectPaths.signin}
            className="text-base font-semibold leading-6 text-forest-500"
          >
            {t('Log In')}
          </Link>
        </div>
      </div>
    </>
  );
};
