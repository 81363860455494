import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { usePermissions } from 'app/PermissionsProvider';
import { useCandidate } from 'components/CandidateDetails';
import { settings } from 'settings';

import { mapSupportTeamUserListToOptions } from './utils';

import type { ApiModel } from '@nodal/api';

export const useSupportTeam = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const { candidate } = useCandidate();
  const { permissions } = usePermissions();

  const isNodalDonorOrParent =
    candidate?.role === apiEnums.UserRoleEnum.Dnr ||
    candidate?.role === apiEnums.UserRoleEnum.Par;

  const showSupportTeamUserDropdown =
    settings.getIpDashboardFeatureEnabled() &&
    !!permissions?.canViewSupportStaff &&
    isNodalDonorOrParent;

  const { data: usersSupportTeamListData } = useQuery(
    queryKeys.usersSupportTeamUsersList,
    () => apiClient.api.UsersApi.usersSupportTeamUsersList(),
    {
      enabled: showSupportTeamUserDropdown,
    },
  );

  const { data: usersSupportTeamUser } = useQuery(
    queryKeys.usersSupportTeamRetrieve,
    () =>
      apiClient.api.UsersApi.usersSupportTeamRetrieve({ id: candidate!.id }),
    {
      enabled: showSupportTeamUserDropdown && !!candidate?.id,
    },
  );

  const supportTeamUserUpdateMutation = useMutation(
    (request: ApiModel.UsersApiUsersSupportTeamPartialUpdateRequest) =>
      apiClient.api.UsersApi.usersSupportTeamPartialUpdate(request),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeys.usersSupportTeamRetrieve),
    },
  );

  const handleAssignSupportTeamUser = async (supportTeamUserId: number) => {
    const assignedSupportTeamMember = usersSupportTeamListData?.data?.find(
      (supportTeamUser) => supportTeamUser.id === supportTeamUserId,
    );

    if (assignedSupportTeamMember && candidate?.id) {
      await supportTeamUserUpdateMutation.mutateAsync({
        id: candidate.id,
        patchedUserSupportTeamUser: {
          id: supportTeamUserId,
          assigned_support_team_members_ids: [supportTeamUserId],
          assigned_support_team_members: [assignedSupportTeamMember],
        },
      });
    }
  };

  const canEditSupportStaffDropdown = !!permissions?.canEditSupportStaff;

  const supportTeamUsersOptions = mapSupportTeamUserListToOptions(
    usersSupportTeamListData?.data,
  );

  const assignedSupportTeamMembers =
    usersSupportTeamUser?.data.assigned_support_team_members;

  return {
    supportTeamUsersList: usersSupportTeamListData?.data,
    showSupportTeamUserDropdown,
    usersSupportTeamUser:
      assignedSupportTeamMembers && assignedSupportTeamMembers.length > 0
        ? usersSupportTeamUser?.data.assigned_support_team_members[0]
        : undefined,
    onAssignSupportTeamUser: handleAssignSupportTeamUser,
    supportTeamUsersOptions,
    canEditSupportStaffDropdown,
  };
};
