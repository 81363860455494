import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { screenTypes } from '@nodal/core/consts/screenTypes';
import {
  dnrProfileInformation,
  MatchProfile,
  parProfileInformation,
} from '@nodal/core/flows/MatchProfile';
import {
  buildProfileInformationWithSections,
  buildScreeningDetails,
} from '@nodal/core/flows/MatchProfile/utils';
import { t } from '@nodal/i18n';
import { BackButton } from '@nodal/uikit/components/BackButton';
import { useModal } from '@nodal/uikit/components/Modal';
import { Spinner } from '@nodal/uikit/components/Spinner';
import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCandidate } from '../CandidateDetails.connect';

import { EditForm } from './EditForm';
import { EditPhotos } from './EditPhotos';
import { convertToPdf } from './matchProfilePdf';

import type { MatchProfileConnectedProps } from './MatchProfile.interface';
import type { ApiModel } from '@nodal/api';
import type { SectionTypes } from '@nodal/core/flows/MatchProfile';
import type { PdfRefs } from '@nodal/core/flows/MatchProfile/MatchProfile.interface';

const useGenerateMatchProfilePdf = (
  user: MatchProfileConnectedProps['user'],
) => {
  const navigate = useNavigate();
  const pdfRefs = useRef<PdfRefs>(null);
  const [pdfGenerating, setPdfGenerating] = useState<boolean>(false);

  const generatePdfDocument = useCallback(async () => {
    try {
      setPdfGenerating(true);

      const { getPdfElements } = pdfRefs.current || {};

      const pdfElements = getPdfElements ? getPdfElements() : undefined;

      if (pdfElements && user?.profile?.email) {
        await convertToPdf(pdfElements, user.profile.email);

        navigate(-1);
        setPdfGenerating(false);
      }
    } catch (error) {
      setPdfGenerating(false);
    }
  }, [navigate, user?.profile?.email]);

  useEffect(() => {
    if (location.hash === '#export') {
      generatePdfDocument();
    }
    // NOTE: Only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    pdfGenerating,
    pdfRefs,
  };
};

const useScreeningDetails = () => {
  const { candidate } = useCandidate();

  const backgroundCheckScreen = candidate?.screens.find(
    ({ type }) => type === screenTypes.checkr,
  );

  const { candidates } =
    (backgroundCheckScreen?.content_object as ApiModel.CheckrScreen) || {};

  const partnersBackgroundCheckStatuses = candidates
    ?.filter((candidate) => candidate.role === apiEnums.RoleFd4Enum.Secondary)
    ?.map((candidate) => candidate?.report?.status);

  const screeningDetails = candidate
    ? buildScreeningDetails({
        screens: candidate.screens,
        medicalReviewSteps: candidate?.medical_review?.steps || [],
        partnersBackgroundCheckStatuses,
      })
    : undefined;

  return { screeningDetails };
};

export const MatchProfileConnected: FC<MatchProfileConnectedProps> = ({
  user,
}) => {
  const { open, close } = useModal();
  const { pdfGenerating, pdfRefs } = useGenerateMatchProfilePdf(user);
  const { screeningDetails } = useScreeningDetails();

  const profileInformationWithSections = useMemo(
    () =>
      user?.role === apiEnums.UserRoleEnum.Dnr
        ? dnrProfileInformation
        : parProfileInformation,
    [user?.role],
  );

  const profileDataWithSections = useMemo(
    () =>
      buildProfileInformationWithSections(profileInformationWithSections, user),
    [user, profileInformationWithSections],
  );

  const onEdit = (title: string, section: SectionTypes) =>
    open({
      title,
      render: () =>
        section === 'photos' ? (
          <EditPhotos />
        ) : (
          <EditForm
            profileSections={profileDataWithSections}
            section={section}
            onCloseModal={close}
          />
        ),
    });

  return (
    <div className="mx-auto w-full max-w-5xl">
      <div className="flex flex-col px-4 pt-6 w-fit sm:pt-8">
        <BackButton title={t('Back to Candidate Page')} />
      </div>
      {pdfGenerating && (
        <div className="flex relative gap-4 justify-center items-center px-4 pt-6">
          <div className="pr-8 -mt-8">
            <Spinner size="medium" variant="secondary" />
          </div>
          <p className="font-medium text-center text-forest-400">
            {t(
              'Please wait while the PDF document for the match profile is being generated',
            )}
          </p>
        </div>
      )}
      <MatchProfile
        profileData={user}
        owner={location.hash === '#export' ? false : true}
        onEdit={onEdit}
        screeningDetails={screeningDetails}
        requestIntroductionHidden
        ref={pdfRefs}
      />
    </div>
  );
};
