import type { ApiModel } from '@nodal/api';

export const mapSupportTeamUserListToOptions = (
  supportTeamUserList?: ApiModel.SupportTeamUser[],
) => {
  if (!supportTeamUserList) return [];

  return supportTeamUserList.map((supportTeamUser) => {
    return {
      label: `${supportTeamUser.first_name} ${supportTeamUser.last_name}`,
      value: supportTeamUser.id,
    };
  });
};
