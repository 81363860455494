import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';

import { ChevronUpDown } from '@uikit/assets';
import { Pagination } from '@uikit/components/Pagination';
import { Spinner } from '@uikit/components/Spinner';

import type { TableProps } from './Table.interface';
import type { RowData } from '@tanstack/react-table';

export const Table = <T extends RowData>({
  page,
  headerGroups,
  rowModel,
  onPageChange,
  pageCount,
  isLoading,
}: TableProps<T>) => (
  <>
    <table className="min-w-full divide-y divide-grey-forest-200">
      <thead className="bg-light-gray">
        {headerGroups.map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, idx) => (
              <th
                scope="col"
                className="py-3.5 pr-3 pl-4 text-xs font-semibold text-left text-grey-forest-400 uppercase sm:pl-6"
                key={header.id}
                colSpan={header.colSpan}
              >
                <div
                  className={classNames({
                    'cursor-pointer select-none flex leading-5 items-center':
                      header.column.getCanSort(),
                    'justify-end': idx === headerGroup.headers.length - 1,
                  })}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  {header.column.getIsSorted() === 'asc' && (
                    <ChevronUpIcon
                      className="flex-none ml-2 w-5 h-5 text-gray-400 rounded"
                      aria-hidden="true"
                    />
                  )}
                  {header.column.getIsSorted() === 'desc' && (
                    <ChevronDownIcon
                      className="flex-none ml-2 w-5 h-5 text-gray-400 rounded"
                      aria-hidden="true"
                    />
                  )}
                  {!header.column.getIsSorted() &&
                    header.column.getCanSort() && (
                      <ChevronUpDown className="flex-none ml-2 w-5 h-5 text-gray-400 rounded" />
                    )}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody className="h-14 bg-white">
        {isLoading ? (
          <tr>
            <td colSpan={100} className="w-full">
              <div className="flex justify-center items-center h-full">
                <Spinner size="medium" variant="secondary" />
              </div>
            </td>
          </tr>
        ) : (
          <>
            {rowModel.rows.length ? (
              rowModel.rows.map((row) => {
                return (
                  <tr
                    className={
                      row.index % 2 === 0 ? undefined : 'bg-light-gray'
                    }
                    key={row.id}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        className="py-4 px-3 text-sm font-medium last:text-center text-grey-forest-500 whitespace-nowrap sm:pl-6"
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  className="py-4 px-3 text-sm text-center text-grey-forest-700 whitespace-nowrap sm:pl-6"
                  colSpan={100}
                >
                  {t('No results')}
                </td>
              </tr>
            )}
            {page && pageCount && (
              <tr>
                <td colSpan={100}>
                  <Pagination
                    pageCount={pageCount}
                    page={page}
                    onChange={onPageChange}
                  />
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </table>
  </>
);
