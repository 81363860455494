import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ApiModel } from '@nodal/api';
import type { AxiosResponse } from 'axios';

export const useProfileDetails = (): {
  profile?: ApiModel.ProfileObject;
  photos?: ApiModel.UserProfileOwnPhoto[];
  updateProfile: (
    updatedProfile: ApiModel.PatchedProfileObject,
  ) => Promise<AxiosResponse<void>>;
} => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const { data: userData } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  const updateProfileMutation = useMutation(
    (requestParameters: ApiModel.UsersApiUsersMeProfilePartialUpdateRequest) =>
      apiClient.api.UsersApi.usersMeProfilePartialUpdate(requestParameters),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.usersMeRetrieve),
    },
  );

  const updateProfile = async (updatedProfile: ApiModel.PatchedProfileObject) =>
    await updateProfileMutation.mutateAsync({
      patchedProfileObject: updatedProfile,
    });

  return {
    profile: userData?.data?.profile,
    photos: userData?.data?.profile_photos,
    updateProfile,
  };
};
