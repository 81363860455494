import type { ReactNode } from 'react';

import { useApiClient, useAuth } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { AppShell } from '@nodal/uikit/components/AppShell';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import jwt_decode from 'jwt-decode';
import { posthog } from 'posthog-js';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { PaymentSummary } from 'components/ExternalPayment/PaymentSummary';
import { settings } from 'settings';

import { PaymentComplete } from '@b2b/components/ExternalPayment/PaymentComplete';
import { PaymentRequest } from '@b2b/components/ExternalPayment/PaymentRequest';
import { TopNav } from '@b2b/components/TopNav';
import {
  externalPayerAppPaths as appPaths,
  externalPayerPaths as paths,
} from '@b2b/consts/paths';

import { useExternalPayerForcedRedirectPath } from './useExternalPayerForcedRedirectPath';

import type {
  ExternalPayerAccess,
  ExternalPayerAppProtectedContextValues,
} from './ExternalPayerAppProtected.interface';
import type { GlobalError } from '@nodal/api';

const ExternalPayerAppProtectedContext =
  createContext<ExternalPayerAppProtectedContextValues>({
    user: undefined,
    request: undefined,
  });

export const useExternalPayerApp = () =>
  useContext(ExternalPayerAppProtectedContext);

const ExternalPayerAppProtectedProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const apiClient = useApiClient();
  const auth = useAuth();
  const [requestId, setRequestId] = useState<number | undefined>();
  const navigate = useNavigate();

  const retrieveTokenAndDecode = useCallback(async () => {
    const accessToken = await auth.tokenProvider.getAccessToken();

    if (accessToken) {
      return jwt_decode(accessToken) as ExternalPayerAccess;
    }
  }, [auth.tokenProvider]);

  const handleDecodedTokenAction = useCallback(async () => {
    const decodedToken = await retrieveTokenAndDecode();

    if (decodedToken) {
      setRequestId(decodedToken.invitation_id);
    } else {
      navigate(paths.logout);
    }
  }, [navigate, retrieveTokenAndDecode]);

  useEffect(() => {
    handleDecodedTokenAction();

    // NOTE: Only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: userData, isLoading: isUserLoading } = useQuery(
    queryKeys.usersMeRetrieve,
    () => apiClient.api.UsersApi.usersMeRetrieve(),
    {
      onError: (e: GlobalError) => {
        if (e?.response?.data?.code === 'token_not_valid') {
          navigate(paths.logout);
        }
      },
    },
  );

  const { data: request, isLoading: isRequestLoading } = useQuery(
    queryKeys.requestRetrieve,
    () =>
      apiClient.api.B2bApi.b2bInvitationsExternalPayerRetrieve({
        id: requestId!,
      }),
    {
      enabled: !!requestId,
    },
  );

  const { role, email, profile } = userData?.data ?? {};
  const { first_name, last_name } = profile || {};

  useEffect(() => {
    if (!email || !settings.getPostHogEnabled()) return;

    posthog.identify(email, {
      name: `${first_name} ${last_name}`,
      role,
    });
  }, [role, email, first_name, last_name]);

  if (isUserLoading || isRequestLoading) {
    return <LoadingScreen />;
  }

  return (
    <ExternalPayerAppProtectedContext.Provider
      value={{ user: userData?.data, request: request?.data }}
    >
      {children}
    </ExternalPayerAppProtectedContext.Provider>
  );
};

export const ExternalPayerAppProtectedConsumer = () => {
  const { user } = useExternalPayerApp();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { pathname } = useLocation();
  const scrollToTopRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return () => queryClient.removeQueries();
  }, [queryClient]);

  useEffect(() => {
    scrollToTopRef?.current?.scrollIntoView();
  }, [pathname]);

  const forcedRedirectPath = useExternalPayerForcedRedirectPath({
    role: user?.role,
  });

  useEffect(() => {
    if (forcedRedirectPath) {
      navigate(forcedRedirectPath);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcedRedirectPath]);

  return (
    <AppShell
      ref={scrollToTopRef}
      top={<TopNav menuItems={[]} />}
      center={
        user?.role ? (
          <Routes>
            <Route
              path={`${appPaths.request}/*`}
              element={<PaymentRequest />}
            />
            <Route
              path={`${appPaths.paymentComplete}/*`}
              element={<PaymentComplete />}
            />
            <Route
              path={`${appPaths.paymentSummary}/*`}
              element={<PaymentSummary />}
            />
          </Routes>
        ) : (
          <LoadingScreen />
        )
      }
    />
  );
};

export const ExternalPayerAppProtected = () => (
  <ExternalPayerAppProtectedProvider>
    <ExternalPayerAppProtectedConsumer />
  </ExternalPayerAppProtectedProvider>
);
