import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { BackButton } from '@nodal/uikit/components/BackButton';

import { PreviewQuestions } from './PreviewQuestions';

import type { CandidateApplicationProps } from './CandidateApplication.interface';

export const CandidateApplication: FC<CandidateApplicationProps> = ({
  questions,
  values,
  title,
  status,
  manualScreenApproval,
}) => {
  return (
    <div className="flex flex-col gap-4 justify-start items-stretch py-6 px-4 mx-auto w-full max-w-5xl sm:py-8">
      <div className="flex flex-col mb-2 w-fit sm:mb-4">
        <BackButton title={t('Back to Candidate Page')} />
      </div>
      <PreviewQuestions
        title={title}
        questions={questions}
        values={values}
        status={status}
        manualScreenApproval={manualScreenApproval}
      />
    </div>
  );
};
