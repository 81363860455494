import { apiEnums } from '@nodal/api';
import { useScreening } from '@nodal/core/flows/Screening';
import {
  HipaaConsentForms,
  useHipaaConsentForms,
} from '@nodal/core/flows/Screening/HipaaConsentForms';

import { settings } from '@b2b/settings';

const clientId = settings.getHelloSignAppId();

export const HipaaConsentFormsConnected = () => {
  const { currentStep, submit } = useScreening();

  const forms = useHipaaConsentForms({
    currentStep,
    submit,
    clientId,
    skipDomainVerification: !!import.meta.env.DEV,
    consentFormType: apiEnums.DropboxDocumentTypeEnum.ConsentFormB2b,
  });

  if (!forms) {
    return null;
  }

  return <HipaaConsentForms forms={forms} />;
};
