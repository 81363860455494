import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { screenTypes } from '@nodal/core/consts/screenTypes';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useCallback } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';

import { settings } from 'settings';

import { useCandidate } from '@b2b/components/CandidateDetails';

import { useMedicalRecordFormValues } from '../useMedicalRecordFormValues';
import { getDeliveryProviderDetails } from '../utils';

import { FaxForm } from './FaxForm';
import { initialValues } from './initialValues';
import { generateProviderOptions, splitDeliveryProviderValue } from './utils';
import { validationSchema } from './validation';

import type {
  FaxFormConnectedProps,
  FaxProviderDetails,
} from './FaxForm.interface';
import type { ApiModel } from '@nodal/api';

const defaultFaxFormDetails = settings.getDefaultFaxFormDetails();

export const FaxFormConnected: FC<FaxFormConnectedProps> = ({
  editable,
  values,
}) => {
  const { candidate } = useCandidate();
  const formValues = useMedicalRecordFormValues({
    defaultValues: initialValues,
  });

  const medicalScreen = candidate?.screens.find(
    ({ type }) => type === screenTypes.medicalRecordReview,
  )?.content_object as ApiModel.MedicalRecordReviewScreen;

  const { pregnancy_details, ivf_transfers_details } = medicalScreen || {};

  const providerOptions = generateProviderOptions({
    pregnancyDetails: pregnancy_details || undefined,
    ivfTransferDetails: ivf_transfers_details || undefined,
  });

  const onChangeProvider = useCallback(
    (
      updateProviderValues: (
        providerValues: FaxProviderDetails,
        organization: string | null,
      ) => void,
      value: string,
    ) => {
      const { delivery, provider } = splitDeliveryProviderValue(value);
      const providerType = provider as ApiModel.DataProviderTypeEnum;
      const providerDetails = getDeliveryProviderDetails(
        medicalScreen,
        providerType,
        delivery,
      );

      updateProviderValues(
        {
          delivery_provider: value,
          delivery,
          data_provider_type: providerType,
          ...providerDetails,
        },
        providerDetails.doctor_health_system || null,
      );
    },
    [medicalScreen],
  );

  const onChangeFaxType = useCallback(
    (
      updateFaxDetails: (values: ApiModel.RecordPullFaxDetails) => void,
      faxType: ApiModel.FaxAdapterTypeEnum,
    ) => {
      if (faxType === apiEnums.FaxAdapterTypeEnum.Email) {
        updateFaxDetails({
          fax_adapter_type: faxType,
          cp_subject: defaultFaxFormDetails?.cp_subject,
          cp_comment: defaultFaxFormDetails?.cp_comment_email,
          to_email: defaultFaxFormDetails?.to_email,
          cp_from_name: null,
          cp_organization: null,
          cp_to_name: null,
        });
      } else if (faxType === apiEnums.FaxAdapterTypeEnum.Srfax) {
        updateFaxDetails({
          fax_adapter_type: faxType,
          cp_subject: defaultFaxFormDetails?.cp_subject,
          cp_comment: defaultFaxFormDetails?.cp_comment_srfax,
          cp_to_name: defaultFaxFormDetails?.cp_to_name,
          cp_from_name: defaultFaxFormDetails?.cp_from_name,
          cp_organization:
            formValues?.initialValues?.fax_details?.cp_organization,
          to_email: null,
        });
      } else {
        updateFaxDetails({
          fax_adapter_type: faxType,
          cp_subject: null,
          cp_to_name: null,
          cp_comment: null,
          to_email: null,
          cp_from_name: null,
          cp_organization: null,
        });
      }
    },
    [formValues?.initialValues?.fax_details?.cp_organization],
  );

  const onSubmit = async (data: ApiModel.RecordPull) => {
    const parsedPhoneNumber = data?.fax_details?.to_fax_number
      ? parsePhoneNumber(data.fax_details.to_fax_number)
      : null;

    await formValues?.onSubmit({
      ...data,
      fax_details: {
        ...data.fax_details,
        fax_adapter_type:
          data.fax_details?.fax_adapter_type ||
          apiEnums.FaxAdapterTypeEnum.Srfax,
        to_fax_number: parsedPhoneNumber
          ? `${parsedPhoneNumber.countryCallingCode}${parsedPhoneNumber.nationalNumber}`
          : null,
      },
    });
  };

  if (!formValues) {
    return <LoadingScreen />;
  }

  return (
    <FaxForm
      initialValues={
        values
          ? {
              ...values,
              delivery_provider: `${values.delivery}-${values.data_provider_type}`,
              fax_details: {
                ...values.fax_details,
                fax_adapter_type:
                  values.fax_details?.fax_adapter_type ||
                  apiEnums.FaxAdapterTypeEnum.Srfax,
                // NOTE: We store parsed fax numbers without the + prefix.
                // However, the react-phone-number-input library uses phone numbers in E.164 format,
                // requiring us to add the + prefix for correct number display.
                to_fax_number: values?.fax_details?.to_fax_number?.includes('+')
                  ? values?.fax_details?.to_fax_number
                  : `+${values?.fax_details?.to_fax_number}`,
              },
            }
          : {
              ...formValues.initialValues,
              delivery_provider: `${initialValues.delivery}-${initialValues.data_provider_type}`,
            }
      }
      onSubmit={onSubmit}
      editable={editable}
      validationSchema={validationSchema}
      onChangeProvider={onChangeProvider}
      providerOptions={providerOptions}
      onChangeFaxType={onChangeFaxType}
    />
  );
};
