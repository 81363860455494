import type { FC } from 'react';

import { ExclamationIcon } from '@heroicons/react/solid';
import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { Checkbox } from '@nodal/uikit/components/Checkbox';
import { DateInput, DateTimeInput } from '@nodal/uikit/components/DateInput';
import { Select } from '@nodal/uikit/components/Select';
import { Textarea } from '@nodal/uikit/components/Textarea';
import { Form, Formik } from 'formik';

import { introductionStageOptions } from '@b2b/utils/introduction';

import { DecisionStatusIndicator } from './DecisionStatusIndicator';

import type { IntroductionFormProps } from './IntroductionForm.interface';

const rejectReasonOptions = [
  {
    value: apiEnums.ParentPassingReasonEnum.LifestylesDontMatch,
    label: t("Our lifestyles don't match"),
  },
  {
    value: apiEnums.ParentPassingReasonEnum.TooFarAway,
    label: t('Surrogate/parents are too far away'),
  },
  {
    value: apiEnums.ParentPassingReasonEnum.JourneyPreferencesDontMatch,
    label: t("Our surrogacy journey preferences don't match"),
  },
  {
    value: apiEnums.ParentPassingReasonEnum.Other,
    label: t('Other'),
  },
];

const futureYears = new Date().getFullYear() + 5;
const maxDate = `${futureYears}-12-31`;
const minDate = '2018-01-01';
const minTime = '01:00 AM';
const maxTime = '12:59 AM';

export const IntroductionForm: FC<IntroductionFormProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  warningMessage,
  editable,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validationOnChange={false}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <div className="flex flex-col gap-4 justify-between items-start py-8 md:flex-row md:items-center">
            <div className="flex flex-col gap-6 w-full md:flex-row md:gap-8">
              <div className="w-full md:w-[200px]">
                <Select
                  options={introductionStageOptions}
                  name="stage"
                  label={t('Stage')}
                  disabled={!editable}
                />
              </div>
              <DateInput
                name="expiration_date"
                label={t('Date Expires')}
                max={maxDate}
                min={minDate}
                openToDate={new Date()}
                disabled={!editable}
              />
            </div>
            {warningMessage && (
              <div className="flex gap-2 justify-start w-full md:justify-end">
                <div className="w-max">
                  <ExclamationIcon className="w-6 h-6 fill-yellow-500" />
                </div>
                <p className="text-sm text-grey-forest-600">{warningMessage}</p>
              </div>
            )}
          </div>
          <div className="space-y-8">
            <div>
              <div className="relative">
                <div
                  className="flex absolute inset-0 items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-grey-forest-200" />
                </div>
                <div className="flex relative justify-start">
                  <span className="pr-3 text-base font-semibold leading-7 text-forest-500 bg-white">
                    {t('Introduction')}
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-6 mt-6 sm:flex-row sm:gap-12">
                <div className="flex gap-12 justify-between sm:justify-start">
                  <DecisionStatusIndicator
                    id="initial_parent_accepted"
                    value={values.initial_parent_accepted}
                    label={t('IP Accepted')}
                  />
                  <div className="flex flex-col gap-2 items-center">
                    <label
                      htmlFor="call_scheduled"
                      className="text-sm font-medium text-gray-900"
                    >
                      {t('Call scheduled')}
                    </label>
                    <Checkbox
                      label=""
                      name="call_scheduled"
                      disabled={!editable}
                    />
                  </div>
                </div>
                <DateTimeInput
                  name="call_date"
                  label={t('Date of call')}
                  className="w-full sm:w-[200px]"
                  max={`${maxDate}, ${maxTime}`}
                  min={`${minDate}, ${minTime}`}
                  disabled={!editable}
                />
              </div>
            </div>
            <div>
              <div className="relative">
                <div
                  className="flex absolute inset-0 items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-grey-forest-200" />
                </div>
                <div className="flex relative justify-start">
                  <span className="pr-3 text-base font-semibold leading-7 text-forest-500 bg-white">
                    {t('Match Acceptance')}
                  </span>
                </div>
              </div>

              <div className="flex gap-12 justify-between mt-6 sm:justify-start">
                <DecisionStatusIndicator
                  id="match_accepted_donor"
                  value={values.match_accepted_donor}
                  label={t('Surrogate Accepted')}
                />
                <DecisionStatusIndicator
                  id="match_accepted_parent"
                  value={values.match_accepted_parent}
                  label={t('IP Accepted')}
                />
              </div>
              <Textarea
                name="notes"
                label={t('Notes')}
                className="mt-6"
                disabled={!editable}
              />
            </div>
            <div>
              <div className="relative">
                <div
                  className="flex absolute inset-0 items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-grey-forest-200" />
                </div>
                <div className="flex relative justify-start">
                  <span className="pr-3 text-base font-semibold leading-7 text-forest-500 bg-white">
                    {t('Rejection')}
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-4 mt-6 w-full">
                <div className="flex gap-4 w-full">
                  <p className="text-sm font-medium text-gray-900">
                    {t('IP reason')}
                  </p>
                  <p className="text-sm text-grey-forest-600">
                    <span>
                      {
                        rejectReasonOptions.find(
                          ({ value }) => value === values.parent_passing_reason,
                        )?.label
                      }
                      &nbsp;
                    </span>
                    {values.parent_passing_reason ===
                      apiEnums.ParentPassingReasonEnum.Other && (
                      <span>- {values.parent_other_answer}</span>
                    )}
                  </p>
                </div>
                <div className="flex gap-4 w-full">
                  <p className="text-sm font-medium text-gray-900">
                    {t('Surrogate reason')}
                  </p>
                  <p className="text-sm text-grey-forest-600">
                    <span>
                      {
                        rejectReasonOptions.find(
                          ({ value }) => value === values.donor_passing_reason,
                        )?.label
                      }
                      &nbsp;
                    </span>
                    {values.donor_passing_reason ===
                      apiEnums.DonorPassingReasonEnum.Other && (
                      <span>- {values.donor_other_answer}</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            {editable && (
              <div className="mt-6 ml-auto w-full text-right">
                <ButtonLoading
                  variant="primary"
                  type="submit"
                  loading={isSubmitting}
                >
                  {t('Save')}
                </ButtonLoading>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
