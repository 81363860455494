import type { FC, ReactNode } from 'react';

import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { findIndex } from 'lodash';
import {
  useContext,
  useReducer,
  useEffect,
  createContext,
  useCallback,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useExternalPayerApp } from '@b2b/app/ExternalPayerAppProtected';
import {
  externalPayerAppPaths as appPaths,
  externalPayerPaths as paths,
} from '@b2b/consts/paths';
import { buildRequestSteps } from '@b2b/utils/candidateRequest';

import { PaymentRequest } from './PaymentRequest';
import { requestStepsMeta } from './requestSteps';

import type {
  PaymentRequestAction,
  PaymentRequestDispatch,
  PaymentRequestState,
} from './PaymentRequest.interface';

const paymentRequestReducer = (
  state: PaymentRequestState,
  action: PaymentRequestAction,
): PaymentRequestState => {
  switch (action.type) {
    case 'updateSteps':
      return { ...state, steps: action.payload };
    case 'updateCurrentStepIndex':
      return { ...state, currentStepIndex: action.payload };
    case 'updateCompleted':
      return { ...state, completed: action.payload };
    default:
      return state;
  }
};

export const PaymentRequestContext = createContext<
  { state: PaymentRequestState; dispatch: PaymentRequestDispatch } | undefined
>(undefined);

export const PaymentRequestContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(paymentRequestReducer, {
    steps: [],
    currentStepIndex: undefined,
    completed: false,
  });

  return (
    <PaymentRequestContext.Provider value={{ state, dispatch }}>
      {children}
    </PaymentRequestContext.Provider>
  );
};

export const usePaymentRequest = () => {
  const context = useContext(PaymentRequestContext);

  if (!context) {
    throw new Error(
      'usePaymentRequest must be used within a PaymentRequestContextProvider',
    );
  }

  const {
    state: { steps, currentStepIndex, completed },
    dispatch,
  } = context;

  const { request } = useExternalPayerApp();
  const apiClient = useApiClient();

  const requestId = request?.id;

  const requestRetrieve = useQuery(
    queryKeys.requestRetrieve,
    () =>
      apiClient.api.B2bApi.b2bInvitationsExternalPayerRetrieve({
        id: requestId!,
      }),
    {
      enabled: false,
    },
  );

  const refresh = useCallback(async () => {
    const { data: refetchedRequest } = await requestRetrieve.refetch();

    if (!refetchedRequest?.data) {
      return;
    }

    const stepIndex = findIndex(requestStepsMeta, (step) =>
      step.isCurrentStep(refetchedRequest.data),
    );

    if (stepIndex === -1) {
      dispatch({
        type: 'updateCompleted',
        payload: true,
      });
      return;
    }

    const requestSteps = buildRequestSteps({
      currentStepIndex: stepIndex,
      steps: requestStepsMeta,
    });

    dispatch({
      type: 'updateCurrentStepIndex',
      payload: stepIndex,
    });

    dispatch({
      type: 'updateSteps',
      payload: requestSteps,
    });
  }, [dispatch, requestRetrieve]);

  return {
    steps,
    currentStepIndex,
    completed,
    refresh,
  };
};

const PaymentRequestContextConsumer: FC = () => {
  const queryClient = useQueryClient();

  const { completed, steps, currentStepIndex, refresh } = usePaymentRequest();

  const navigate = useNavigate();

  useEffect(() => {
    return () => queryClient.removeQueries(queryKeys.requestRetrieve);
  }, [queryClient]);

  useEffect(() => {
    refresh();
    // NOTE: Only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (completed) {
      navigate(`${paths.app}/${appPaths.paymentComplete}`);
      return;
    }

    // NOTE: We cannot determine yet where we are.
    if (currentStepIndex === undefined || !steps.length) {
      return;
    }

    const requestPath =
      steps.find((step) => step.index === currentStepIndex)?.path ||
      steps[0].path;

    navigate(`${paths.app}/${appPaths.request}/${requestPath}`);
    return;
  }, [currentStepIndex, navigate, completed, steps]);

  if (!steps?.length || currentStepIndex === undefined) {
    return <LoadingScreen />;
  }

  return <PaymentRequest steps={steps} activeStepIndex={currentStepIndex} />;
};

export const PaymentRequestConnected: FC = () => {
  return (
    <PaymentRequestContextProvider>
      <PaymentRequestContextConsumer />
    </PaymentRequestContextProvider>
  );
};
