import { apiEnums } from '@nodal/api';
import { badgeStatus } from '@nodal/uikit/components/StatusBadge';

import type { ApiModel } from '@nodal/api';
import type { Status as BadgeStatus } from '@nodal/uikit/components/StatusBadge';

export const candidateStepsStatusToBagdeStatus = new Map<
  ApiModel.CandidateStepsEnum,
  BadgeStatus | undefined
>([
  [apiEnums.CandidateStepsEnum.Complete, badgeStatus.complete],
  [apiEnums.CandidateStepsEnum.Deactivated, badgeStatus.deactivated],
  [apiEnums.CandidateStepsEnum.PersonalInformation, badgeStatus.processing],
  [apiEnums.CandidateStepsEnum.Application, badgeStatus.processing],
  [apiEnums.CandidateStepsEnum.IdCheck, badgeStatus.processing],
  [apiEnums.CandidateStepsEnum.HipaaConsentForm, badgeStatus.processing],
  [apiEnums.CandidateStepsEnum.MedicalForm, badgeStatus.processing],
  [apiEnums.CandidateStepsEnum.Checkr, badgeStatus.processing],
  [apiEnums.CandidateStepsEnum.FinalReview, badgeStatus.processing],
  [apiEnums.CandidateStepsEnum.Matching, badgeStatus.processing],
  [apiEnums.CandidateStepsEnum.NotRegistered, badgeStatus.pending],
  [apiEnums.CandidateStepsEnum.Rejected, badgeStatus.rejected],
]);
