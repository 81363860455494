import { apiEnums } from '@nodal/api';
import every from 'lodash/every';

import type { ApiModel } from '@nodal/api';

const roleToRequiredMedicalRequestScreenTypes = new Map<
  ApiModel.UserRoleEnum | undefined,
  string[]
>([
  [apiEnums.UserRoleEnum.Dnr, ['donor-profile', 'hipaa-consent-form']],
  [
    apiEnums.UserRoleEnum.Odo,
    ['organization-candidate-profile', 'hipaa-consent-form'],
  ],
  [
    apiEnums.UserRoleEnum.Osd,
    ['organization-candidate-profile', 'hipaa-consent-form'],
  ],
  [
    apiEnums.UserRoleEnum.Oed,
    ['organization-candidate-profile', 'hipaa-consent-form'],
  ],
]);

export const isMedicalRequestCreationAllowed = (
  screens: ApiModel.ScreenDetailsNested[],
  role?: ApiModel.UserRoleEnum,
) =>
  every(
    roleToRequiredMedicalRequestScreenTypes
      .get(role)
      ?.map((type) => screens.find((screen) => screen.type === type)?.status),
    (status) => status === apiEnums.ScreenStatusEnum.App,
  );
