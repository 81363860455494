import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useModal } from '@nodal/uikit/components/Modal';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { DonorEditProfileForm } from './DonorEditProfileForm';
import { schema as validationSchema } from './validation';

import type {
  DonorProfileFormValues,
  DonorEditProfileFormConnectProps,
} from './DonorEditProfileForm.interface';
import type { ApiModel } from '@nodal/api';

export const DonorEditProfileFormConnected: FC<
  DonorEditProfileFormConnectProps
> = ({ id, profile }) => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const { close } = useModal();

  const [uploadProgress, setUploadProgress] = useState<number>();

  const update = useMutation(
    (request: ApiModel.B2bApiB2bUserProfilePartialUpdateRequest) =>
      apiClient.api.B2bApi.b2bUserProfilePartialUpdate(request, {
        onUploadProgress: (e) => {
          if (e.total) {
            setUploadProgress(e.loaded / e.total);
          }
        },
      }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeys.candidateProfileRetrieve),
    },
  );

  const handleUpdate = async (data: DonorProfileFormValues) => {
    await update.mutateAsync({
      id,
      patchedProfileObject: data,
    });

    close();
  };

  if (!profile) {
    return null;
  }

  return (
    <DonorEditProfileForm
      uploadProgress={uploadProgress}
      // NOTE: Type casting because we define the type in more detail than we get from the API,
      // the view will be only for donor profile
      initialValues={profile as DonorProfileFormValues}
      onSubmit={handleUpdate}
      validationSchema={validationSchema}
    />
  );
};
