import type { FC } from 'react';

import { InformationCircleIcon } from '@heroicons/react/outline';
import { t } from '@nodal/i18n';
import { Tooltip } from '@nodal/uikit/components/Tooltip';
import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { appPaths, organizationPaths as paths } from '@b2b/consts/paths';
import { convertTimeDifferenceToTimeLabel } from '@b2b/utils';

import type { NodalMarketplaceProps } from './NodalMarketplaceDashboard.interface';

const lifeTimeDetailsKeyToTitle = new Map<string, string>([
  ['disqualified_count', t('Disqualified')],
  ['donor_count', t('Surrogates')],
  ['ip_count', t('Intended Parents')],
  ['matches_count', t('Matches')],
  ['med_reviews_completed_count', t('Medical Reviews')],
]);

const matchingDetailsKeyToTitle = new Map<string, string>([
  ['donors_matching_count', t('Surrogates Matching')],
  ['parents_matching_count', t('Parents Matching')],
]);

export const NodalMarketplaceDashboard: FC<NodalMarketplaceProps> = ({
  lifetimeDetails,
  matchingDetails,
  stageTable: { headerGroups, rowModel },
}) => {
  return (
    <div className="flex relative flex-col col-span-12 py-6 w-full h-full font-avenir-next text-sm font-normal leading-5">
      <div className="w-full">
        <div className="flex gap-6 justify-center items-center pb-6 mx-auto max-w-7xl sm:justify-between md:px-6 lg:px-8">
          <h2 className="text-2xl leading-7 text-gray-900">
            {t('Nodal Marketplace')}
          </h2>
        </div>
      </div>
      <div className="overflow-x-hidden px-4 mx-auto space-y-6 w-full max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-col gap-10 sm:flex-row">
          <div className="flex flex-col gap-4 py-4 px-10 bg-white rounded-lg sm:w-2/3">
            <p className="w-full text-xs text-right text-grey-forest-500">
              {t('Lifetime numbers')}
            </p>
            <div className="flex gap-4 justify-around">
              {Object.entries(lifetimeDetails).map(
                ([key, value]) =>
                  key !== 'med_reviews_legacy_completed_count' && (
                    <div key={key} className="space-y-2 w-[110px] text-center">
                      <div className="flex relative justify-center items-center p-2 text-2xl text-forest-500 rounded-lg border border-forest-400">
                        {value}
                        {key === 'med_reviews_completed_count' && (
                          <Tooltip
                            message={t(
                              'Includes {number} legacy reviews',
                              lifetimeDetails.med_reviews_legacy_completed_count.toString(),
                            )}
                            position="top"
                            className="absolute top-1 right-1"
                          >
                            <InformationCircleIcon className="w-5 h-5 text-grey-forest-900" />
                          </Tooltip>
                        )}
                      </div>
                      <p className="font-medium text-grey-forest-900">
                        {lifeTimeDetailsKeyToTitle.get(key)}
                      </p>
                    </div>
                  ),
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4 p-4 w-full bg-white rounded-lg sm:w-1/3">
            <p className="w-full text-xs text-right text-grey-forest-500">
              {t('Today')}
            </p>
            <div className="flex gap-2 justify-around">
              {Object.entries(matchingDetails).map(([key, value], i) => (
                <div key={key} className="space-y-2 w-[110px] text-center">
                  <div
                    className={classNames(
                      'flex justify-center items-center p-2 text-2xl text-white rounded-lg border',
                      i % 2 === 0
                        ? 'bg-forest-300 border-forest-300'
                        : 'bg-forest-400 border-forest-400',
                    )}
                  >
                    {value}
                  </div>
                  <p className="font-medium text-grey-forest-900">
                    {matchingDetailsKeyToTitle.get(key)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="overflow-x-auto p-8 w-full bg-white rounded-lg">
          <div className="flex flex-col gap-4">
            <div>
              <h3 className="text-grey-forest-900">
                <span className="text-xl font-semibold leading-7">
                  {t('Stages Overview')}&nbsp;
                </span>
                <span className="text-base">{t('(today)')}</span>
              </h3>
            </div>
            <table
              className="border-separate"
              style={{ borderSpacing: '0 0.75rem' }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        scope="col"
                        className="pr-3 pl-4 text-sm font-medium text-center first:text-left text-grey-forest-900 sm:pl-6"
                        key={header.id}
                        colSpan={header.colSpan}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {rowModel.rows.map((row) => (
                  <Fragment key={row.id}>
                    <tr>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          className="py-4 px-3 text-base text-center first:text-left whitespace-nowrap bg-grey-forest-50 last:rounded-r-lg first:rounded-l-lg sm:pl-6"
                          key={cell.id}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      ))}
                    </tr>
                    {row.getIsExpanded() && (
                      <tr>
                        <td
                          colSpan={row.getVisibleCells().length}
                          className="pr-3 pl-4 text-sm font-medium text-center first:text-left text-grey-forest-900 sm:pl-6"
                        >
                          <table className="w-full">
                            <tbody>
                              {row.original.outsideAvgUsers.map(
                                ({
                                  first_name,
                                  last_name,
                                  id,
                                  start_date_time,
                                  last_login,
                                }) => (
                                  <tr
                                    key={id}
                                    className="grid grid-cols-3 gap-4 py-4 w-full text-sm font-normal text-grey-forest-600 border-t border-grey-forest-100 first:border-none"
                                  >
                                    <td className="underline">
                                      <Link
                                        to={`${paths.app}/${appPaths.candidate}/${id}`}
                                      >
                                        {first_name} {last_name}
                                      </Link>
                                    </td>
                                    <td>
                                      <span>{t('Time on stage:')}</span>&nbsp;
                                      <span className="font-medium">
                                        {convertTimeDifferenceToTimeLabel(
                                          start_date_time,
                                        )}
                                      </span>
                                    </td>
                                    <td>
                                      <span>{t('Last login:')}&nbsp;</span>
                                      <span className="font-medium">
                                        {format(
                                          new Date(last_login),
                                          'MM/dd/yyyy',
                                        )}
                                      </span>
                                    </td>
                                  </tr>
                                ),
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
