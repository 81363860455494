import type { FC } from 'react';

import { t } from '@nodal/i18n';

import type { OrganizationProfileProps } from './OrganizationProfile.interface';

export const OrganizationProfile: FC<OrganizationProfileProps> = ({
  contactDetails,
  organizationDetails,
  id,
}) => {
  return (
    <>
      <div className="p-8 w-full bg-white rounded-lg">
        <div className="flex flex-col grid-cols-2 gap-12 justify-between sm:grid">
          <div className="flex flex-col gap-4 w-full divide-y divide-grey-forest-200">
            <div className="flex justify-between">
              <h4 className="text-base font-semibold text-grey-forest-900">
                {t('Organization Information')}
              </h4>
              <p className="text-base font-medium text-grey-forest-500">
                {t('ID: {id}', id.toString())}
              </p>
            </div>
            {organizationDetails.map(({ label, value }) => (
              <div className="grid grid-cols-1/2 gap-2 pt-4" key={label}>
                <p className="w-fit text-base font-medium text-grey-forest-500">
                  {label}
                </p>
                <p className="w-full text-base font-medium text-grey-forest-900 whitespace-pre-line">
                  {value}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-4 w-full divide-y divide-grey-forest-200">
            <h4 className="text-base font-semibold text-grey-forest-900">
              {t('Contact Information')}
            </h4>
            {contactDetails.map(({ label, value }) => (
              <div className="grid grid-cols-1/2 gap-2 pt-4" key={label}>
                <p className="w-fit text-base font-medium text-grey-forest-500">
                  {label}
                </p>
                <p className="w-full text-base font-medium text-grey-forest-900 whitespace-pre-line">
                  {value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
