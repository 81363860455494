import type { FC } from 'react';

import { CheckCircleIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';

import type { PlanProps } from './Plan.interface';

export const Plan: FC<PlanProps> = ({ name, planPackage }) => {
  return (
    <div className="py-4 px-8 space-y-6 w-full bg-white rounded-lg border border-grey-forest-200 shadow-sm">
      <div className="flex justify-between items-center w-full text-sm font-medium text-left text-grey-forest-900 focus:outline-none">
        <div>
          <h2 className="text-xl font-semibold leading-7 text-grey-forest-900">
            {name}
          </h2>
        </div>
      </div>
      <div className="space-y-6 text-sm text-gray-500">
        <div className="relative">
          <div
            className="flex absolute inset-0 items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-grey-forest-200" />
          </div>
          <div className="flex relative justify-start">
            <span className="text-xs font-medium tracking-wide leading-4 uppercase bg-white">
              {t("What's included")}
            </span>
          </div>
        </div>
        <div className="space-y-5">
          <div>
            <h3 className="mb-3 text-base font-semibold text-grey-forest-900">
              {planPackage.title}
            </h3>
            <div className="flex flex-col gap-4">
              {planPackage.features.map((title) => (
                <div className="flex gap-3 w-full" key={title}>
                  <CheckCircleIcon className="w-4 h-4 text-forest-200" />
                  <p className="text-base text-grey-forest-900">{title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
