import { apiEnums } from '@nodal/api';
import { screenTypes } from '@nodal/core/consts/screenTypes';

import { usePermissions } from '@b2b/app/PermissionsProvider';
import { useCandidate } from '@b2b/components/CandidateDetails';
import { useManualScreenApproval } from '@b2b/components/CandidateDetails/ManualScreenApproval/useManualScreenApproval';

import { CandidateApplication } from './CandidateApplication';

import type { QuestionnaireScreen } from '@nodal/core/flows/Screening/FormQuestions';

export const CandidateApplicationConnected = () => {
  const { candidate } = useCandidate();
  const { permissions } = usePermissions();

  const screen = candidate?.screens.find(
    ({ type }) =>
      type === screenTypes.parentsQuestionnaire ||
      type === screenTypes.donorQuestionnaire,
  );

  const manualScreenApproval = useManualScreenApproval({
    status: screen?.status,
    id: screen?.id,
    type: screen?.type,
  });

  if (!candidate || !permissions || !screen) return null;

  const { role } = candidate;

  const { content_object, status, manual_check } = screen || {};

  // NOTE: Too many screen types defined in API types cause TS error
  // Expected type in this screen is QuestionnaireScreen
  const { flags } = (content_object as QuestionnaireScreen) || {};

  const markedForReviewCount = flags?.filter(
    ({ weight }) =>
      weight === apiEnums.WeightEnum.Review ||
      weight === apiEnums.WeightEnum.SoftReview,
  ).length;

  const autorejectCount = flags?.filter(
    ({ weight }) => weight === apiEnums.WeightEnum.Autoreject,
  ).length;

  return (
    <CandidateApplication
      role={role}
      markedForReviewCount={markedForReviewCount}
      status={status}
      autorejectCount={autorejectCount}
      previewEnabled={permissions.canViewCandidateScreens}
      manuallyChecked={!!manual_check}
      manualScreenApproval={manualScreenApproval}
    />
  );
};
