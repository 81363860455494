import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useFeatureFlags } from 'app/FeatureFlagsProvider';
import { usePermissions } from 'app/PermissionsProvider';
import { candidatesDashboardPaths } from 'consts/paths';
import { canCreateCandidateRequest } from 'utils/candidate';
import { getCurrentOrganization } from 'utils/organization';

import { Candidates } from './Candidates';
import { tabIds } from './tabIds';

import type { ApiModel } from '@nodal/api';

export const CandidatesConnected = () => {
  const { permissions } = usePermissions();
  const navigate = useNavigate();
  const params = useParams();
  const path = params['*'];

  useEffect(() => {
    if (!path) {
      // NOTE: Initially redirect to users path
      navigate(candidatesDashboardPaths.users);
    }
  }, [path, navigate]);

  const { features } = useFeatureFlags();
  const apiClient = useApiClient();

  const { data: organizationsList } = useQuery(
    queryKeys.organizationsList,
    () => apiClient.api.B2bApi.b2bOrganizationsList(),
    {
      refetchOnMount: false,
    },
  );

  const { data: invitationsList } = useQuery(
    queryKeys.invitationsList,
    () => apiClient.api.B2bApi.b2bInvitationsList(),
    {
      refetchOnMount: false,
    },
  );

  const tabs = [
    {
      id: tabIds.candidates,
      label: t('Users'),
      index: 0,
      path: candidatesDashboardPaths.users,
    },
    {
      id: tabIds.invitations,
      label: t('Requests Pending'),
      index: 1,
      path: candidatesDashboardPaths.requests,
    },
  ];

  const organization = getCurrentOrganization<ApiModel.Organization>(
    organizationsList?.data,
    permissions?.canViewAllOrganizations,
  );

  const { is_prepaid, prepaid_screening_plans } = organization || {};
  const { results } = invitationsList?.data || {};
  const { canManageInvitations, canViewAllOrganizations } = permissions || {};

  // NOTE: For one step requests (prepaid with one screening plan) we will not have pending (draft) requests
  // or if the organization has disabled the feature for creating requests
  // because one-step invitation is submitted and sent, there will never be anything in the table where we show
  // pending invitations (this applies at least for 2-step, where invitation is created and saved in the first step)
  //
  const activeTabs =
    (is_prepaid && prepaid_screening_plans?.length === 1 && !results?.length) ||
    !canCreateCandidateRequest({
      isInvitationFeatureEnabled: !!features?.invitations,
      canManageInvitations: !!canManageInvitations,
      canViewAllOrganizations: !!canViewAllOrganizations,
    })
      ? tabs.filter((tab) => tab.id !== tabIds.invitations)
      : tabs;

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    tabs.find((tab) => tab.path === path)?.index || 0,
  );

  const handleTabIndexChange = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);

    const tabPath = tabs.find((tab) => tab.index === tabIndex)?.path;
    if (tabPath && path !== tabPath) {
      navigate(tabPath);
    }
  };

  return (
    <Candidates
      onChange={handleTabIndexChange}
      activeTabIndex={activeTabIndex}
      tabs={activeTabs}
      disabledCandidateRequest={
        !canCreateCandidateRequest({
          isInvitationFeatureEnabled: !!features?.invitations,
          canManageInvitations: !!canManageInvitations,
          canViewAllOrganizations: !!canViewAllOrganizations,
        })
      }
    />
  );
};
