import type { FC } from 'react';

import type { CardItems } from './Card.interface';

export const Card: FC<CardItems> = ({ icon: Icon, title, description }) => {
  return (
    <div className="py-4 px-6 w-full bg-white rounded-lg border-grey-forest-200">
      <div className="flex gap-6">
        <div>
          <Icon className="w-6 fill-forest-500 sm:w-8" />
        </div>
        <div className="flex flex-col gap-1">
          <h2 className="text-base font-semibold text-grey-forest-900">
            {title}
          </h2>
          <p className="text-sm text-grey-forest-600">{description}</p>
        </div>
      </div>
    </div>
  );
};
