import type { FC } from 'react';

import { useField } from 'formik';
import { useState } from 'react';

import { ProfilePhotoEditor } from './ProfilePhotoEditor';

import type { ProfilePhotoEditorConnectedProps } from './ProfilePhotoEditor.interface';
import type { UserProfilePhoto } from '@nodal/api';

export const ProfilePhotoEditorConnected: FC<
  ProfilePhotoEditorConnectedProps
> = ({ name, label }) => {
  const [field, meta, { setValue }] = useField(name);
  const [editedValue, setEditedValue] = useState<
    UserProfilePhoto | undefined
  >();

  const handleAdd = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setEditedValue({
        ...field.value,
        image: reader.result as string,
      });
    };

    reader.readAsDataURL(file);
  };

  const handleSave = async (url: string) =>
    setValue({ ...field.value, image: url });

  return (
    <ProfilePhotoEditor
      onAdd={handleAdd}
      onEdit={() => setEditedValue(field.value)}
      onDelete={() => setValue({ ...field.value, image: undefined })}
      onSave={handleSave}
      label={label}
      editedUrl={editedValue?.image}
      error={meta.touched && meta.error ? meta.error : undefined}
      {...field}
    />
  );
};
