import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { Checkbox } from '@nodal/uikit/components/Checkbox/Checkbox';

import type { IdentityVerificationProps } from './IdentityVerification.interface';

export const IdentityVerification: FC<IdentityVerificationProps> = ({
  label,
  manuallyChecked,
  status,
  manualScreenApproval: { onManualApprove, manualApprovalEnabled },
}) => {
  return (
    <div className="flex flex-col gap-4 w-full sm:grid sm:grid-cols-3 sm:items-center">
      <p className="col-start-1 text-sm text-grey-forest-700">
        {t('Stripe status')}
      </p>
      <p className="col-start-2 text-sm font-medium text-forest-500">{label}</p>
      {manualApprovalEnabled && (
        <div className="flex col-start-3 gap-6 items-end sm:ml-auto">
          <Checkbox
            label={t('ID check verified manually')}
            onChange={(e) =>
              onManualApprove(
                e.target.checked ? e.target.checked.toString() : undefined,
              )
            }
            id="stripe_identity_manual_approve"
            value={manuallyChecked ? manuallyChecked?.toString() : undefined}
            disabled={
              status === apiEnums.ScreenStatusEnum.App && !manuallyChecked
            }
          />
        </div>
      )}
    </div>
  );
};
