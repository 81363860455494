import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { PaymentComplete } from '@nodal/uikit/components/Payment';
import { useQuery } from 'react-query';

import { useExternalPayerApp } from '@b2b/app/ExternalPayerAppProtected';
import { usePaymentComplete } from '@b2b/components/PaymentFlow';
import {
  externalPayerAppPaths as appPaths,
  externalPayerPaths as paths,
  externalPayerRequestPaths as requestPaths,
} from '@b2b/consts/paths';
import { canProceedWithPayment } from '@b2b/utils/payment';

export const PaymentCompleteConnected: FC = () => {
  const { request } = useExternalPayerApp();
  const apiClient = useApiClient();

  const requestId = request?.id;

  const { data: requestRetrieve } = useQuery(
    queryKeys.requestRetrieve,
    () =>
      apiClient.api.B2bApi.b2bInvitationsExternalPayerRetrieve({
        id: requestId!,
      }),
    {
      // Payments are async, check user payment status every 3 seconds
      refetchInterval: 3000,
      enabled: !!requestId,
    },
  );

  const screeningPayment = requestRetrieve?.data?.screening_payment;
  const { payment_status: paymentStatus } = screeningPayment || {};

  const paymentComplete = usePaymentComplete({
    redirectPath: canProceedWithPayment(paymentStatus)
      ? `${paths.app}/${appPaths.request}`
      : `${paths.app}/${appPaths.paymentSummary}`,
    paymentStatus,
  });

  if (!paymentComplete || !request) return <LoadingScreen />;

  return (
    <PaymentComplete
      {...paymentComplete}
      buttonMeta={
        paymentComplete.status === 'failed'
          ? {
              path: `${paths.app}/${appPaths.request}/${requestPaths.payment}`,
              title: t('Resubmit Payment'),
            }
          : undefined
      }
    />
  );
};
