import { Checkbox } from '@nodal/uikit/components/Checkbox/Checkbox';
import { Select } from '@nodal/uikit/components/Select/Select';
import { isArray } from 'lodash';
import { Fragment } from 'react';

import type { FiltersProps } from './Filters.interface';

export const Filters = <TFilter extends object>({
  title,
  details,
  onChange,
}: FiltersProps<TFilter>) => {
  return (
    <div className="hidden gap-4 p-6 w-[300px] min-w-[300px] h-max text-left bg-white rounded-lg sm:flex sm:visible sm:flex-col">
      <h3 className="mb-4 text-base font-semibold leading-8 text-grey-forest-900">
        {title}
      </h3>
      {details.map(({ id, type, options, label, value }) => (
        <Fragment key={id.toString()}>
          {(type === 'select' || type === 'multiselect') && options && (
            <Select
              options={options}
              name={id.toString()}
              onChange={(value) => onChange(id, value)}
              label={label}
              value={isArray(value) ? value : value?.toString() || undefined}
              multiple={type === 'multiselect'}
            />
          )}
          {type === 'checkbox' && (
            <Checkbox
              id={id.toString()}
              onChange={(e) =>
                onChange(
                  id,
                  e.target.checked ? e.target.checked.toString() : undefined,
                )
              }
              label={label}
              checked={value?.toString() === 'true'}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};
