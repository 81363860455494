import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { PaymentComplete } from '@nodal/uikit/components/Payment';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useExternalPayerApp } from '@b2b/app/ExternalPayerAppProtected';
import {
  getPaymentInfo,
  transformPaymentStatus,
} from '@b2b/components/PaymentFlow/utils';
import {
  externalPayerAppPaths as appPaths,
  externalPayerPaths as paths,
} from '@b2b/consts/paths';
import { canProceedWithPayment } from '@b2b/utils/payment';

import type { ApiModel } from '@nodal/api';

const calculateTotalPriceBy = (
  paymentType?: ApiModel.PaymentTypeEnum,
  price?: ApiModel.B2bScreeningPackagePrice,
): number => {
  const { amount, card_payment_fees, bank_transfer_fees } = price || {};

  const paymentTypeToFees = new Map([
    [apiEnums.PaymentTypeEnum.BankTransfer, bank_transfer_fees],
    [apiEnums.PaymentTypeEnum.CardPayment, card_payment_fees],
    [apiEnums.PaymentTypeEnum.None, undefined],
  ]);

  const fees = paymentType ? paymentTypeToFees.get(paymentType) : undefined;

  return (
    (amount ? Number.parseInt(amount) : 0) + (fees ? Number.parseInt(fees) : 0)
  );
};

export const PaymentSummaryConnected: FC = () => {
  const { request, user } = useExternalPayerApp();
  const navigate = useNavigate();

  const screeningPayment = request?.screening_payment;
  const {
    payment_type,
    payment_status,
    package: screeningPackage,
    completion_date,
  } = screeningPayment || {};

  useEffect(() => {
    if (canProceedWithPayment(payment_status)) {
      navigate(`${paths.app}/${appPaths.request}`);
    }
  }, [navigate, payment_status]);

  if (!request || !payment_status) return <LoadingScreen />;

  const totalAmount = calculateTotalPriceBy(
    payment_type,
    screeningPackage?.screening_price,
  );

  const { title, messages } = getPaymentInfo(payment_status, user?.email);

  const paymentSummaryDetails = {
    amount: totalAmount,
    date: completion_date ? new Date(completion_date) : undefined,
    status: transformPaymentStatus(payment_status),
    title,
    messages,
    email: user?.email,
  };

  // NOTE: We currently don't have UI for payment summary screen -
  // in this case we will display the PaymentComplete
  return <PaymentComplete {...paymentSummaryDetails} />;
};
