import { t } from '@nodal/i18n';
import { capitalize } from 'lodash';

import { candidateRequestStepIds } from '@b2b/consts/candidateRequestStepIds';

import { requestStepsMeta } from './requestSteps';

import type { Package } from './ScreeningPackage/ScreeningPackage.interface';
import type { ApiModel } from '@nodal/api';

export const createPackageLabel = (name: string, price: string) =>
  `${capitalize(name)} - $${Number(price).toLocaleString('en-US')}`;

export const createPackageDescription = (descriptionEntries?: string[]) => {
  return descriptionEntries?.length
    ? t(
        `Includes: {featureTitles}`,
        descriptionEntries.map((title) => title).join(', '),
      )
    : '';
};

export const buildDisplayPackages = (
  availablePackages: ApiModel.ScreeningPackage[],
): Package[] =>
  availablePackages.map((availablePackage) => {
    return {
      ...availablePackage,
      label: createPackageLabel(
        availablePackage.name,
        availablePackage.screening_price.amount,
      ),
      description: createPackageDescription(
        availablePackage?.description_entries,
      ),
    };
  });

export const getAvailableRequestSteps = ({
  organization,
  request,
  canSelectOrganization,
  canSelectScreeningPackage,
}: {
  canSelectOrganization: boolean;
  organization?: ApiModel.Organization;
  request?: ApiModel.Invitation;
  canSelectScreeningPackage: boolean;
}) => {
  const hasPrepaidOrganization = organization?.is_prepaid;
  const hasOnePrepaidPlan = organization?.prepaid_screening_plans?.length === 1;

  // NOTE: For prepaid organizations with one plan,
  // show only 1 step - filling out the profile
  if (hasPrepaidOrganization && hasOnePrepaidPlan && !request) {
    return requestStepsMeta.filter(
      (step) => step.id === candidateRequestStepIds.profile,
    );
  }

  const hasMultiplePrepaidPlans =
    organization && organization.prepaid_screening_plans?.length > 1;
  const hasRequestWithNoScreeningPayments =
    request && !request.screening_payments.length;

  // NOTE: For pre paid organization with more plans than 1 or
  // for users who choose an organization for the candidate in the request
  // we show 2 steps - profile and selection of screens (without further payment steps)
  if (
    (hasPrepaidOrganization &&
      (hasMultiplePrepaidPlans || hasRequestWithNoScreeningPayments)) ||
    canSelectOrganization
  ) {
    return requestStepsMeta.filter(
      (step) =>
        step.id === candidateRequestStepIds.profile ||
        step.id === candidateRequestStepIds.screening,
    );
  }

  // NOTE: Exclude the screening step for users without permission to choose a screening package
  if (!canSelectScreeningPackage) {
    return requestStepsMeta.filter(
      (step) => step.id !== candidateRequestStepIds.screening,
    );
  }

  return requestStepsMeta;
};
