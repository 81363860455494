import { addBasePathReduce } from '@nodal/core/utils';

export const rolePaths = {
  candidate: '/candidate',
  organization: '/organization',
  externalPayer: '/external-payer',
};

export const basePaths = {
  app: '/app',
  authenticate: '/authenticate',
  forgotPassword: '/forgot-password',
  logout: '/logout',
  resetPassword: '/reset-password',
  root: '/',
  signin: '/login',
  signup: '/register',
};

export const externalPayerBasePaths = {
  invalidLink: '/invalid-link',
  app: '/app',
  authenticate: '/authenticate',
  logout: '/logout',
  root: '/',
};

// NOTE: Absolute paths defined for each role, with added 'candidate' or 'organization' prefix
export const pathsFrom = {
  candidate: {
    ...addBasePathReduce(basePaths, rolePaths.candidate),
  },
  organization: {
    ...addBasePathReduce(basePaths, rolePaths.organization),
  },
  externalPayer: addBasePathReduce(
    externalPayerBasePaths,
    rolePaths.externalPayer,
  ),
};

// NOTE: absolute paths defined by role
export const candidatePaths = pathsFrom['candidate'];
export const organizationPaths = pathsFrom['organization'];
export const externalPayerPaths = pathsFrom['externalPayer'];

export const externalPayerAppPaths = {
  request: 'request',
  paymentComplete: 'payment-complete',
  paymentSummary: 'payment-summary',
};

export const externalPayerRequestPaths = {
  contract: 'contract',
  payment: 'payment',
};

export const forgotPasswordPaths = {
  checkEmail: 'check-email',
};

export const signupPaths = {
  checkEmail: 'check-email',
  confirmEmail: 'confirm-email',
  emailNotConfirmed: 'not-confirmed',
  creationSuccess: 'creation-success',
};

export const appPaths = {
  profile: 'profile',
  candidates: 'candidates',
  organizations: 'organizations',
  organization: 'organization',
  organizationId: 'organization/:id',
  introductions: 'introductions',
  introduction: 'introduction',
  introductionId: 'introduction/:id',
  requestId: 'request/:id',
  request: 'request',
  welcome: 'welcome',
  paymentComplete: 'payment-complete',
  paymentCompleteId: 'payment-complete/:id',
  requestSubmitted: 'request-submitted',
  requestSubmittedId: 'request-submitted/:id',
  candidate: 'candidate',
  candidateId: 'candidate/:id',
  screening: 'screening',
  deactivated: 'deactivated',
  nodalMarketplace: 'nodal-marketplace',
};

export const requestPaths = {
  new: 'new',
  update: 'update',
  updateId: 'update/:id',
  profile: 'profile',
  screening: 'screening',
  payment: 'payment',
  contract: 'contract',
  paymentComplete: 'payment-complete',
};

export const screeningPaths = {
  profile: 'profile',
  questions: 'questions',
  medical: 'medical',
  complete: 'complete',
  identity: 'identity',
  background: 'background',
  signing: 'signing',
};

export const candidateDetailsPaths = {
  application: 'application',
  medical: 'medical',
  medicalRequest: 'medical-request',
  matchProfile: 'match-profile',
  introduction: 'introduction',
  introductionId: 'introduction/:id',
};

export const medicalRequestPaths = {
  new: 'new',
  previewId: 'preview/:id',
  preview: 'preview',
};

export const candidatesDashboardPaths = {
  users: 'users',
  requests: 'requests',
};
