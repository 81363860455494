import { apiEnums } from '@nodal/api';
import { getOrdinalNumber } from '@nodal/core/utils';
import { t } from '@nodal/i18n';

import type { ApiModel } from '@nodal/api';

export const generateProviderOptions = ({
  pregnancyDetails,
  ivfTransferDetails,
}: {
  pregnancyDetails?: ApiModel.MedicalRecordReviewScreenPregnancyDetail[];
  ivfTransferDetails?: ApiModel.MedicalRecordReviewScreenIvfTransferDetail[];
}) => {
  let options = [
    {
      label: t('Current OB/GYN'),
      value: `1-${apiEnums.DataProviderTypeEnum.ObGyn}`,
    },
  ];

  if (pregnancyDetails?.length) {
    const pregnancyDetailsOptions = [...Array(pregnancyDetails.length)].map(
      (el, idx) => ({
        label: t(
          '{numberWithOrdinal} Pregnancy and Delivery',
          getOrdinalNumber(idx + 1),
        ),
        value: `${idx + 1}-${apiEnums.DataProviderTypeEnum.Pregnancy}`,
      }),
    );

    options = [...options, ...pregnancyDetailsOptions];
  }

  if (ivfTransferDetails?.length) {
    const ivfTransfersDetails = [...Array(ivfTransferDetails.length)].map(
      (el, idx) => ({
        label: t('{numberWithOrdinal} IVF Transfer', getOrdinalNumber(idx + 1)),
        value: `${idx + 1}-${apiEnums.DataProviderTypeEnum.IvfTransfers}`,
      }),
    );
    options = [...options, ...ivfTransfersDetails];
  }

  return options;
};

export const splitDeliveryProviderValue = (value: string) => {
  const [delivery, provider] = value.split('-');
  return { delivery: parseInt(delivery), provider };
};
