import type { ReactNode } from 'react';

import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { createContext, useContext } from 'react';
import { useQuery } from 'react-query';

import type { PermissionsScope } from './PermissionsProvider.interface';
import type { ApiModel } from '@nodal/api';

const getPermissionsScopeBy = ({
  role,
}: {
  role: ApiModel.UserRoleEnum;
}): PermissionsScope => {
  switch (role) {
    // Superadmin
    case apiEnums.UserRoleEnum.Sad:
      return {
        hasAccessToApp: true,
        canInitiateMedicalRequest: true,
        canEditMedicalDocuments: true,
        canViewCandidateScreens: true,
        canEditCandidateDetails: true,
        canEditCandidateInvitation: true,
        canSelectOrganizationForCandidate: true,
        canViewMedicalDocuments: true,
        canEditOrganization: false,
        canEditIntroduction: true,
        canViewAllOrganizations: true,
        canViewAndEditManualChecks: true,
        canManageInvitations: true,
        canManageMatching: true,
        canManageNavigator: true,
        canSelectScreeningPackage: true,
        canEditMedicalFiles: true,
        canDownloadMatchProfile: true,
        canResubmitBackgroundCheck: true,
        canViewAndEditNotes: true,
        canViewSupportStaff: true,
        canEditSupportStaff: true,
      };

    // Organization Admin
    case apiEnums.UserRoleEnum.Oad:
      return {
        hasAccessToApp: true,
        canInitiateMedicalRequest: false,
        canEditMedicalDocuments: false,
        canEditCandidateDetails: true,
        canEditCandidateInvitation: true,
        canViewCandidateScreens: true,
        canSelectOrganizationForCandidate: false,
        canViewMedicalDocuments: true,
        canEditOrganization: true,
        canEditIntroduction: true,
        canViewAllOrganizations: false,
        canViewAndEditManualChecks: false,
        canManageInvitations: true,
        canManageMatching: true,
        canManageNavigator: true,
        canSelectScreeningPackage: true,
        canEditMedicalFiles: true,
        canDownloadMatchProfile: true,
        canResubmitBackgroundCheck: true,
        canViewAndEditNotes: true,
        canViewSupportStaff: false,
        canEditSupportStaff: false,
      };

    // Organization Staff
    case apiEnums.UserRoleEnum.Oss:
      return {
        hasAccessToApp: true,
        canInitiateMedicalRequest: false,
        canEditMedicalDocuments: false,
        canViewCandidateScreens: true,
        canEditCandidateDetails: true,
        canEditCandidateInvitation: true,
        canSelectOrganizationForCandidate: false,
        canViewMedicalDocuments: false,
        canEditOrganization: false,
        canEditIntroduction: false,
        canViewAllOrganizations: false,
        canViewAndEditManualChecks: false,
        canManageInvitations: true,
        canManageMatching: true,
        canManageNavigator: true,
        canSelectScreeningPackage: true,
        canEditMedicalFiles: true,
        canDownloadMatchProfile: true,
        canResubmitBackgroundCheck: false,
        canViewAndEditNotes: true,
        canViewSupportStaff: true,
        canEditSupportStaff: true,
      };

    // Organization Staff With Medical Review Permission
    case apiEnums.UserRoleEnum.Oms:
      return {
        hasAccessToApp: true,
        canInitiateMedicalRequest: true,
        canEditMedicalDocuments: false,
        canViewCandidateScreens: true,
        canEditCandidateDetails: true,
        canEditCandidateInvitation: true,
        canSelectOrganizationForCandidate: false,
        canViewMedicalDocuments: true,
        canEditOrganization: false,
        canEditIntroduction: false,
        canViewAllOrganizations: false,
        canViewAndEditManualChecks: false,
        canManageInvitations: true,
        canManageMatching: true,
        canManageNavigator: true,
        canSelectScreeningPackage: true,
        canEditMedicalFiles: true,
        canDownloadMatchProfile: true,
        canResubmitBackgroundCheck: false,
        canViewAndEditNotes: true,
        canViewSupportStaff: true,
        canEditSupportStaff: true,
      };

    // Organization Donor Mentor
    case apiEnums.UserRoleEnum.Dnm:
      return {
        hasAccessToApp: true,
        canInitiateMedicalRequest: false,
        canEditMedicalDocuments: false,
        canEditCandidateDetails: false,
        canEditCandidateInvitation: false,
        canViewCandidateScreens: false,
        canSelectOrganizationForCandidate: false,
        canViewMedicalDocuments: false,
        canEditOrganization: false,
        canEditIntroduction: false,
        canViewAllOrganizations: false,
        canViewAndEditManualChecks: false,
        canManageInvitations: false,
        canManageMatching: true,
        canManageNavigator: true,
        canSelectScreeningPackage: false,
        canEditMedicalFiles: false,
        canDownloadMatchProfile: false,
        canResubmitBackgroundCheck: false,
        canViewAndEditNotes: true,
        canViewSupportStaff: true,
        canEditSupportStaff: false,
      };

    // Parent Admin
    case apiEnums.UserRoleEnum.Opa:
      return {
        hasAccessToApp: true,
        canInitiateMedicalRequest: false,
        canEditMedicalDocuments: false,
        canEditCandidateDetails: false,
        canEditCandidateInvitation: true,
        canViewCandidateScreens: false,
        canSelectOrganizationForCandidate: false,
        canViewMedicalDocuments: false,
        canEditOrganization: true,
        canEditIntroduction: false,
        canViewAllOrganizations: false,
        canViewAndEditManualChecks: false,
        canManageInvitations: true,
        canManageMatching: false,
        canManageNavigator: false,
        canSelectScreeningPackage: false,
        canEditMedicalFiles: false,
        canDownloadMatchProfile: false,
        canResubmitBackgroundCheck: false,
        canViewAndEditNotes: false,
        canViewSupportStaff: false,
        canEditSupportStaff: false,
      };

    default:
      return {
        hasAccessToApp: false,
        canInitiateMedicalRequest: false,
        canViewMedicalDocuments: false,
        canEditMedicalDocuments: false,
        canViewCandidateScreens: false,
        canEditCandidateDetails: false,
        canEditCandidateInvitation: false,
        canSelectOrganizationForCandidate: false,
        canEditOrganization: false,
        canEditIntroduction: false,
        canViewAllOrganizations: false,
        canViewAndEditManualChecks: false,
        canManageInvitations: false,
        canManageMatching: false,
        canManageNavigator: false,
        canSelectScreeningPackage: false,
        canEditMedicalFiles: false,
        canDownloadMatchProfile: false,
        canResubmitBackgroundCheck: false,
        canViewAndEditNotes: false,
        canViewSupportStaff: false,
        canEditSupportStaff: false,
      };
  }
};

export const PermissionsContext = createContext<{
  permissions?: PermissionsScope;
}>({
  permissions: undefined,
});

export const usePermissions = () => useContext(PermissionsContext);

export const PermissionsProvider = ({ children }: { children: ReactNode }) => {
  const apiClient = useApiClient();
  const { data: userData } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  if (!userData?.data) return <LoadingScreen />;

  const permissions = getPermissionsScopeBy({
    role: userData.data.role,
  });

  return (
    <PermissionsContext.Provider value={{ permissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};
