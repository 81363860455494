import { getFormattedAddress } from '@nodal/core/utils';
import { isInRelationship } from '@nodal/core/utils/profile';
import { t } from '@nodal/i18n';
import { format, parseISO } from 'date-fns';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import type { CandidateProfileDetails } from './CandidateProfile.interface';

export const dnrProfileDetails: CandidateProfileDetails[] = [
  {
    name: 'maiden_name',
    label: t('Maiden Name'),
    value: null,
    formatter: (candidate) => get(candidate, 'maiden_name') || undefined,
  },
  {
    name: 'date_of_birth',
    label: t('Date of Birth'),
    value: null,
    formatter: (candidate) => {
      const dateOfBirth = get(candidate, 'date_of_birth');

      return dateOfBirth
        ? format(parseISO(dateOfBirth), 'MM/dd/yyyy')
        : undefined;
    },
  },
  {
    name: 'email',
    label: t('Email(s)'),
    value: null,
    formatter: (candidate) => {
      const partnerEmail = get(candidate, 'partner_email');
      const email = get(candidate, 'email');

      return partnerEmail ? `${email}\n${partnerEmail}` : email;
    },
  },

  {
    name: 'phone_number',
    label: t('Phone Number'),
    value: null,
    formatter: (candidate) => get(candidate, 'phone_number') || undefined,
  },
  {
    name: 'address',
    label: t('Home Address'),
    value: null,
    formatter: (candidate) => {
      const address = get(candidate, 'address');
      return address
        ? getFormattedAddress(address, ['line1', 'city', 'state', 'zipcode'])
        : undefined;
    },
  },
];

export const parProfileDetails: CandidateProfileDetails[] = [
  {
    name: 'email',
    label: t('Email(s)'),
    value: null,
    formatter: (candidate) => {
      const email = get(candidate, 'email');

      if (
        !email ||
        !('relationship_status' in candidate) ||
        !('partner_email' in candidate)
      ) {
        return undefined;
      }

      const { relationship_status, partner_email } = candidate || {};

      return isInRelationship(relationship_status) && partner_email
        ? `${email}\n${partner_email}`
        : email;
    },
  },
  {
    name: 'phone_number',
    label: t('Phone Number'),
    value: null,
    formatter: (candidate) => {
      const phoneNumber = get(candidate, 'phone_number');
      if (
        !phoneNumber ||
        !('relationship_status' in candidate) ||
        !('partner_phone_number' in candidate)
      ) {
        return undefined;
      }

      const { relationship_status, partner_phone_number } = candidate || {};

      return isInRelationship(relationship_status) && partner_phone_number
        ? `${phoneNumber}\n${partner_phone_number}`
        : phoneNumber;
    },
  },
  {
    name: 'address',
    label: t('Home Address'),
    value: null,
    formatter: (candidate) => {
      const address = get(candidate, 'address');
      if (
        !address ||
        !('relationship_status' in candidate) ||
        !('partner_address' in candidate)
      ) {
        return undefined;
      }

      const { partner_address, relationship_status } = candidate || {};

      const formattedAddress = address
        ? getFormattedAddress(address, [
            'line1',
            'line2',
            'city',
            'state',
            'zipcode',
          ])
        : undefined;

      const formattedPartnerAddress = partner_address
        ? getFormattedAddress(partner_address, [
            'line1',
            'line2',
            'city',
            'state',
            'zipcode',
          ])
        : undefined;

      return isInRelationship(relationship_status) &&
        !isEqual(address, partner_address) &&
        formattedPartnerAddress
        ? `${formattedAddress}\n${formattedPartnerAddress}`
        : formattedAddress;
    },
  },
];
