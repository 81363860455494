import { queryKeys } from '@nodal/core/consts/query';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { useFeatureFlags } from 'app/FeatureFlagsProvider';
import { usePermissions } from 'app/PermissionsProvider';
import { canViewMatchingSections } from 'utils/candidate';

import { useCandidate } from '@b2b/components/CandidateDetails';

import { CandidateOverview } from './CandidateOverview';

export const CandidateOverviewConnected = () => {
  const { candidate, profile, ipTasks } = useCandidate();
  const { id } = useParams() as { id: string };
  const { permissions } = usePermissions();
  const { features } = useFeatureFlags();

  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.removeQueries([
        queryKeys.candidateRetrieve,
        Number.parseInt(id),
      ]);
      queryClient.removeQueries(queryKeys.candidateProfileRetrieve);
      queryClient.removeQueries([
        queryKeys.usersDocumentsList,
        Number.parseInt(id),
      ]);
      queryClient.removeQueries(queryKeys.plutoDetailsRetrieve);
      queryClient.removeQueries(queryKeys.usersIntroductionsList);
      queryClient.removeQueries(queryKeys.usersSupportTeamRetrieve);
      queryClient.removeQueries([queryKeys.b2bUserTasksList, id]);
    };
  }, [queryClient, id]);

  if (!candidate || !profile || !permissions || !features) {
    return <LoadingScreen />;
  }

  const { canManageMatching, canViewAllOrganizations, canViewAndEditNotes } =
    permissions;
  const { matching: isMatchingFeatureEnabled } = features;

  const shouldDisplayNotes =
    canViewMatchingSections({
      isMatchingFeatureEnabled,
      canManageMatching,
      canViewAllOrganizations,
    }) && canViewAndEditNotes;

  return (
    <CandidateOverview
      candidate={candidate}
      profile={profile}
      shouldDisplayNotes={shouldDisplayNotes}
      ipTasks={ipTasks}
    />
  );
};
