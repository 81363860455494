import type { ReactElement, FC } from 'react';

import { FlagIcon } from '@heroicons/react/solid';
import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import classNames from 'classnames';
import find from 'lodash/find';
import { useCallback } from 'react';

import type { PreviewQuestionsProps } from './PreviewQuestions.interface';
import type { ApiModel } from '@nodal/api';
import type { QuestionnaireScreen } from '@nodal/core/flows/Screening/FormQuestions';
import type { Choice } from '@nodal/core/flows/Screening/FormQuestions/FormQuestions.interface';
import type { BodyMeasurements, Name } from '@nodal/core/models';

const weightToFlagColor = new Map<ApiModel.WeightEnum | undefined, string>([
  [apiEnums.WeightEnum.SoftReview, 'fill-yellow-500'],
  [apiEnums.WeightEnum.Review, 'fill-yellow-500'],
  [apiEnums.WeightEnum.Autoreject, 'fill-red-500'],
]);

const weightToQuestionBorder = new Map<ApiModel.WeightEnum | undefined, string>(
  [
    [apiEnums.WeightEnum.SoftReview, 'border border-yellow-500'],
    [apiEnums.WeightEnum.Review, 'border border-yellow-500'],
    [apiEnums.WeightEnum.Autoreject, 'border border-red-500'],
  ],
);

// NOTE: some flags are named differently from the name defined in the questions
const questionNameToFlagFieldName = new Map<string, string>([
  ['body_measurements', 'weight'],
]);

const renderValue = (
  type: string,
  value: QuestionnaireScreen[keyof QuestionnaireScreen],
  choices?: Choice[],
): ReactElement | null => {
  switch (type) {
    case 'select':
    case 'radio':
      return (
        <p className="text-grey-forest-700">
          {choices?.find((choice) => choice.value === value?.toString())?.label}
        </p>
      );

    case 'bmi':
      const { weight, height_ft, height_in } =
        (value as BodyMeasurements) || {};

      return (
        <div className="flex flex-col gap-2">
          {typeof weight === 'number' && (
            <p className="text-grey-forest-700">
              {t('Weight: {number} lbs', weight.toString())}
            </p>
          )}
          {typeof height_ft === 'number' && typeof height_in === 'number' && (
            <p className="text-grey-forest-700">
              {t(
                'Height: {feet} ft {inches} in',
                height_ft.toString(),
                height_in.toString(),
              )}
            </p>
          )}
        </div>
      );

    case 'name':
      const { first_name, last_name } = (value as Name) || {};

      return first_name && last_name ? (
        <p className="text-grey-forest-700">
          {first_name} {last_name}
        </p>
      ) : null;

    default:
      return <p className="text-grey-forest-700">{value?.toString()}</p>;
  }
};

export const PreviewQuestions: FC<PreviewQuestionsProps> = ({
  title,
  questions,
  values,
  manualScreenApproval,
  status,
}) => {
  const shouldRenderQuestion = (
    formValues: QuestionnaireScreen,
    hidden?: (value: QuestionnaireScreen) => boolean,
  ) => (hidden ? !hidden(formValues) : true);

  const getQuestionFlagWeight = useCallback(
    (name: string) => {
      const fieldName = questionNameToFlagFieldName.get(name) || name;

      return find(values.flags, ['field_name', fieldName])?.weight;
    },
    [values.flags],
  );

  const screeningCompleted =
    status === apiEnums.ScreenStatusEnum.App ||
    status === apiEnums.ScreenStatusEnum.Rej;

  return (
    <>
      <div className="p-8 w-full text-left bg-beige-500 rounded-lg">
        <div className="flex flex-col gap-4">
          <h3 className="text-2xl font-semibold leading-8 text-sand-900">
            {title}
          </h3>
        </div>
      </div>
      {questions.map(
        ({ label, type, choices, hidden, value }) =>
          shouldRenderQuestion(values, hidden) && (
            <div
              className={classNames(
                'flex flex-col gap-4 p-8 bg-white rounded-lg',
                weightToQuestionBorder.get(getQuestionFlagWeight(value)),
              )}
              key={label}
            >
              <div className="flex justify-between">
                <p className="text-lg font-medium text-grey-forest-700">
                  {label}
                </p>
              </div>
              <div className="flex justify-between">
                <>
                  {renderValue(type, values[value], choices)}
                  {getQuestionFlagWeight(value) && (
                    <FlagIcon
                      className={classNames(
                        'w-6 h-6',
                        weightToFlagColor.get(getQuestionFlagWeight(value)),
                      )}
                    />
                  )}
                </>
              </div>
            </div>
          ),
      )}
      {!!manualScreenApproval?.manualApprovalEnabled && (
        <div className="flex gap-8 justify-end pt-8">
          <Button
            variant="primary"
            onClick={() => manualScreenApproval.onManualApprove('true')}
            disabled={screeningCompleted}
          >
            {t('Approve')}
          </Button>
          <Button
            variant="danger"
            onClick={() => manualScreenApproval.onManualApprove('false')}
            disabled={screeningCompleted}
          >
            {t('Disqualify')}
          </Button>
        </div>
      )}
    </>
  );
};
