import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { BackButton } from '@nodal/uikit/components/BackButton';

import { CandidateInformationBar } from './CandidateInformationBar';
import { CandidateProfile } from './CandidateProfile';
import { Notes } from './Notes';
import { ScreeningDetails } from './ScreeningDetails';

import type { CandidateOverviewProps } from './CandidateOverview.interface';

export const CandidateOverview: FC<CandidateOverviewProps> = ({
  candidate,
  profile,
  shouldDisplayNotes,
  ipTasks,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-8 py-6 mx-4 max-w-full bg-transparent sm:gap-6 sm:mx-6 lg:mx-8 2xl:mx-auto 2xl:max-w-7xl">
        <div className="flex flex-col w-fit">
          <BackButton title={t('Back')} />
        </div>
        <div className="flex flex-col gap-12">
          <CandidateProfile
            candidate={candidate}
            profile={profile}
            ipTasks={ipTasks}
          />
          <div className="p-8 w-full max-w-full bg-white rounded-lg">
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-4">
                <CandidateInformationBar />
                {shouldDisplayNotes && <Notes />}
                <ScreeningDetails
                  candidate={candidate}
                  profile={profile}
                  ipTasks={ipTasks}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
