import { apiEnums } from '@nodal/api';
import find from 'lodash/find';

import type { ApiModel } from '@nodal/api';

export const buildRequestSteps = <TSteps extends object>({
  currentStepIndex,
  steps,
}: {
  currentStepIndex: number;
  steps: TSteps[];
}): TSteps[] => {
  return steps.map((step, idx) => {
    if (currentStepIndex > idx) {
      return { ...step, status: 'complete', index: idx };
    }

    if (currentStepIndex == idx) {
      return { ...step, status: 'processing', index: idx };
    }

    return { ...step, status: 'pending', index: idx };
  });
};

export const isCandidateRequestPrepaid = ({
  isOrganizationPrepaid,
  canSelectOrganization,
}: {
  isOrganizationPrepaid: boolean;
  canSelectOrganization: boolean;
}) =>
  isOrganizationPrepaid ||
  // NOTE: for user choosing an organization for a candidate,
  // we treat the request as prepaid
  canSelectOrganization;

// NOTE: As agreed with BE, we will always returns the last screening payment in the array (the api returns them sorted)
export const getCurrentScreeningPayment = <
  T extends ApiModel.B2bCandidatePaymentNested,
>(
  screeningPayments: T[],
): T => {
  return screeningPayments[screeningPayments.length - 1];
};

export const isPaymentFailed = (
  payments: ApiModel.B2bCandidatePaymentNested[],
): boolean => {
  const lastPayment = getCurrentScreeningPayment(payments);
  const failedPayment = find(
    payments,
    ({ payment_status }) =>
      payment_status === apiEnums.PaymentStatusEnum.Failed,
  );

  // NOTE: When a payment fails, the BE initiates the creation of a second screening payment with a 'not initiated' state.
  // This allows the system to retry the failed payment while also maintaining
  // a history of previous failed payments.
  // It should not happen that the last payment is failed,
  // because BE will always create an additional invitation with the status not initiated,
  // but we will also cover this case
  return !!(
    lastPayment?.payment_status === apiEnums.PaymentStatusEnum.Failed ||
    (failedPayment &&
      lastPayment?.payment_status === apiEnums.PaymentStatusEnum.NotInitiated)
  );
};
