import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { BackButton } from '@nodal/uikit/components/BackButton';

import { InvitedCandidates } from './InvitedCandidates';
import { OrganizationProfile } from './OrganizationProfile';
import { OrganizationUsers } from './OrganizationUsers';

import type { OrganizationDetailsProps } from './OrganizationDetails.interface';

export const OrganizationDetails: FC<OrganizationDetailsProps> = ({
  organizationType,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-8 py-6 mx-4 max-w-full bg-transparent sm:gap-6 sm:mx-6 lg:mx-8 2xl:mx-auto 2xl:max-w-7xl">
        <div className="flex flex-col w-fit">
          <BackButton title={t('Back')} />
        </div>
        <div className="flex flex-col gap-12">
          <OrganizationProfile />
          <InvitedCandidates />
          {/* NOTE: we don't want to display organization users when org type is intended parent,
           * because in this case there should be no other
           * organization users (it's a single-person organization)
           */}
          {organizationType === apiEnums.OrganizationTypeEnum.Ip ? null : (
            <OrganizationUsers />
          )}
        </div>
      </div>
    </div>
  );
};
