import { ApiClient, ApiClientProvider, AuthProvider } from '@nodal/api';
import { ProtectedRoute } from '@nodal/core/flows/ProtectedRoute';
import { addBasePath } from '@nodal/core/utils';
import { ToastProvider } from '@nodal/uikit/components/Toast';
import axiosFactory from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { logout } from '@b2b/components/Logout';
import { organizationPaths, rolePaths } from '@b2b/consts/paths';
import { settings } from '@b2b/settings';
import { createAuthTokensProvider } from '@b2b/storage/authTokensProvider';

import { AppForcedRedirect } from './AppForcedRedirect';
import { ExternalPayerRoute } from './ExternalPayerRoute';
import { RoleRoute } from './RoleRoute';

export const axios = axiosFactory.create();
const apiClient = new ApiClient(axios, import.meta.env.VITE_API_URL);

// TODO: Consider moving to shared package, e.g. api or core?
const authConfig = {
  axios,
  callbacks: {
    logout,
    // NOTE:
    // Due to broken AuthApi types, some additional handling is required.
    // Ref. to /swagger for detailed information about req res data shapes.
    refresh: (refresh: string) =>
      new Promise<string>((resolve, reject) => {
        apiClient.api.AuthApi.authTokenRefreshCreate({
          tokenRefresh: { refresh, access: '' },
        })
          .then((res) => {
            if (res.data.access) {
              resolve(res.data.access);
            } else {
              reject();
            }
          })
          .catch(reject);
      }),
  },
  tokenProvider: createAuthTokensProvider(),
  noRefresh: [
    `${import.meta.env.VITE_API_URL}/api/auth/login/`,
    `${import.meta.env.VITE_API_URL}/api/auth/token/refresh/`,
    `${import.meta.env.VITE_API_URL}/api/auth/token/verify/`,
  ],
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// NOTE: The base path defined in settings is added to the basename of the application
const basename = addBasePath('/', settings.getBasePath());

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ApiClientProvider value={apiClient}>
        <AuthProvider value={authConfig}>
          <BrowserRouter basename={basename}>
            <Routes>
              <Route
                path={`${rolePaths.candidate}/*`}
                element={<RoleRoute role="candidate" />}
              />
              <Route
                path={`${rolePaths.organization}/*`}
                element={<RoleRoute role="organization" />}
              />
              {settings.getFeatureAdminExternalPaymentEnabled() && (
                <Route
                  path={`${rolePaths.externalPayer}/*`}
                  element={<ExternalPayerRoute />}
                />
              )}
              <Route
                path="*"
                element={
                  <ProtectedRoute
                    redirectPaths={{
                      unauthenticated: organizationPaths.signin,
                    }}
                  >
                    <AppForcedRedirect />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </BrowserRouter>
          <ToastProvider />
        </AuthProvider>
      </ApiClientProvider>
    </QueryClientProvider>
  );
};

export { App };
