import type { FC, SVGProps } from 'react';

import {
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import classNames from 'classnames';

import type { DecisionStatusIndicatorProps } from './DecisionStatusIndicator.interface';

const valueToStatusLabel = new Map<boolean | null, string>([
  [true, t('Accepted')],
  [false, t('Rejected')],
  [null, t('Pending')],
]);

const valueToIcon = new Map<
  boolean | null,
  (props: SVGProps<SVGSVGElement>) => JSX.Element
>([
  [true, CheckCircleIcon],
  [false, XCircleIcon],
  [null, ClockIcon],
]);

const valueToIconColor = new Map<boolean | null, string>([
  [true, 'text-forest-200'],
  [false, 'text-red-600'],
  [null, 'text-grey-forest-400'],
]);

const valueToStatusLabelColor = new Map<boolean | null, string>([
  [true, 'text-forest-400'],
  [false, 'text-red-700'],
  [null, 'text-grey-forest-400'],
]);

export const DecisionStatusIndicator: FC<DecisionStatusIndicatorProps> = ({
  value = null,
  id,
  label,
}) => {
  const statusLabel = valueToStatusLabel.get(value);
  const Icon = valueToIcon.get(value);
  const iconColor = valueToIconColor.get(value);
  const statusLabelColor = valueToStatusLabelColor.get(value);

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor={id} className="text-sm font-medium text-gray-900">
        {label}
      </label>

      <div className="flex gap-1 items-center">
        {Icon && <Icon className={classNames('w-5 h-5', iconColor)} />}
        <p className={classNames('text-sm', statusLabelColor)}>{statusLabel}</p>
      </div>
    </div>
  );
};
