import { t } from '@nodal/i18n';

import type { Filter } from '@b2b/components/SearchFilterTable/Filters';
import { introductionStageOptions } from '@b2b/utils/introduction';

import type { ApiModel } from '@nodal/api';

export const initialFilters = {
  stage: undefined,
  hasCallScheduled: undefined,
};

export const filtersDetails: Filter<ApiModel.B2bApiB2bIntroductionsListRequest>[] =
  [
    {
      id: 'stage',
      label: t('Phase'),
      options: introductionStageOptions,
      value: null,
      type: 'select',
    },
    {
      id: 'hasCallScheduled',
      label: t('Call scheduled'),
      value: null,
      type: 'checkbox',
    },
  ];
