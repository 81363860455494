import { apiEnums } from '@nodal/api';

import type { ApiModel } from '@nodal/api';

// NOTE: we have several types of organization user roles in the application, these are all roles that belong to them
export const orgUserRoleKeys: Array<ApiModel.UserRoleEnum> = [
  apiEnums.UserRoleEnum.Oad,
  apiEnums.UserRoleEnum.Oss,
  apiEnums.UserRoleEnum.Oms,
  apiEnums.UserRoleEnum.Dnm,
  apiEnums.UserRoleEnum.Opa,
];
