import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { useCallback, useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  appPaths,
  organizationPaths as paths,
  requestPaths,
} from 'consts/paths';

import { usePermissions } from '@b2b/app/PermissionsProvider';
import { candidateRequestStepIds } from '@b2b/consts/candidateRequestStepIds';
import { buildRequestSteps } from '@b2b/utils/candidateRequest';
import { getCurrentOrganization } from '@b2b/utils/organization';

import { CandidateRequestContext } from '../CandidateRequest.connect';
import { getAvailableRequestSteps } from '../utils';

import type { ApiModel } from '@nodal/api';

// NOTE: This hook must be embedded inside PermissionProvider
export const useNewCandidateRequest = () => {
  const {
    updateCurrentStepIndex,
    updateSteps,
    started,
    updateStarted,
    steps,
    currentStepIndex,
    updateRequestId,
  } = useContext(CandidateRequestContext);

  const apiClient = useApiClient();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { permissions } = usePermissions();

  const { data: organizationsList } = useQuery(
    queryKeys.organizationsList,
    () => apiClient.api.B2bApi.b2bOrganizationsList(),
    {
      refetchOnMount: false,
    },
  );

  const requestCreate = useMutation(
    (request: ApiModel.B2bApiB2bInvitationsCreateRequest) =>
      apiClient.api.B2bApi.b2bInvitationsCreate(request),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.usersList),
    },
  );

  const requestSubmit = useMutation(
    (request: ApiModel.B2bApiB2bInvitationsSubmitCreateRequest) =>
      apiClient.api.B2bApi.b2bInvitationsSubmitCreate(request),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.usersList),
      onError: () => {
        toast.error(t('Something went wrong...'));
      },
    },
  );

  const create = useCallback(
    async ({
      data,
      callback,
    }: {
      data: ApiModel.PatchedInvitation;
      callback?: (requestId: number) => Promise<void>;
    }) => {
      const response = await requestCreate.mutateAsync({
        // TODO: Temp.solution - use casting, because of broken API type
        // the post data does not have screens, user and id
        // which is required in the type
        invitationList: data as ApiModel.InvitationList,
      });

      if (response.data.id) {
        updateStarted(true);
        updateRequestId(response.data.id);

        if (callback) {
          await callback(response.data.id);
        }

        const nextStep = steps[(currentStepIndex ?? 0) + 1];
        const isNextStepInitiatingPayment =
          nextStep?.id === candidateRequestStepIds.contract;

        // NOTE: If we have a one-step request (mainly for prepaid organizations)
        // or the next step initiates the payment,
        // we need to submit the request after its creation.
        // This leads to the creation of a user who can already start the screening process
        // before the payment stage is completed or there is no next step.
        if (!nextStep || isNextStepInitiatingPayment) {
          await requestSubmit.mutateAsync({
            id: response.data.id,
          });
        }

        if (!nextStep) {
          navigate(`${paths.app}/${appPaths.candidates}`);
        } else {
          navigate(
            `${paths.app}/${appPaths.request}/${requestPaths.update}/${response.data.id}/${nextStep.path}`,
          );
        }
      }

      return response.data;
    },
    [
      requestCreate,
      updateStarted,
      currentStepIndex,
      navigate,
      requestSubmit,
      steps,
      updateRequestId,
    ],
  );

  const setInitialSteps = useCallback(async () => {
    const availableSteps = getAvailableRequestSteps({
      organization: getCurrentOrganization(organizationsList?.data),
      canSelectOrganization: !!permissions?.canSelectOrganizationForCandidate,
      canSelectScreeningPackage: !!permissions?.canSelectScreeningPackage,
    });

    const requestSteps = buildRequestSteps({
      currentStepIndex: 0,
      steps: availableSteps,
    });

    updateCurrentStepIndex(0);
    updateSteps(requestSteps);
  }, [
    updateCurrentStepIndex,
    updateSteps,
    organizationsList?.data,
    permissions,
  ]);

  return {
    steps,
    currentStepIndex,
    started,
    setInitialSteps,
    create,
  };
};
