import type { FC } from 'react';

import { ChevronLeftIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { Spinner } from '@nodal/uikit/components/Spinner';
import { Steps } from '@nodal/uikit/components/Steps';
import classNames from 'classnames';
import { Link, Route, Routes } from 'react-router-dom';

import {
  requestPaths,
  organizationPaths as paths,
  appPaths,
} from '@b2b/consts/paths';

import { CandidateInformation } from './CandidateInformation';
import { PaymentContract } from './PaymentContract';
import { PaymentFlow } from './PaymentFlow';
import { ScreeningPackage } from './ScreeningPackage';

import type { CandidateRequestProps } from './CandidateRequest.interface';

export const CandidateRequest: FC<CandidateRequestProps> = ({
  steps,
  activeStepIndex,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-8 py-6 mx-4 max-w-full bg-transparent sm:gap-6 sm:mx-6 lg:mx-8 2xl:mx-auto 2xl:max-w-7xl">
        <div className="flex flex-col w-fit">
          <Link to={`${paths.app}/${appPaths.candidates}`}>
            <div className="flex gap-2 items-center font-avenir-next text-sm font-medium leading-5 text-grey-forest-600">
              <ChevronLeftIcon className="aspect-square w-6 fill-current" />
              {t('Back to Candidates')}
            </div>
          </Link>
        </div>
        <div className="flex flex-col gap-4 sm:flex sm:flex-row sm:gap-8">
          {steps.length > 1 && (
            <div className="w-full h-full sm:w-72">
              <Steps steps={steps} activeIndex={activeStepIndex} />
            </div>
          )}
          <div
            className={classNames('flex-1 w-full', {
              'flex justify-center': steps.length < 2,
            })}
          >
            <Routes>
              <Route
                path={requestPaths.profile}
                element={<CandidateInformation />}
              />
              <Route
                path={requestPaths.screening}
                element={<ScreeningPackage />}
              />
              <Route
                path={requestPaths.contract}
                element={<PaymentContract />}
              />
              <Route
                path={requestPaths.payment}
                element={
                  <PaymentFlow
                    completePath={`${paths.app}/${appPaths.paymentComplete}`}
                  />
                }
              />

              <Route
                path="*"
                element={
                  <div className="flex justify-center items-center w-full">
                    <Spinner size="large" variant="secondary" />
                  </div>
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};
