import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { rolePaths } from 'consts/paths';

import type { ApiModel } from '@nodal/api';

const userRoleToPath = new Map<ApiModel.UserRoleEnum, string>([
  [apiEnums.UserRoleEnum.Oad, rolePaths.organization],
  [apiEnums.UserRoleEnum.Opa, rolePaths.organization],
  [apiEnums.UserRoleEnum.Sad, rolePaths.organization],
  [apiEnums.UserRoleEnum.Dnm, rolePaths.organization],
  [apiEnums.UserRoleEnum.Oms, rolePaths.organization],
  [apiEnums.UserRoleEnum.Oss, rolePaths.organization],
  [apiEnums.UserRoleEnum.Odo, rolePaths.candidate],
  [apiEnums.UserRoleEnum.Oed, rolePaths.candidate],
  [apiEnums.UserRoleEnum.Osd, rolePaths.candidate],
  [apiEnums.UserRoleEnum.Oextp, rolePaths.externalPayer],
]);

export const AppForcedRedirect = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const { data: authUser } = useQuery(queryKeys.authUserRetrieve, () =>
    apiClient.api.AuthApi.authUserRetrieve(),
  );

  useEffect(() => {
    if (authUser?.data) {
      const path = userRoleToPath.get(authUser.data.role);

      if (path) {
        navigate(path);
      }
    }
  }, [authUser?.data, navigate]);

  return <></>;
};
