import { t } from '@nodal/i18n';

import { candidateRequestStepIds } from '@b2b/consts/candidateRequestStepIds';
import { externalPayerRequestPaths as requestPaths } from '@b2b/consts/paths';
import { canProceedWithPayment } from '@b2b/utils/payment';

import type { RequestStep } from './PaymentRequest.interface';

export const requestStepsMeta: RequestStep[] = [
  {
    title: t('Nodal Contract'),
    path: requestPaths.contract,
    id: candidateRequestStepIds.contract,
    isCurrentStep: (request) =>
      !!(request && !request.screening_payment?.contract?.is_signed),
    index: 0,
  },
  {
    title: t('Payment'),
    path: requestPaths.payment,
    id: candidateRequestStepIds.payment,
    isCurrentStep: (request) =>
      !!(
        request &&
        canProceedWithPayment(request.screening_payment.payment_status)
      ),
    index: 1,
  },
];
