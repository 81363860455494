import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { Route, Routes } from 'react-router-dom';

import { candidateDetailsPaths as paths } from '@b2b/consts/paths';

import { CandidateApplication } from './CandidateApplication';
import { CandidateOverview } from './CandidateOverview';
import { Introduction } from './Introduction';
import {
  ParentsMatchProfile,
  SurrogateMatchProfile,
  NavigatorMatchProfile,
} from './MatchProfile';
import { MedicalRecord } from './MedicalRecord';
import { MedicalRequest } from './MedicalRequest';

import type { CandidateDetailsProps } from './CandidateDetails.interface';

export const CandidateDetails: FC<CandidateDetailsProps> = ({ role }) => {
  return (
    <Routes>
      <Route path="/" element={<CandidateOverview />} />
      <Route path={paths.application} element={<CandidateApplication />} />
      <Route path={paths.medical} element={<MedicalRecord />} />
      <Route path={`${paths.medicalRequest}/*`} element={<MedicalRequest />} />
      {role === apiEnums.UserRoleEnum.Par && (
        <Route path={paths.matchProfile} element={<ParentsMatchProfile />} />
      )}
      {role === apiEnums.UserRoleEnum.Nap && (
        <Route path={paths.matchProfile} element={<NavigatorMatchProfile />} />
      )}
      {role === apiEnums.UserRoleEnum.Dnr && (
        <Route path={paths.matchProfile} element={<SurrogateMatchProfile />} />
      )}
      <Route path={`${paths.introductionId}/*`} element={<Introduction />} />
    </Routes>
  );
};
