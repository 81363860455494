import { screenTypes } from '@nodal/core/consts/screenTypes';
import { t } from '@nodal/i18n';
import capitalize from 'lodash/capitalize';

import { usePermissions } from '@b2b/app/PermissionsProvider';
import { useCandidate } from '@b2b/components/CandidateDetails';
import { useManualScreenApproval } from '@b2b/components/CandidateDetails/ManualScreenApproval/useManualScreenApproval';

import { IdentityVerification } from './IdentityVerification';

import type { ApiModel } from '@nodal/api';

export const IdentityVerificationConnected = () => {
  const { candidate } = useCandidate();

  const { permissions } = usePermissions();
  const screen = candidate?.screens.find(
    ({ type }) => type === screenTypes.stripeIdentity,
  );

  const manualScreenApproval = useManualScreenApproval({
    status: screen?.status,
    id: screen?.id,
    type: screen?.type,
  });

  if (!candidate || (permissions && !permissions.canViewCandidateScreens)) {
    return null;
  }

  const { content_object, status, manual_check } = screen || {};

  // NOTE: Too many screen types defined in API types cause TS error
  // Expected type in this screen is ApiModel.StripeIdentityScreen
  const { stripe_status } =
    (content_object as ApiModel.StripeIdentityScreen) || {};

  return (
    <IdentityVerification
      label={stripe_status ? capitalize(stripe_status) : t('Not Started')}
      status={status}
      manuallyChecked={!!manual_check}
      manualScreenApproval={manualScreenApproval}
    />
  );
};
