import { t } from '@nodal/i18n';
import * as Yup from 'yup';

import type { UserProfilePhoto } from '@nodal/api';

export const schema = Yup.object({
  first_name: Yup.string().nullable().required(t('This field is required')),
  last_name: Yup.string().nullable().required(t('This field is required')),
  title: Yup.string().nullable().required(t('This field is required')),
  image: Yup.mixed<UserProfilePhoto>().optional(),
});
