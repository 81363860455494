import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useCandidate } from 'components/CandidateDetails/CandidateDetails.connect';

import { NoteItem } from './NoteItem';
import { Notes } from './Notes';

import type { ApiModel } from '@nodal/api';

const columnHelper = createColumnHelper<ApiModel.UserNote>();

const columns = [
  columnHelper.accessor((row) => row, {
    id: 'id',
    cell: (info) => {
      const { id, content, type } = info.getValue();

      return <NoteItem id={id} content={content} type={type} />;
    },
    header: () => t('Note'),
  }),
  columnHelper.accessor('created', {
    cell: (info) => (
      <span className="text-grey-forest-700">
        {format(new Date(info.getValue()), 'P')}
      </span>
    ),
    header: () => t('Created Date'),
  }),
];

export const NotesConnected = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { candidate } = useCandidate();

  const userId = candidate?.id;

  const { data: userNotesList } = useQuery(
    queryKeys.userNotesList,
    () =>
      apiClient.api.UsersApi.usersUserNotesList({
        userId: userId!,
      }),
    {
      enabled: !!userId,
    },
  );

  const notesCreateMutation = useMutation(
    (request: ApiModel.UsersApiUsersUserNotesCreateRequest) =>
      apiClient.api.UsersApi.usersUserNotesCreate(request),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.userNotesList),
    },
  );

  const handleAdd = useCallback(async () => {
    await notesCreateMutation.mutateAsync({
      userId: userId!,
      // NOTE: Bug in Api Schema - user, created and id is required
      // but it should be optional
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      userNoteEditCreate: {
        type: apiEnums.TypeEnum.Manual,
      },
    });
  }, [notesCreateMutation, userId]);

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: userNotesList?.data || [],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Notes
      table={{
        headerGroups: getHeaderGroups(),
        rowModel: getRowModel(),
      }}
      onAdd={handleAdd}
    />
  );
};
