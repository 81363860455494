import type { FC } from 'react';

import { CheckIcon } from '@heroicons/react/solid';
import { apiEnums } from '@nodal/api';

import { stageToTitleMap } from './utils';

import type { StageDetailsProps } from './StageDetails.interface';

export const StageDetails: FC<StageDetailsProps> = ({
  stage,
  stagesWithTasks,
}) => (
  <div>
    <p className="text-2xl font-semibold text-forest-400">
      {stageToTitleMap.get(stage)}
    </p>
    {stagesWithTasks[stage].map(({ status, id, title }) => (
      <div key={id} className="flex items-center pt-4">
        <div
          className={`flex shrink-0 justify-center items-center w-8 h-8 rounded-full ${
            status === apiEnums.TaskStatusEnum.Complete
              ? 'bg-forest-400'
              : 'bg-white border-2 border-forest-400'
          }`}
        >
          {status === apiEnums.TaskStatusEnum.Complete ? (
            <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
          ) : (
            <span
              className="absolute w-2.5 h-2.5 bg-transparent rounded-full"
              aria-hidden="true"
            />
          )}
        </div>
        <p className="pl-2 text-sm font-medium text-grey-forest-500">{title}</p>
      </div>
    ))}
  </div>
);
