import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { capitalize } from 'lodash';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  PaymentFlow,
  PaymentFlowProvider,
  useClientSecret,
} from '@b2b/components/PaymentFlow';
import { getCurrentScreeningPayment } from '@b2b/utils/candidateRequest';

import type { PaymentFlowConnectedProps } from './PaymentFlow.interface';

export const PaymentFlowConnected: FC<PaymentFlowConnectedProps> = ({
  completePath,
}) => {
  const apiClient = useApiClient();
  const { id } = useParams() as { id: string };

  const { clientSecret } = useClientSecret(Number.parseInt(id));

  const { data: requestRetrieve } = useQuery(queryKeys.requestRetrieve, () =>
    apiClient.api.B2bApi.b2bInvitationsRetrieve({
      id: Number.parseInt(id),
    }),
  );
  const request = requestRetrieve?.data;

  const screeningPayment = request?.screening_payments
    ? getCurrentScreeningPayment(request.screening_payments)
    : undefined;

  if (!screeningPayment || !clientSecret) return <LoadingScreen />;

  const planPackage = {
    title: `${capitalize(screeningPayment.package.name)} Screening`,
    features: screeningPayment.package.description_entries || [],
  };

  const requestId = Number.parseInt(id);

  return (
    <PaymentFlowProvider
      completePath={`${completePath}/${requestId}`}
      requestId={requestId}
    >
      <PaymentFlow
        planPackage={planPackage}
        planPrice={screeningPayment.package.screening_price}
        clientSecret={clientSecret}
      />
    </PaymentFlowProvider>
  );
};
