import type { FC } from 'react';

import { states } from '@nodal/core/consts/states';
import { t } from '@nodal/i18n';
import { AddressInput } from '@nodal/uikit/components/AddressInput';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { PastDateInput } from '@nodal/uikit/components/DateInput';
import { PhoneNumberInput } from '@nodal/uikit/components/PhoneNumberInput';
import { ProgressBar } from '@nodal/uikit/components/ProgressBar';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Form, Formik } from 'formik';

import type { DonorEditProfileFormProps } from './DonorEditProfileForm.interface';
import type { Address } from '@nodal/uikit/models';

export const DonorEditProfileForm: FC<DonorEditProfileFormProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  uploadProgress,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, setFieldValue, setFieldTouched }) => (
        <Form className="flex  flex-col justify-start items-start w-full h-full">
          <div className="overflow-y-auto py-4 px-8 w-full max-h-full sm:max-h-100-modal">
            <div className="flex flex-col grid-cols-6 gap-x-4 gap-y-10">
              <TextInput
                label={t('First Name')}
                name="first_name"
                type="text"
              />
              <TextInput
                label={t('Middle Name')}
                name="middle_name"
                type="text"
              />
              <TextInput label={t('Last Name')} name="last_name" type="text" />
              <TextInput
                label={t(
                  'Maiden name or any other names used in the past (optional)',
                )}
                name="maiden_name"
                type="text"
              />
              <PastDateInput
                label={t('Date of Birth')}
                name="date_of_birth"
                placeholder="YYYY-DD-MM"
                className="lg:w-1/2"
              />
              <div className="col-span-6">
                <div className="relative">
                  <div
                    className="flex absolute inset-0 items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="flex relative justify-start">
                    <span className="pr-3 text-base font-semibold leading-7 text-gray-900 bg-white">
                      {t('Contact Details')}
                    </span>
                  </div>
                </div>
              </div>
              <PhoneNumberInput
                label={t('Phone Number')}
                name="phone_number"
                placeholder="(555) 987-6543"
                type="text"
                className="lg:w-1/2"
              />
              <AddressInput
                name="address"
                onChange={(address: Address) =>
                  Object.entries(address).forEach(([key, value]) => {
                    setFieldValue(`address.${key}`, value);
                    setFieldTouched(`address.${key}`, false);
                  })
                }
                states={states}
              />
            </div>
          </div>
          <ProgressBar progress={uploadProgress} />
          <div className="py-3 px-8 ml-auto w-full text-right border-t border-grey-forest-200">
            <ButtonLoading
              type="submit"
              variant="primary"
              className="justify-center ml-auto w-full sm:w-max"
              loading={isSubmitting}
            >
              {t('Submit')}
            </ButtonLoading>
          </div>
        </Form>
      )}
    </Formik>
  );
};
