import { addressSchema } from '@nodal/core/consts/addressSchema';
import { isInRelationship } from '@nodal/core/utils/profile';
import { t } from '@nodal/i18n';
import * as Yup from 'yup';

import type { ApiModel } from '@nodal/api';

export const schema = Yup.object({
  same_partner_address: Yup.boolean().nullable(),
  first_name: Yup.string().nullable().required(t('This field is required')),
  middle_name: Yup.string().nullable(),
  last_name: Yup.string().nullable().required(t('This field is required')),
  phone_number: Yup.number().nullable().required(t('This field is required')),
  address: addressSchema,
  relationship_status: Yup.string()
    .nullable()
    .required(t('This field is required')),
  partner_first_name: Yup.string()
    .nullable()
    .when('relationship_status', {
      is: isInRelationship,
      then: Yup.string().nullable().required(t('This field is required')),
    }),
  partner_middle_name: Yup.string().nullable().when('relationship_status', {
    is: isInRelationship,
    then: Yup.string().nullable(),
  }),
  partner_last_name: Yup.string()
    .nullable()
    .when('relationship_status', {
      is: isInRelationship,
      then: Yup.string().nullable().required(t('This field is required')),
    }),
  partner_phone_number: Yup.number()
    .nullable()
    .when('relationship_status', {
      is: isInRelationship,
      then: Yup.number().nullable().required(t('This field is required')),
    }),
  partner_address: Yup.object()
    .nullable()
    .when(['relationship_status', 'same_partner_address'], {
      is: (
        relationship_status: ApiModel.ParentsProfile['relationship_status'],
        same_partner_address: boolean | null,
      ) => isInRelationship(relationship_status) && !same_partner_address,
      then: addressSchema,
    }),
});
