import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { useModal } from '@nodal/uikit/components/Modal';
import { Spinner } from '@nodal/uikit/components/Spinner';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { usePermissions } from 'app/PermissionsProvider';
import { appPaths, organizationPaths as paths } from 'consts/paths';
import { settings } from 'settings';
import { isCandidateRequestPrepaid } from 'utils/candidateRequest';
import { getCurrentOrganization } from 'utils/organization';

import { useCandidateRequest } from '@b2b/components/CandidateRequest/hooks/useCandidateRequest';
import { useScreeningPackages } from '@b2b/components/CandidateRequest/hooks/useScreeningPackages';
import { buildDisplayPackages } from '@b2b/components/CandidateRequest/utils';

import { RequestSubmittedConfirmation } from '../RequestSubmittedConfirmation';

import { ScreeningPackage } from './ScreeningPackage';

import type { ApiModel } from '@nodal/api';

const validationSchema = Yup.object({
  package: Yup.string().nullable().required(t('This field is required')),
  paying_entity: Yup.string().nullable().required(t('This field is required')),
  payer_name: Yup.string()
    .nullable()
    .when('paying_entity', {
      is: apiEnums.PayingEntityEnum.ExtParents,
      then: Yup.string().nullable().required(t('This field is required')),
    }),
  payer_email: Yup.string()
    .nullable()
    .when('paying_entity', {
      is: apiEnums.PayingEntityEnum.ExtParents,
      then: Yup.string()
        .nullable()
        .email(t('Enter a valid email address'))
        .required(t('This field is required')),
    }),
});

const initialValues: ApiModel.InvitationScreeningPackage = {
  paying_entity: null,
  payer_name: null,
  payer_email: null,
  package: null,
};

const getInitialPayingEntity = (isPrepaid: boolean) => {
  if (isPrepaid) {
    return apiEnums.PayingEntityEnum.Prepaid;
  }

  // NOTE: When external payment feature is disabled
  // Then assign organization payers by default
  if (!settings.getFeatureAdminExternalPaymentEnabled()) {
    return apiEnums.PayingEntityEnum.Org;
  }

  return initialValues.paying_entity;
};

export const ScreeningPackageConnected = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const { open, close } = useModal();

  const { request, finishStep } = useCandidateRequest();

  const { data: organizationsList, isLoading: isOrganizationListLoading } =
    useQuery(queryKeys.organizationsList, () =>
      apiClient.api.B2bApi.b2bOrganizationsList(),
    );

  const paymentLinkCreate = useMutation(
    (
      request: ApiModel.B2bApiB2bInvitationsSendExternalPaymentLinkCreateRequest,
    ) =>
      apiClient.api.B2bApi.b2bInvitationsSendExternalPaymentLinkCreate(request),
  );

  const organization = getCurrentOrganization(
    organizationsList?.data,
    permissions?.canViewAllOrganizations,
  );

  const isPrepaid = isCandidateRequestPrepaid({
    isOrganizationPrepaid: !!organization?.is_prepaid,
    canSelectOrganization: !!permissions?.canSelectOrganizationForCandidate,
  });

  const screeningPackage = useScreeningPackages({
    organization,
    isPrepaid,
  });

  const requestSubmitCreate = useMutation(
    (request: ApiModel.B2bApiB2bInvitationsSubmitCreateRequest) =>
      apiClient.api.B2bApi.b2bInvitationsSubmitCreate(request),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.usersList),
      onError: () => {
        toast.error(t('Something went wrong...'));
      },
    },
  );

  if (isOrganizationListLoading || !screeningPackage || !request?.id) {
    return (
      <div className="flex justify-center items-center w-full">
        <Spinner size="large" variant="secondary" />
      </div>
    );
  }

  const { submit: packageCreate, packages } = screeningPackage;

  const requestSubmit = async (data: ApiModel.InvitationScreeningPackage) => {
    await packageCreate(request.id, data);

    // NOTE: at this stage we also we need to submit request to send the candidate the invitation
    // and create the user
    await requestSubmitCreate.mutateAsync({
      id: request.id,
    });
  };

  const onBusinessPayerSubmit = async (
    data: ApiModel.InvitationScreeningPackage,
  ) => {
    await requestSubmit(data);

    await finishStep();

    open({
      title: t('Candidate request submitted'),
      render: () => <RequestSubmittedConfirmation onNext={close} />,
    });
  };

  const onParentsPayerSubmit = async (
    data: ApiModel.InvitationScreeningPackage,
  ) => {
    await requestSubmit(data);
    await paymentLinkCreate.mutateAsync({ id: request.id });

    navigate(`${paths.app}/${appPaths.requestSubmitted}/${request.id}`, {
      state: {
        email: data.payer_email,
      },
    });
  };

  const onSubmit = async (data: ApiModel.InvitationScreeningPackage) => {
    if (data.paying_entity === apiEnums.PayingEntityEnum.ExtParents) {
      await onParentsPayerSubmit(data);
    } else if (data.paying_entity === apiEnums.PayingEntityEnum.Org) {
      await onBusinessPayerSubmit(data);
    } else {
      await requestSubmit(data);

      await finishStep();
    }
  };

  const displayPackages = buildDisplayPackages(packages);

  return (
    <ScreeningPackage
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues,
        paying_entity: getInitialPayingEntity(isPrepaid),
      }}
      onSubmit={onSubmit}
      packages={displayPackages}
    />
  );
};
