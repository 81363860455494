import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useEmbeddedSignature } from '@nodal/core/flows/hooks/useEmbeddedSignature';
import { t } from '@nodal/i18n';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import type { ContractFormConnectedProps } from './ContractForm.interface';
import type { ApiModel } from '@nodal/api';

export const useContractForm = ({
  submit,
  userId,
  clientId,
  documentId,
}: ContractFormConnectedProps) => {
  const apiClient = useApiClient();

  const { data: document, isLoading } = useQuery(
    queryKeys.usersDocumentsRetrieve,
    () =>
      apiClient.api.UsersApi.usersDocumentsRetrieve({
        userId: userId!,
        id: documentId!,
      }),
    {
      cacheTime: 0,
      enabled: !!(userId && documentId),
    },
  );

  const contractDocument = document?.data;

  const updateDocuments = useMutation(
    (request: ApiModel.UsersApiUsersDocumentsPartialUpdateRequest) =>
      apiClient.api.UsersApi.usersDocumentsPartialUpdate(request),
    {
      onError: () => {
        toast.error(t('Something went wrong'));
      },
    },
  );

  const onUpdate = async (data: ApiModel.PatchedEmbeddedDropboxDocument) => {
    if (contractDocument?.id && userId) {
      await updateDocuments.mutateAsync({
        id: contractDocument.id,
        userId,
        patchedEmbeddedDropboxDocument: data,
      });
    }
  };

  const onSubmit = async () => {
    await onUpdate({ is_signed: true });
    await submit();
  };

  const { sign_url, sign_id, is_signed } = contractDocument || {};

  const contractSignRef = useEmbeddedSignature({
    clientId,
    onSubmit,
    requestUrl: sign_url || undefined,
    requestId: sign_id || undefined,
    signed: !!is_signed,
    skipDomainVerification: !!import.meta.env.DEV,
  });

  if (isLoading || !userId) {
    return null;
  }

  return { contractSignRef, signed: !!is_signed };
};
