import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { screenTypes } from '@nodal/core/consts/screenTypes';
import { t } from '@nodal/i18n';
import { parsePhoneNumber } from 'react-phone-number-input';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { usePermissions } from '@b2b/app/PermissionsProvider';
import { useCandidate } from '@b2b/components/CandidateDetails';
import { isMedicalRequestCreationAllowed } from '@b2b/components/CandidateDetails/utils';

import { roleToMedicalReviewsUids } from '../stages';

import { RecordsPull } from './RecordsPull';

import type { ApiModel, GlobalError } from '@nodal/api';

const usePlutoMedicalRecord = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { candidate, signedDocuments } = useCandidate();
  const userId = candidate?.id;

  const plutoRecord = candidate?.medical_review?.pulls?.find(
    (pull) =>
      pull.third_party_provider === apiEnums.ThirdPartyProviderEnum.Pluto,
  );

  // NOTE: Currently we support only one pluto request
  // so there will be always one element in the pluto_data_sync
  const [recordId] = plutoRecord?.pluto_data_sync || [];

  const { data: detailsRetrieve } = useQuery(
    queryKeys.plutoDetailsRetrieve,
    () =>
      apiClient.api.MedicalReviewsApi.medicalReviewsPlutoDataSyncDetailsRetrieve(
        {
          id: recordId,
        },
      ),
    {
      enabled: !!recordId,
    },
  );

  const statusCreate = useMutation(
    (
      request: ApiModel.MedicalReviewsApiMedicalReviewsPlutoDataSyncUpdateStatusCreateRequest,
    ) =>
      apiClient.api.MedicalReviewsApi.medicalReviewsPlutoDataSyncUpdateStatusCreate(
        request,
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeys.plutoDetailsRetrieve),
      onError: (error: GlobalError) => {
        toast.error(
          error.response.data.message || t('Something went wrong...'),
        );
      },
    },
  );

  const create = useMutation(
    (request: ApiModel.MedicalReviewsApiMedicalReviewsPullCreateRequest) =>
      apiClient.api.MedicalReviewsApi.medicalReviewsPullCreate(request),
    {
      onSuccess: (...[, variables]) => {
        queryClient.invalidateQueries([
          queryKeys.candidateRetrieve,
          variables.userId,
        ]);
      },
      onError: (error: GlobalError) => {
        toast.error(
          error.response.data.message || t('Something went wrong...'),
        );
      },
    },
  );

  const profile = candidate?.screens.find(
    ({ type }) =>
      type === screenTypes.organizationCandidateProfile ||
      type === screenTypes.donorProfile,
  )?.content_object as ApiModel.DonorProfile;

  const consentForm = signedDocuments?.find(
    ({ document_type }) =>
      document_type === apiEnums.DropboxDocumentTypeEnum.ConsentFormB2b ||
      document_type === apiEnums.DropboxDocumentTypeEnum.ConsentForm,
  );

  const plutoRecordValues: Omit<
    ApiModel.RecordPull,
    | 'id'
    | 'created'
    | 'updated'
    | 'fax_pdf_documents'
    | 'pluto_data_sync'
    | 'fax_outbox_fax_status'
  > = {
    ...profile,
    phone_number: profile.phone_number
      ? parsePhoneNumber(profile.phone_number)?.nationalNumber
      : null,
    consent_file_path: consentForm?.signed_file_url || undefined,
    gender: t('Female'),
    third_party_provider: apiEnums.ThirdPartyProviderEnum.Pluto,
  };

  const onCreate = async () => {
    await create.mutateAsync({
      userId: userId!,
      // NOTE: Bug in api schema - id, created and updated are read-only properties,
      // but required in api schema, so we need to do casting here
      recordPull: plutoRecordValues as ApiModel.RecordPull,
    });
  };

  const onDataSync = async () => {
    await statusCreate.mutateAsync({
      id: recordId,
    });
  };

  return {
    onCreate,
    onDataSync,
    created: detailsRetrieve?.data?.created,
    syncCompleted: !!detailsRetrieve?.data?.sync_completed,
  };
};

const useFaxMedicalRecords = () => {
  const apiClient = useApiClient();
  const { candidate } = useCandidate();

  const faxRecords =
    candidate?.medical_review?.pulls?.filter(
      (pull) =>
        pull.third_party_provider === apiEnums.ThirdPartyProviderEnum.Fax,
    ) || [];

  const faxRecordQueries = useQueries(
    faxRecords.map((record) => {
      // NOTE: Currently we support only one fax request
      // so there will be always one element in the fax_outbox_fax_status
      const [recordId] = record?.fax_outbox_fax_status || [];
      return {
        queryKey: `${queryKeys.faxStatusDetailsRetrieve}-${recordId}`,
        queryFn: () =>
          apiClient.api.MedicalReviewsApi.medicalReviewsFaxOutboxFaxStatusDetailsRetrieve(
            {
              id: recordId,
            },
          ),
        enabled: !!recordId,
      };
    }),
  );

  const faxStatuses = faxRecordQueries.map(({ data }) => ({
    status: data?.data?.sent_status,
    recordPullId: data?.data?.record_pull,
  }));

  return { faxStatuses };
};

export const RecordsPullConnected = () => {
  const { permissions } = usePermissions();
  const { candidate, onUpdateMedicalReview } = useCandidate();
  const { faxStatuses } = useFaxMedicalRecords() || {};

  const plutoMedicalRecord = usePlutoMedicalRecord();

  const medicalRecordsPullUid = roleToMedicalReviewsUids.get(
    candidate?.role,
  )?.medicalRecordPull;

  const { content_object } =
    candidate?.medical_review?.steps?.find(
      ({ uid }) => uid === medicalRecordsPullUid,
    ) || {};

  if (!medicalRecordsPullUid || !candidate || !faxStatuses) {
    return null;
  }

  const { medical_review, screens, role } = candidate;

  const manualPullsWithFaxStatuses = medical_review?.pulls
    ?.map((pull) =>
      pull.third_party_provider === apiEnums.ThirdPartyProviderEnum.Fax
        ? {
            ...pull,
            fax_status: faxStatuses.find(
              ({ recordPullId }) => recordPullId === pull.id,
            )?.status,
          }
        : pull,
    )
    ?.filter(
      (pull) =>
        pull.third_party_provider !== apiEnums.ThirdPartyProviderEnum.Pluto,
    );

  return (
    <RecordsPull
      manualPulls={manualPullsWithFaxStatuses}
      supervisionAccess={!!permissions?.canInitiateMedicalRequest}
      disabled={!isMedicalRequestCreationAllowed(screens, role)}
      details={content_object}
      onChange={(data) =>
        onUpdateMedicalReview(candidate.id, data, medicalRecordsPullUid)
      }
      plutoRecord={plutoMedicalRecord}
    />
  );
};
