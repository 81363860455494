import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { screenTypes } from '@nodal/core/consts/screenTypes';
import { t } from '@nodal/i18n';
import { useModal } from '@nodal/uikit/components/Modal';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { usePermissions } from '@b2b/app/PermissionsProvider';
import { useCandidate } from '@b2b/components/CandidateDetails';
import { useManualScreenApproval } from '@b2b/components/CandidateDetails/ManualScreenApproval/useManualScreenApproval';

import { BackgroundCheck } from './BackgroundCheck';
import { NewBackgroundCheckForm } from './NewBackgroundCheckForm';
import { stages } from './stages';

import type { BackgroundCheckStage, Stage } from './BackgroundCheck.interface';
import type { ApiModel } from '@nodal/api';

export const BackgroundCheckConnected: FC = () => {
  const { candidate } = useCandidate();
  const { permissions } = usePermissions();
  const { open } = useModal();

  const screen = candidate?.screens.find(
    ({ type }) => type === screenTypes.checkr,
  );

  const manualScreenApproval = useManualScreenApproval({
    status: screen?.status,
    id: screen?.id,
    type: screen?.type,
  });

  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const generateLink = useMutation(
    (request: ApiModel.ScreensApiScreensResendCheckrInvitationsCreateRequest) =>
      apiClient.api.ScreensApi.screensResendCheckrInvitationsCreate(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          queryKeys.candidateRetrieve,
          // component where mutation is called wouldn't be rendered if there is no candidate
          candidate!.id,
        ]);
      },
      onError: () => {
        toast.error(t('Something went wrong...'));
      },
    },
  );

  if (!candidate || !screen) return null;

  const { content_object, id, status, manual_check } = screen || {};

  const { candidates } = (content_object as ApiModel.CheckrScreen) || {};

  const onGenerateNewLink = async (candidateId: string) => {
    await generateLink.mutateAsync({
      id,
      checkrScreenResendInvitations: {
        candidate_id: candidateId,
      },
    });
  };

  const onAddNewCheckr = () =>
    open({
      title: t('New background check'),
      render: () => <NewBackgroundCheckForm id={id} />,
    });

  const backgroundCheckStages: Stage[] = stages.map(
    (q: BackgroundCheckStage) => {
      const results = candidates?.length
        ? candidates.map((v) => {
            let value = get(v, q.name) || get(v.searches, q.name);

            if (
              typeof value === 'object' &&
              'result' in value &&
              'status' in value
            ) {
              value = capitalize(value.result || value.status);
            }

            return {
              value: value?.toString(),
              label:
                q.options?.find((o) => o.value === value)?.label ||
                value?.toString(),
              id: v.candidate_id || undefined,
            };
          })
        : [];

      return { title: q.title, results };
    },
  );

  return (
    <BackgroundCheck
      stages={permissions?.canViewCandidateScreens ? backgroundCheckStages : []}
      onGenerateNewLink={onGenerateNewLink}
      status={status}
      manuallyChecked={!!manual_check}
      manualScreenApproval={manualScreenApproval}
      onAddNewCheckr={
        permissions?.canResubmitBackgroundCheck ? onAddNewCheckr : undefined
      }
    />
  );
};
