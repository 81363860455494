import { t } from '@nodal/i18n';
import { FilterChip } from '@nodal/uikit/components/FilterChip';
import { Search } from '@nodal/uikit/components/Search';
import { Table } from '@nodal/uikit/components/Table';
import { isArray } from 'lodash';
import get from 'lodash/get';

import { Filters } from './Filters';

import type { SearchFilterTableProps } from './SearchFilterTable.interface';

export const SearchFilterTable = <
  TData extends object,
  TFilters extends object,
>({
  query,
  totalCount,
  filters,
  onQueryChange,
  ...table
}: SearchFilterTableProps<TData, TFilters>) => {
  return (
    <div className="flex flex-col my-6 w-full h-full">
      <div className="flex gap-6 py-2 my-auto">
        {filters && <Filters {...filters} />}
        <div className="inline-block relative w-full align-middle ">
          <div className="flex flex-col gap-2 justify-between items-center mb-2 w-full sm:flex-row">
            <div className="flex flex-col gap-1 w-full">
              <p className="m-2 w-full">
                {t('Total Records:')}&nbsp;
                <span className="font-semibold">{totalCount}</span>
              </p>
              <div className="flex flex-wrap gap-2 w-full">
                {filters?.details.map(
                  ({ options, label, id, value }) =>
                    value &&
                    (isArray(value) ? value : [value]).map((val) => (
                      <FilterChip
                        key={`${id?.toString()}-${val}`}
                        label={
                          options?.find((option) => option.value === val)
                            ?.label || label
                        }
                        onRemove={() => {
                          const removedValue = isArray(value)
                            ? get(query.filters, id.toString()).filter(
                                (filterValue: string) => filterValue !== val,
                              )
                            : get(filters.initialValues, id.toString());

                          return filters.onChange(id, removedValue);
                        }}
                      />
                    )),
                )}
              </div>
            </div>

            <div className="py-2 ml-auto w-full sm:w-[400px]">
              <Search
                value={query.search}
                onChange={(value) =>
                  onQueryChange({
                    ...query,
                    search: value,
                    page: 1,
                  })
                }
                placeholder={t('Search by name')}
              />
            </div>
          </div>
          <div className="w-full bg-white rounded-lg ring-1 ring-grey-forest-200 sm:overflow-hidden">
            <Table
              {...table}
              page={query.page}
              onPageChange={(page) =>
                onQueryChange({
                  ...query,
                  page,
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
