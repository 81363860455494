import { Disclosure } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { forwardRef } from 'react';

import type { PaymentContractProps } from './ContractForm.interface';

export const ContractForm = forwardRef<HTMLDivElement, PaymentContractProps>(
  ({ signed }, ref) => (
    <div className="flex flex-col h-full">
      <Disclosure>
        <div className="flex flex-col gap-4 py-2 px-4 w-full bg-sage-500 border border-grey-forest-500">
          <div className="flex justify-between items-center w-full">
            <p className="font-medium text-grey-forest-900">
              {t('Nodal SCREEN Services Agreement')}
            </p>
            {signed ? (
              <div className="flex items-center w-10 h-10">
                <CheckCircleIcon className="w-8 h-8 text-forest-500 rounded-full" />
              </div>
            ) : null}
          </div>
          <div className={!signed ? 'block' : 'hidden'}>
            <div className="overflow-hidden w-full h-screen" ref={ref} />
          </div>
        </div>
      </Disclosure>
    </div>
  ),
);

ContractForm.displayName = 'ContractForm';
