import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';

import type { ApiModel } from '@nodal/api';
import type { PaymentStatus as PaymentCompleteStatus } from '@nodal/uikit/components/Payment/PaymentStatusCard';

export const transformPaymentStatus = (
  status?: ApiModel.PaymentStatusEnum,
): PaymentCompleteStatus => {
  switch (status) {
    case apiEnums.PaymentStatusEnum.Completed:
      return 'active';
    case apiEnums.PaymentStatusEnum.Failed:
      return 'failed';
    case apiEnums.PaymentStatusEnum.Processing:
    case apiEnums.PaymentStatusEnum.Initiated:
    default:
      return 'processing';
  }
};

export const getPaymentInfo = (
  status: ApiModel.PaymentStatusEnum,
  email?: string,
) => {
  switch (status) {
    case apiEnums.PaymentStatusEnum.Completed:
      return {
        title: t('Payment complete'),
        messages: [
          {
            line1: t('Thank you! Your payment has been successful.'),
            line2: t('A confirmation email has been sent to'),
            boldText: `${email}.`,
          },
          {
            line2: t('We sent a screening link to the surrogate.'),
          },
          {
            line1: t("Once screening is complete, you'll be notified."),
          },
        ],
      };
    case apiEnums.PaymentStatusEnum.Failed:
      return {
        title: t('Payment was not successful'),
        messages: [
          {
            line1: t('Oh no, it looks like the payment was not successful.'),
          },
        ],
      };
    case apiEnums.PaymentStatusEnum.Initiated:
    case apiEnums.PaymentStatusEnum.Processing:
    default:
      return {
        title: t('Processing your payment'),
        messages: [
          {
            line1: t(
              'Please wait, your order is being processed and it may take up to few seconds to finalize.',
            ),
            line2: t('We will email you when your order succeeds.'),
          },
        ],
      };
  }
};
