import type { ApiModel } from '@nodal/api';
import type { TableProps } from '@nodal/uikit/components/Table';

export const candidateStepsStatus = {
  processing: 'processing',
  review: 'review',
  complete: 'complete',
  deactivated: 'deactivated',
} as const;

export type CandidateStepStatus =
  typeof candidateStepsStatus[keyof typeof candidateStepsStatus];

export type StatusCount = {
  status: CandidateStepStatus;
  count: number;
};

export interface InvitedCandidatesProps
  extends TableProps<ApiModel.B2BUserNonDetailedList> {
  totalActive: number;
  statusCounts: StatusCount[];
}
