import type { FC } from 'react';

import { useField } from 'formik';
import { useCallback } from 'react';

import { dateFormat, timeFormat } from './dateFormat';
import { DateInput } from './DateInput';

import type { DateValue } from './DateInput.interface';
import type { DateTimeInputConnectedProps } from './DateTimeInput.interface';

export const DateTimeInputConnected: FC<DateTimeInputConnectedProps> = ({
  onChange,
  ...props
}) => {
  const [field, meta, { setValue }] = useField<DateValue>(props);

  const handleChange = useCallback(
    (newValue: DateValue) => {
      setValue(newValue);

      if (onChange) {
        onChange(newValue);
      }
    },
    [setValue, onChange],
  );

  return (
    <DateInput
      error={meta.touched && meta.error ? meta.error : undefined}
      {...props}
      {...field}
      onChange={handleChange}
      dateFormat={dateFormat}
      timeFormat={timeFormat}
    />
  );
};
