import { apiEnums } from '@nodal/api';

import type { ApiModel } from '@nodal/api';

// NOTE: we have several types of donors in the application, these are all roles that belong to them
export const donorRoleKeys: Array<ApiModel.UserRoleEnum> = [
  apiEnums.UserRoleEnum.Dnr,
  apiEnums.UserRoleEnum.Odo,
  apiEnums.UserRoleEnum.Oed,
  apiEnums.UserRoleEnum.Osd,
];
