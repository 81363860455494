import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useModal } from '@nodal/uikit/components/Modal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isCandidateRequestPrepaid } from 'utils/candidateRequest';
import { getCurrentOrganization } from 'utils/organization';

import { usePermissions } from '@b2b/app/PermissionsProvider';
import { useCandidateRequest } from '@b2b/components/CandidateRequest/hooks/useCandidateRequest';
import { useNewCandidateRequest } from '@b2b/components/CandidateRequest/hooks/useNewCandidateRequest';
import { useScreeningPackages } from '@b2b/components/CandidateRequest/hooks/useScreeningPackages';

import { RequestSubmittedConfirmation } from '../RequestSubmittedConfirmation';

import { CandidateInformation } from './CandidateInformation';
import { initialCandidate } from './initialCandidate';
import { getSchema } from './validation';

// ESLint produces warning for GlobalError import
// GlobalError is intentionally only used in the catch block below
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { ApiModel, GlobalError } from '@nodal/api';
import type { FormikHelpers } from 'formik';

const emailErrorCodeToMessage = new Map<string, string>([
  [
    'invalid_email_exists_nodal_candidate',
    t('A user with this email already exists'),
  ],
  [
    'invalid_email_exists_other_org_candidate',
    t('A user with this email already exists'),
  ],
  [
    'invalid_email_exists_other_role',
    t('A user with this email already exists'),
  ],
  [
    'invalid_email_exists_active_invitation',
    t(
      'A user with this email already has an existing invitation. Cancel it before creating a new one.',
    ),
  ],
  [
    'invalid_email_exists_user_active',
    t(
      'A user with this email already has an active account. Contact screening@nodal.com for assistance.',
    ),
  ],
]);

export const CandidateInformationConnected = () => {
  const { id } = useParams() as { id: string };
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { open, close } = useModal();

  const requestUpdate = useMutation(
    (request: ApiModel.B2bApiB2bInvitationsPartialUpdateRequest) =>
      apiClient.api.B2bApi.b2bInvitationsPartialUpdate(request),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.usersList),
    },
  );

  const onUpdate = async (id: number, data: ApiModel.PatchedInvitation) => {
    await requestUpdate.mutateAsync({
      patchedInvitation: data,
      id,
    });
  };

  const { request, finishStep } = useCandidateRequest();
  const {
    create: createRequest,
    steps,
    currentStepIndex,
  } = useNewCandidateRequest();
  const { permissions } = usePermissions();

  const canSelectOrganizationForCandidate =
    !!permissions?.canSelectOrganizationForCandidate;

  const { data: organizationsList, isLoading: organizationsListLoading } =
    useQuery(queryKeys.organizationsList, () =>
      apiClient.api.B2bApi.b2bOrganizationsList(),
    );

  const organizationOptions = organizationsList?.data
    ?.filter((org) => org.feature_invitations)
    ?.map((org) => ({
      value: org.id,
      // NOTE: make fallback, because org name is optional in API Schema,
      // and the label needs to be a string
      label: org.name || '',
    }));

  const organization = getCurrentOrganization<ApiModel.Organization>(
    organizationsList?.data,
    permissions?.canViewAllOrganizations,
  );

  const isPrepaid = isCandidateRequestPrepaid({
    isOrganizationPrepaid: !!organization?.is_prepaid,
    canSelectOrganization: !!permissions?.canSelectOrganizationForCandidate,
  });

  const screeningPackages = useScreeningPackages({
    organization,
    isPrepaid,
  });

  const initialValues = id && request ? request : initialCandidate;

  if (organizationsListLoading || !screeningPackages) return <LoadingScreen />;

  const { packages, submit: createPackage } = screeningPackages;

  const onCreateSinglePackage = async (requestId: number) => {
    await createPackage(requestId, {
      paying_entity: isPrepaid
        ? apiEnums.PayingEntityEnum.Prepaid
        : apiEnums.PayingEntityEnum.Org,
      package: packages[0].id,
    });
  };

  const onSubmit = async (
    data: ApiModel.PatchedInvitation,
    { setErrors }: FormikHelpers<ApiModel.PatchedInvitation>,
  ) => {
    try {
      if (id && request) {
        await onUpdate(request.id, data);

        await finishStep();
      } else {
        await createRequest({
          data,
          callback: packages.length === 1 ? onCreateSinglePackage : undefined,
        });

        if (packages.length === 1 && !isPrepaid) {
          open({
            title: t('Candidate request submitted'),
            render: () => <RequestSubmittedConfirmation onNext={close} />,
          });
        }
      }
    } catch (e: GlobalError) {
      const emailErrorCodeMsg = emailErrorCodeToMessage.get(
        e.response.data.code,
      );

      if (emailErrorCodeMsg) {
        setErrors({
          email: emailErrorCodeMsg,
        });
      } else {
        toast.error(t('Something went wrong'));
      }
    }
  };

  return (
    <CandidateInformation
      validationSchema={getSchema(canSelectOrganizationForCandidate)}
      initialValues={initialValues}
      onSubmit={onSubmit}
      organizationSelectionEnabled={canSelectOrganizationForCandidate}
      organizationOptions={organizationOptions}
      isLastStep={currentStepIndex === steps?.length - 1}
    />
  );
};
