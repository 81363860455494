import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { screenTypes } from '@nodal/core/consts/screenTypes';
import { t } from '@nodal/i18n';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCandidate } from '@b2b/components/CandidateDetails';

import {
  buildPopulatedInitialValues,
  getDeliveryProviderDetails,
} from './utils';

import type { RecordPullFormValues } from './MedicalRequestFormsWrapper.interface';
import type { ApiModel, GlobalError } from '@nodal/api';

export const useMedicalRecordFormValues = ({
  defaultValues,
}: {
  defaultValues: RecordPullFormValues;
}) => {
  const { candidate, signedDocuments } = useCandidate();
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const submit = useMutation(
    (request: ApiModel.MedicalReviewsApiMedicalReviewsPullCreateRequest) =>
      apiClient.api.MedicalReviewsApi.medicalReviewsPullCreate(request),
    {
      onSuccess: (...[, variables]) => {
        queryClient.invalidateQueries([
          queryKeys.candidateRetrieve,
          variables.userId,
        ]);
        navigate(-1);
      },
      onError: (error: GlobalError) => {
        if (
          error?.response?.data?.message?.includes('invalid_user_hipaa_state')
        ) {
          toast.error(t('Hipaa document is not signed or missing'));
        } else {
          toast.error(
            error?.response?.data?.message || t('Something went wrong...'),
          );
        }
      },
    },
  );

  const medicalScreen = candidate?.screens.find(
    ({ type }) => type === screenTypes.medicalRecordReview,
  )?.content_object as ApiModel.MedicalRecordReviewScreen;

  const profileScreen = candidate?.screens.find(
    ({ type }) =>
      type === screenTypes.organizationCandidateProfile ||
      type === screenTypes.donorProfile,
  )?.content_object as ApiModel.DonorProfileScreen;

  const initialProviderDetails = getDeliveryProviderDetails(
    medicalScreen,
    defaultValues.data_provider_type || undefined,
    defaultValues.delivery || undefined,
  );

  if (!candidate || !signedDocuments) {
    return null;
  }

  const consentForm = signedDocuments?.find(
    ({ document_type }) =>
      document_type === apiEnums.DropboxDocumentTypeEnum.ConsentFormB2b ||
      document_type === apiEnums.DropboxDocumentTypeEnum.ConsentForm,
  );

  const populatedInitialValues = buildPopulatedInitialValues({
    initialValues: defaultValues,
    profile: profileScreen,
    candidate,
    providerDetails: initialProviderDetails,
    signedConsentFormUrl: consentForm?.signed_file_url || undefined,
  });

  const onSubmit = async (data: ApiModel.RecordPull) =>
    submit.mutateAsync({
      userId: candidate.id,
      recordPull: data,
    });

  return {
    initialValues: populatedInitialValues,
    onSubmit,
  };
};
