import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { useSignIn } from '@nodal/core/flows/hooks/useSignIn';
import { useNavigate } from 'react-router-dom';

import { settings } from 'settings';

import { useAppRole } from '@b2b/app/RoleRoute';
import { pathsFrom, signupPaths } from '@b2b/consts/paths';
import { getRoleSignupPath } from '@b2b/utils';

import { SignInScreen } from './SignInScreen';

import type { ApiModel } from '@nodal/api';

const pathRoleToPermittedUserRoles = new Map<string, ApiModel.UserRoleEnum[]>([
  [
    'candidate',
    [
      apiEnums.UserRoleEnum.Odo,
      apiEnums.UserRoleEnum.Oed,
      apiEnums.UserRoleEnum.Osd,
    ],
  ],
  [
    'organization',
    [
      apiEnums.UserRoleEnum.Oad,
      apiEnums.UserRoleEnum.Opa,
      apiEnums.UserRoleEnum.Sad,
      apiEnums.UserRoleEnum.Oss,
      apiEnums.UserRoleEnum.Oms,
      apiEnums.UserRoleEnum.Dnm,
    ],
  ],
]);

const pathRoleToSignUpHidden = new Map<string, boolean>([
  ['candidate', settings.getCandidateSignUpHidden()],
  ['organization', settings.getOrganizationSignUpHidden()],
]);

export const SignInScreenConnected: FC = () => {
  const { role } = useAppRole();
  const paths = pathsFrom[role];
  const signupPath = getRoleSignupPath(role);
  const navigate = useNavigate();

  const onNavigateToApp = () => navigate(paths.app);
  const onNavigateToEmailNotConfirmed = (email: string) =>
    navigate(`..${signupPath}/${signupPaths.emailNotConfirmed}`, {
      state: { email },
    });

  const { onSubmit, initialValues, validationSchema } = useSignIn({
    onNavigateToApp,
    onNavigateToEmailNotConfirmed,
    permittedRoles: pathRoleToPermittedUserRoles.get(role) || [],
  });

  return (
    <SignInScreen
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      redirectPaths={{
        signup: paths.signup,
        forgotPassword: paths.forgotPassword,
      }}
      signUpHidden={!!pathRoleToSignUpHidden.get(role)}
    />
  );
};
