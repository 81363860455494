import type { ReactNode } from 'react';

import { useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useContext, createContext } from 'react';
import { useMutation } from 'react-query';

import type { PaymentFlowContextValues } from './PaymentFlow.interface';
import type { ApiModel } from '@nodal/api';

// TODO: Refactor to state + dispatch with useReducer.
// Linear ticket: https://linear.app/nodal-health/issue/NOD-680/feadmin-refactor-for-external-payments
const PaymentFlowContext = createContext<PaymentFlowContextValues>({
  completePath: undefined,
  initiatePayment: () => {
    throw new Error('initiatePayment not initialized');
  },
});

export const usePayment = () => useContext(PaymentFlowContext);

export const PaymentFlowProvider = ({
  children,
  completePath,
  requestId,
}: {
  children: ReactNode;
  completePath: string;
  requestId: number;
}) => {
  const apiClient = useApiClient();

  const initiatePaymentCreate = useMutation(
    (
      requestParameters: ApiModel.BillingApiBillingStripeInvitationPaymentInitiatePaymentCreateRequest,
    ) =>
      apiClient.api.BillingApi.billingStripeInvitationPaymentInitiatePaymentCreate(
        requestParameters,
      ),
  );

  const initiatePayment = async () => {
    try {
      await initiatePaymentCreate.mutateAsync({
        id: requestId,
      });

      return { success: true, error: undefined };
    } catch (error) {
      return { success: false, error: t('Something went wrong') };
    }
  };

  return (
    <PaymentFlowContext.Provider
      value={{
        initiatePayment,
        completePath,
      }}
    >
      {children}
    </PaymentFlowContext.Provider>
  );
};
