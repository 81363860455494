import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { AvatarPlaceholderSvg } from '@nodal/uikit/assets';
import { Link } from 'react-router-dom';

import { appPaths, organizationPaths } from 'consts/paths';

import { candidateRoleToLabel } from '@b2b/utils/candidate';

import type { ProfilesOverviewProps } from './ProfilesOverview.interface';

export const ProfilesOverview: FC<ProfilesOverviewProps> = ({
  parentsPhotoUrl,
  parentsName,
  donorPhotoUrl,
  donorName,
  parentsId,
  donorId,
}) => {
  return (
    <div className="flex flex-col gap-6 justify-between w-full md:flex-row">
      <div className="flex flex-col gap-4 w-full sm:flex-row">
        <img
          src={parentsPhotoUrl || AvatarPlaceholderSvg}
          className="object-cover w-[200px] max-h-[200px] lg:max-h-full"
        />
        <div className="flex flex-col gap-2">
          <Link
            to={`${organizationPaths.app}/${appPaths.candidate}/${parentsId}`}
          >
            <p className="text-xl font-semibold leading-7 text-gray-900">
              {parentsName}
            </p>
          </Link>
          <p className="py-1 text-base font-medium text-grey-forest-500">
            {candidateRoleToLabel.get(apiEnums.UserRoleEnum.Par)}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-4 w-full sm:flex-row">
        <img
          src={donorPhotoUrl || AvatarPlaceholderSvg}
          className="object-cover w-[200px] max-h-[200px] lg:max-h-full"
        />
        <div className="flex flex-col gap-2">
          <Link
            to={`${organizationPaths.app}/${appPaths.candidate}/${donorId}`}
          >
            <p className="text-xl font-semibold leading-7 text-gray-900">
              {donorName}
            </p>
          </Link>
          <p className="py-1 text-base font-medium text-grey-forest-500">
            {candidateRoleToLabel.get(apiEnums.UserRoleEnum.Dnr)}
          </p>
        </div>
      </div>
    </div>
  );
};
