import type { FeatureFlags } from 'app/FeatureFlagsProvider.interface';
import type { PermissionsScope } from 'app/PermissionsProvider.interface';

// For all other orgs, only a role with dedicated permissions can view and edit feature
export const getManualApprovalEnabled = (
  permissions?: PermissionsScope,
  features?: FeatureFlags,
) =>
  !!(
    (features?.matching &&
      permissions?.canManageMatching &&
      permissions?.canEditCandidateDetails) ||
    permissions?.canViewAndEditManualChecks
  );
