import type { FC } from 'react';

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { flexRender } from '@tanstack/react-table';

import type { NotesProps } from './Notes.interface';

export const Notes: FC<NotesProps> = ({
  table: { headerGroups, rowModel },
  onAdd,
}) => {
  return (
    <div className="flex flex-col gap-3">
      <Disclosure>
        {({ open }) => (
          <>
            <div className="grid grid-cols-4 gap-4 items-center py-4 px-6 bg-beige-500 rounded-lg">
              <h4 className="col-start-1 text-base font-semibold text-grey-forest-900">
                {t('Notes')}
              </h4>

              <div className="flex col-span-2 col-start-3 gap-1 ml-auto sm:gap-8">
                <Disclosure.Button>
                  <ChevronUpIcon
                    className={`${
                      !open ? 'rotate-180 transform' : ''
                    } h-6 w-6 text-grey-forest-500`}
                  />
                </Disclosure.Button>
              </div>
            </div>
            <Disclosure.Panel>
              <div className="px-6 space-y-4 bg-white">
                <table>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th
                            scope="col"
                            className="py-2 px-4 first:pl-0 w-full text-sm font-medium text-right first:text-left text-grey-forest-900 whitespace-nowrap"
                            key={header.id}
                            colSpan={header.colSpan}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {rowModel.rows.length ? (
                      rowModel.rows.map((row) => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td
                              className="py-2 px-4 first:pl-0 text-base text-right first:text-left"
                              key={cell.id}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </td>
                          ))}
                        </tr>
                      ))
                    ) : (
                      <tr className="text-sm text-grey-forest-700">
                        <td>{t('There are no notes')}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <button type="button" onClick={onAdd}>
                  <span>+</span>&nbsp;
                  <span className="leading-8 underline">{t('new note')}</span>
                </button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
