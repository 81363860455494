import { t } from '@nodal/i18n';
import * as Yup from 'yup';

export const getSchema = (organizationSelectionEnabled: boolean) =>
  Yup.object().shape({
    role: Yup.string().nullable().required(t('This field is required')),
    first_name: Yup.string().nullable().required(t('This field is required')),
    middle_name: Yup.string().nullable(),
    last_name: Yup.string().nullable().required(t('This field is required')),
    email: Yup.string()
      .nullable()
      .email(t('Enter a valid email address'))
      .required(t('This field is required')),
    partner_email: Yup.string()
      .nullable()
      .email(t('Enter a valid email address'))
      .optional(),
    phone_number: Yup.number().nullable().required(t('This field is required')),
    organization_id: organizationSelectionEnabled
      ? Yup.number().nullable().required(t('This field is required'))
      : Yup.number().nullable(),
  });
