import { apiEnums } from '@nodal/api';

import type { RecordPullFormValues } from '../MedicalRequestFormsWrapper.interface';

export const initialValues: RecordPullFormValues = {
  third_party_provider: apiEnums.ThirdPartyProviderEnum.Datavant,
  consent_file_path: null,
  first_name: null,
  middle_name: null,
  last_name: null,
  date_of_birth: null,
  gender: null,
  address: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    zipcode: null,
    location: null,
  },
  phone_number: null,
  delivery: 1,
  doctor_first_name: null,
  doctor_middle_name: null,
  doctor_last_name: null,
  doctor_health_system: null,
  doctor_address: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    zipcode: null,
    location: null,
  },
  doctor_date_of_service_start: null,
  doctor_date_of_service_end: null,
  doctor_phone_number: null,
  doctor_instructions: null,
  data_provider_type: apiEnums.DataProviderTypeEnum.Pregnancy,
};
