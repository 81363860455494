import type { FC } from 'react';

import { sortPhotosByOrder } from '@nodal/core/utils';
import { t } from '@nodal/i18n';
import { BackButton } from '@nodal/uikit/components/BackButton';

import { getProfileDisplayName } from '@b2b/utils/candidate';

import { IntroductionForm } from './IntroductionForm';
import { ProfilesOverview } from './ProfilesOverview';

import type { IntroductionProps } from './Introduction.interface';

export const Introduction: FC<IntroductionProps> = ({
  id,
  parentProfile,
  donorProfile,
  initialValues,
  onSubmit,
  validationSchema,
  warningMessage,
  editable,
}) => (
  <div className="flex flex-col gap-4 justify-start items-stretch py-6 px-4 mx-auto w-full max-w-5xl">
    <div className="flex flex-col w-fit">
      <BackButton title={t('Back')} />
    </div>
    <div className="p-8 w-full text-left bg-white rounded-lg">
      <h3 className="mb-4 text-xl font-medium leading-8 text-grey-forest-900">
        {t('Introduction {id}', id.toString())}
      </h3>
      <ProfilesOverview
        parentsPhotoUrl={
          sortPhotosByOrder(parentProfile.user.profile_photos)[0]?.image_url ||
          undefined
        }
        parentsName={getProfileDisplayName(parentProfile)}
        parentsId={parentProfile.user.id}
        donorPhotoUrl={
          sortPhotosByOrder(donorProfile.user.profile_photos)[0]?.image_url ||
          undefined
        }
        donorName={getProfileDisplayName(donorProfile)}
        donorId={donorProfile.user.id}
      />
      <IntroductionForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        warningMessage={warningMessage}
        editable={editable}
      />
    </div>
  </div>
);
