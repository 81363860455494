import {
  functionalUpdate,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import {
  buildFiltersModel,
  convertQueryParamsToUrl,
  transformOrdering,
  transformSorting,
} from './utils';

import type { Filter, FiltersModel } from './Filters';
import type { QueryParameters, PaginatedList } from '@nodal/api';
import type {
  SortingState,
  ColumnDef,
  OnChangeFn,
  Updater,
} from '@tanstack/react-table';
import type { AxiosResponse } from 'axios';

export const useSearchFilterTable = <
  TData extends object,
  TFilters extends object,
>({
  initialQuery,
  columns,
  queryKey,
  queryCallback,
  urlSearchParamsEnabled,
  immutableOrdering,
  filters,
}: {
  initialQuery: QueryParameters<TFilters>;
  // NOTE: Suppress 'any' error because of type issue: https://github.com/TanStack/table/issues/4241
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[];
  queryKey: string;
  queryCallback: (
    query: QueryParameters<TFilters>,
  ) => Promise<AxiosResponse<PaginatedList<TData>, unknown>>;
  urlSearchParamsEnabled: boolean;
  immutableOrdering?: string[];
  filters?: {
    initialValues: TFilters;
    details: Filter<TFilters>[];
    title: string;
  };
}) => {
  const [sorting, setSorting] = useState<SortingState>(
    initialQuery.ordering
      ? transformOrdering(initialQuery.ordering, immutableOrdering)
      : [],
  );

  const [pageCount, setPageCount] = useState<number | undefined>();

  const [queryParameters, setQueryParameters] =
    useState<QueryParameters<TFilters>>(initialQuery);

  const { data: tableList, isFetching } = useQuery(
    [queryKey, queryParameters],
    () => queryCallback(queryParameters),
    { keepPreviousData: true },
  );

  useEffect(() => {
    if (queryParameters?.pageSize) {
      setPageCount(
        Math.ceil((tableList?.data?.count || 1) / queryParameters.pageSize),
      );
    }
  }, [tableList?.data?.count, queryParameters.pageSize]);

  const onSortingChange: OnChangeFn<SortingState> = (
    sortingUpdater: Updater<SortingState>,
  ) => {
    const newSortingValue = functionalUpdate(sortingUpdater, sorting);
    setSorting(newSortingValue);

    setQueryParameters({
      ...queryParameters,
      ordering: transformSorting(newSortingValue, immutableOrdering),
    });
  };

  const table = useReactTable({
    columns,
    data: tableList?.data?.results || [],
    state: {
      sorting,
    },
    pageCount,
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
  });

  const { getHeaderGroups, getRowModel } = table;

  const onQueryChange = useCallback(
    (query: QueryParameters<TFilters>) => setQueryParameters(query),
    [],
  );

  const filtersModel: FiltersModel<TFilters> | undefined = filters
    ? buildFiltersModel({ filters, query: queryParameters, onQueryChange })
    : undefined;

  return {
    query: queryParameters,
    headerGroups: getHeaderGroups(),
    rowModel: getRowModel(),
    pageCount,
    totalCount: tableList?.data?.count,
    onQueryChange,
    queryStringParams: urlSearchParamsEnabled
      ? convertQueryParamsToUrl(queryParameters)
      : undefined,
    isLoading: isFetching,
    filters: filtersModel,
  };
};
