import { useUserMePhotos } from '@nodal/core/flows/hooks/useUserMePhotos';
import { convertDataUrlToCompressedFile } from '@nodal/core/utils';

import { useProfileDetails } from './useProfileDetails';

import type { ApiModel, UserProfilePhoto } from '@nodal/api';

export const useProfilePhoto = (): {
  profilePhoto: ApiModel.UserProfileOwnPhoto;
  save: (savedImage?: UserProfilePhoto) => Promise<void>;
} => {
  const { upload, update, remove } = useUserMePhotos();
  const { photos } = useProfileDetails();
  const [profilePhoto] = photos || [];

  const handleSave = async (savedImage?: UserProfilePhoto) => {
    if (!savedImage?.image && profilePhoto) {
      await remove(profilePhoto);
    } else if (
      savedImage?.image &&
      (!profilePhoto || savedImage.image !== profilePhoto.image)
    ) {
      const file = await convertDataUrlToCompressedFile({
        dataUrl: savedImage.image,
        // NOTE: Since we do not save the file name and we need it to convert from url to file,
        // we provide the default, hardcoded file name.
        fileName: 'uploadedFile.jpg',
      });

      !profilePhoto
        ? // NOTE: BUG in Api Schema - imageUrl and id is required
          // reported as an issue: https://linear.app/nodal-health/issue/NOD-254/backend-bug-in-api-schema-userprofilephoto
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          await upload({ ...savedImage, image: file, imageUrl: '', id: 0 })
        : await update([{ ...savedImage, image: file }]);
    }
  };

  return {
    profilePhoto,
    save: handleSave,
  };
};
