import type { FC } from 'react';

import { settings } from 'settings';

import { getCurrentInvitation } from '@b2b/utils/candidate';

import { ScreeningDetails } from './ScreeningDetails';
import {
  buildCandidateScreensByRole,
  getFinalReviewStatus,
  getPaymentCompletionDateBy,
} from './utils';

import type {
  CandidateScreen,
  ScreeningDetailsConnectedProps,
} from './ScreeningDetails.interface';
import type { ApiModel } from '@nodal/api';

export const ScreeningDetailsConnected: FC<ScreeningDetailsConnectedProps> = ({
  candidate,
  profile,
  ipTasks,
}) => {
  const invitation = getCurrentInvitation(candidate?.invitations);

  // TODO change after schema adjustments https://linear.app/nodal-health/issue/NOD-897/be-adjust-schema-for-ip-dashboard
  const contractCompleTask = ipTasks?.find(
    (task) => task.type === 'ip-contract',
  );

  if (!candidate?.screens || !candidate.role) return null;

  const candidateScreens: Array<CandidateScreen> = buildCandidateScreensByRole({
    role: candidate.role,
    screens: candidate.screens,
    medicalReview: candidate?.medical_review
      ? {
          ...candidate.medical_review,
          pulls_count: candidate.medical_review.pulls.length,
        }
      : undefined,
    finalReview: {
      // NOTE: Final review section will be used only for donors and parents
      // that's why we do casting here to satisfy the required types for the function
      status: getFinalReviewStatus(
        profile as ApiModel.DonorProfile | ApiModel.ParentsProfile,
      ),
      completion_date:
        'final_review_completion_date' in profile
          ? profile.final_review_completion_date
          : undefined,
    },
    payment: {
      status: candidate.billing_status,
      completion_date: getPaymentCompletionDateBy({
        invitation,
        subscription: candidate?.subscription,
        matchingPayment: candidate?.services_payment,
      }),
    },
    contract: {
      // TODO change after schema adjustments https://linear.app/nodal-health/issue/NOD-897/be-adjust-schema-for-ip-dashboard
      status: contractCompleTask?.status,
      completion_date: contractCompleTask?.completion_date,
    },
    ipDashboardEnabled: settings.getIpDashboardFeatureEnabled(),
  }).sort((a, b) => a.order - b.order);

  return <ScreeningDetails screens={candidateScreens} />;
};
