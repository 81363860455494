import {
  CashIcon,
  CollectionIcon,
  CursorClickIcon,
  UserIcon,
} from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { Card } from '@nodal/uikit/components/Card';
import { Link } from 'react-router-dom';

import { appPaths, organizationPaths as paths } from '@b2b/consts/paths';

import type { CardItems } from '@nodal/uikit/components/Card';

const cardItems: CardItems[] = [
  {
    icon: CursorClickIcon,
    title: t('Start a request'),
    description: t(
      'Select your screenings per candidate, which may include a Medical Record Review, Identity Verification, or Background Check.',
    ),
  },
  {
    icon: CashIcon,
    title: t('Submit a payment'),
    description: t(
      'Upon payment completion, the screening process will begin.',
    ),
  },
  {
    icon: CollectionIcon,
    title: t('Nodal collects records'),
    description: t(
      'We work to collect necessary information and complete your request. Check your dashboard for real-time updates.',
    ),
  },
  {
    icon: UserIcon,
    title: t('Review your dashboard'),
    description: t(
      'Nodal uploads a patient summary sheet highlighting critical areas of concern or acceptance.',
    ),
  },
];

export const WelcomeScreen = () => (
  <div className="flex relative flex-col grid-cols-12 gap-8 py-8 pb-4 mx-auto max-w-7xl h-full bg-transparent sm:flex sm:flex-row sm:py-12 lg:grid">
    <div className="col-span-6 col-start-4 gap-14 justify-center items-center px-4 w-full h-full sm:px-8 lg:px-0">
      <div className="flex flex-col gap-3 justify-center items-center px-4 h-full sm:px-8 lg:px-0">
        <h2 className="pt-2 text-2xl font-semibold leading-8 text-center text-grey-forest-900 sm:pt-3">
          {t('Welcome to Nodal Screen')}
        </h2>
        <p className="mb-4 text-center">
          {t(
            "We simplify the screening process. Getting started is easy. Here's what's you can expect:",
          )}
        </p>
        {cardItems.map(({ icon, title, description }) => (
          <Card
            key={title}
            icon={icon}
            title={title}
            description={description}
          />
        ))}
        <Link
          to={`${paths.app}/${appPaths.request}`}
          className="w-full sm:w-max"
        >
          <Button
            variant="primary"
            className="justify-center mt-6 w-full sm:w-96"
          >
            {t('Begin your first request')}
          </Button>
        </Link>
      </div>
    </div>
  </div>
);
