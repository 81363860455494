import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ApiModel } from '@nodal/api';
import type { AxiosResponse } from 'axios';

export const useOrganizationDetails = (): {
  organization?: ApiModel.Organization;
  updateOrganization: (
    id: number,
    updatedOrganization: ApiModel.PatchedOrganization,
  ) => Promise<AxiosResponse<ApiModel.Organization>>;
} => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const { data: organizationsList } = useQuery(
    queryKeys.organizationsList,
    () => apiClient.api.B2bApi.b2bOrganizationsList(),
  );

  // NOTE: Currently we support only one organization per user
  // so there will always be one element in the array
  const organization = organizationsList?.data?.length
    ? organizationsList?.data[0]
    : undefined;

  const updateOrganizationMutation = useMutation(
    (requestParameters: ApiModel.B2bApiB2bOrganizationsPartialUpdateRequest) =>
      apiClient.api.B2bApi.b2bOrganizationsPartialUpdate(requestParameters),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeys.organizationsList),
    },
  );

  const updateOrganization = async (
    id: ApiModel.Organization['id'],
    updatedOrganization: ApiModel.PatchedOrganization,
  ) =>
    await updateOrganizationMutation.mutateAsync({
      id,
      patchedOrganization: updatedOrganization,
    });

  return {
    organization,
    updateOrganization,
  };
};
