import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { BackButton } from '@nodal/uikit/components/BackButton';
import { Navigate, Route, Routes } from 'react-router-dom';

import { medicalRequestPaths } from 'consts/paths';

import { MedicalRequestFormsWrapper } from './MedicalRequestFormsWrapper';
import { MedicalRequestPreview } from './MedicalRequestPreview';

export const MedicalRequest: FC = () => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-8 py-6 mx-4 max-w-full bg-transparent sm:gap-6 sm:mx-6 lg:mx-8 2xl:mx-auto 2xl:max-w-5xl">
        <div className="flex flex-col w-fit">
          <BackButton title={t('Back to Candidate Page')} />
        </div>
        <div className="flex justify-center items-center w-full">
          <Routes>
            <Route
              path={`${medicalRequestPaths.new}/*`}
              element={<MedicalRequestFormsWrapper editable />}
            />
            <Route
              path={`${medicalRequestPaths.previewId}/*`}
              element={<MedicalRequestPreview />}
            />
            <Route
              path="*"
              element={<Navigate to={medicalRequestPaths.new} />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};
