import type { FC } from 'react';

import { FileDropZone } from '@nodal/uikit/components/FileDropZone';
import { UploadedFileList } from '@nodal/uikit/components/UploadedFileList';

import type { ClinicRecordsProps } from './ClinicRecords.interface';

export const ClinicRecords: FC<ClinicRecordsProps> = ({
  uploaded,
  onUpload,
  onDelete,
  disabled,
}) => {
  return (
    <div className="flex flex-col col-span-2 row-start-1 rounded-lg border border-grey-forest-100">
      <div className="border-b border-grey-forest-100">
        <FileDropZone
          onUpload={onUpload}
          disabled={disabled}
          acceptFileTypes="image/*, .pdf, .doc, .docx"
        />
      </div>
      <UploadedFileList
        files={uploaded}
        onDelete={onDelete}
        uploaderIconVisible
      />
    </div>
  );
};
