import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { SelectAsync } from '@nodal/uikit/components/Select';
import { format } from 'date-fns';

import { medicalReviewStatusToLabel } from '@b2b/utils/candidate';

import type { ReviewDecisionProps } from './ReviewDecision.interface';

const statusOptions = [
  {
    label: t('Select your option'),
    value: apiEnums.ReviewStepStatusEnum.Pending,
    hidden: true,
  },
  {
    label: t('Complete'),
    value: apiEnums.ReviewStepStatusEnum.Complete,
  },
  {
    label: t('Disqualified'),
    value: apiEnums.ReviewStepStatusEnum.Rejected,
  },
];

export const ReviewDecision: FC<ReviewDecisionProps> = ({
  onChange,
  status,
  supervisionAccess,
  completionDate,
}) => {
  return supervisionAccess ? (
    <div className="grid grid-cols-2 col-start-3 row-start-1 justify-between items-center">
      <div className="-mt-1 sm:row-start-1">
        <SelectAsync
          onChange={(value) => onChange({ status: value })}
          value={status}
          name="status"
          options={statusOptions}
          optionsPosition="top"
          disabled={!status}
        />
      </div>
      <p className="ml-auto text-grey-forest-400 sm:row-start-1">
        {completionDate ? format(completionDate, 'MM/dd/yyyy') : null}
      </p>
    </div>
  ) : (
    <p className="col-start-2 ml-auto text-sm font-medium text-forest-500 sm:ml-0">
      {/* NOTE: until the candidate registers, no medical reviews are created
       * In this case status is undefined and we want to show pending status. */}
      {medicalReviewStatusToLabel.get(
        status || apiEnums.ReviewStepStatusEnum.Pending,
      )}
    </p>
  );
};
