import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { SelectAsync } from '@nodal/uikit/components/Select';
import { format } from 'date-fns';

import type { CandidateInformationBarProps } from './CandidateInformationBar.interface';

export const CandidateInformationBar: FC<CandidateInformationBarProps> = ({
  organizationName,
  joined,
  lastLogin,
  statusControls: {
    status,
    onStatusUpdate,
    statusOptions,
    statusDisabled,
    showStatusDropdown,
  },
  supportTeamControls: {
    onAssignSupportTeamUser,
    supportTeamUsersOptions,
    usersSupportTeamUser,
    canEditSupportStaffDropdown,
    showSupportTeamUserDropdown,
  },
}) => (
  <div className="flex justify-between pb-4 border-b border-grey-forest-100">
    <div className="flex flex-col gap-2 justify-between">
      <h3 className="text-xl font-semibold leading-7 text-gray-900">
        {organizationName}
      </h3>
      <div className="flex gap-8 w-full">
        <p className="text-sm text-grey-forest-500">
          {joined ? t('Joined {date}', format(joined, 'MM/dd/yyyy')) : null}
        </p>
        <p className="text-sm text-grey-forest-500">
          {lastLogin
            ? t('Last login {date}', format(lastLogin, 'MM/dd/yyyy'))
            : null}
        </p>
      </div>
    </div>
    <div className="flex flex-col gap-2 pl-4 lg:flex-row lg:gap-20 lg:pl-0">
      {showSupportTeamUserDropdown && (
        <div className="flex gap-4 items-center">
          <p className="text-sm text-grey-forest-500">{t('Support Staff')}</p>
          <div className="min-w-[200px]">
            <SelectAsync
              onChange={onAssignSupportTeamUser}
              options={supportTeamUsersOptions}
              name="supportTeamUser"
              value={usersSupportTeamUser?.id}
              disabled={!canEditSupportStaffDropdown}
            />
          </div>
        </div>
      )}
      {showStatusDropdown && (
        <div className="flex gap-4 items-center">
          <div className="min-w-[200px]">
            <SelectAsync
              onChange={onStatusUpdate}
              options={statusOptions}
              name="userStatus"
              value={status}
              disabled={statusDisabled}
            />
          </div>
        </div>
      )}
    </div>
  </div>
);
