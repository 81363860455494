import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { useCandidate } from 'components/CandidateDetails';

import { Contract } from './Contract';

import type { ApiModel } from '@nodal/api';

const contractOptions = [
  {
    label: t('Progyny: Single'),
    value: apiEnums.IpContractDocumentTypeDocumentTypeEnum.Ps,
  },
  {
    label: t('Progyny: Couple'),
    value: apiEnums.IpContractDocumentTypeDocumentTypeEnum.Pp,
  },
  {
    label: t('Maven/Carrot: Couple'),
    value: apiEnums.IpContractDocumentTypeDocumentTypeEnum.Mp,
  },
  {
    label: t('Friends/Family: Single'),
    value: apiEnums.IpContractDocumentTypeDocumentTypeEnum.Fs,
  },
  {
    label: t('Friends/Family: Couple'),
    value: apiEnums.IpContractDocumentTypeDocumentTypeEnum.Fp,
  },
  {
    label: t('Regular IP Contract: Single'),
    value: apiEnums.IpContractDocumentTypeDocumentTypeEnum.Ns,
  },
  {
    label: t('Regular IP Contract: Couple'),
    value: apiEnums.IpContractDocumentTypeDocumentTypeEnum.Np,
  },
];

export const ContractConnected = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const { candidate, ipTasks } = useCandidate();

  // TODO change to enum after schema adjustments https://linear.app/nodal-health/issue/NOD-897/be-adjust-schema-for-ip-dashboard
  const contractTypeTask = ipTasks?.find((task) => task.type === 'ip-contract');
  const contractLink =
    contractTypeTask && 'document' in contractTypeTask.content_object
      ? contractTypeTask.content_object.document.signed_file_url
      : undefined;

  const contractType =
    contractTypeTask && 'document' in contractTypeTask?.content_object
      ? (contractTypeTask?.content_object.document
          ?.document_type as ApiModel.IpContractDocumentTypeDocumentTypeEnum)
      : undefined;

  const selectContractTypeMutation = useMutation(
    (request: ApiModel.TasksApiTasksDocumentTypePartialUpdateRequest) =>
      apiClient.api.TasksApi.tasksDocumentTypePartialUpdate(request),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          queryKeys.b2bUserTasksList,
          // component where mutation is called wouldn't be rendered if there is no candidate
          candidate!.id,
        ]),
    },
  );

  const onSelectContractType = useCallback(
    async (contractType: ApiModel.IpContractDocumentTypeDocumentTypeEnum) => {
      if (candidate && contractTypeTask) {
        await selectContractTypeMutation.mutateAsync({
          id: contractTypeTask.id,
          patchedDocumentType: {
            document_type: contractType,
          },
        });
      }
    },
    [candidate, contractTypeTask, selectContractTypeMutation],
  );

  if (!candidate || !contractTypeTask) return null;

  return (
    <Contract
      onSelectContractType={onSelectContractType}
      contractType={contractType ?? undefined}
      contractOptions={contractOptions}
      contractLink={contractLink ?? undefined}
    />
  );
};
