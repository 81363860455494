import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { useQuery } from 'react-query';

import { useFeatureFlags } from 'app/FeatureFlagsProvider';
import { usePermissions } from 'app/PermissionsProvider';

import { getFiltersDetails, getInitialFilters } from './candidateFilters';

import type { ApiModel } from '@nodal/api';
import type {
  Filter,
  FilterOption,
} from 'components/SearchFilterTable/Filters';

const getFilterIdsForOrganizationBy = (
  hasAccessToMatching: boolean,
  hasAccessToNavigator: boolean,
) => {
  if (hasAccessToMatching) {
    return [
      'role',
      'status',
      'candidateSteps',
      'billingCompleted',
      'medicalReviewStatus',
      'applicationFlaggedToReview',
      'matchProfileToReview',
    ];
  }

  if (hasAccessToNavigator) {
    return [
      'status',
      'candidateSteps',
      'billingCompleted',
      'medicalReviewStatus',
      'applicationFlaggedToReview',
      'matchProfileToReview',
    ];
  }

  return ['candidateSteps', 'billingCompleted', 'medicalReviewStatus'];
};

export const generateFiltersDetails = ({
  organizationsOptions,
  canViewAllOrganizations,
  hasAccessToMatching,
  hasAccessToNavigator,
}: {
  organizationsOptions: FilterOption[];
  canViewAllOrganizations: boolean;
  hasAccessToMatching: boolean;
  hasAccessToNavigator: boolean;
}): Filter<ApiModel.B2bApiB2bUsersListRequest>[] => {
  let filters: Filter<ApiModel.B2bApiB2bUsersListRequest>[] = getFiltersDetails(
    {
      canViewAllOrganizations,
      hasAccessToMatching,
      hasAccessToNavigator,
    },
  );
  // NOTE: show organization filter only when user has more than one organization (currently this is only supported for superadmin)
  if (organizationsOptions.length > 1) {
    filters = filters.map((filter) =>
      filter.id === 'organizationId'
        ? { ...filter, options: organizationsOptions }
        : filter,
    );
  } else {
    const filterIds = getFilterIdsForOrganizationBy(
      hasAccessToMatching,
      hasAccessToNavigator,
    );

    filters = filters.filter((filter) => filterIds.includes(filter.id));
  }

  return filters;
};

export const useCandidatesFilters = () => {
  const { permissions } = usePermissions();
  const { features } = useFeatureFlags();
  const apiClient = useApiClient();

  const { data: organizationsList, isLoading: organizationsListLoading } =
    useQuery(queryKeys.organizationsList, () =>
      apiClient.api.B2bApi.b2bOrganizationsList(),
    );

  const organizationsOptions =
    organizationsList?.data?.map((org) => ({
      value: org.id?.toString(),
      label: org.name || '',
    })) || [];

  if (organizationsListLoading || !permissions || !features) {
    return undefined;
  }
  const { canViewAllOrganizations, canManageMatching, canManageNavigator } =
    permissions;

  const hasAccessToMatching = canManageMatching && features.matching;
  const hasAccessToNavigator = canManageNavigator && features.navigator;

  return {
    details: generateFiltersDetails({
      organizationsOptions,
      canViewAllOrganizations,
      hasAccessToMatching,
      hasAccessToNavigator,
    }),
    title: t('Candidate Filters'),
    initialValues: getInitialFilters(permissions, features),
  };
};
