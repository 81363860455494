import { ChevronRightIcon } from '@heroicons/react/solid';
import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { StatusBadge } from '@nodal/uikit/components/StatusBadge';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  transformUrlQueryToUiFilterQuery,
  convertUrlToParsedQuery,
} from 'components/SearchFilterTable/utils';

import { useFeatureFlags } from '@b2b/app/FeatureFlagsProvider';
import { usePermissions } from '@b2b/app/PermissionsProvider';
import {
  SearchFilterTable,
  useSearchFilterTable,
} from '@b2b/components/SearchFilterTable';
import { appPaths, organizationPaths as paths } from '@b2b/consts/paths';
import {
  billingStatusToBagdeStatus,
  medicalReviewStatusToBadgeStatus,
} from '@b2b/utils/candidate';

import { candidateStepsStatusToBagdeStatus } from '../utils';

import { candidateStepsOptions, getInitialFilters } from './candidateFilters';
import { useCandidatesFilters } from './useCandidatesFilters';

import type { ApiModel, QueryParameters } from '@nodal/api';

// NOTE: This represents the default ordering key for API queries, which is not user-selectable in the UI/UX.
// This key is always included in the payload when calling the API.
const immutableOrderingKey = 'display_priority';

const columnHelper = createColumnHelper<ApiModel.B2BUserList>();

const columns = [
  columnHelper.accessor('id', {
    cell: (info) => info.getValue(),
    enableSorting: false,
  }),
  columnHelper.accessor(
    (row) =>
      row.first_name && row.last_name
        ? `${row.first_name} ${row.last_name}`
        : row.email,
    {
      id: 'search_name_display',
      cell: (info) => (
        <span className="text-grey-forest-700">{info.getValue()}</span>
      ),
      header: () => t('Name'),
    },
  ),
  columnHelper.accessor(
    (row) => (row.date_joined ? format(new Date(row.date_joined), 'P') : null),
    {
      id: 'date_joined',
      header: () => t('Date of join'),
      cell: (info) => info.renderValue(),
    },
  ),
  columnHelper.accessor(
    (row) => {
      const bagdeStatus = candidateStepsStatusToBagdeStatus.get(
        row.candidate_steps,
      );

      const badgeLabel = candidateStepsOptions.find(
        ({ value }) => value === row.candidate_steps,
      )?.label;

      return bagdeStatus ? (
        <StatusBadge
          status={bagdeStatus}
          dot={
            row.application_flagged_for_review ||
            row.match_profile_flagged_for_review
          }
          label={badgeLabel}
        />
      ) : null;
    },
    {
      id: 'candidate_steps',
      header: t('Candidate Steps'),
      cell: (info) => info.renderValue(),
      enableSorting: false,
    },
  ),
  columnHelper.accessor(
    (row) =>
      row.billing_status === apiEnums.BillingStatusEnum.Minus ? null : (
        <StatusBadge
          // NOTE: Fallback to 'pending' as get method can return undefined and status is required here
          // From business/product context 'pending' status is applied initially when there is paymentStatus defined
          // to ensure a default and consistent representation for payment.
          // This helps to prevent potential issues that could arise from displaying undefined status
          status={
            billingStatusToBagdeStatus.get(row.billing_status) || 'pending'
          }
        />
      ),
    {
      id: 'billing_status',
      header: t('Billing Status'),
      cell: (info) => info.renderValue(),
    },
  ),
  columnHelper.accessor(
    (row) =>
      row.medical_review_status !==
      apiEnums.MedicalReviewStatusEnum.NotApplicable ? (
        <StatusBadge
          // NOTE: Fallback to 'pending' as get method can return undefined and status is required here
          // From business/product context 'pending' status is applied to all users initially
          // to ensure a default and consistent representation for medical reviews.
          // This helps to prevent potential issues that could arise from displaying undefined status
          status={
            medicalReviewStatusToBadgeStatus.get(row.medical_review_status) ||
            'pending'
          }
        />
      ) : null,
    {
      id: 'medical_review_status',
      header: t('Medical Summary'),
      cell: (info) => info.renderValue(),
    },
  ),
  columnHelper.accessor(
    (row) => (
      <div className="mx-auto w-6 h-6 fill-grey-forest-500">
        <Link to={`${paths.app}/${appPaths.candidate}/${row.id}`}>
          <ChevronRightIcon className="w-full" />
        </Link>
      </div>
    ),
    {
      id: 'action',
      cell: (info) => info.renderValue(),
      header: () => '',
      enableSorting: false,
    },
  ),
];

export const CandidatesSearchFilterTableConnected = () => {
  const apiClient = useApiClient();
  const { permissions } = usePermissions();
  const navigate = useNavigate();
  const { features } = useFeatureFlags();

  const initialQueryParameters: QueryParameters<ApiModel.B2bApiB2bUsersListRequest> =
    {
      page: 1,
      pageSize: 10,
      ordering: [immutableOrderingKey, '-date_joined'],
      search: '',
      filters: getInitialFilters(permissions, features),
    };

  const queryCallback = (
    query: QueryParameters<ApiModel.B2bApiB2bUsersListRequest>,
  ) =>
    apiClient.api.B2bApi.b2bUsersList({
      ...query.filters,
      ...query,
      searchNameDisplay: query.search,
      // NOTE: Use casting because ordering type (string[]) in QueryParameters
      // is less specific than in the API Schema
    } as ApiModel.B2bApiB2bUsersListRequest);

  const urlQueryParameters = location.search
    ? convertUrlToParsedQuery(location.search)
    : null;

  const candidatesFilters = useCandidatesFilters();

  const table = useSearchFilterTable<
    ApiModel.B2BUserList,
    ApiModel.B2bApiB2bUsersListRequest
  >({
    initialQuery: urlQueryParameters
      ? transformUrlQueryToUiFilterQuery<ApiModel.B2bApiB2bUsersListRequest>(
          urlQueryParameters,
          initialQueryParameters.filters,
        )
      : initialQueryParameters,
    columns,
    queryKey: queryKeys.usersList,
    queryCallback,
    urlSearchParamsEnabled: true,
    immutableOrdering: [immutableOrderingKey],
    filters: candidatesFilters,
  });

  useEffect(() => {
    if (table.queryStringParams) {
      navigate(`?${table.queryStringParams}`);
    }
  }, [navigate, table.queryStringParams]);

  if (!candidatesFilters) {
    return <LoadingScreen />;
  }

  return <SearchFilterTable {...table} />;
};
