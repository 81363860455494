import type { FC, ReactNode } from 'react';

import { AuthenticateScreen } from '@nodal/core/flows/AuthenticateScreen';
import { ForgotPasswordScreen } from '@nodal/core/flows/ForgotPasswordScreen';
import { InitialScreensDecorator } from '@nodal/core/flows/InitialScreensDecorator';
import { ProtectedRoute } from '@nodal/core/flows/ProtectedRoute';
import { ResetPasswordScreen } from '@nodal/core/flows/ResetPasswordScreen';
import { t } from '@nodal/i18n';
import posthog from 'posthog-js';
import { useEffect, useContext, createContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { CandidateAppProtected } from '@b2b/app/CandidateAppProtected';
import { OrganizationAppProtected } from '@b2b/app/OrganizationAppProtected';
import {
  HappySurrogate,
  HappySurrogateJpg,
  ParentsInClinic,
  ParentsInClinicJpg,
} from '@b2b/assets';
import { Logout } from '@b2b/components/Logout';
import { SignInScreen } from '@b2b/components/SignInScreen';
import { SignUp } from '@b2b/components/SignUp';
import { basePaths, forgotPasswordPaths, pathsFrom } from '@b2b/consts/paths';
import { settings } from '@b2b/settings';
import { getRoleSignupPath } from '@b2b/utils';

import { FeatureFlagsProvider } from './FeatureFlagsProvider';
import { PermissionsProvider } from './PermissionsProvider';

import type { Role, RoleImage, RoleRouteProps } from './RoleRoute.interface';

const imageFrom: RoleImage = {
  candidate: {
    src: HappySurrogate,
    fallbackSrc: HappySurrogateJpg,
    alt: t('Happy Surrogate'),
  },
  organization: {
    src: ParentsInClinic,
    fallbackSrc: ParentsInClinicJpg,
    alt: t('Parents in the Clinic'),
  },
};

const initialValues: { role: Role } = {
  role: 'organization',
};

export const UnprotectedAppRoleRouteContext = createContext(initialValues);

export const useAppRole = () => {
  const { role } = useContext(UnprotectedAppRoleRouteContext);

  return { role };
};

const RoleRouteContextProvider = ({
  children,
  role,
}: {
  children: ReactNode;
  role: Role;
}) => {
  return (
    <UnprotectedAppRoleRouteContext.Provider value={{ role }}>
      {children}
    </UnprotectedAppRoleRouteContext.Provider>
  );
};

// TODO: temp. solution, most likely use effect behavior should be a part of logout function
const WrappedLogout = () => {
  const { role } = useAppRole();

  const paths = pathsFrom[role];

  useEffect(() => {
    if (settings.getPostHogEnabled()) {
      posthog.reset();
    }
  }, []);

  return <Logout redirectPath={paths.signin} />;
};

export const RoleRoute: FC<RoleRouteProps> = ({ role }) => {
  const image = imageFrom[role];

  const paths = pathsFrom[role];

  const signupPath = getRoleSignupPath(role);

  return (
    <RoleRouteContextProvider role={role}>
      <Routes>
        <Route
          path={`${basePaths.signin}/*`}
          element={
            <InitialScreensDecorator image={image}>
              <SignInScreen />
            </InitialScreensDecorator>
          }
        />
        <Route
          path={`${signupPath}/*`}
          element={
            <InitialScreensDecorator image={image}>
              <SignUp />
            </InitialScreensDecorator>
          }
        />
        <Route
          path={`${basePaths.forgotPassword}/*`}
          element={
            <InitialScreensDecorator image={image}>
              <ForgotPasswordScreen
                routePaths={{
                  checkEmail: forgotPasswordPaths.checkEmail,
                }}
                redirectPaths={{
                  signin: paths.signin,
                  checkEmail: forgotPasswordPaths.checkEmail,
                }}
              />
            </InitialScreensDecorator>
          }
        />
        <Route
          path={`${basePaths.resetPassword}/*`}
          element={
            <InitialScreensDecorator image={image}>
              <ResetPasswordScreen
                redirectPaths={{
                  signin: paths.signin,
                  checkEmail: `${paths.forgotPassword}/${forgotPasswordPaths.checkEmail}`,
                }}
              />
            </InitialScreensDecorator>
          }
        />
        <Route path={basePaths.logout} element={<WrappedLogout />} />
        <Route
          path={`${basePaths.app}/*`}
          element={
            <ProtectedRoute redirectPaths={{ unauthenticated: paths.signin }}>
              <PermissionsProvider>
                <FeatureFlagsProvider>
                  {role === 'candidate' && <CandidateAppProtected />}
                  {role === 'organization' && <OrganizationAppProtected />}
                </FeatureFlagsProvider>
              </PermissionsProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${basePaths.root}/*`}
          element={
            <ProtectedRoute redirectPaths={{ unauthenticated: paths.signin }}>
              <Navigate to={paths.app} />
            </ProtectedRoute>
          }
        />
        <Route
          path={basePaths.authenticate}
          element={
            <AuthenticateScreen
              redirectPaths={{ app: paths.app, unauthenticated: paths.signin }}
            />
          }
        />
        <Route path="*" element={<Navigate to={paths.signin} />} />
      </Routes>
    </RoleRouteContextProvider>
  );
};
