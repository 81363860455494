import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { usePermissions } from '@b2b/app/PermissionsProvider';

import type { ApiModel } from '@nodal/api';

const getAvailableScreeningPackages = ({
  allScreeningPackages,
  isPrepaid,
  organization,
  canSelectScreeningPackage,
}: {
  allScreeningPackages: ApiModel.ScreeningPackage[];
  isPrepaid: boolean;
  organization?: ApiModel.Organization | ApiModel.OrganizationNested;
  canSelectScreeningPackage: boolean;
}) => {
  // NOTE: Users who do not have access to select a screening package
  // will be automatically assigned the Full Screening Package.
  if (!canSelectScreeningPackage) {
    return allScreeningPackages.filter(
      (screen) => screen.name === apiEnums.NameEnum.Full,
    );
  }

  if (
    isPrepaid &&
    organization &&
    'prepaid_screening_plans' in organization &&
    organization.prepaid_screening_plans.length
  ) {
    return allScreeningPackages.filter((screen) =>
      organization.prepaid_screening_plans.includes(screen.id),
    );
  }

  return allScreeningPackages;
};

export const useScreeningPackages = ({
  organization,
  isPrepaid,
}: {
  organization?: ApiModel.Organization | ApiModel.OrganizationNested;
  isPrepaid: boolean;
}) => {
  const apiClient = useApiClient();
  const { permissions } = usePermissions();

  const {
    data: screeningPackagesList,
    isLoading: isScreeningPackageListLoading,
  } = useQuery(queryKeys.billingScreeningPackagesList, () =>
    apiClient.api.BillingApi.billingScreeningPackagesList(),
  );

  const createInvitationScreeningPackage = useMutation(
    (request: ApiModel.B2bApiB2bInvitationsScreeningPackageCreateRequest) =>
      apiClient.api.B2bApi.b2bInvitationsScreeningPackageCreate(request),
    {
      onError: () => {
        toast.error(t('Something went wrong...'));
      },
    },
  );

  if (!screeningPackagesList?.data || isScreeningPackageListLoading) {
    return null;
  }

  const submit = async (
    id: number,
    data: ApiModel.InvitationScreeningPackage,
  ) =>
    await createInvitationScreeningPackage.mutateAsync({
      id,
      invitationScreeningPackage: data,
    });

  const packages = getAvailableScreeningPackages({
    allScreeningPackages: screeningPackagesList.data,
    isPrepaid,
    organization,
    canSelectScreeningPackage: !!permissions?.canSelectScreeningPackage,
  });

  return {
    packages,
    submit,
  };
};
