import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useQuery } from 'react-query';

import { getCurrentOrganization } from 'utils/organization';

import { ScreeningComplete } from './ScreeningComplete';

export const ScreeningCompleteConnected = () => {
  const apiClient = useApiClient();

  const { data: organizationsList } = useQuery(
    queryKeys.organizationsList,
    () => apiClient.api.B2bApi.b2bOrganizationsList(),
  );

  const orgName = getCurrentOrganization(organizationsList?.data)?.name;

  if (!orgName) return <LoadingScreen />;

  return <ScreeningComplete organizationName={orgName} />;
};
