import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { CheckboxAsync } from '@nodal/uikit/components/Checkbox';
import classNames from 'classnames';

import type { MatchingPaymentProps } from './MatchingPayment.interface';

export const MatchingPayment: FC<MatchingPaymentProps> = ({
  planName,
  paymentTypeLabel,
  onChangePaymentReceived,
  showPaymentReceivedCheckbox,
  paymentReceived,
}) => (
  <div
    className={classNames(
      'text-gray-700 bg-white border-gray-300',
      showPaymentReceivedCheckbox
        ? 'w-full divide-y divide-grey-forest-100'
        : null,
    )}
  >
    <div className="flex flex-col gap-2 py-3 w-full sm:grid sm:grid-cols-3 sm:items-center">
      <p className="col-start-1 text-sm text-grey-forest-700">
        {t('Nodal Services')}
      </p>
      {planName && (
        <p className="col-start-2 text-sm font-medium text-forest-500">
          {t('Plan Name:')}&nbsp;
          {planName}
        </p>
      )}
      <div className="flex col-start-2">
        <p className="text-sm font-medium text-forest-500">
          {t('Payment Type:')}&nbsp;
          {paymentTypeLabel}
        </p>
      </div>
    </div>
    {showPaymentReceivedCheckbox && (
      <div className="flex flex-col gap-2 py-3 w-full sm:grid sm:grid-cols-3 sm:items-center">
        <p className="col-start-1 text-sm text-grey-forest-700">
          {t('Final match payment')}
        </p>
        <CheckboxAsync
          label={t('Payment has beeen received')}
          checked={paymentReceived}
          onChange={() => onChangePaymentReceived(!paymentReceived)}
        />
      </div>
    )}
  </div>
);
