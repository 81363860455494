import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { MatchProfileSkeleton } from '@nodal/core/flows/MatchProfile';
import { hasSamePartnerAddress } from '@nodal/core/utils/profile';

import { useCandidate } from '../CandidateDetails.connect';

import { MatchProfileConnected as MatchProfile } from './MatchProfile.connect';

import type { ApiModel } from '@nodal/api';

export const NavigatorMatchProfileConnected: FC = () => {
  const { candidate, profile } = useCandidate();

  // NOTE: Type casting because we define the type in more detail than we get from the API,
  // the view will be only for navigator profile
  const navigatorProfile = profile as ApiModel.NavigatorParentsProfile;

  if (!candidate || !profile) {
    return <MatchProfileSkeleton inMarketplace={false} />;
  }

  const user = {
    ...candidate,
    role: apiEnums.UserRoleEnum.Nap,
    profile: {
      ...navigatorProfile,
      same_partner_address: hasSamePartnerAddress(navigatorProfile),
    },
  };

  return <MatchProfile user={user} />;
};
