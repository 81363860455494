import type { FC } from 'react';

import { MedicalRecordReview } from '@nodal/core/flows/Screening/MedicalRecordReview';
import { t } from '@nodal/i18n';
import { BackButton } from '@nodal/uikit/components/BackButton';

import type { MedicalRecordProps } from './MedicalRecord.interface';

export const MedicalRecord: FC<MedicalRecordProps> = ({
  initialValues,
  onSubmit,
  editable,
  validationSchema,
  title,
  previewPhotoEnabled,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-8 py-6 mx-4 max-w-full bg-transparent sm:gap-6 sm:mx-6 lg:mx-8 2xl:mx-auto 2xl:max-w-5xl">
        <div className="flex flex-col w-fit">
          <BackButton title={t('Back to Candidate Page')} />
        </div>
        <div className="flex justify-center w-full">
          <MedicalRecordReview
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            title={title}
            displayPregnancyCountInput
            displayIvfTransferCountInput
            editable={editable}
            previewPhotoEnabled={previewPhotoEnabled}
          />
        </div>
      </div>
    </div>
  );
};
