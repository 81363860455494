import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { usePermissions } from 'app/PermissionsProvider';

import { convertToIsoStringWithoutOffset } from '@b2b/utils';

import { conflictStateMessageMap } from './conflictStateMessages';
import { Introduction } from './Introduction';

import type { ApiModel } from '@nodal/api';

export const IntroductionConnected = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { permissions } = usePermissions();

  const { id: introductionId } = useParams() as { id: string };

  const { data: userIntroduction, isLoading } = useQuery(
    queryKeys.usersIntroductionsRetrieve,
    () =>
      apiClient.api.UsersApi.usersIntroductionsRetrieve({
        id: Number.parseInt(introductionId),
      }),
  );

  const submit = useMutation(
    (request: ApiModel.UsersApiUsersIntroductionsPartialUpdateRequest) =>
      apiClient.api.UsersApi.usersIntroductionsPartialUpdate(request),
    {
      onSuccess: () => {
        toast.success(t('Successfully updated'));
        queryClient.invalidateQueries(queryKeys.usersIntroductionsList);
      },
      onError: () => {
        toast.error(t('Something went wrong'));
      },
    },
  );

  const introduction = userIntroduction?.data;

  useEffect(() => {
    if (!isLoading && !introduction) {
      // NOTE: Go back if there is no introduction with this id
      navigate('../');
    }
  }, [isLoading, navigate, introduction]);

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(queryKeys.usersIntroductionsRetrieve);
    };
  }, [queryClient]);

  if (isLoading || !introduction || !permissions) {
    return <LoadingScreen />;
  }

  const onSubmit = async (data: ApiModel.PatchedIntroductionFull) => {
    // NOTE: We need to convert date before submit into iso string without offset
    const convertedCallDate = data.call_date
      ? convertToIsoStringWithoutOffset(data.call_date)
      : null;

    await submit.mutateAsync({
      id: introduction.id,
      patchedIntroductionFull: { ...data, call_date: convertedCallDate },
    });
  };

  const { parent_profile, donor_profile, id, ...initialValues } = introduction;

  const warningMessage = donor_profile.address?.state
    ? conflictStateMessageMap.get(donor_profile.address.state)
    : undefined;

  return (
    <Introduction
      id={id}
      parentProfile={parent_profile}
      donorProfile={donor_profile}
      initialValues={initialValues}
      onSubmit={onSubmit}
      warningMessage={warningMessage}
      editable={permissions.canEditIntroduction}
    />
  );
};
