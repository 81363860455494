import range from 'lodash/range';
import { useMemo } from 'react';

import type { PaginationRange } from './Pagination.interface';

const ellipsis = '...';

// Minimum number of pages between page range edge and ellipsis (...) to display.
const ellipsisMargin = 2;

// Number of fixed pages always shown on pagination bar: first, last, and current page.
const fixedPageCount = 3;

// The number of pages to display before and after the current page number.
const siblingCount = 1;

export const usePaginationRange = ({ pageCount, page }: PaginationRange) =>
  useMemo(() => {
    // The total number of items to show, including 2 ellipsis and fixed pages
    const totalItemNumbers = siblingCount + fixedPageCount + 2;

    // The total number of pages to display on either side of the current page
    const pageRange = siblingCount * 2;

    const leftSiblingIndex = Math.max(page - siblingCount, 1);
    const rightSiblingIndex = Math.min(page + siblingCount, pageCount);

    const shouldShowLeftEllipsis = leftSiblingIndex > ellipsisMargin;
    const shouldShowRightEllipsis =
      rightSiblingIndex < pageCount - ellipsisMargin;

    const firstPageIndex = 1;
    const lastPageIndex = pageCount;

    // Display all page numbers
    if (totalItemNumbers >= pageCount) {
      return range(1, pageCount + 1);
    }

    // Display only the right ellipsis
    if (!shouldShowLeftEllipsis && shouldShowRightEllipsis) {
      const leftItemCount = fixedPageCount + pageRange;

      const leftRange = range(1, leftItemCount + 1);

      return [...leftRange, ellipsis, pageCount];
    }

    // Display only the left ellipsis
    if (shouldShowLeftEllipsis && !shouldShowRightEllipsis) {
      const rightItemCount = fixedPageCount + pageRange;

      const rightRange = range(pageCount - rightItemCount + 1, pageCount + 1);

      return [firstPageIndex, ellipsis, ...rightRange];
    }

    // Display both left and right ellipses
    if (shouldShowLeftEllipsis && shouldShowRightEllipsis) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex + 1);

      return [
        firstPageIndex,
        ellipsis,
        ...middleRange,
        ellipsis,
        lastPageIndex,
      ];
    }
  }, [page, pageCount]);
