import { apiEnums } from '@nodal/api';

import type { ApiModel } from '@nodal/api';

export const canProceedWithPayment = (
  paymentStatus?: ApiModel.PaymentStatusEnum,
) =>
  paymentStatus === apiEnums.PaymentStatusEnum.NotInitiated ||
  paymentStatus === apiEnums.PaymentStatusEnum.Failed ||
  // NOTE: The Initiate payment status is an internal indicator showing that the user has initiated a payment request.
  // It's distinct from the "processing" status, which is set after we've sent payment confirmation to Stripe, signifying active payment processing.
  // In case of validation or stripe errors, the status may still be initiated,
  // so we allow to re-initiate the payment
  paymentStatus === apiEnums.PaymentStatusEnum.Initiated;
