import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import {
  dataWithSamePartnerAddress,
  dataWithSingleParentNoPartner,
  hasSamePartnerAddress,
  isInRelationship,
} from '@nodal/core/utils/profile';
import { useModal } from '@nodal/uikit/components/Modal';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { ParentsEditProfileForm } from './ParentsEditProfileForm';
import { schema as validationSchema } from './validation';

import type {
  ParentsEditProfileFormConnectProps,
  ParentsProfileFormValues,
} from './ParentsEditProfileForm.interface';
import type { ApiModel } from '@nodal/api';

export const ParentsEditProfileFormConnected: FC<
  ParentsEditProfileFormConnectProps
> = ({ id, profile }) => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const { close } = useModal();

  const [uploadProgress, setUploadProgress] = useState<number>();

  const update = useMutation(
    (request: ApiModel.B2bApiB2bUserProfilePartialUpdateRequest) =>
      apiClient.api.B2bApi.b2bUserProfilePartialUpdate(request, {
        onUploadProgress: (e) => {
          if (e.total) {
            setUploadProgress(e.loaded / e.total);
          }
        },
      }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeys.candidateProfileRetrieve),
    },
  );

  const handleUpdate = async (data: ParentsProfileFormValues) => {
    const { same_partner_address, ...profileData } = data || {};
    let updatedData = { ...profileData };

    if (same_partner_address && isInRelationship(data.relationship_status)) {
      updatedData = dataWithSamePartnerAddress(profileData);
    } else if (!isInRelationship(data.relationship_status)) {
      updatedData = dataWithSingleParentNoPartner(profileData);
    }

    await update.mutateAsync({
      id,
      patchedProfileObject: updatedData,
    });

    close();
  };

  if (!profile) {
    return null;
  }

  // NOTE: Type casting because we define the type in more detail than we get from the API,
  // the view will be only for parents profile
  const parentsProfile = profile as ParentsProfileFormValues;

  return (
    <ParentsEditProfileForm
      uploadProgress={uploadProgress}
      initialValues={{
        ...parentsProfile,
        same_partner_address: hasSamePartnerAddress(parentsProfile),
      }}
      onSubmit={handleUpdate}
      validationSchema={validationSchema}
    />
  );
};
