// ESLint produces warning for GlobalError import
// GlobalError is intentionally only used in the catch block below
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { GlobalError, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useFeatureFlags } from '@b2b/app/FeatureFlagsProvider';
import { usePermissions } from '@b2b/app/PermissionsProvider';
import { appPaths } from '@b2b/consts/paths';
import { canCreateCandidateRequest } from '@b2b/utils/candidate';

import type { ApiModel } from '@nodal/api';

type FormValues = ApiModel.PatchedOrganizationUserProfile &
  ApiModel.PatchedOrganization;

export const useContactDetailsForm = ({
  submit,
}: {
  submit: (submitData: FormValues) => Promise<void>;
}): {
  onSubmit: (submitData: FormValues) => Promise<void>;
} => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const { features } = useFeatureFlags();

  const { canManageInvitations, canViewAllOrganizations } = permissions || {};

  const { data: invitationsList } = useQuery(queryKeys.invitationsList, () =>
    apiClient.api.B2bApi.b2bInvitationsList(),
  );

  const onSubmit = async (submitData: FormValues) => {
    try {
      await submit(submitData);

      if (
        invitationsList?.data?.results &&
        !invitationsList.data.results.length &&
        canCreateCandidateRequest({
          isInvitationFeatureEnabled: !!features?.invitations,
          canManageInvitations: !!canManageInvitations,
          canViewAllOrganizations: !!canViewAllOrganizations,
        })
      ) {
        // NOTE: if the organization does not have requests yet and has permission to create requestes, redirect to the welcome page
        navigate(`../${appPaths.welcome}`);
      } else {
        toast.success(t('Successfully updated'));
      }
    } catch (error: GlobalError) {
      if (error?.response?.data?.code === 'illegal_organization_name') {
        toast.error(
          t(
            'You cannot use this organization name. Please use a different one.',
          ),
        );
      } else if (!error?.response?.data?.non_field_errors) {
        toast.error(t('Something went wrong...'));
      }
    }
  };

  return { onSubmit };
};
