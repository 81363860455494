import type { FC } from 'react';

import { PlusIcon } from '@heroicons/react/outline';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { Tabs } from '@nodal/uikit/components/Tabs';
import { Link, Route, Routes } from 'react-router-dom';

import {
  candidatesDashboardPaths,
  appPaths,
  organizationPaths as paths,
  requestPaths,
} from '@b2b/consts/paths';

import {
  CandidatesSearchFilterTable,
  InvitationsSearchFilterTable,
} from './SearchFilterTable';

import type { CandidatesProps } from './Candidates.interface';

export const Candidates: FC<CandidatesProps> = ({
  activeTabIndex,
  onChange,
  tabs,
  disabledCandidateRequest,
}) => {
  return (
    <div className="flex relative flex-col col-span-12 w-full h-full font-avenir-next text-sm font-normal leading-5">
      <div className="w-full bg-white">
        {!disabledCandidateRequest && (
          <div className="flex gap-6 justify-center items-center p-6 mx-auto max-w-7xl sm:justify-between md:px-6 lg:px-8">
            <h2 className="hidden text-2xl leading-7 text-gray-900 sm:block">
              {t('Candidates')}
            </h2>
            <Link
              to={`${paths.app}/${appPaths.request}/${requestPaths.new}`}
              className="flex gap-3 justify-center w-max sm:justify-between"
            >
              <Button variant="primary">
                <div className="w-6 h-6">
                  <PlusIcon />
                </div>
                {t('New Request')}
              </Button>
            </Link>
          </div>
        )}
      </div>
      <div className="overflow-x-auto px-4 pb-8 mx-auto w-full max-w-7xl sm:px-6 lg:px-8">
        <Tabs tabs={tabs} activeTabIndex={activeTabIndex} onChange={onChange} />
        <Routes>
          <Route
            path={`${candidatesDashboardPaths.users}/*`}
            element={<CandidatesSearchFilterTable />}
          />
          <Route
            path={`${candidatesDashboardPaths.requests}/*`}
            element={<InvitationsSearchFilterTable />}
          />
        </Routes>
      </div>
    </div>
  );
};
