import type { FC } from 'react';

import { useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { capitalize } from 'lodash';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { usePermissions } from '@b2b/app/PermissionsProvider';
import { useCandidate } from '@b2b/components/CandidateDetails/CandidateDetails.connect';
import {
  appPaths,
  requestPaths,
  organizationPaths as paths,
} from '@b2b/consts/paths';
import { getCurrentInvitation, payerToLabel } from '@b2b/utils/candidate';
import {
  getCurrentScreeningPayment,
  isPaymentFailed,
} from '@b2b/utils/candidateRequest';

import { RequestPayment } from './RequestPayment';

import type { ApiModel } from '@nodal/api';

export const RequestPaymentConnected: FC = () => {
  const { candidate } = useCandidate();
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const { permissions } = usePermissions();

  const paymentLinkCreate = useMutation(
    (
      request: ApiModel.B2bApiB2bInvitationsSendExternalPaymentLinkCreateRequest,
    ) =>
      apiClient.api.B2bApi.b2bInvitationsSendExternalPaymentLinkCreate(request),
    {
      onSuccess: () => {
        toast.success(t('Successfully sent'));
      },
      onError: () => {
        toast.error(t('Something went wrong...'));
      },
    },
  );

  const invitation = getCurrentInvitation(candidate?.invitations);

  if (!invitation || !permissions) {
    return null;
  }

  const onResendPaymentLink = async () => {
    await paymentLinkCreate.mutateAsync({ id: invitation.id });
  };

  const screeningPayment = getCurrentScreeningPayment(
    invitation.screening_payments,
  );

  const {
    paying_entity,
    package: screeningPackage,
    payment_status: lastPaymentStatus,
  } = screeningPayment || {};

  const errorMessage = isPaymentFailed(invitation.screening_payments)
    ? t('Error with the payment, please resubmit')
    : undefined;

  const onNavigateRequest = () => {
    navigate(
      `${paths.app}/${appPaths.request}/${requestPaths.update}/${invitation.id}`,
    );
  };

  return (
    <RequestPayment
      payer={paying_entity || undefined}
      payerLabel={
        permissions.canSelectScreeningPackage
          ? payerToLabel.get(paying_entity!)
          : undefined
      }
      packageName={`${capitalize(screeningPackage?.name)} screening`}
      paymentStatus={lastPaymentStatus}
      onResendPaymentLink={onResendPaymentLink}
      errorMessage={errorMessage}
      onNavigateRequest={onNavigateRequest}
    />
  );
};
