import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { PhoneNumberInput } from '@nodal/uikit/components/PhoneNumberInput';
import { RadioGroup } from '@nodal/uikit/components/RadioGroup';
import { Select } from '@nodal/uikit/components/Select';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Form, Formik } from 'formik';

import { settings } from 'settings';

import { candidateRoleToLabel } from '@b2b/utils/candidate';

import type {
  CandidateInformationProps,
  CandidateTypeOption,
} from './CandidateInformation.interface';

const candidateTypes: CandidateTypeOption[] = [
  {
    value: 'odo',
    label: candidateRoleToLabel.get('odo') || '',
  },
  {
    value: 'oed',
    label: candidateRoleToLabel.get('oed') || '',
  },
  {
    value: 'osd',
    label: candidateRoleToLabel.get('osd') || '',
  },
];

const candidateRoleSelectionVisible =
  settings.hasSpermAndEggDonorsIncludedInCandidateRoles();

export const CandidateInformation: FC<CandidateInformationProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  organizationSelectionEnabled,
  organizationOptions,
  isLastStep,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validationOnChange={false}
    >
      {({ isSubmitting }) => (
        <Form className="space-y-4 w-full lg:w-step-view">
          {organizationSelectionEnabled && organizationOptions && (
            <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
              <h3 className="text-xl font-semibold leading-7 text-gray-900">
                {t('Candidate Organization')}
              </h3>
              <div className="mt-6">
                <Select options={organizationOptions} name="organization_id" />
              </div>
            </div>
          )}
          {candidateRoleSelectionVisible && (
            <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
              <h3 className="text-xl font-semibold leading-7 text-gray-900">
                {t('Candidate Type')}
              </h3>
              <div className="mt-6">
                <RadioGroup name="role" options={candidateTypes} />
              </div>
            </div>
          )}
          <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
            <div>
              <h3 className="text-xl font-semibold leading-7 text-gray-900">
                {t('Candidate Information')}
              </h3>
              <p className="pt-2 text-base font-normal leading-6 text-grey-forest-600 sm:text-sm sm:leading-5">
                {t(
                  'Fill out the information below for the candidate who requires screening, and they will receive a link to fill out the screening application and documentation.',
                )}
              </p>
            </div>
            <div className="flex flex-col gap-x-4 gap-y-10 mt-6">
              <TextInput
                label={t('First Name')}
                name="first_name"
                type="text"
              />
              <TextInput
                label={t('Middle Name')}
                name="middle_name"
                type="text"
              />
              <TextInput label={t('Last Name')} name="last_name" type="text" />

              <div className="col-span-6">
                <div className="relative">
                  <div
                    className="flex absolute inset-0 items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="flex relative justify-start">
                    <span className="pr-3 text-base font-semibold leading-7 text-gray-900 bg-white">
                      {t('Contact Details')}
                    </span>
                  </div>
                </div>
              </div>
              <TextInput label={t('Email')} name="email" type="text" />
              <TextInput
                label={t('Partner Email')}
                name="partner_email"
                type="text"
              />
              <PhoneNumberInput
                label={t('Phone Number')}
                name="phone_number"
                placeholder="(555) 987-6543"
                type="text"
                className="lg:w-1/2"
              />
            </div>
          </div>
          <div className="flex justify-end">
            <ButtonLoading
              type="submit"
              variant="primary"
              loading={isSubmitting}
              className="justify-center px-6 w-full sm:w-max"
            >
              {isLastStep ? t('Submit') : t('Next')}
            </ButtonLoading>
          </div>
        </Form>
      )}
    </Formik>
  );
};
