import { t } from '@nodal/i18n';

import type { ApiModel } from '@nodal/api';

export const conflictStateMessageMap = new Map<ApiModel.AddressState, string>([
  [
    'ID',
    t(
      'No pre-birth orders, Caution if using both donor egg and/or donor sperm.',
    ),
  ],
  [
    'FL',
    t(
      'No pre-birth orders, Caution if IPs are unmarried and/or using both donor egg and donor sperm, adoption is necessary.',
    ),
  ],
  [
    'HI',
    t(
      'No pre-birth orders, Caution if IPs are using donor egg and/or donor sperm, adoption is necessary.',
    ),
  ],
  [
    'KS',
    t(
      'No pre-birth orders, Caution if IPs are using donor egg and/or donor sperm, adoption is necessary.',
    ),
  ],
  [
    'MN',
    t(
      'Caution if IPs are unmarried and/or LGBTQ and/or using donor egg and/or donor sperm.',
    ),
  ],
  [
    'MS',
    t(
      'Caution if IPs are unmarried or LGBTQ or using both donor egg and donor sperm.',
    ),
  ],
  [
    'MO',
    t(
      'Caution if IPs are unmarried or LGBTQ or using both donor egg and donor sperm or single parent by choice using both donor egg and donor sperm.',
    ),
  ],
  ['TN', t('Caution if IPs are using donor egg or donor sperm.')],
  ['WY', t('No pre-birth orders. Caution in all cases.')],
  ['AZ', t('Caution in all cases.')],
  ['IN', t('Caution in all cases.')],
  ['IA', t('Caution in all cases.')],
  ['VA', t('Caution in all cases.')],
  [
    'AL',
    t(
      'Adoption may be necessary if IPs are not married. Caution if neither IP shares a genetic connection with the embryo.',
    ),
  ],
  ['AK', t('Caution if IPs are LGBTQ and neither are related to the embryo.')],
  [
    'AR',
    t(
      'Caution if IPs are unmarried because only the genetically related IP will get a pre-birth order and the IP with no genetic connection will need to adopt.',
    ),
  ],
  ['GA', t('Caution if IPs are unmarried or LGBTQ.')],
  ['IL', t('Adoption necessary if using both donor egg and donor sperm.')],
  [
    'KY',
    t(
      'Caution if IPs are unmarried and/or if IPs have no genetic connection to the embryo.',
    ),
  ],
  [
    'MT',
    t(
      'Caution if IPs are unmarried and using donor material and for married IPs with no genetic connection to the embryo.',
    ),
  ],
  ['NM', t('Caution if single parent by choice.')],
  ['NC', t('Caution if IPs are unmarried.')],
  ['ND', t('Caution if using both donor egg and donor sperm.')],
  ['OH', t('Caution, pre-birth possible but varies by judge.')],
  ['OK', t('Caution if using both donor egg and donor sperm.')],
  ['SC', t('Caution if LGBTQ or using both donor egg and donor sperm.')],
  [
    'TX',
    t('Caution if IPs are unmarried or using donor egg and/or donor sperm.'),
  ],
  ['UT', t('Caution if IPs are unmarried.')],
  ['WI', t('Caution, pre-birth possible but varies by county.')],
  ['WV', t('Caution if IPs are unmarried.')],
]);
