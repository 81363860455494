import { ChevronRightIcon } from '@heroicons/react/solid';
import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useSearchFilterTable } from '@b2b/components/SearchFilterTable';
import { donorRoleKeys } from '@b2b/consts/candidateRoles';
import { appPaths, organizationPaths as paths } from '@b2b/consts/paths';

import { useOrganizationDetails } from '../OrganizationDetails.connect';

import { InvitedCandidates } from './InvitedCandidates';
import {
  calulateTotalActive,
  colorByStatus,
  getCandidateStepStatus,
  getDonorStatusCounts,
  labelByStatus,
} from './utils';

import type { StatusCount } from './InvitedCandidates.interface';
import type { ApiModel, QueryParameters } from '@nodal/api';

// NOTE: This represents the default ordering key for API queries, which is not user-selectable in the UI/UX.
// This key is always included in the payload when calling the API.
const immutableOrderingKey = 'display_priority';

const columnHelper = createColumnHelper<ApiModel.B2BUserNonDetailedList>();

const columns = [
  columnHelper.accessor((row) => `${row.first_name} ${row.last_name}`, {
    id: 'search_name_display',
    cell: (info) => (
      <span className="text-grey-forest-700">{info.getValue()}</span>
    ),
    header: () => t('Name'),
  }),
  columnHelper.accessor(
    (row) => (row.date_joined ? format(new Date(row.date_joined), 'P') : null),
    {
      id: 'date_joined',
      header: () => t('Date of join'),
      cell: (info) => info.renderValue(),
    },
  ),
  columnHelper.accessor(
    (row) => {
      const medicalReviewStatus = getCandidateStepStatus({
        status: row.status,
        medicalReview: row.medical_review as unknown as ApiModel.ReviewNested,
      });

      return (
        <div className={colorByStatus[medicalReviewStatus]}>
          {labelByStatus[medicalReviewStatus]}
        </div>
      );
    },
    {
      id: 'status',
      header: () => t('Step Status'),
      cell: (info) => info.renderValue(),
      enableSorting: false,
    },
  ),

  columnHelper.accessor(
    (row) => (
      <div className="mx-auto w-6 h-6 fill-grey-forest-500">
        <Link to={`${paths.app}/${appPaths.candidate}/${row.id}`}>
          <ChevronRightIcon className="w-full" />
        </Link>
      </div>
    ),
    {
      id: 'action',
      cell: (info) => info.renderValue(),
      header: () => '',
      enableSorting: false,
    },
  ),
];

export const InvitedCandidatesConnected = () => {
  const { organization, users } = useOrganizationDetails();
  const apiClient = useApiClient();

  const initialQueryParameters: QueryParameters<ApiModel.B2bApiB2bUsersNonDetailedListRequest> =
    {
      page: 1,
      pageSize: 10,
      ordering: [immutableOrderingKey, '-date_joined'],
      search: '',
      filters: {
        organizationId: organization.id,
        role: donorRoleKeys,
      },
    };

  const queryCallback = (
    query: QueryParameters<ApiModel.B2bApiB2bUsersNonDetailedListRequest>,
  ) =>
    apiClient.api.B2bApi.b2bUsersNonDetailedList({
      ...query.filters,
      ...query,
      searchNameDisplay: query.search,
      // NOTE: Use casting because ordering type (string[]) in QueryParameters
      // is less specific than in the API Schema
    } as ApiModel.B2bApiB2bUsersNonDetailedListRequest);

  const table = useSearchFilterTable<
    ApiModel.B2BUserNonDetailedList,
    ApiModel.B2bApiB2bUsersNonDetailedListRequest
  >({
    initialQuery: initialQueryParameters,
    columns,
    queryKey: queryKeys.usersNonDetailedList,
    queryCallback,
    urlSearchParamsEnabled: false,
    immutableOrdering: [immutableOrderingKey],
  });

  const statusCounts: StatusCount[] = getDonorStatusCounts(users);

  const totalActive = calulateTotalActive(statusCounts);

  const onPageChange = useCallback(
    (page: number) =>
      table.onQueryChange({
        ...table.query,
        page,
      }),
    [table],
  );

  return (
    <InvitedCandidates
      statusCounts={statusCounts}
      totalActive={totalActive}
      page={table.query.page}
      onPageChange={onPageChange}
      {...table}
    />
  );
};
