import type { FC } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { Fragment } from 'react';

import type { DropdownMenuProps } from './DropdownMenu.interface';

export const DropdownMenu: FC<DropdownMenuProps> = ({ menuItems }) => (
  <Menu as="div" className="inline-block relative text-left">
    {({ open }) => (
      <>
        <div>
          <Menu.Button className="flex items-center">
            <span className="sr-only">Open menu items</span>
            <div className="p-2.5 text-grey-forest-500 bg-white hover:bg-grey-forest-100 rounded-lg border border-grey-forest-300 outline-none">
              <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
            </div>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          show={open}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 py-1 mt-2 w-80 bg-white rounded-md outline-none ring-1 ring-black/5 shadow-lg origin-top-right">
            <div className="p-2 pb-3 space-y-1">
              {menuItems.map(({ label, onClick }) => (
                <Menu.Item key={label}>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames(
                        active
                          ? 'bg-beige-200 text-grey-forest-900'
                          : 'text-grey-forest-700',
                        'block w-full px-3 py-2 text-left text-sm focus:outline-none',
                      )}
                      onClick={onClick}
                    >
                      {label}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </>
    )}
  </Menu>
);
