import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';

import type { RequestSubmittedConfirmationProps } from './RequestSubmittedConfirmation.interface';

export const RequestSubmittedConfirmation: FC<
  RequestSubmittedConfirmationProps
> = ({ onNext }) => {
  return (
    <div className="py-4 px-8">
      <p className="text-grey-forest-600 sm:text-sm">
        {t(
          'The new request has been submitted and the candidate will receive an email link to begin screening. Candidate status updates can be found in your dashboard and candidate detail view.',
        )}
      </p>
      <br />
      <p className="text-grey-forest-600 sm:text-sm">
        {t(
          'To finalize the request and view the completed screening, please sign the contract and submit payment.',
        )}
      </p>
      <div className="flex justify-end">
        <Button
          variant="primary"
          className="justify-center mt-6 ml-auto w-full sm:w-max "
          onClick={onNext}
        >
          {t('Next')}
        </Button>
      </div>
    </div>
  );
};
