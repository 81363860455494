import { ChevronRightIcon } from '@heroicons/react/solid';
import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

import {
  SearchFilterTable,
  useSearchFilterTable,
} from '@b2b/components/SearchFilterTable';
import {
  convertUrlToParsedQuery,
  transformUrlQueryToUiFilterQuery,
} from '@b2b/components/SearchFilterTable/utils';
import { appPaths, organizationPaths as paths } from '@b2b/consts/paths';
import { getProfileDisplayName } from '@b2b/utils/candidate';
import { introductionStageOptions } from '@b2b/utils/introduction';

import { filtersDetails, initialFilters } from './filters';

import type { ApiModel, QueryParameters } from '@nodal/api';

const columnHelper = createColumnHelper<ApiModel.B2bIntroduction>();

// NOTE: This represents the default ordering key for API queries, which is not user-selectable in the UI/UX.
// This key is always included in the payload when calling the API.
const immutableOrderingKey = 'display_priority';

const columns = [
  columnHelper.accessor('id', {
    cell: (info) => info.getValue(),
    enableSorting: false,
  }),
  columnHelper.accessor(
    (row) =>
      getProfileDisplayName({
        ...row,
        first_name: row.donor_first_name,
        last_name: row.donor_last_name,
      }),
    {
      id: 'donor_first_name',
      cell: (info) => (
        <span className="text-grey-forest-700">{info.getValue()}</span>
      ),
      header: () => t('Surrogate'),
    },
  ),
  columnHelper.accessor(
    (row) =>
      getProfileDisplayName({
        ...row,
        partner_first_name: row.parent_partner_first_name,
        partner_last_name: row.parent_partner_last_name,
        first_name: row.parent_first_name,
        last_name: row.parent_last_name,
      }),
    {
      id: 'parent_first_name',
      cell: (info) => (
        <span className="text-grey-forest-700">{info.getValue()}</span>
      ),
      header: () => t('Intended Parents'),
    },
  ),
  columnHelper.accessor(
    (row) =>
      introductionStageOptions.find(({ value }) => value === row.stage)?.label,
    {
      id: 'stage',
      header: () => t('Phase'),
      cell: (info) => info.renderValue(),
    },
  ),
  columnHelper.accessor(
    (row) =>
      row.expiration_date ? format(new Date(row.expiration_date), 'P') : null,
    {
      id: 'expiration_date',
      header: () => t('Date Expires'),
      cell: (info) => info.renderValue(),
    },
  ),
  columnHelper.accessor(
    (row) => (
      <div className="mx-auto w-6 h-6 fill-grey-forest-500">
        <Link to={`${paths.app}/${appPaths.introduction}/${row.id}`}>
          <ChevronRightIcon className="w-full" />
        </Link>
      </div>
    ),
    {
      id: 'action',
      cell: (info) => info.renderValue(),
      header: () => '',
      enableSorting: false,
    },
  ),
];

export const IntroductionsConnected = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const initialQueryParameters: QueryParameters<ApiModel.B2bApiB2bIntroductionsListRequest> =
    {
      page: 1,
      pageSize: 10,
      ordering: [immutableOrderingKey, 'stage'],
      search: '',
      filters: initialFilters,
    };

  const queryCallback = (
    query: QueryParameters<ApiModel.B2bApiB2bIntroductionsListRequest>,
  ) =>
    apiClient.api.B2bApi.b2bIntroductionsList({
      ...query.filters,
      ...query,
      donorParentPartnerName: query.search,
      // NOTE: Use casting because ordering type (string[]) in QueryParameters
      // is less specific than in the API Schema
    } as ApiModel.B2bApiB2bIntroductionsListRequest);

  const urlQueryParameters = location.search
    ? convertUrlToParsedQuery(location.search)
    : null;

  const table = useSearchFilterTable<
    ApiModel.B2bIntroduction,
    ApiModel.B2bApiB2bIntroductionsListRequest
  >({
    initialQuery: urlQueryParameters
      ? transformUrlQueryToUiFilterQuery<ApiModel.B2bApiB2bIntroductionsListRequest>(
          urlQueryParameters,
          initialQueryParameters.filters,
        )
      : initialQueryParameters,
    columns,
    queryKey: queryKeys.b2bIntroductionsList,
    queryCallback,
    urlSearchParamsEnabled: true,
    immutableOrdering: [immutableOrderingKey],
    filters: {
      details: filtersDetails,
      initialValues: initialFilters,
      title: t('Introduction Filters'),
    },
  });

  useEffect(() => {
    if (table.queryStringParams) {
      navigate(`?${table.queryStringParams}`);
    }
  }, [navigate, table.queryStringParams]);

  useEffect(() => {
    return () =>
      queryClient.removeQueries(queryKeys.usersIntroductionsRetrieve);
  }, [queryClient]);

  return (
    <div className="flex relative flex-col col-span-12 w-full h-full font-avenir-next text-sm font-normal leading-5">
      <div className="w-full bg-white">
        <div className="flex gap-6 justify-center items-center p-6 mx-auto max-w-7xl sm:justify-between md:px-6 lg:px-8">
          <h2 className="hidden text-2xl leading-7 text-gray-900 sm:block">
            {t('Introductions')}
          </h2>
        </div>
      </div>
      <div className="overflow-x-auto px-4 mx-auto w-full max-w-7xl min-h-[400px] sm:px-6 lg:px-8">
        <SearchFilterTable {...table} />;
      </div>
    </div>
  );
};
