import type { FC } from 'react';

import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { capitalize } from 'lodash';

import { useExternalPayerApp } from '@b2b/app/ExternalPayerAppProtected';
import {
  PaymentFlow,
  PaymentFlowProvider,
  useClientSecret,
} from '@b2b/components/PaymentFlow';

import type { PaymentFlowConnectedProps } from './PaymentFlow.interface';

export const PaymentFlowConnected: FC<PaymentFlowConnectedProps> = ({
  completePath,
}) => {
  const { request } = useExternalPayerApp() || {};

  const { clientSecret } = useClientSecret(request?.id);

  const screeningPayment = request?.screening_payment;

  if (!screeningPayment || !clientSecret) return <LoadingScreen />;

  const planPackage = {
    title: `${capitalize(screeningPayment.package.name)} Screening`,
    features: screeningPayment.package.description_entries || [],
  };

  return (
    <PaymentFlowProvider completePath={completePath} requestId={request.id}>
      <PaymentFlow
        planPackage={planPackage}
        planPrice={screeningPayment.package.screening_price}
        clientSecret={clientSecret}
      />
    </PaymentFlowProvider>
  );
};
