import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useEffect, createContext, useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { appPaths, organizationPaths as paths } from '@b2b/consts/paths';

import { OrganizationDetails } from './OrganizationDetails';

import type { ApiModel } from '@nodal/api';

const OrganizationContext = createContext<
  | {
      organization: ApiModel.Organization;
      users: ApiModel.B2BUserNonDetailedList[];
    }
  | undefined
>(undefined);

export const useOrganizationDetails = () => {
  const context = useContext(OrganizationContext);

  if (!context) {
    throw new Error(
      'useOrganizationDetails must be used within a OrganizationContext',
    );
  }

  return { ...context };
};

export const OrganizationDetailsConnected = () => {
  const { id } = useParams() as { id: string };
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.removeQueries('organizationsRetrieve');
    };
  }, [queryClient]);

  const { data: organizationData } = useQuery(
    'organizationsRetrieve',
    () =>
      apiClient.api.B2bApi.b2bOrganizationsRetrieve({
        id: Number.parseInt(id),
      }),
    {
      refetchOnMount: false,
      // TODO: Temp. workaround with 'any' type - need to change on proper type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error?.request?.status === 404) {
          navigate(`${paths.app}/${appPaths.organizations}`);
        }
      },
    },
  );

  const { data: usersNonDetailedList } = useQuery(
    queryKeys.usersNonDetailedList,
    () =>
      apiClient.api.B2bApi.b2bUsersNonDetailedList({
        organizationId: organizationData?.data?.id,
        // TODO: Temp. workaround with defining max page size (100)
        // At the moment, it will only work if the organization has a maximum of 100 users.
        // This will be fixed by introducing new endpoint that will return number of users statuses for given organization
        // Ref: https://linear.app/nodal-health/issue/NOD-858/feadmin-integrate-new-api-for-calculating-user-statuses-in-organizaton
        pageSize: 100,
      }),
    {
      enabled: !!organizationData?.data?.id,
    },
  );

  if (!organizationData?.data || !usersNonDetailedList?.data?.results)
    return <LoadingScreen />;

  return (
    <OrganizationContext.Provider
      value={{
        organization: organizationData.data,
        users: usersNonDetailedList.data.results,
      }}
    >
      <OrganizationDetails
        organizationType={organizationData.data.organization_type}
      />
    </OrganizationContext.Provider>
  );
};
