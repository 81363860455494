import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { states } from '@nodal/core/consts/states';
import { isInRelationship } from '@nodal/core/utils/profile';
import { t } from '@nodal/i18n';
import { AddressInput } from '@nodal/uikit/components/AddressInput';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { Checkbox } from '@nodal/uikit/components/Checkbox';
import { PhoneNumberInput } from '@nodal/uikit/components/PhoneNumberInput';
import { ProgressBar } from '@nodal/uikit/components/ProgressBar';
import { Select } from '@nodal/uikit/components/Select';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Form, Formik } from 'formik';

import type { ParentsEditProfileFormProps } from './ParentsEditProfileForm.interface';
import type { Address } from '@nodal/uikit/models';

export const relationshipStatusChoices = [
  {
    label: t('Single'),
    value: apiEnums.RelationshipStatusEnum.Sin,
  },
  {
    label: t('In relationship'),
    value: apiEnums.RelationshipStatusEnum.Rel,
  },
  {
    label: t('Engaged'),
    value: apiEnums.RelationshipStatusEnum.Eng,
  },
  {
    label: t('Married'),
    value: apiEnums.RelationshipStatusEnum.Mar,
  },
  {
    label: t('Divorced'),
    value: apiEnums.RelationshipStatusEnum.Div,
  },
  {
    label: t('Separated'),
    value: apiEnums.RelationshipStatusEnum.Sep,
  },
  {
    label: t('Widowed'),
    value: apiEnums.RelationshipStatusEnum.Wid,
  },
  {
    label: t('Other'),
    value: apiEnums.RelationshipStatusEnum.Oth,
  },
];

export const ParentsEditProfileForm: FC<ParentsEditProfileFormProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  uploadProgress,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, setFieldValue, setFieldTouched, values }) => (
        <Form className="flex flex-col justify-start items-start w-full h-full">
          <div className="overflow-y-auto py-4 px-8 w-full max-h-full sm:max-h-100-modal">
            <div className="flex flex-col grid-cols-6 gap-x-4 gap-y-10">
              <TextInput
                label={t('First Name')}
                name="first_name"
                type="text"
              />
              <TextInput
                label={t('Middle Name')}
                name="middle_name"
                type="text"
              />
              <TextInput label={t('Last Name')} name="last_name" type="text" />
              <Select
                label={t('Relationship status')}
                name="relationship_status"
                options={relationshipStatusChoices}
                className="lg:w-1/2"
              />
              {isInRelationship(values.relationship_status) && (
                <>
                  <div className="relative">
                    <div
                      className="flex absolute inset-0 items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="flex relative justify-start">
                      <span className="pr-3 text-base font-semibold leading-7 text-gray-900 bg-white">
                        {t('Partner')}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-x-4 gap-y-10">
                    <TextInput
                      label={t('First Name')}
                      name="partner_first_name"
                      type="text"
                    />
                    <TextInput
                      label={t('Middle Name')}
                      name="partner_middle_name"
                      type="text"
                    />
                    <TextInput
                      label={t('Last Name')}
                      name="partner_last_name"
                      type="text"
                    />
                  </div>
                </>
              )}
              <div className="col-span-6">
                <div className="relative">
                  <div
                    className="flex absolute inset-0 items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="flex relative justify-start">
                    <span className="pr-3 text-base font-semibold leading-7 text-gray-900 bg-white">
                      {t('Contact Details')}
                    </span>
                  </div>
                </div>
              </div>
              <PhoneNumberInput
                label={t('Phone Number')}
                name="phone_number"
                placeholder="(555) 987-6543"
                type="text"
                className="lg:w-1/2"
              />
              <AddressInput
                name="address"
                onChange={(address: Address) =>
                  Object.entries(address).forEach(([key, value]) => {
                    setFieldValue(`address.${key}`, value);
                    setFieldTouched(`address.${key}`, false);
                  })
                }
                states={states}
              />
              {isInRelationship(values.relationship_status) && (
                <>
                  <div className="relative">
                    <div
                      className="flex absolute inset-0 items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="flex relative justify-start">
                      <span className="pr-3 text-base font-semibold leading-7 text-gray-900 bg-white">
                        {t('Partner')}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-x-4 gap-y-10">
                    <PhoneNumberInput
                      label={t('Phone Number')}
                      className="lg:w-1/2"
                      name="partner_phone_number"
                      placeholder="(555) 987-6543"
                      type="text"
                    />
                    <Checkbox
                      name="same_partner_address"
                      label={t('Same as mine')}
                    />
                    <AddressInput
                      name="partner_address"
                      onChange={(address: Address) =>
                        Object.entries(address).forEach(([key, value]) => {
                          setFieldValue(`partner_address.${key}`, value);
                          setFieldTouched(`partner_address.${key}`, false);
                        })
                      }
                      states={states}
                      disabled={values.same_partner_address}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <ProgressBar progress={uploadProgress} />
          <div className="py-3 px-8 ml-auto w-full text-right border-t border-grey-forest-200">
            <ButtonLoading
              type="submit"
              variant="primary"
              className="justify-center ml-auto w-full sm:w-max"
              loading={isSubmitting}
            >
              {t('Submit')}
            </ButtonLoading>
          </div>
        </Form>
      )}
    </Formik>
  );
};
