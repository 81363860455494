import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { findIndex } from 'lodash';
import { useCallback, useContext } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { usePermissions } from '@b2b/app/PermissionsProvider';
import { CandidateRequestContext } from '@b2b/components/CandidateRequest/CandidateRequest.connect';
import { getAvailableRequestSteps } from '@b2b/components/CandidateRequest/utils';
import {
  appPaths,
  organizationPaths as paths,
  requestPaths,
} from '@b2b/consts/paths';
import { buildRequestSteps } from '@b2b/utils/candidateRequest';
import { getCurrentOrganization } from '@b2b/utils/organization';

import type { GlobalError } from '@nodal/api';

export const useCandidateRequest = () => {
  const {
    updateCurrentStepIndex,
    updateSteps,
    started,
    updateStarted,
    completed,
    updateCompleted,
    steps,
    currentStepIndex,
    requestId,
    updateRequestId,
  } = useContext(CandidateRequestContext);

  const apiClient = useApiClient();
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  const { data: organizationsList } = useQuery(
    queryKeys.organizationsList,
    () => apiClient.api.B2bApi.b2bOrganizationsList(),
    {
      refetchOnMount: false,
    },
  );

  const requestRetrieve = useQuery(
    queryKeys.requestRetrieve,
    () =>
      apiClient.api.B2bApi.b2bInvitationsRetrieve({
        id: requestId!,
      }),
    {
      enabled: !!requestId,
      cacheTime: 0,
      onError: (error: GlobalError) => {
        if (error?.request?.status === 404) {
          // NOTE: if no request with id was found, redirect to create a new request
          navigate(`${paths.app}/${appPaths.request}/${requestPaths.new}`);
        }
      },
    },
  );

  const refresh = useCallback(
    async ({ activeIndex }: { activeIndex?: number }) => {
      const { data: requestData } = await requestRetrieve.refetch();
      const request = requestData?.data;

      const initialSteps = getAvailableRequestSteps({
        organization: getCurrentOrganization(organizationsList?.data),
        request,
        canSelectOrganization: !!permissions?.canSelectOrganizationForCandidate,
        canSelectScreeningPackage: !!permissions?.canSelectScreeningPackage,
      });

      const activeSteps = steps?.length ? steps : initialSteps;

      const stepIndex = findIndex(activeSteps, (step) =>
        step.isCurrentStep(request),
      );

      if (stepIndex === -1) {
        updateCompleted(true);
        return;
      }

      const requestSteps = buildRequestSteps({
        currentStepIndex: stepIndex,
        steps: activeSteps,
      });

      updateCurrentStepIndex(activeIndex ?? stepIndex);
      updateSteps(requestSteps);

      if (stepIndex !== 0) {
        updateStarted(true);
      }
    },
    [
      updateCurrentStepIndex,
      updateSteps,
      updateCompleted,
      updateStarted,
      requestRetrieve,
      steps,
      organizationsList?.data,
      permissions,
    ],
  );

  // NOTE: triggered at the end of each step to complete it and move to the next one,
  // responsible for updating data for candidate request (steps, current step index, completed)
  // and navigation
  const finishStep = useCallback(async () => {
    const lastStepIndex = currentStepIndex === steps.length - 1;

    if (lastStepIndex) {
      navigate(`${paths.app}/${appPaths.candidates}`);
    } else {
      await refresh({ activeIndex: undefined });

      navigate(
        `${paths.app}/${appPaths.request}/${requestPaths.update}/${requestId}`,
      );
    }
  }, [navigate, refresh, currentStepIndex, steps, requestId]);

  return {
    steps,
    currentStepIndex,
    started,
    completed,
    refresh,
    finishStep,
    request: requestRetrieve?.data?.data,
    updateRequestId,
  };
};
