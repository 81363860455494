import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { PaymentSummaryCard } from '@nodal/uikit/components/Payment';

import { PaymentForm } from './PaymentForm';
import { Plan } from './Plan';

import type { PaymentFlowProps } from './PaymentFlow.interface';

export const PaymentFlow: FC<PaymentFlowProps> = ({
  planPrice,
  planPackage,
  clientSecret,
}) => {
  return (
    <div className="flex flex-col gap-4 w-full lg:w-step-view">
      <Plan name={t('Screening Package')} planPackage={planPackage} />
      <PaymentSummaryCard
        subtotal={planPrice.amount}
        fees={planPrice.card_payment_fees || undefined}
      />
      <div className="py-4 px-8 space-y-6 bg-white rounded-lg border border-grey-forest-200 shadow-sm">
        <p className="text-lg font-medium leading-6 text-grey-forest-900">
          {t('Payment Information')}
        </p>
        <PaymentForm clientSecret={clientSecret} />
      </div>
    </div>
  );
};
