import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { Form, Formik } from 'formik';

import type { NewBackgroundCheckFormProps } from './NewBackgroundCheckForm.interface';

export const NewBackgroundCheckForm: FC<NewBackgroundCheckFormProps> = ({
  onSubmit,
  initialValues,
  validationSchema,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form className="flex flex-col justify-start items-start w-full h-full">
          <div className="p-8 w-full h-max">
            <TextInput
              name="email"
              type="text"
              placeholder={t('Insert email address')}
            />
          </div>
          <div className="py-3 px-8 ml-auto w-full text-right border-t border-grey-forest-200">
            <ButtonLoading
              type="submit"
              variant="primary"
              className="justify-center ml-auto w-full sm:w-max"
              loading={isSubmitting}
            >
              {t('Send')}
            </ButtonLoading>
          </div>
        </Form>
      )}
    </Formik>
  );
};
