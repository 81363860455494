import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { Table } from '@nodal/uikit/components/Table';
import classNames from 'classnames';

import { colorByStatus, labelByStatus } from './utils';

import type { InvitedCandidatesProps } from './InvitedCandidates.interface';

export const InvitedCandidates: FC<InvitedCandidatesProps> = ({
  statusCounts,
  totalActive,
  ...table
}) => {
  return (
    <div className="p-8 w-full max-w-full bg-white rounded-lg">
      <div className="flex flex-col gap-6 divide-y divide-grey-forest-100">
        <div className="flex flex-wrap gap-6 justify-between">
          <h3 className="text-xl font-medium text-grey-forest-900">
            {t('Candidates')}
          </h3>

          <div className="flex flex-wrap gap-6 items-center">
            <div className="flex gap-2 justify-between items-center">
              <p className="text-sm font-medium text-grey-forest-900">
                {t('Total Active')}
              </p>
              <div className="py-1.5 px-3 bg-grey-forest-50 rounded-full">
                <p className="text-sm font-medium text-grey-forest-900">
                  {totalActive}
                </p>
              </div>
            </div>
            {statusCounts.map(({ status, count }) => (
              <div
                className="flex gap-2 justify-between items-center"
                key={status}
              >
                <p
                  className={classNames(
                    'text-sm font-medium',
                    colorByStatus[status],
                  )}
                >
                  {labelByStatus[status]}
                </p>
                <div className="py-1.5 px-3 bg-grey-forest-50 rounded-full">
                  <p
                    className={classNames(
                      'text-sm font-medium',
                      colorByStatus[status],
                    )}
                  >
                    {count}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-6 whitespace-pre-wrap md:grid md:grid-cols-3">
          <div className="overflow-x-auto col-span-3 gap-x-6 gap-y-2 break-all">
            <Table {...table} />
          </div>
        </div>
      </div>
    </div>
  );
};
