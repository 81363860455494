import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { screenTypes } from '@nodal/core/consts/screenTypes';
import { t } from '@nodal/i18n';
import { useConfirmationDialog } from '@nodal/uikit/components/ConfirmationDialog';
import { useMutation, useQueryClient } from 'react-query';

import { useFeatureFlags } from '@b2b/app/FeatureFlagsProvider';
import { usePermissions } from '@b2b/app/PermissionsProvider';
import { screenStatusToLabel } from '@b2b/utils/candidate';

import { useCandidate } from '../CandidateDetails.connect';

import { getManualApprovalEnabled } from './utils';

import type { ManualScreenApproval } from './ManualScreenApproval.interface';
import type { ApiModel } from '@nodal/api';
import type { ScreenType } from '@nodal/core/consts/screenTypes';

const manualScreenApprovalValueToScreenStatus = new Map<
  string | undefined,
  ApiModel.ScreenStatusEnum
>([
  ['true', apiEnums.ScreenStatusEnum.App],
  ['false', apiEnums.ScreenStatusEnum.Rej],
]);

const screenStatusTypeToTitle = new Map<ScreenType, string>([
  [screenTypes.parentsQuestionnaire, t('Nodal Application')],
  [screenTypes.donorQuestionnaire, t('Nodal Application')],
  [screenTypes.stripeIdentity, t('Identity Verifiction')],
  [screenTypes.checkr, t('Background Check')],
  [screenTypes.hipaaConsentForm, t('Hipaa and Consent Form')],
]);

export const useManualScreenApproval = ({
  status,
  id,
  type,
}: {
  status?: ApiModel.ScreenStatusEnum;
  id?: number;
  type?: string;
}): ManualScreenApproval => {
  const { permissions } = usePermissions();
  const { features } = useFeatureFlags();
  const confirm = useConfirmationDialog();
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { candidate } = useCandidate();

  const updateStatus = useMutation(
    (request: ApiModel.ScreensApiScreensPartialUpdateRequest) =>
      apiClient.api.ScreensApi.screensPartialUpdate(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          queryKeys.candidateRetrieve,
          // component where mutation is called wouldn't be rendered if there is no candidate
          candidate!.id,
        ]);
      },
    },
  );

  const manuallyChangeScreenStatus = async ({
    updatedStatus,
  }: {
    updatedStatus?: ApiModel.ScreenStatusEnum;
    screen?: ApiModel.ScreenNested;
  }) => {
    const title = screenStatusTypeToTitle.get(type as ScreenType)!;

    if (updatedStatus && status) {
      const confirmed = await confirm({
        title: t('Update {screen} Status', title),
        message: t(
          'Are you sure you want to update the status manually from {oldStatus} to {newStatus} for this screen?',
          screenStatusToLabel.get(status)!,
          screenStatusToLabel.get(updatedStatus)!,
        ),
        variant: 'question',
      });

      if (confirmed && candidate?.id) {
        await updateStatus.mutateAsync({
          id: id!,
          patchedScreen: { status: updatedStatus, manual_check: true },
        });
      }
    }
  };

  const onManualApprove = async (value?: string) => {
    const updatedStatus: ApiModel.ScreenStatusEnum | undefined =
      manualScreenApprovalValueToScreenStatus.get(value);

    await manuallyChangeScreenStatus({
      updatedStatus,
    });
  };

  return {
    onManualApprove,
    manualApprovalEnabled: getManualApprovalEnabled(permissions, features),
  };
};
