import type { FC } from 'react';

import { FlagIcon } from '@heroicons/react/solid';
import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { Button } from '@nodal/uikit/components/Button';
import { RadioGroup } from '@nodal/uikit/components/RadioGroup/RadioGroup';
import { Link } from 'react-router-dom';

import { candidateDetailsPaths as paths } from '@b2b/consts/paths';
import { candidateRoleToLabel } from '@b2b/utils/candidate';

import type { CandidateApplicationProps } from './CandidateApplication.interface';
import type { ApiModel } from '@nodal/api';

const roleToManualApproveTitle = new Map<
  ApiModel.UserRoleEnum | undefined,
  string
>([
  [apiEnums.UserRoleEnum.Par, t('Is IP cleared to continue?')],
  [apiEnums.UserRoleEnum.Dnr, t('Is surrogate cleared to continue?')],
  [apiEnums.UserRoleEnum.Odo, t('Is surrogate cleared to continue?')],
  [apiEnums.UserRoleEnum.Oed, t('Is surrogate cleared to continue?')],
  [apiEnums.UserRoleEnum.Osd, t('Is surrogate cleared to continue?')],
]);

const manualApproveOptions = [
  {
    value: 'true',
    label: t('Yes'),
  },
  {
    value: 'false',
    label: t('No'),
  },
];

const getManualApproveValue = (
  manuallyChecked?: boolean,
  status?: ApiModel.ScreenStatusEnum,
) => {
  if (manuallyChecked && status === apiEnums.ScreenStatusEnum.App) {
    return 'true';
  }

  if (manuallyChecked && status === apiEnums.ScreenStatusEnum.Rej) {
    return 'false';
  }

  return undefined;
};

export const CandidateApplication: FC<CandidateApplicationProps> = ({
  role,
  markedForReviewCount,
  status,
  autorejectCount,
  previewEnabled,
  manuallyChecked,
  manualScreenApproval: { onManualApprove, manualApprovalEnabled },
}) => {
  const roleLabel = candidateRoleToLabel.get(role);

  return (
    <>
      <div className="flex flex-col gap-4 w-full sm:grid sm:grid-cols-3 sm:items-center">
        <p className="col-start-1 text-sm  text-grey-forest-700">
          {roleLabel && t('Nodal {role} Application', roleLabel)}
        </p>
        {previewEnabled && (
          <>
            <div className="flex col-start-2 gap-4 text-sm font-medium text-forest-500">
              {markedForReviewCount ? (
                <div className="flex gap-1">
                  <span className="text-yellow-700">
                    {markedForReviewCount}{' '}
                  </span>
                  <FlagIcon className="w-5 h-5 fill-yellow-500" />
                </div>
              ) : null}
              {autorejectCount ? (
                <div className="flex gap-1">
                  <span className="text-red-700">{autorejectCount} </span>
                  <FlagIcon className="w-5 h-5 fill-red-700" />
                </div>
              ) : null}
            </div>
            <div className="col-start-3 sm:ml-auto">
              <Link to={paths.application}>
                <Button
                  variant="primary"
                  disabled={
                    !status || status === apiEnums.ScreenStatusEnum.Pend
                  }
                  className="sm:text-sm"
                >
                  {t('View application')}
                </Button>
              </Link>
            </div>
          </>
        )}
      </div>
      {manualApprovalEnabled && (
        <div className="flex flex-col gap-2 justify-center items-center mt-4 w-full sm:flex-row sm:gap-6 sm:justify-end sm:items-end sm:ml-auto">
          <p className="w-full text-sm font-medium text-center text-grey-forest-900 sm:w-fit">
            {roleToManualApproveTitle.get(role)}
          </p>
          <RadioGroup
            onChange={(e) => onManualApprove(e.target.value)}
            options={manualApproveOptions}
            name="candidate_application_manual_approve"
            type="range"
            value={getManualApproveValue(manuallyChecked, status)}
            disabled={
              status === apiEnums.ScreenStatusEnum.App && !manuallyChecked
            }
          />
        </div>
      )}
    </>
  );
};
