import type { FC, ReactElement } from 'react';

import { medicalReviewsUids } from '@nodal/core/consts/medicalReviewsUids';
import classNames from 'classnames';

import { ClinicRecords } from './ClinicRecords';
import { MfmClearance } from './MfmClearance';
import { RecordsPull } from './RecordsPull';
import { ReviewDecision } from './ReviewDecision';
import { RxDxHistory } from './RxDxHistory';

import type { MedicalReviewProps } from './MedicalReview.interface';
import type { MedicalReviewsUid } from '@nodal/core/consts/medicalReviewsUids';

const stageUidToElement: { [key in MedicalReviewsUid]: ReactElement | null } = {
  [medicalReviewsUids.donorMedicalRecordPull]: <RecordsPull />,
  [medicalReviewsUids.orgCandidateMedicalRecordPull]: <RecordsPull />,
  [medicalReviewsUids.donorReviewDecision]: <ReviewDecision />,
  [medicalReviewsUids.orgCandidateReviewDecision]: <ReviewDecision />,
  [medicalReviewsUids.donorMfmClearance]: <MfmClearance />,
  [medicalReviewsUids.orgCandidateMfmClearance]: <MfmClearance />,
  [medicalReviewsUids.donorRxDxHistory]: <RxDxHistory />,
  [medicalReviewsUids.orgCandidateRxDxHistory]: <RxDxHistory />,
  [medicalReviewsUids.orgCandidateClinicRecords]: <ClinicRecords />,
  [medicalReviewsUids.donorClinicRecords]: <ClinicRecords />,
};

export const MedicalReview: FC<MedicalReviewProps> = ({
  stages,
  supervisionAccess,
}) => {
  return (
    <div className="w-full divide-y divide-grey-forest-100">
      {stages.map(({ title, uid }) => (
        <div
          key={uid}
          className={classNames(
            {
              'grid grid-cols-2 sm:items-center': !supervisionAccess,
              'flex flex-col': supervisionAccess,
            },
            'gap-4 justify-between py-3 whitespace-pre-wrap sm:grid sm:grid-cols-[1fr_0.5fr_1.5fr]',
          )}
        >
          <p
            className={classNames(
              'col-start-1 row-start-1 text-sm text-grey-forest-700',
              {
                'row-span-2 text-center sm:text-left': supervisionAccess,
              },
            )}
          >
            {title}
          </p>
          {uid ? stageUidToElement[uid] : null}
        </div>
      ))}
    </div>
  );
};
