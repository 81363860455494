import { apiEnums } from '@nodal/api';
import { screenTypes } from '@nodal/core/consts/screenTypes';
import {
  transformDonorInitialValues,
  transformEggDonorInitialValues,
  transformSpermDonorInitialValues,
  getDonorQuestions,
  eggDonorQuestions,
  spermDonorQuestions,
  parentQuestions,
} from '@nodal/core/flows/Screening/FormQuestions';
import { t } from '@nodal/i18n';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePermissions } from 'app/PermissionsProvider';

import { useCandidate } from '@b2b/components/CandidateDetails';

import { useManualScreenApproval } from '../ManualScreenApproval/useManualScreenApproval';

import { CandidateApplication } from './CandidateApplication';

import type { CandidateApplicationValues } from './CandidateApplication.interface';
import type { ApiModel } from '@nodal/api';
import type { DonorQuestionnaireScreen } from '@nodal/core/flows/Screening/FormQuestions';

const candidateRoleToApplicationValues = new Map<
  ApiModel.UserRoleEnum,
  CandidateApplicationValues
>([
  [
    apiEnums.UserRoleEnum.Odo,
    {
      title: t('Surrogate Application'),
      questions: getDonorQuestions(false),
      initialFormatter: transformDonorInitialValues,
    },
  ],
  [
    apiEnums.UserRoleEnum.Oed,
    {
      title: t('Egg Donor Application'),
      questions: eggDonorQuestions,
      initialFormatter: transformEggDonorInitialValues,
    },
  ],
  [
    apiEnums.UserRoleEnum.Osd,
    {
      title: t('Sperm Donor Application'),
      questions: spermDonorQuestions,
      initialFormatter: transformSpermDonorInitialValues,
    },
  ],
  [
    apiEnums.UserRoleEnum.Dnr,
    {
      title: t('Surrogate Application'),
      questions: getDonorQuestions(false),
      initialFormatter: transformDonorInitialValues,
    },
  ],
  [
    apiEnums.UserRoleEnum.Par,
    {
      title: t('Intended Parent(s) Application'),
      questions: parentQuestions,
    },
  ],
  [
    apiEnums.UserRoleEnum.Nap,
    {
      title: t('Navigator Application'),
      questions: parentQuestions,
    },
  ],
]);

export const useCandidateApplication = () => {
  const { candidate } = useCandidate();
  const { permissions } = usePermissions();
  const navigate = useNavigate();

  const screen = candidate?.screens.find(
    ({ type }) =>
      type === screenTypes.parentsQuestionnaire ||
      type === screenTypes.donorQuestionnaire,
  );

  const manualScreenApproval = useManualScreenApproval({
    status: screen?.status,
    id: screen?.id,
    type: screen?.type,
  });

  useEffect(() => {
    if (
      candidate &&
      permissions &&
      (!screen || !permissions.canViewCandidateScreens)
    ) {
      // NOTE: Go back to the candidate detail overview page if this candidate
      // does not have this screen defined in the screening process or permissions to view it
      navigate('../');
    }
  }, [candidate, screen, navigate, permissions]);

  if (!candidate || !candidate.role || !screen) {
    return null;
  }

  const { content_object, id, status } = screen;

  // NOTE: Too many screen types defined in API types cause TS error
  // Expected type in this screen is DonorQuestionnaireScreen
  const screenContent = content_object as DonorQuestionnaireScreen;
  const { flags } = screenContent;

  const candidateValues = candidateRoleToApplicationValues.get(candidate.role);

  if (!candidateValues) {
    return null;
  }

  const { questions, initialFormatter, title } = candidateValues;

  const initialValues =
    screenContent && initialFormatter
      ? initialFormatter(screenContent)
      : fromPairs(
          questions.map((q) => [
            q.value,
            get(screenContent, q.value)?.toString() || q.default,
          ]),
        );

  return {
    values: { ...initialValues, id, flags },
    title,
    questions,
    status,
    manualScreenApproval,
  };
};

export const CandidateApplicationConnected = () => {
  const candidateApplication = useCandidateApplication();

  if (!candidateApplication) {
    return <LoadingScreen />;
  }

  return <CandidateApplication {...candidateApplication} />;
};
