import { addressSchema } from '@nodal/core/consts/addressSchema';
import { t } from '@nodal/i18n';
import * as Yup from 'yup';

export const schema = Yup.object({
  first_name: Yup.string().nullable().required(t('This field is required')),
  last_name: Yup.string().nullable().required(t('This field is required')),
  phone_number: Yup.number().nullable().required(t('This field is required')),
  address: addressSchema.required(),
});
