import type { ReactNode } from 'react';

import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { createContext, useContext } from 'react';
import { useQuery } from 'react-query';

import { getCurrentOrganization } from 'utils/organization';

import type { FeatureFlags } from './FeatureFlagsProvider.interface';

export const FeatureFlagsContext = createContext<{
  features?: FeatureFlags;
}>({
  features: undefined,
});

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

export const FeatureFlagsProvider = ({ children }: { children: ReactNode }) => {
  const apiClient = useApiClient();

  const { data: organizationsList, isLoading } = useQuery(
    queryKeys.organizationsList,
    () => apiClient.api.B2bApi.b2bOrganizationsList(),
  );

  if (isLoading) return <LoadingScreen />;

  const organization = getCurrentOrganization(organizationsList?.data);

  const features = {
    matching: !!organization?.feature_matching,
    invitations: !!organization?.feature_invitations,
    business:
      organization?.organization_type === apiEnums.OrganizationTypeEnum.Org,
    navigator: !!organization?.feature_navigator,
  };

  return (
    <FeatureFlagsContext.Provider value={{ features }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
