import type { FC } from 'react';

import { useGooglePlacesApi } from '@nodal/core/hooks/useGooglePlacesApi';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePermissions } from '@b2b/app/PermissionsProvider';
import { useCandidate } from '@b2b/components/CandidateDetails';

import { isMedicalRequestCreationAllowed } from '../utils';

import { MedicalRequest } from './MedicalRequest';

export const MedicalRequestConnected: FC = () => {
  const { permissions } = usePermissions();
  const navigate = useNavigate();
  const { candidate } = useCandidate();
  const placesApiReady = useGooglePlacesApi();

  useEffect(() => {
    // NOTE: Go back to the candidate details overview page if this candidate
    // didn't complete all required screens or the user doesn't have permission
    // to initiate the request
    if (
      (candidate &&
        !isMedicalRequestCreationAllowed(candidate.screens, candidate.role)) ||
      !permissions?.canInitiateMedicalRequest
    ) {
      navigate('../');
    }
  }, [candidate, navigate, permissions?.canInitiateMedicalRequest]);

  if (!candidate || !placesApiReady) {
    return <LoadingScreen />;
  }

  return <MedicalRequest />;
};
