import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { useConfirmationDialog } from '@nodal/uikit/components/ConfirmationDialog';
import { useModal } from '@nodal/uikit/components/Modal';
import { useMutation, useQueryClient } from 'react-query';

import { useFeatureFlags } from 'app/FeatureFlagsProvider';
import { usePermissions } from 'app/PermissionsProvider';
import { useCandidate } from 'components/CandidateDetails/CandidateDetails.connect';
import {
  getCandidateStatusOptionsByOrganization,
  userStatusToLabel,
} from 'utils/candidate';

import { getCurrentOrganization } from '@b2b/utils/organization';

import { CandidateInformationBar } from './CandidateInformationBar';
import { DisqualificationForm } from './DisqualificationForm';
import { useSupportTeam } from './useSupportTeam';

import type { ApiModel } from '@nodal/api';

export const CandidateInformationBarConnected = () => {
  const apiClient = useApiClient();
  const { candidate } = useCandidate();
  const { permissions } = usePermissions();
  const { features } = useFeatureFlags();
  const queryClient = useQueryClient();
  const confirm = useConfirmationDialog();
  const { open, close } = useModal();
  const {
    supportTeamUsersList,
    showSupportTeamUserDropdown,
    canEditSupportStaffDropdown,
    onAssignSupportTeamUser,
    supportTeamUsersOptions,
    usersSupportTeamUser,
  } = useSupportTeam();

  const userDisqualifyMutation = useMutation(
    (request: ApiModel.B2bApiB2bUserDisqualifyPartialUpdateRequest) =>
      apiClient.api.B2bApi.b2bUserDisqualifyPartialUpdate(request),
    {
      onSuccess: (...[, variables]) => {
        queryClient.invalidateQueries([
          queryKeys.candidateRetrieve,
          variables.id,
        ]);
        close();
      },
    },
  );

  const userUpdateMutation = useMutation(
    (request: ApiModel.B2bApiB2bUsersPartialUpdateRequest) =>
      apiClient.api.B2bApi.b2bUsersPartialUpdate(request),
    {
      onSuccess: (...[, variables]) => {
        queryClient.invalidateQueries([
          queryKeys.candidateRetrieve,
          variables.id,
        ]);
      },
    },
  );

  if (
    !candidate ||
    !candidate.status ||
    !permissions ||
    (showSupportTeamUserDropdown && !supportTeamUsersList)
  )
    return null;

  const handleUserDisqualify = async (
    submitData: ApiModel.PatchedB2BUserDisqualify,
  ) => {
    await userDisqualifyMutation.mutateAsync({
      id: candidate.id,
      patchedB2BUserDisqualify: {
        disqualification_reason: submitData.disqualification_reason,
        disqualification_reason_optional_details:
          submitData.disqualification_reason_optional_details,
        status: apiEnums.UserStatusEnum.Dis,
      },
    });
  };

  const showUserDisqualifyModal = async () => {
    const { status } = candidate;

    if (status) {
      open({
        title: t('Update Status'),
        modalVariant: 'confirmation',
        render: () => (
          <DisqualificationForm
            message={t(
              'Are you sure you want to update the status from {oldStatus} to {newStatus} for this candidate?',
              userStatusToLabel[status],
              userStatusToLabel['dis'],
            )}
            onSubmit={handleUserDisqualify}
            onClose={close}
          />
        ),
      });
    }
  };

  const handleStatusUpdate = async (status: ApiModel.UserStatusEnum) => {
    const confirmed = candidate.status
      ? await confirm({
          title: t('Update Status'),
          message: t(
            'Are you sure you want to update the status from {oldStatus} to {newStatus} for this candidate?',
            userStatusToLabel[candidate.status],
            userStatusToLabel[status],
          ),
          variant: 'question',
        })
      : null;

    if (confirmed) {
      await userUpdateMutation.mutateAsync({
        id: candidate.id,
        patchedB2BUser: { status },
      });
    }
  };

  const currentOrganization =
    getCurrentOrganization<ApiModel.OrganizationNested>(
      candidate.organizations,
    );

  const statusOptions = getCandidateStatusOptionsByOrganization({
    hasAccessToMatching: !!currentOrganization?.feature_matching,
    hasAccessToNavigator: !!currentOrganization?.feature_navigator,
  });

  const hasAccessToMatching =
    features?.matching && permissions.canManageMatching;
  const hasAccessToNavigator =
    features?.navigator && permissions.canManageNavigator;

  // NOTE: For users that has no access to matching or navigator, we want to remove status dropdown
  // unless the user has access to all organizations
  const showStatusDropdown =
    hasAccessToMatching ||
    hasAccessToNavigator ||
    permissions.canViewAllOrganizations;

  const handleStatusChange = (status: ApiModel.UserStatusEnum) => {
    return status === apiEnums.UserStatusEnum.Dis
      ? showUserDisqualifyModal()
      : handleStatusUpdate(status);
  };

  return (
    <CandidateInformationBar
      organizationName={candidate?.organizations[0]?.name || undefined}
      joined={
        candidate?.date_joined ? new Date(candidate?.date_joined) : undefined
      }
      lastLogin={
        candidate?.last_login ? new Date(candidate?.last_login) : undefined
      }
      statusControls={{
        status: candidate.status,
        onStatusUpdate: handleStatusChange,
        statusOptions,
        statusDisabled: !permissions.canEditCandidateDetails,
        showStatusDropdown,
      }}
      supportTeamControls={{
        showSupportTeamUserDropdown,
        usersSupportTeamUser,
        supportTeamUsersOptions,
        canEditSupportStaffDropdown,
        onAssignSupportTeamUser,
      }}
    />
  );
};
