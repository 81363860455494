import { AuthenticateScreen } from '@nodal/core/flows/AuthenticateScreen';
import { ProtectedRoute } from '@nodal/core/flows/ProtectedRoute';
import { Navigate, Route, Routes } from 'react-router-dom';

import { InvalidPaymentLink } from '@b2b/components/ExternalPayment/InvalidPaymentLink';
import { Logout } from '@b2b/components/Logout';
import {
  externalPayerBasePaths as basePaths,
  externalPayerPaths as paths,
} from '@b2b/consts/paths';

import { ExternalPayerAppProtected } from './ExternalPayerAppProtected';

export const ExternalPayerRoute = () => {
  return (
    <Routes>
      <Route
        path={basePaths.authenticate}
        element={
          <AuthenticateScreen
            redirectPaths={{
              app: paths.app,
              unauthenticated: paths.invalidLink,
            }}
          />
        }
      />
      <Route
        path={basePaths.logout}
        element={<Logout redirectPath={paths.invalidLink} />}
      />
      <Route
        path={`${basePaths.invalidLink}/*`}
        element={<InvalidPaymentLink />}
      />
      <Route
        path={`${basePaths.app}/*`}
        element={
          <ProtectedRoute
            redirectPaths={{
              unauthenticated: paths.invalidLink,
            }}
          >
            <ExternalPayerAppProtected />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${basePaths.root}/*`}
        element={
          <ProtectedRoute
            redirectPaths={{
              unauthenticated: paths.invalidLink,
            }}
          >
            <Navigate to={paths.app} />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
