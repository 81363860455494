import type { ChangeEvent, FC } from 'react';

import debounce from 'lodash/debounce';
import { useRef, useEffect, useState } from 'react';

import { Checkbox } from './Checkbox';

import type { CheckboxAsyncProps } from './CheckboxAsync.interface';

export const CheckboxAsync: FC<CheckboxAsyncProps> = ({
  onChange,
  ...props
}) => {
  const [disabled, setDisabled] = useState(props.disabled || false);

  const debouncedOnChange = useRef(
    debounce(
      async (e: ChangeEvent<HTMLInputElement>) => {
        try {
          setDisabled(true);
          await onChange(e.target.checked);
        } finally {
          setDisabled(false);
        }
      },
      500,
      { leading: true, trailing: false },
    ),
  ).current;

  useEffect(
    () => () => {
      debouncedOnChange.cancel();
    },
    [debouncedOnChange],
  );

  return (
    <Checkbox {...props} onChange={debouncedOnChange} disabled={disabled} />
  );
};
