import { ChevronUpIcon } from '@heroicons/react/solid';
import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import get from 'lodash/get';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { convertMinutesToTimeLabel } from 'utils';

import { NodalMarketplaceDashboard } from './NodalMarketplaceDashboard';

import type { StageData } from './NodalMarketplaceDashboard.interface';

const columnHelper = createColumnHelper<StageData>();

const stageKeyToLabel = new Map<StageData['stage'], string>([
  ['application', t('Application')],
  ['id_check', t('Identity Check')],
  ['hipaa_consent_form', t('HIPAA / Consent Forms')],
  ['medical_form', t('Medical Record Form')],
  ['checkr', t('Background Check')],
  ['medical_review', t('Medical Review')],
  ['final_review', t('Final Review')],
  ['matching', t('Matching')],
]);

const columns = [
  columnHelper.accessor('stage', {
    cell: (info) => (
      <span className="font-semibold text-grey-forest-900">
        {stageKeyToLabel.get(info.getValue())}
      </span>
    ),
    header: () => t('Stage'),
  }),
  columnHelper.accessor('donors', {
    cell: (info) => (
      <span className="text-grey-forest-700">{info.getValue()}</span>
    ),
    header: () => t('Surrogates'),
  }),
  columnHelper.accessor('parents', {
    cell: (info) => (
      <span className="text-grey-forest-700">{info.getValue()}</span>
    ),
    header: () => t('Parents'),
  }),
  columnHelper.accessor('avgTime', {
    cell: (info) => (
      <span className="text-grey-forest-700">
        {convertMinutesToTimeLabel(info.getValue())}
      </span>
    ),
    header: () => t('Avg Time'),
  }),
  columnHelper.accessor((row) => row, {
    id: 'outsideAvgCount',
    cell: (info) => (
      <div className="flex relative gap-2 justify-center items-center">
        {info.getValue().outsideAvgUsers.length ? (
          <div className="flex absolute -ml-8 w-max">
            <span className="w-2 h-2 bg-red-600 rounded-full" />
          </div>
        ) : null}

        <span className="text-grey-forest-700">
          {info.getValue().outsideAvgUsers.length}
        </span>
      </div>
    ),
    header: () => t('Outside Avg'),
  }),
  columnHelper.accessor((row) => row, {
    id: 'outsideAvgUsers',
    cell: (info) => {
      return info.getValue().outsideAvgUsers?.length ? (
        <>
          <div className="w-full">
            <div className="flex col-span-2 col-start-3 gap-1 ml-auto sm:gap-8">
              <button onClick={() => info.row.toggleExpanded()} type="button">
                <ChevronUpIcon
                  className={`${
                    !info.row.getIsExpanded() ? 'rotate-180 transform' : ''
                  } h-6 w-6 text-grey-forest-500`}
                />
              </button>
            </div>
          </div>
        </>
      ) : null;
    },
    header: () => '',
  }),
];

export const NodalMarketplaceDashboardConnected = () => {
  const apiClient = useApiClient();

  const { data: dashboardMatching } = useQuery(
    queryKeys.b2bDashboardMatchingRetrieve,
    () => apiClient.api.B2bApi.b2bDashboardMatchingRetrieve(),
  );

  const { stages_data } = dashboardMatching?.data || {};

  const stageOrderKeys: Array<StageData['stage']> = useMemo(
    () => [
      'application',
      'id_check',
      'hipaa_consent_form',
      'medical_form',
      'checkr',
      'medical_review',
      'final_review',
      'matching',
    ],
    [],
  );

  const transformedStagesData: Array<StageData> = useMemo(
    () =>
      stages_data
        ? stageOrderKeys.map((stage) => ({
            stage,
            donors: get(stages_data, stage)?.donors_count ?? 0,
            parents: get(stages_data, stage)?.parents_count ?? 0,
            avgTime: get(stages_data, stage)?.avg_time ?? 0,
            outsideAvgUsers: get(stages_data, stage)?.step_overdue_users || [],
          }))
        : [],
    [stageOrderKeys, stages_data],
  );

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: transformedStagesData,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  if (!dashboardMatching?.data) {
    return <LoadingScreen />;
  }

  const { lifetime_data, matching_data } = dashboardMatching.data;

  const lifetimeDetails = {
    ...lifetime_data,
    med_reviews_completed_count:
      lifetime_data.med_reviews_completed_count +
      lifetime_data.med_reviews_legacy_completed_count,
  };

  return (
    <NodalMarketplaceDashboard
      lifetimeDetails={lifetimeDetails}
      matchingDetails={matching_data}
      stageTable={{
        headerGroups: getHeaderGroups(),
        rowModel: getRowModel(),
      }}
    />
  );
};
