import get from 'lodash/get';
import isArray from 'lodash/isArray';
import uniq from 'lodash/uniq';

import type { Filter, FiltersModel, FilterValue } from './Filters';
import type { QueryParameters } from '@nodal/api';
import type { SortingState } from '@tanstack/react-table';
import type { URLSearchParamsInit } from 'react-router-dom';

// NOTE: We will use [] in url for the keys with array values
// to easily convert url to query params and vice-versa (e.g. role[]=par)
const urlArrayFormat = '[]';

export const transformSorting = (
  selectedSorting: SortingState,
  immutableOrdering?: string[],
): string[] => {
  const ordering = selectedSorting.map((s) => (s.desc ? `-${s.id}` : s.id));
  return immutableOrdering ? [...immutableOrdering, ...ordering] : ordering;
};

export const transformOrdering = (
  selectedOrdering: string[],
  immutableOrdering?: string[],
): SortingState => {
  const completeOrdering = immutableOrdering
    ? [...immutableOrdering, ...selectedOrdering]
    : selectedOrdering;

  return completeOrdering.map((order) => ({
    id: order.replace(/^-/, ''),
    desc: !!order.startsWith('-'),
  }));
};

export const transformUrlQueryToUiFilterQuery = <TFilters extends object>(
  urlQuery: Record<string, string | number | string[]>,
  initialFilters?: Pick<TFilters, keyof TFilters>,
) => {
  const filterKeys = Object.keys(initialFilters || []) as Array<keyof TFilters>;
  const query = Object.entries(urlQuery).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(filterKeys.includes(key as keyof TFilters)
        ? { filters: { ...acc.filters, [key]: value } }
        : { [key]: value }),
    }),
    { filters: initialFilters || {} },
  );

  return query as QueryParameters<TFilters>;
};

export const convertUrlToParsedQuery = (url: string) => {
  const params = new URLSearchParams(url);

  const parsedQuery: Record<string, string | number | string[]> = Array.from(
    params.entries(),
  ).reduce((result, [key, value]) => {
    let decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent(value);

    if (decodedKey.endsWith(urlArrayFormat)) {
      decodedKey = decodedKey.replace(urlArrayFormat, '');

      return {
        ...result,
        // NOTE: Remove duplicate values from an array, ensuring unique values in the URL
        [decodedKey]: uniq([...(get(result, decodedKey) || []), decodedValue]),
      };
    } else {
      const isValidNumber = !isNaN(parseInt(decodedValue));
      return {
        ...result,
        [decodedKey]: isValidNumber ? parseInt(decodedValue) : decodedValue,
      };
    }
  }, {});

  return parsedQuery;
};

export const convertQueryParamsToUrl = <TFilters extends object>(
  queryParameters: QueryParameters<TFilters>,
) => {
  const transformedQueryParams: URLSearchParamsInit = Object.fromEntries(
    Object.entries({
      ...queryParameters,
      ...queryParameters.filters,
      filters: undefined,
    })
      .map(([key, value]) => [key, value])
      .filter(([key, value]) => value && key !== 'filters'),
  );

  const queryString = Object.entries(transformedQueryParams)
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(
          (v) => `${key}${urlArrayFormat}=${encodeURIComponent(v)}`,
        );
      } else {
        return `${key}=${encodeURIComponent(value)}`;
      }
    })
    .join('&');

  return queryString;
};

export const buildFiltersModel = <TFilters extends object>({
  filters,
  query,
  onQueryChange,
}: {
  filters: {
    initialValues: TFilters;
    details: Filter<TFilters>[];
    title: string;
  };
  query: QueryParameters<TFilters>;
  onQueryChange: (query: QueryParameters<TFilters>) => void;
}): FiltersModel<TFilters> => ({
  details: filters.details.map((filter) => {
    const value = get(query.filters, filter.id) as FilterValue | undefined;

    return {
      ...filter,
      value: isArray(value) ? value : value?.toString(),
    };
  }),
  title: filters.title,
  initialValues: filters.initialValues,
  onChange: (filterKey, updatedValue) =>
    onQueryChange({
      ...query,
      page: 1,
      filters: query.filters
        ? {
            ...query.filters,
            [filterKey]: updatedValue,
          }
        : undefined,
    }),
});
