import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { usePermissions } from 'app/PermissionsProvider';
import { convertToIsoStringWithoutOffset } from 'utils';

import { useCandidate } from '@b2b/components/CandidateDetails';

import { Matching } from './Matching';
import { buildIntroductions, buildMatchingDetails } from './utils';

import type { ApiModel } from '@nodal/api';

export const ParentsMatchingConnected = () => {
  const apiClient = useApiClient();
  const { candidate, ipTasks } = useCandidate();
  const queryClient = useQueryClient();
  const { permissions } = usePermissions();

  const hasPermissions = !!permissions?.canViewAndEditManualChecks;

  const { data: usersIntroductionsList } = useQuery(
    queryKeys.usersIntroductionsList,
    () =>
      apiClient.api.UsersApi.usersIntroductionsList({
        userId: candidate!.id,
      }),
    { enabled: !!candidate?.id },
  );

  const { data: profileViewsCount } = useQuery(
    queryKeys.usersProfileViewCountRetrieve,
    () =>
      apiClient.api.UsersApi.usersProfileViewCountRetrieve({
        id: candidate!.id,
      }),
    { enabled: !!candidate?.id },
  );

  const { data: favoritedByList } = useQuery(
    queryKeys.usersFavoritedByList,
    () =>
      apiClient.api.UsersApi.usersFavoritedByList({ userId: candidate!.id }),
    { enabled: !!candidate?.id },
  );

  // TODO change to enum after schema adjustments https://linear.app/nodal-health/issue/NOD-897/be-adjust-schema-for-ip-dashboard
  const runbookCallTask = ipTasks?.find(
    (task) => task.type === 'ip-support-call',
  );

  const runbookCallScheduled =
    runbookCallTask?.content_object &&
    'call_scheduled' in runbookCallTask?.content_object
      ? runbookCallTask?.content_object.call_scheduled
      : undefined;

  const runbookCallDate =
    runbookCallTask?.content_object &&
    'call_date_time' in runbookCallTask?.content_object
      ? runbookCallTask?.content_object.call_date_time
      : undefined;

  const scheduleRunbookCallMutation = useMutation(
    (request: ApiModel.TasksApiTasksStaffSubmitCreateRequest) =>
      apiClient.api.TasksApi.tasksStaffSubmitCreate(request),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          queryKeys.b2bUserTasksList,
          // mutation is called only if there is a candidate
          candidate!.id,
        ]),
    },
  );

  const onChangeRunbookCallScheduled = async (callScheduled: boolean) => {
    if (candidate?.id && runbookCallTask && hasPermissions) {
      await scheduleRunbookCallMutation.mutateAsync({
        id: runbookCallTask.id,
        taskContentObject: {
          id: candidate.id,
          // TODO change to enum after schema adjustments https://linear.app/nodal-health/issue/NOD-897/be-adjust-schema-for-ip-dashboard
          task_type: 'ip-support-call',
          call_scheduled: callScheduled,
        },
      });
    }
  };

  const onChangeRunbookCallDate = async (runbookCallDate: Date) => {
    if (candidate?.id && runbookCallTask && hasPermissions) {
      await scheduleRunbookCallMutation.mutateAsync({
        id: runbookCallTask.id,
        taskContentObject: {
          id: candidate.id,
          // TODO change to enum after schema adjustments https://linear.app/nodal-health/issue/NOD-897/be-adjust-schema-for-ip-dashboard
          task_type: 'ip-support-call',
          call_date_time: runbookCallDate.toISOString(),
        },
      });
    }
  };

  const matchingDetails = buildMatchingDetails({
    favoritesCount: favoritedByList?.data?.length ?? 0,
    profileViewsCount: profileViewsCount?.data?.count ?? 0,
    uniqueProfileViewsCount: profileViewsCount?.data.unique_count ?? 0,
  });

  if (!matchingDetails || !usersIntroductionsList?.data) {
    return null;
  }

  const introductionsDetails = buildIntroductions(
    usersIntroductionsList.data,
    candidate?.role,
  );

  return (
    <Matching
      matchingDetails={matchingDetails}
      introductions={introductionsDetails}
      onChangeRunbookCallDate={onChangeRunbookCallDate}
      onChangeRunbookCallScheduled={onChangeRunbookCallScheduled}
      runbookCallDate={
        runbookCallDate
          ? convertToIsoStringWithoutOffset(runbookCallDate)
          : undefined
      }
      runbookCallScheduled={runbookCallScheduled}
    />
  );
};
