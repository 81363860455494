import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';

import type { ApiModel } from '@nodal/api';

export const stageToTitleMap = new Map<ApiModel.TaskStageEnum, string>([
  [apiEnums.TaskStageEnum.Screening, t('Screening')],
  [apiEnums.TaskStageEnum.MatchProfile, t('Match Profile')],
  [apiEnums.TaskStageEnum.Introduction, t('Introduction')],
  [apiEnums.TaskStageEnum.Match, t('Match')],
]);

export const screeningStageTasksHalfCompleted: ApiModel.Task[] = [
  {
    id: 365,
    title: 'Personal Information',
    uid: 'parents-personal-info-task',
    type: 'parents-profile',
    order: 1,
    user: 61,
    stage: 'screening',
    status: 'complete',
    content_object: {
      id: 29,
      task_type: 'parents-profile',
    },
    completion_date: '2024-03-21T09:17:07.325510Z',
    completion_type: 'linked',
    prerequisite_uids: [],
  },
  {
    id: 366,
    title: 'Nodal Application',
    uid: 'parents-questionnaire-task',
    type: 'parents-questionnaire',
    order: 2,
    user: 61,
    stage: 'screening',
    status: 'complete',
    content_object: {
      id: 29,
      task_type: 'parents-questionnaire',
    },
    completion_date: '2024-03-21T09:17:20.175460Z',
    completion_type: 'linked',
    prerequisite_uids: ['parents-personal-info-task'],
  },
  {
    id: 367,
    title: 'Identity Check',
    uid: 'parents-stripe-identity-task',
    type: 'stripe-identity',
    order: 3,
    user: 61,
    stage: 'screening',
    status: 'pending',
    content_object: {
      id: 29,
      task_type: 'stripe-identity',
    },
    completion_date: null,
    completion_type: 'linked',
    prerequisite_uids: ['parents-questionnaire-task'],
  },
  {
    id: 368,
    title: 'Background Check',
    uid: 'parents-checkr-task',
    type: 'checkr',
    order: 4,
    user: 61,
    stage: 'screening',
    status: 'pending',
    content_object: {
      id: 29,
      task_type: 'checkr',
    },
    completion_date: null,
    completion_type: 'linked',
    prerequisite_uids: ['parents-stripe-identity-task'],
  },
];

export const screeningStageTasksZeroCompleted: ApiModel.Task[] =
  screeningStageTasksHalfCompleted.map((task) => {
    return { ...task, status: apiEnums.TaskStatusEnum.Pending };
  });

export const screeningStageTasksAllCompleted: ApiModel.Task[] =
  screeningStageTasksHalfCompleted.map((task) => {
    return { ...task, status: apiEnums.TaskStatusEnum.Complete };
  });
