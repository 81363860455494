import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { t } from '@nodal/i18n';
import { createColumnHelper } from '@tanstack/react-table';
import { useCallback } from 'react';

import { useSearchFilterTable } from '@b2b/components/SearchFilterTable';
import { orgUserRoleKeys } from '@b2b/consts/organizationRoles';

import { useOrganizationDetails } from '../OrganizationDetails.connect';

import { OrganizationUsers } from './OrganizationUsers';

import type { ApiModel, QueryParameters } from '@nodal/api';

const columnHelper = createColumnHelper<ApiModel.B2BUserNonDetailedList>();

const columns = [
  columnHelper.accessor((row) => `${row.first_name} ${row.last_name}`, {
    id: 'search_name_display',
    cell: (info) => (
      <span className="text-grey-forest-700">{info.getValue()}</span>
    ),
    header: () => t('Name'),
  }),
  columnHelper.accessor((row) => row.email, {
    id: 'email',
    header: () => t('Email'),
    cell: (info) => info.renderValue(),
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.title, {
    id: 'title',
    header: () => t('Title'),
    cell: (info) => info.renderValue(),
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.id, {
    id: 'action',
    cell: () => '',
    header: () => '',
    enableSorting: false,
  }),
];

export const OrganizationUsersConnected = () => {
  const { organization } = useOrganizationDetails();
  const apiClient = useApiClient();

  const initialQueryParameters: QueryParameters<ApiModel.B2bApiB2bUsersNonDetailedListRequest> =
    {
      page: 1,
      pageSize: 10,
      ordering: ['-date_joined'],
      search: '',
      filters: {
        organizationId: organization.id,
        role: orgUserRoleKeys,
      },
    };

  const queryCallback = (
    query: QueryParameters<ApiModel.B2bApiB2bUsersNonDetailedListRequest>,
  ) =>
    apiClient.api.B2bApi.b2bUsersNonDetailedList({
      ...query.filters,
      ...query,
      searchNameDisplay: query.search,
      // NOTE: Use casting because ordering type (string[]) in QueryParameters
      // is less specific than in the API Schema
    } as ApiModel.B2bApiB2bUsersNonDetailedListRequest);

  const table = useSearchFilterTable<
    ApiModel.B2BUserNonDetailedList,
    ApiModel.B2bApiB2bUsersNonDetailedListRequest
  >({
    initialQuery: initialQueryParameters,
    columns,
    queryKey: queryKeys.usersNonDetailedList,
    queryCallback,
    urlSearchParamsEnabled: false,
  });

  const onPageChange = useCallback(
    (page: number) =>
      table.onQueryChange({
        ...table.query,
        page,
      }),
    [table],
  );

  return (
    <OrganizationUsers
      page={table.query.page}
      onPageChange={onPageChange}
      {...table}
    />
  );
};
