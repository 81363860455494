import type { FC } from 'react';

import { Popover } from '@headlessui/react';

import { DesktopNav } from './DesktopNav/DesktopNav';
import { MobileNav } from './MobileNav';

import type { TopNavProps } from './TopNav.interface';

export const TopNav: FC<TopNavProps> = ({
  user,
  menuItems,
  navigationItems,
}) => {
  return (
    <Popover
      as="nav"
      className="px-0 w-full bg-forest-500 sm:px-4 md:px-6 lg:px-8"
    >
      {({ open, close }) => (
        <>
          <div className="hidden sm:block">
            <DesktopNav
              menuItems={menuItems}
              navigationItems={navigationItems}
              user={user}
            />
          </div>
          <div className="block sm:hidden">
            <MobileNav
              open={open}
              menuItems={menuItems}
              navigationItems={navigationItems}
              user={user}
              onClose={close}
            />
          </div>
          {open && (
            <Popover.Overlay className="block fixed w-full h-full bg-gray-500/75 sm:hidden" />
          )}
        </>
      )}
    </Popover>
  );
};
