import type { FC } from 'react';

import { t } from '@nodal/i18n';

import { NodalShapesLogo } from 'assets';

import type { ScreeningCompleteProps } from './ScreeningComplete.interface';

export const ScreeningComplete: FC<ScreeningCompleteProps> = ({
  organizationName,
}) => (
  <div className="flex flex-col grid-cols-8 col-span-10 col-start-2 gap-x-8 justify-center items-center py-16 px-4 m-auto bg-transparent rounded-lg sm:grid sm:justify-center sm:px-0 lg:grid-cols-10">
    <div className="flex col-span-6 col-start-2 row-start-1 justify-center items-center w-full lg:col-start-3">
      <img src={NodalShapesLogo} className="object-cover w-20 h-20" />
    </div>
    <h1 className="col-span-6 col-start-2 row-start-2 pt-12 m-auto w-max text-2xl font-semibold leading-8 text-center text-grey-forest-900 sm:text-3xl sm:font-bold sm:leading-9 lg:col-start-3">
      {t('Application complete')}
    </h1>
    <p className="col-span-6 col-start-2 row-start-3 pt-4 w-full text-base font-normal leading-6 text-center text-grey-forest-700 sm:text-lg lg:col-start-3">
      {t(
        'Thank you for completing the Nodal screening. {organizationName} has received your information. Their team will be in touch with you soon.',
        organizationName,
      )}
    </p>
  </div>
);
