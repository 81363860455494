import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';

import { useExternalPayerApp } from '@b2b/app/ExternalPayerAppProtected';
import { useContractForm } from '@b2b/components/ContractForm/useContractForm';
import { settings } from '@b2b/settings';

import { usePaymentRequest } from '../PaymentRequest.connect';

import { PaymentContract } from './PaymentContract';

const clientId = settings.getHelloSignAppId();

export const PaymentContractConnected = () => {
  const { request, user } = useExternalPayerApp();
  const { refresh } = usePaymentRequest();

  const contractDocument = request?.screening_payment?.contract;

  const contractForm = useContractForm({
    submit: refresh,
    userId: user?.id,
    clientId,
    documentId: contractDocument?.id,
  });

  if (!contractForm) {
    return <LoadingScreen />;
  }

  const { contractSignRef, signed } = contractForm;

  return <PaymentContract ref={contractSignRef} signed={signed} />;
};
